import { Row } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #f5f5f5;
  width: 100%;
  min-height: 100%;
  border-radius: 5px;
  padding: 16px;

  .title {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 16px 10px;
    margin-bottom: 16px;

    h4 {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.blue};
      margin-bottom: 0;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label {
    padding: 0;
    label {
      color: ${({ theme }) => theme.colors.gray_light};
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      &:not([for='active']) {
        height: auto;
      }
    }
  }

  .ant-form-item-control-input {
    input {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 5px;
      color: ${({ theme }) => theme.colors.title};
      line-height: 16px;
      border-radius: 3px;
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 8px;
    flex: 1 0 33.33%;
    max-width: 33.33%;

    span {
      &.ant-checkbox {
        top: auto;
        height: auto;
      }

      span {
        display: flex;
        gap: 7px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.gray_light};
      }
    }
  }

  .ant-input-number-group-addon {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.gray_light};
    font-size: 14px;
  }

  #frequency {
    border-radius: 3px;
    text-align: center;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
