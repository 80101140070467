import { ThemeConfig } from 'antd';
import theme from './theme';

export const antTheme: ThemeConfig = {
  token: {
    fontFamily: 'AcerFoco',
    colorPrimary: theme.colorsDesignSystem.primary,
    colorIcon: theme.colorsDesignSystem.primary,
    colorTextTertiary: theme.colorsDesignSystem.primary,
  },
  components: {
    Select: {
      controlItemBgActive: theme.colorsDesignSystem.primary,
    },
  },
};
