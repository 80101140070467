import styled from 'styled-components';
import { Row as AntdRow, Button, Col } from 'antd';

export const Container = styled.div`
  height: 100vh;
`;

export const Main = styled(AntdRow)`
  height: 100vh;
  justify-content: space-between;
`;

export const ButtonLogin = styled(Button)`
  background: ${({ theme }) => theme.colors.link};
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: 500;

  &:hover,
  &:focus {
    filter: brightness(0.9);
    background: ${({ theme }) => theme.colors.link};
  }
`;

export const ButtonLink = styled(Button)`
  padding: 16px;
  color: ${({ theme }) => theme.colors.link};

  &:hover,
  &:focus {
    filter: brightness(0.9);
    color: ${({ theme }) => theme.colors.link};
  }
`;

export const FormCol = styled(Col)`
  align-items: center;
  padding: 32px;

  .ant-form {
    margin-top: 128px;
  }
`;

export const LogoHeader = styled.header`
  align-items: self-start;
  display: flex;
  flex-flow: column wrap;

  span {
    font-size: 18px;
  }
`;

export const LogoAcerImg = styled.img`
  height: 32px;
  margin-right: 16px;
  padding: 4px 0;
`;

export const BackgroundImg = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    background: url('https://dtwxl6rxzzeui.cloudfront.net/static/media/login-bg.83c387e1.jpg')
      center center / cover rgb(76, 175, 80);
    display: block;
    height: 100vh;
    width: 65%;
    ::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.44), rgba(128, 195, 67, 0.68));
      content: ' ';
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
    }
  }
`;
