/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { ResponsiveContainer, Bar, BarChart, XAxis, YAxis, Tooltip, LabelList } from 'recharts';
import { useCallback } from 'react';
import { Divider } from 'antd';
import * as S from './styles';

type GraphDataType =
  | {
      name: string;
      fob: number;
      fob_percentage: number;
      br: number;
      br_percentage: number;
    }
  | {
      name: string;
      occurred: number;
      occurredPercentage: number;
      forecast: number;
      forecastPercentage: number;
    };

type VerticalBarChartProps = {
  data: GraphDataType[];
  type: 'specifications' | 'SKU';
};

type Payload = GraphDataType & {
  fob_label?: string;
  br_label?: string;
  ocurred_label?: string;
  forecast_label?: string;
};

type TooltipPayload = {
  color: string;
  fill: string;
  name: string;
  dataKey: string;
  payload: Payload;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
};

const VerticalBarChart = ({ data, type }: VerticalBarChartProps) => {
  const FIRST_BAR_COLOR = type === 'specifications' ? '#8BB7F9' : '#F79E4F';
  const SECOND_BAR_COLOR = type === 'specifications' ? '#FFC600' : '#999';

  function formatDecimalPlaces(number: number) {
    return Math.round(number * 100) / 100;
  }

  function formatData(graphData: GraphDataType[]) {
    const firstBarPercentage = type === 'specifications' ? 'fob_percentage' : 'occurredPercentage';
    const secondBarPercentage = type === 'specifications' ? 'br_percentage' : 'forecastPercentage';

    const firstBarLabelKey = type === 'specifications' ? 'fob_label' : 'ocurredLabel';
    const secondBarLabelKey = type === 'specifications' ? 'br_label' : 'forecastLabel';

    return graphData.map((item) => {
      return {
        ...item,
        [firstBarPercentage]: formatDecimalPlaces(
          Number(item[firstBarPercentage as keyof GraphDataType])
        ),
        [secondBarPercentage]: formatDecimalPlaces(
          Number(item[secondBarPercentage as keyof GraphDataType])
        ),
        [firstBarLabelKey]: `${formatDecimalPlaces(
          Number(item[firstBarPercentage as keyof GraphDataType])
        )}%`,
        [secondBarLabelKey]: `${formatDecimalPlaces(
          Number(item[secondBarPercentage as keyof GraphDataType])
        )}%`,
      };
    });
  }

  const CustomTooltip = useCallback(({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <S.TooltipContainer>
          <p>{label}</p>
          <Divider style={{ margin: 0 }} />
          <S.TooltipList>
            {payload.map((item, index) => {
              let key =
                type === 'specifications' ? item.dataKey.split('_')[0] : item.dataKey.split('P')[0]; // split _ for snake_case and P for camelCase (occurredPercentage/forecastPercentage)
              const isEven = index % 2 === 0;

              if (key === 'ocurred') {
                key = 'occurred';
              }

              return (
                <S.TooltipItem
                  key={crypto.randomUUID()}
                  color={item.fill}
                  divider={index !== 0 && isEven}
                >
                  {key}: {item.payload[key as keyof Payload]}
                </S.TooltipItem>
              );
            })}
          </S.TooltipList>
        </S.TooltipContainer>
      );
    }
    return null;
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={formatData(data)}
        margin={{
          left: 60,
          right: 45,
        }}
      >
        <XAxis type="number" style={{ display: 'none' }} />
        <YAxis dataKey="name" type="category" />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey={type === 'specifications' ? 'fob_percentage' : 'occurredPercentage'}
          fill={FIRST_BAR_COLOR}
        >
          <LabelList
            dataKey={type === 'specifications' ? 'fob_label' : 'ocurredLabel'}
            position="right"
          />
        </Bar>
        <Bar
          dataKey={type === 'specifications' ? 'br_percentage' : 'forecastPercentage'}
          fill={SECOND_BAR_COLOR}
        >
          <LabelList
            dataKey={type === 'specifications' ? 'br_label' : 'forecastLabel'}
            position="right"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export { VerticalBarChart };
