import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTagFOB = styled(Tag)`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlueBackground};
  border-radius: 0.25rem !important;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
  .text {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
    font-weight: 600;
  }
`;
export const StyledTagBR = styled(Tag)`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tagBackgroundColorGreen};
  border-radius: 0.25rem !important;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tagColorGreen};
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorGreen};
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorGreen};
    font-weight: 600;
  }
`;
export const StyledTagNA = styled(Tag)`
  background-color: ${({ theme }) => theme.colorsDesignSystem.gray_background};
  border-radius: 0.25rem !important;
  border-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderColor};
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorText};
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorText};
    font-weight: 600;
  }
`;

export const StyledTagQG = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
  }
`;

export const StyledTagQD = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const StyledTagQF = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.red};
  }
`;
export const StyledTagQC = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
`;
export const StyledTagNQ = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.red};
  }
`;

export const StyledTagNP = styled(Tag)`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  .text {
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: 15px;
    font-weight: 400;
  }
  .icon {
    font-size: 15px;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;
