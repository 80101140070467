import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -1.063rem;
`;

export const Title = styled.strong`
  font-size: ${({ theme }) => theme.dimensions.font_title};
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-weight: 600;
`;

export const InfoContainer = styled.div`
  margin-top: 0.5rem;
  padding: 1.25rem;
  width: 100%;
  height: 7.688rem;
  background-color: #f6f8fa;
  border-radius: ${({ theme }) => theme.dimensions.border_radius_medium};
  border: 1px solid #dce0e5;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.438rem;
`;

export const Labels = styled.p`
  font-size: ${({ theme }) => theme.dimensions.font_regular};
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin: 0;
`;

export const LabelsFields = styled.p`
  font-size: ${({ theme }) => theme.dimensions.font_regular};
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  min-width: 3.375rem;
  margin: 0;
`;

export const TextContent = styled.p`
  color: ${({ theme }) => theme.colors.title};
  font-weight: 700;
  max-width: 31.5rem;
  margin: 0;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const ContainerComponents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
`;

export const ContainerItemComponents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: flex-end;
  justify-content: center;

  p {
    width: 20%;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e8ec;
`;
