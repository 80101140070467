import styled from 'styled-components';

export const Container = styled.main`
  background-attachment: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  padding: 3.063rem 1.125rem 0 1.375rem;
  margin: 0.5rem;
  overflow-x: scroll;
  padding-bottom: 4rem;
  box-sizing: content-box;
  .pageTitle {
    margin-bottom: 1rem;
  }
`;

export const TitleConponentsPrice = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
`;
