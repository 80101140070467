/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { RangeValue } from './types';
import * as S from './styles';
import StyledTabs from '../../components/Common/StyledTabs';
import { StyledTab } from '../../components/Common/StyledTabs/types';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledSearchInput from '../../components/Common/StyledSearchInput';
import StyledRangePiker from '../../components/Common/StyledRangePicker';
import StyledButton from '../../components/Common/StyledButton';
// import { usePricesComponents } from './usePricesComponents';
import { DataPriceComponents } from '../../mocks/dataPriceComponents';
import StyledInputAutoComplete from '../../components/Common/StyledInputAutoComplete';
import { PricesPage } from './PricePage';
import { usePricesPage } from './PricePage/usePricesPage';
import { PackingPricePage } from './PackingPricePage';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function ComponentsPricesPage() {
  const { t } = useTranslation();

  useDocumentTitle(t('pages.componentsPrices.Title'));

  const [tabs, setTabs] = useState<StyledTab[]>([
    { selected: true, title: t('pages.componentsPrices.tabTitles.prices') },
    { selected: false, title: t('pages.componentsPrices.tabTitles.packing') },
  ]);

  return (
    <S.Container>
      <div className="pageTitle">
        <S.TitleConponentsPrice>{t('pages.componentsPrices.Title')}</S.TitleConponentsPrice>
      </div>
      <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      {tabs[0].selected && <PricesPage />}
      {tabs[1].selected && <PackingPricePage />}
    </S.Container>
  );
}
