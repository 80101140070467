import { Col, ConfigProvider, Row, Form } from 'antd';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownload, BiPlus } from 'react-icons/bi';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { useComponents } from './useComponents';
import { EditableCell } from '../../components/EditableCell';
import { CustomPagination } from '../../components/CustomPagination';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import * as SC from '../../styles/common';
import { CsvExport } from '../../components/Common/CsvExport';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';

export function ComponentsPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.components.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    fetchComponents,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    handleCloseFilteredTag,
    mergedColumns,
    exportColumns,
    transformData,
    getQueryParams,
    expandedRowKeys,
    setExpandedRowKeys,
  } = useComponents();

  const modifiedDate = (dataModal && dataModal.modified) || data[0]?.lastUpdateDateFile;

  const showTagsFilter =
    params.header_partType?.length ||
    params.header_spec?.length ||
    params.header_partNumber?.length ||
    params.header_description?.length ||
    params.header_local?.length;

  useEffect(() => {
    fetchComponents();
  }, [params]);

  const expandRowIds = (expanded, record) => {
    const keys = [];

    if (record.partNumber) return; // if is last level, do nothing

    if (record.partType && expanded) {
      keys.push(record.id);
    }

    if (record.spec) {
      const splitId = record.id.split('$');
      const partTypeId = `${splitId[0]}$${splitId[1]}`;

      if (expanded) {
        keys.push(partTypeId, record.id);
      } else {
        keys.push(partTypeId);
      }
    }

    setExpandedRowKeys(keys);
  };

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.components.title')} updated={modifiedDate} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      buttonText={`${t('common.add')} ${t('common.item')}`}
                      icon={<BiPlus />}
                      key={1}
                      onClick={handleAddItem}
                      disabled={isNewItem}
                    />,
                    <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
                      <CsvExport
                        url={`components/tree?${getQueryParams()}`}
                        filename={['components']}
                        limit={pageControl.totalElements}
                        headers={exportColumns}
                        transformData={transformData}
                      />
                    </ActionButton>,
                  ]}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter ? (
                    <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>
                  ) : null}
                  <SC.TagFilter
                    closable
                    visible={params.header_partType?.length}
                    onClose={() => handleCloseFilteredTag('header_partType')}
                  >
                    <div className="tag-title">{t('pages.components.fields.partType')}</div>
                    <div className="tag-value">{params.header_partType}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_spec?.length}
                    onClose={() => handleCloseFilteredTag('header_spec')}
                  >
                    <div className="tag-title">{t('pages.components.fields.spec')}</div>
                    <div className="tag-value">{params.header_spec}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_partNumber?.length}
                    onClose={() => handleCloseFilteredTag('header_partNumber')}
                  >
                    <div className="tag-title">{t('pages.components.fields.partNumber')}</div>
                    <div className="tag-value">{params.header_partNumber}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_description?.length}
                    onClose={() => handleCloseFilteredTag('header_description')}
                  >
                    <div className="tag-title">{t('pages.components.fields.description')}</div>
                    <div className="tag-value">{params.header_description}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_local?.length}
                    onClose={() => handleCloseFilteredTag('header_local')}
                  >
                    <div className="tag-title">{t('pages.components.fields.local')}</div>
                    <div className="tag-value">{params.header_local}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      expandRowByClick
                      defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                      expandable={{ expandedRowKeys, indentSize: 0 }}
                      onExpand={expandRowIds}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
