import { SKUAlertData } from '../pages/Alert/SKUAlert/types';
import { Alert } from '../pages/Alert/types';

export const dataSimulationMock = {
  id: 'ID-1',
  notes: null, //null || string || [string]
  deprecated: false,
  totalCost: 999999999.99,
  currentScore: '3.9',
  partTypeMaxScore: '3.5',
  maxScore: '4.0',
  simulationPartTypes: [
    {
      id: 'ID-2',
      partType: 'ADAPTER',
      currentScore: '3.9',
      partTypeMaxScore: '3.5',
      maxScore: '4.0',
      totalCost: 999999999.99,
      totalGap: 999999999.99,
      costPerPoint: '1',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: 'ADAPTER-january-ID',
          month: 'january',
          baseForecast: '999.999',
          userForecast: '999.999',
          kcFob: '888.888',
          kcBr: '999.999',
          ppb: '99.99',
          currentScore: '3.9',
          totalGap: 999999999.99,
          totalCost: 999999999.99,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: 'ADAPTER-february-ID',
          month: 'february',
          baseForecast: '999.999',
          userForecast: '999.999',
          kcFob: '888.888',
          kcBr: '999.999',
          ppb: '99.99',
          currentScore: '3.9',
          totalGap: 999999999.99,
          totalCost: 999999999.99,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
  ],
};

export const dataSimulationModal = [
  {
    id: 'ID-1',
    partType: 'ADAPTER',
    parentMaxScore: '3.0',
    currentScore: '3.5',
    maxScore: '4.0',
    investmentPoints: 999999999.99,
    kcFob: 999999999.99,
    kcBr: 999999999.99,
    kcTotal: 999999999.99,
    totalGap: 999999999.99,
    totalCost: 999999999.99,
  },
];

export const dataAlerts: Alert[] = [
  {
    id: 'testealerta',
    alertName: "SKU's sem motherboard",
    description: "Geração de alertas para SKU's sem motherboard",
    active: false,
    updatedAt: '',
    createdAt: '',
    emailMsg: '',
    frequency: 0,
    contacts: [],
  },
];

export const dataSKUAlert: SKUAlertData = {
  content: [
    {
      id: '19286391687',
      sku: 'NX.KEZAL.002',
      projectName: 'Callisto_ADU',
      modelName: 'A315-59-35DA',
    },
    {
      id: '19286391688',
      sku: 'NX.KEZAL.001',
      projectName: 'Callisto_ADU',
      modelName: 'A315-59-34M4',
    },
    {
      id: '19286391689',
      sku: 'NX.KACAL.004',
      projectName: 'Sake_TL',
      modelName: 'SF314-511-55CK',
    },
  ],
  scenario: 'Cenário Base - 2023',
  modifiedAt: '05/04/2023 - 00:10',
};

export const dataSpot = [
  {
    id: '123456',
    partTypes: [
      {
        currentScore: '12',
        maxScore: '2',
        id: '321456',
        name: 'Memory Modules (4GB/8GB/16GB)',
        stampDateTime: '2023-03-27T18:07:11.913Z',
        updateDateTime: '2023-03-27T18:07:11.913Z',
        local: '00.00',
        import: '00.00',
      },
      {
        currentScore: '0',
        maxScore: '3',
        id: '32145641',
        name: 'Ac_Adapter 45W',
        stampDateTime: '2023-03-27T18:07:11.913Z',
        updateDateTime: '2023-03-27T18:07:11.913Z',
        local: '00.00',
        import: '00.00',
      },
      {
        currentScore: '0',
        maxScore: '1',
        id: '321456411',
        name: 'Ac_Adapter 65W',
        stampDateTime: '2023-03-27T18:07:11.913Z',
        updateDateTime: '2023-03-27T18:07:11.913Z',
        local: '00.00',
        import: '00.00',
      },
    ],
    stampDateTime: '2023-03-27T18:07:11.913Z',
    updateDateTime: '2023-03-27T18:07:11.913Z',
    currentScore: '45',
  },
];

export const dataBomV1 = [
  {
    id: '63f2b7859ffce57dee8b13b1$MEM',
    partType: 'MEM',
    children: [
      {
        id: '63f2b7859ffce57dee8b13b1$MEM$TWVtb3J5ICg4R0Ip',
        groupName: 'Memory (8GB)',
        children: [
          {
            id: '63f2b7859ffce57dee8b13b1$MEM$TWVtb3J5ICg4R0Ip$CM8GBIV',
            spec: 'CM8GBIV',
            children: null,
            items: [
              {
                partNumber: '1321354',
                supplier: 'Acer',
              },
              {
                partNumber: '13213541',
                supplier: 'None',
              },
            ],
          },
        ],
      },
      {
        id: '63f2b7859ffce57dee8b13b1$MEM$TWVtb3J5ICg4R0IsIDE2R0Ip',
        groupName: 'Memory (8GB, 16GB)',
        children: [
          {
            id: '63f2b7859ffce57dee8b13b1$MEM$TWVtb3J5ICg4R0IsIDE2R0Ip$SO16GBIV',
            spec: 'SO16GBIV',
            children: null,
            items: [
              {
                partNumber: '1321354',
                supplier: 'Acer',
              },
              {
                partNumber: '13213541',
                supplier: 'None',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const dataBomV2 = [
  {
    id: 'NH.QD4AL.006',
    sku: 'NH.QD4AL.006',
    modelName: 'AN515-55-59T4',
    project: 'Stonic_CMS',
    children: [
      {
        id: 'NH.QD4AL.006$ADAPTER$135W_5.5PHY-SLIM_BR$KP.135B1.001',
        className: 'partType',
        spec: '135W_5.5PHY-SLIM_BR',
        amount: 1,
        partType: 'ADAPTER',
        group: '0001',
        altGroup: '_',
        level: 2,
        partNumber: 'KP.135B1.001',
        description: 'AC ADAP ADP-135NB BA 135W 3P',
        local: '1',
        children: null,
      },
      {
        id: 'NH.QD4AL.006$SSD$F80512PM_BR$KN.512BD.003',
        className: 'partType',
        spec: 'F80512PM_BR',
        amount: 1,
        partType: 'SSD',
        group: '0001',
        altGroup: '_',
        level: 2,
        partNumber: 'KN.512BD.003',
        description: 'SSD_M 512G SDBPNPZ-512G-1014',
        local: '1',
        children: [
          {
            id: 'NH.QD4AL.006$SSD$F80512PM_BR$KN.512BG.030',
            spec: 'F80512PM_BR',
            amount: 1,
            partType: 'SSD',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.512BG.030',
            description: 'Flash Disk HYNIX SSD NAND 512GB M.2 2280',
            local: '1',
            partTypeId: 'NH.QD4AL.006$SSD$F80512PM_BR$KN.512BD.003',
            children: null,
          },
          {
            id: 'NH.QD4AL.006$SSD$F80512PM_BR$KN.512BB.004',
            spec: 'F80512PM_BR',
            amount: 1,
            partType: 'SSD',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.512BB.004',
            description: 'Flash Disk SAMSUNG SSD NAND 512GB M.2 22',
            local: '1',
            partTypeId: 'NH.QD4AL.006$SSD$F80512PM_BR$KN.512BD.003',
            children: null,
          },
        ],
      },
      {
        id: 'NH.QD4AL.006$BATTERY$4CELL3.72_BR$KT.004B7.003',
        className: 'partType',
        spec: '4CELL3.72_BR',
        amount: 1,
        partType: 'BATTERY',
        group: '0002',
        altGroup: '1',
        level: 2,
        partNumber: 'KT.004B7.003',
        description: 'BATT SP SM 4S1P 3.815AH 7 4.23 2K6',
        local: '1',
        children: [
          {
            id: 'NH.QD4AL.006$BATTERY$4CELL3.57_BR$KT.004BG.008',
            spec: '4CELL3.57_BR',
            amount: 1,
            partType: 'BATTERY',
            group: '0004',
            altGroup: '1',
            level: 2,
            partNumber: 'KT.004BG.008',
            description: 'BATT LG LG 4S1P 3.73AH 7 2K1',
            local: '1',
            partTypeId: 'NH.QD4AL.006$BATTERY$4CELL3.72_BR$KT.004B7.003',
            children: null,
          },
        ],
      },
      {
        id: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
        className: 'partType',
        spec: 'SO8GBIV',
        amount: 1,
        partType: 'MEM',
        group: '0001',
        altGroup: '_',
        level: 2,
        partNumber: 'KN.8GB0G.069',
        description: 'Memory HYNIX SO-DIMM DDRIV 3200 8GB HMA8',
        local: '1',
        children: [
          {
            id: 'NH.QD4AL.006$MEM$SO8GBIV_BR$KN.8GBBC.010',
            spec: 'SO8GBIV_BR',
            amount: 1,
            partType: 'MEM',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.8GBBC.010',
            description: 'DDR4 MODU AD4S320038G22-B 8G/3200 ABOBR',
            local: '4',
            partTypeId: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
            children: null,
          },
          {
            id: 'NH.QD4AL.006$MEM$SO8GBIV_BR$KN.8GBBS.011',
            spec: 'SO8GBIV_BR',
            amount: 1,
            partType: 'MEM',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.8GBBS.011',
            description: 'Memory SMART SO-DIMM DDRIV 3200 8GB SMS4',
            local: '4',
            partTypeId: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
            children: null,
          },
          {
            id: 'NH.QD4AL.006$MEM$SO8GBIV_BR$KN.8GBBC.013',
            spec: 'SO8GBIV_BR',
            amount: 1,
            partType: 'MEM',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.8GBBC.013',
            description: 'Memory A-DATA SO-DIMM DDRIV 3200 8GB AD4',
            local: '4',
            partTypeId: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
            children: null,
          },
          {
            id: 'NH.QD4AL.006$MEM$SO8GBIV_BR$KN.8GBB4.008',
            spec: 'SO8GBIV_BR',
            amount: 1,
            partType: 'MEM',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.8GBB4.008',
            description: 'Memory MICRON SO-DIMM DDRIV 3200 8GB MTA',
            local: '1',
            partTypeId: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
            children: null,
          },
          {
            id: 'NH.QD4AL.006$MEM$SO8GBIV_BR$KN.8GBBG.033',
            spec: 'SO8GBIV_BR',
            amount: 1,
            partType: 'MEM',
            group: '0001',
            altGroup: '_',
            level: 2,
            partNumber: 'KN.8GBBG.033',
            description: 'DDR4 MODU HMA81GS6DJR8N-XN 8G ABOBR !',
            local: '1',
            partTypeId: 'NH.QD4AL.006$MEM$SO8GBIV$KN.8GB0G.069',
            children: null,
          },
        ],
      },
      {
        id: 'NH.QD4AL.006$WIRELESS LAN$INTAX201.NGWG.NV_AX_W/BT 5.0 2230_BR$KE.11X0N.010',
        className: 'partType',
        spec: 'INTAX201.NGWG.NV_AX_W_BT 5.0 2230_BR',
        amount: 1,
        partType: 'WIRELESS LAN',
        group: '0001',
        altGroup: '_',
        level: 2,
        partNumber: 'KE.11X0N.010',
        description: 'Wireless LAN Intel 802.11ax Bluetooth FM',
        local: '1',
        children: null,
      },
    ],
  },
];

export const Forecast = [
  {
    id: '639148c2755a0b69075d37c9',
    sku: 'DG.E2QAL.005',
    modelName: 'PO5-620-BR12',
    project: 'MC20',
    children: [
      {
        id: '1234_ID_UNICO',
        january: null,
        february: {
          amount: 149,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.904',
          updateDateTime: null,
        },
        march: null,
        april: {
          amount: 199,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.904',
          updateDateTime: null,
        },
        may: null,
        june: {
          amount: 3,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.905',
          updateDateTime: null,
        },
        july: {
          amount: 79,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.905',
          updateDateTime: null,
        },
        august: {
          amount: 100,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.905',
          updateDateTime: null,
        },
        september: {
          amount: 30,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.907',
          updateDateTime: null,
        },
        october: {
          amount: 171,
          edited: false,
          locked: true,
          stampDateTime: '2022-12-07T05:37:59.906',
          updateDateTime: null,
        },
        november: null,
        december: null,
        stampDateTime: null,
        updateDateTime: '2022-12-07T05:36:33.986',
      },
    ],
  },
];

export const Forecast2 = [
  {
    id: '639148c2755a0b69075d3798',
    sku: 'DC.11211.02R',
    january: null,
    february: null,
    march: null,
    april: null,
    may: null,
    june: null,
    july: null,
    august: null,
    september: null,
    october: null,
    november: null,
    december: null,
    stampDateTime: null,
    updateDateTime: '2022-12-07T05:36:33.911',
    modelName: null,
    project: null,
  },
  {
    id: '639148c2755a0b69075d37ca',
    sku: 'DG.E2QAL.004',
    january: null,
    february: {
      amount: 48,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.904',
      updateDateTime: null,
    },
    march: {
      amount: 91,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.904',
      updateDateTime: null,
    },
    april: null,
    may: null,
    june: null,
    july: {
      amount: 89,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.905',
      updateDateTime: null,
    },
    august: {
      amount: 90,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.907',
      updateDateTime: null,
    },
    september: {
      amount: 204,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.905',
      updateDateTime: null,
    },
    october: {
      amount: 55,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.906',
      updateDateTime: null,
    },
    november: null,
    december: {
      amount: 50,
      edited: false,
      locked: true,
      stampDateTime: null,
      updateDateTime: '2022-12-01T18:49:23.258',
    },
    stampDateTime: null,
    updateDateTime: '2022-12-07T05:36:33.989',
    modelName: null,
    project: null,
  },
  {
    id: '639148c2755a0b69075d37c9',
    sku: 'DG.E2QAL.005',
    january: null,
    february: {
      amount: 149,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.904',
      updateDateTime: null,
    },
    march: null,
    april: {
      amount: 199,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.904',
      updateDateTime: null,
    },
    may: null,
    june: {
      amount: 3,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.905',
      updateDateTime: null,
    },
    july: {
      amount: 79,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.905',
      updateDateTime: null,
    },
    august: {
      amount: 100,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.905',
      updateDateTime: null,
    },
    september: {
      amount: 30,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.907',
      updateDateTime: null,
    },
    october: {
      amount: 171,
      edited: false,
      locked: true,
      stampDateTime: '2022-12-07T05:37:59.906',
      updateDateTime: null,
    },
    november: null,
    december: null,
    stampDateTime: null,
    updateDateTime: '2022-12-07T05:36:33.986',
    modelName: 'PO5-620-BR12',
    project: 'MC20',
  },
];

export const scenarioReport = [
  {
    id: 'Battery$4CELL3.57_BR$KN.8GBBS.008',
    sku: 'NH.QD4AL.006',
    partType: 'MEM',
    spec: 'SO8GBIV',
    partNumber: 'KN.8GB0G.069',
    componentAmount: 1,
    local: 1,
  },
];

export const dataSettings = [
  {
    id: 'Battery$4CELL3.57_BR$KN.8GBBS.008',
    partType: 'Battery',
    spec: '4CELL3.57_BR',
    partNumber: 'KN.8GBBS.008',
    description: 'Bateria XPTO',
    supplier: 'ACER',
    amount: 10,
  },
  {
    id: 'Battery$4CELL3.57_BR$KN.8GBBS.009',
    partType: 'Battery 2',
    spec: '4CELL3.57_BR',
    partNumber: 'KN.8GBBS.008',
    description: 'Bateria XPTO',
    supplier: 'ADATA',
    amount: 20,
  },
];

export const dataLogUsers = [
  {
    id: 'id',
    modifiedDate: '2022-11-26T04:34:17.956578',
    user: 'user@acer.com',
    area: 'Scenarios',
    message: 'Log Message Scenarios',
  },
  {
    id: 'id2',
    modifiedDate: '2022-10-16T16:34:17.956578',
    user: 'ppb@acer.com',
    area: 'BOM',
    message: 'Log Message BOM',
  },
];

export const dataFileErrors = [
  {
    message: 'message success',
    file: 'file',
    id: 'id',
    status: 'success',
    modifiedDate: new Date(),
  },
  {
    message: 'message error',
    file: 'file',
    id: 'id2',
    status: 'error',
    modifiedDate: new Date(),
  },
];

export const dataGroups = [
  {
    id: '1',
    name: 'User',
    created: '30/04/22',
    updated: '01/05/22',
  },
  {
    id: '2',
    name: 'Admin',
    created: '30/04/22',
    updated: '01/05/22',
  },
];

export const dataUsers = [
  {
    id: '1',
    name: 'User Name Teste',
    email: 'email@email.com',
    phone: '11 1234-1234',
    group: 'Admin',
    status: 'Ativo',
    created: '30/04/22',
    updated: '01/05/22',
  },
  {
    id: '2',
    name: 'User Name Teste 2',
    email: 'email2@email.com',
    phone: '22 1234-1234',
    group: 'User',
    status: 'Ativo',
    created: '30/04/22',
    updated: '01/05/22',
  },
];

export const dataPricesMock = [
  {
    id: '45W_3phy',
    spec: '45_3phy',
    children: [
      {
        id: '45W_3phy$KN.8GBBS.0081', //Usar $ como separador, "-" e "#" geraram bugs.
        partNumber: 'KN.8GBBS.0081',
        description:
          'HDD SEAGATE 2.5\\" 5400rpm 1000GB ST1000LM035 ,1RK172-192 ,Rosewood 1TB ,7mmzh HDD - SATA III 128MB LF F/W:ADM1',
        children: [
          {
            id: '45W_3phy$KN.8GBBS.0081$ACER$PPB',
            supplier: {
              id: '61fad9b0b62a105582e8e5ca',
              name: 'ACER INC.',
              shortName: 'ACER',
              place: 'FOB', //place FOB/PPB
            },
            january: {
              price: {
                value: 31,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 32,
            },
            february: {
              price: {
                value: 18,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            march: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            april: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            may: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            june: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            july: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            august: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            september: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            october: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            november: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            december: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
          },
          {
            id: '45W_3phy$KN.8GBBS.0081$ACER$PPB2',
            supplier: {
              id: '61fad9b0b62a105582e8e5ca',
              name: 'ACER INC.',
              shortName: 'ACER',
              place: 'FOB',
            },
            january: {
              price: {
                value: 21,
                edited: false,
              },
              factor: {
                value: 2,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            february: {
              price: {
                value: 22,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            march: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            april: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            may: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            june: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            july: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            august: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            september: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            october: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            november: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            december: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
          },
        ],
      },
      {
        id: '45W_3phy$KN.8GBBS.008', //Usar $ como separador, "-" e "#" geraram bugs.
        partNumber: 'KN.8GBBS.008',
        description:
          'HDD SEAGATE 2.5\\" 5400rpm 1000GB ST1000LM035 ,1RK172-192 ,Rosewood 1TB ,7mmzh HDD - SATA III 128MB LF F/W:ADM1',
        children: [
          {
            id: '45W_3phy$KN.8GBBS.008$ACER$PPB',
            supplier: {
              id: '61fad9b0b62a105582e8e5ca',
              name: 'ACER INC.',
              shortName: 'ACER',
              place: 'PPB',
            },
            january: {
              price: {
                value: 18,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            february: {
              price: {
                value: 18,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            march: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            april: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            may: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            june: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            july: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            august: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            september: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            october: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            november: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            december: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
          },
          {
            id: '45W_3phy$KN.8GBBS.008$ACER$PPB2',
            supplier: {
              id: '61fad9b0b62a105582e8e5ca',
              name: 'ACER INC.',
              shortName: 'ACER',
              place: 'PPB',
            },
            january: {
              price: {
                value: 10,
                edited: false,
              },
              factor: {
                value: 2,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            february: {
              price: {
                value: 18,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 18,
            },
            march: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            april: {
              price: {
                value: 19,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 19,
            },
            may: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            june: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            july: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            august: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            september: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            october: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            november: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
            december: {
              price: {
                value: 17,
                edited: false,
              },
              factor: {
                value: 1,
                edited: false,
              },
              gap: null,
              locked: null,
              total: 17,
            },
          },
        ],
      },
    ],
  },
];

export const dataPricesOld = [
  {
    id: '1',
    vendor: 'Inventus Power Eletronica do Brasil LTDA',
    spec: '3RD WIFI 1X1 AC+ BT M.2',
    local: 'PPB',
    1: '3.15',
    2: '3.15',
    3: '3.15',
    4: '3.15',
    5: '3.15',
    6: '3.15',
    7: '3.15',
    8: '3.15',
    9: '3.15',
    10: '3.15',
    11: '3.15',
    12: '3.15',
  },
  {
    id: '2',
    vendor: 'ACER',
    spec: '3RD WIFI 2X2 AC+ BT M.2_BR',
    local: 'FOB',
    1: '3.80',
    2: '3.80',
    3: '3.80',
    4: '3.80',
    5: '3.80',
    6: '3.80',
    7: '3.80',
    8: '4.10',
    9: '4.10',
    10: '4.10',
    11: '4.10',
    12: '4.10',
  },
  {
    id: '3',
    vendor: 'ACER',
    spec: 'F80256S3_BR',
    local: 'FOB',
    1: '40.00',
    2: '40.00',
    3: '40.00',
    4: '40.00',
    5: '40.00',
    6: '40.00',
    7: '40.00',
    8: '41.10',
    9: '41.10',
    10: '41.10',
    11: '41.10',
    12: '41.10',
  },
  {
    id: '4',
    vendor: 'ADATA ELETRONICS BRAZIL S/A',
    spec: 'F80256S3_BR',
    local: 'PPB',
    1: '45.20',
    2: '45.20',
    3: '45.20',
    4: '45.20',
    5: '45.20',
    6: '45.20',
    7: '44.60',
    8: '44.60',
    9: '44.60',
    10: '44.60',
    11: '44.60',
    12: '44.60',
  },
  {
    id: '5',
    vendor: 'ACER',
    spec: 'F80128PM_BR',
    local: 'FOB',
    1: '32.10',
    2: '32.10',
    3: '32.10',
    4: '32.10',
    5: '32.10',
    6: '32.10',
    7: '32.80',
    8: '32.80',
    9: '32.80',
    10: '32.80',
    11: '32.80',
    12: '32.80',
  },
  {
    id: '6',
    vendor: 'ADATA ELETRONICS BRAZIL S/A',
    spec: 'F80128PM_BR',
    local: 'PPB',
    1: '31.40',
    2: '31.40',
    3: '31.40',
    4: '31.40',
    5: '31.40',
    6: '31.40',
    7: '32.40',
    8: '32.40',
    9: '32.40',
    10: '32.40',
    11: '32.40',
    12: '32.40',
  },
];

export const dataPricesGap = [
  {
    id: '1',
    vendor: 'Inventus',
    spec: '3RD WIFI 1X1 AC+ BT M.2',
    local: 'PPB',
    1: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    2: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    3: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    4: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    5: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    6: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    7: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    8: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    9: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    10: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    11: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
    12: { price: '3.15', factor: '1.1', gap: '1.09', total: '3.47' },
  },
  {
    id: '2',
    vendor: 'ACER',
    spec: '3RD WIFI 2X2 AC+ BT M.2_BR',
    local: 'FOB',
    1: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    2: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    3: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    4: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    5: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    6: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    7: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    8: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    9: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    10: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    11: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
    12: { price: '3.80', factor: '1.2', gap: '-1.09', total: '4.56' },
  },
  {
    id: '3',
    vendor: 'ACER',
    spec: 'F80256S3_BR',
    local: 'FOB',
    1: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    2: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    3: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    4: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    5: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    6: { price: '40.00', factor: '1.2', gap: '1.72', total: '48.00' },
    7: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
    8: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
    9: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
    10: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
    11: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
    12: { price: '41.10', factor: '1.2', gap: '-0.26', total: '49,32' },
  },
  {
    id: '4',
    vendor: 'ADATA',
    spec: 'F80256S3_BR',
    local: 'PPB',
    1: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    2: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    3: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    4: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    5: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    6: { price: '45.20', factor: '1.1', gap: '-1.72', total: '49.72' },
    7: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
    8: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
    9: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
    10: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
    11: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
    12: { price: '44.60', factor: '1.1', gap: '0.26', total: '49.60' },
  },
  {
    id: '5',
    vendor: 'ACER',
    spec: 'F80128PM_BR',
    local: 'FOB',
    1: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    2: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    3: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    4: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    5: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    6: { price: '32.10', factor: '1.2', gap: '-3.98', total: '38.52' },
    7: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
    8: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
    9: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
    10: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
    11: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
    12: { price: '32.80', factor: '1.2', gap: '-3.72', total: '39.36' },
  },
  {
    id: '6',
    vendor: 'ADATA',
    spec: 'F80128PM_BR',
    local: 'PPB',
    1: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    2: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    3: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    4: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    5: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    6: { price: '31.40', factor: '1.1', gap: '3.98', total: '34.54' },
    7: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
    8: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
    9: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
    10: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
    11: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
    12: { price: '32.40', factor: '1.1', gap: '3.72', total: '35.64' },
  },
];

export const dataFactors = [
  {
    id: '1',
    vendor: 'Inventus',
    spec: '3RD WIFI 1X1 AC+ BT M.2',
    local: 'PPB',
    1: '1.11',
    2: '1.11',
    3: '1.11',
    4: '1.11',
    5: '1.11',
    6: '1.11',
    7: '1.11',
    8: '1.11',
    9: '1.11',
    10: '1.11',
    11: '1.11',
    12: '1.11',
  },
  {
    id: '2',
    vendor: 'ACER',
    spec: '3RD WIFI 2X2 AC+ BT M.2_BR',
    local: 'FOB',
    1: '1.20',
    2: '1.20',
    3: '1.20',
    4: '1.20',
    5: '1.20',
    6: '1.20',
    7: '1.20',
    8: '1.20',
    9: '1.20',
    10: '1.20',
    11: '1.20',
    12: '1.20',
  },
  {
    id: '3',
    vendor: 'ACER',
    spec: 'F80256S3_BR',
    local: 'FOB',
    1: '1.20',
    2: '1.20',
    3: '1.20',
    4: '1.20',
    5: '1.20',
    6: '1.20',
    7: '1.20',
    8: '1.20',
    9: '1.20',
    10: '1.20',
    11: '1.20',
    12: '1.20',
  },
  {
    id: '4',
    vendor: 'ADATA',
    spec: 'F80256S3_BR',
    local: 'PPB',
    1: '1.11',
    2: '1.11',
    3: '1.11',
    4: '1.11',
    5: '1.11',
    6: '1.11',
    7: '1.11',
    8: '1.11',
    9: '1.11',
    10: '1.11',
    11: '1.11',
    12: '1.11',
  },
  {
    id: '5',
    vendor: 'ACER',
    spec: 'F80128PM_BR',
    local: 'FOB',
    1: '1.20',
    2: '1.20',
    3: '1.20',
    4: '1.20',
    5: '1.20',
    6: '1.20',
    7: '1.20',
    8: '1.20',
    9: '1.20',
    10: '1.20',
    11: '1.20',
    12: '1.20',
  },
  {
    id: '6',
    vendor: 'ADATA',
    spec: 'F80128PM_BR',
    local: 'PPB',
    1: '1.11',
    2: '1.11',
    3: '1.11',
    4: '1.11',
    5: '1.11',
    6: '1.11',
    7: '1.11',
    8: '1.11',
    9: '1.11',
    10: '1.11',
    11: '1.11',
    12: '1.11',
  },
];

export const dataForecasts = [
  {
    id: '1',
    sku: 'NHQ6PAL009',
    january: { amount: 1500, edited: false },
    february: { amount: 0, edited: false },
    march: { amount: 2435, edited: false },
    april: { amount: 1764, edited: false },
    may: { amount: 1001, edited: false },
    june: { amount: 998, edited: false },
    july: { amount: 3002, edited: false },
    august: { amount: 0, edited: false },
    september: { amount: 3000, edited: false },
    october: { amount: 0, edited: false },
    november: { amount: 0, edited: false },
    december: { amount: 0, edited: false },
  },
  {
    id: '2',
    sku: 'NHQ6PAL00B',
    january: { amount: 10461, edited: false },
    february: { amount: 0, edited: false },
    march: { amount: 1495, edited: false },
    april: { amount: 1505, edited: false },
    may: { amount: 997, edited: false },
    june: { amount: 2503, edited: false },
    july: { amount: 2065, edited: false },
    august: { amount: 2000, edited: false },
    september: { amount: 1000, edited: false },
    october: { amount: 1000, edited: false },
    november: { amount: 0, edited: false },
    december: { amount: 0, edited: false },
  },
  {
    id: '3',
    sku: 'NHQ6PAL00A',
    january: { amount: 627, edited: false },
    february: { amount: 470, edited: false },
    march: { amount: 100, edited: false },
    april: { amount: 0, edited: false },
    may: { amount: 991, edited: false },
    june: { amount: 435, edited: false },
    july: { amount: 256, edited: false },
    august: { amount: 0, edited: false },
    september: { amount: 0, edited: false },
    october: { amount: 0, edited: false },
    november: { amount: 0, edited: false },
    december: { amount: 0, edited: false },
  },
  {
    id: '4',
    sku: 'NXHKPAL002',
    january: { amount: 0, edited: false },
    february: { amount: 3636, edited: false },
    march: { amount: 1195, edited: false },
    april: { amount: 4767, edited: false },
    may: { amount: 2, edited: false },
    june: { amount: 3217, edited: false },
    july: { amount: 3217, edited: false },
    august: { amount: 180, edited: false },
    september: { amount: 2903, edited: false },
    october: { amount: 1597, edited: false },
    november: { amount: 3027, edited: false },
    december: { amount: 1811, edited: false },
  },
  {
    id: '5',
    sku: 'NXHKPAL003',
    january: { amount: 1994, edited: false },
    february: { amount: 1995, edited: false },
    march: { amount: 1971, edited: false },
    april: { amount: 45, edited: false },
    may: { amount: 978, edited: false },
    june: { amount: 15, edited: false },
    july: { amount: 0, edited: false },
    august: { amount: 1661, edited: false },
    september: { amount: 202, edited: false },
    october: { amount: 943, edited: false },
    november: { amount: 1863, edited: false },
    december: { amount: 1949, edited: false },
  },
  {
    id: '6',
    sku: 'NXHKPAL004',
    january: { amount: 3751, edited: false },
    february: { amount: 5736, edited: false },
    march: { amount: 6194, edited: false },
    april: { amount: 1473, edited: false },
    may: { amount: 234, edited: false },
    june: { amount: 0, edited: false },
    july: { amount: 4655, edited: false },
    august: { amount: 2774, edited: false },
    september: { amount: 33, edited: false },
    october: { amount: 4280, edited: false },
    november: { amount: 5061, edited: false },
    december: { amount: 3012, edited: false },
  },
];

export const dataComponents = [
  {
    id: 'MOTHERBOARD',
    partType: 'MOTHERBOARD',
    children: [
      {
        id: 'MOTHERBOARD$45W_3phy',
        spec: '45W_3phy',
        children: [
          {
            id: 'MOTHERBOARD$45W_3phy$KN.8GBBS.008',
            partNumber: 'KN.8GBBS.008',
            description:
              'HDD SEAGATE 2.5\\" 5400rpm 1000GB ST1000LM035 ,1RK172-192 ,Rosewood 1TB ,7mmzh HDD - SATA III 128MB LF F/W:ADM1',
          },
        ],
      },
    ],
  },
];

export const dataDetailsByProductPPB = [
  {
    id: '1',
    description: 'VIII - Motherboard',
    partTypes: [{ name: 'MOTHERBOARD', countSubComponents: false }],
    maxScore: 11,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB', '0 - Local'],
    ignoreSKUs: '',
  },
  {
    id: '2',
    description: 'IX - Wlan',
    partTypes: [{ name: 'WLAN', countSubComponents: false }],
    maxScore: 2,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '3',
    description: 'X - AC Adapter',
    partTypes: [{ name: 'ADAPTER', countSubComponents: false }],
    maxScore: 4,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '4',
    description: 'XI - Battery',
    partTypes: [{ name: 'BATTERY', countSubComponents: false }],
    maxScore: 5,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '5',
    description: 'XII - HDD',
    partTypes: [{ name: 'HDD', countSubComponents: false }],
    maxScore: 7,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '6',
    description: 'XIII - RAM ICs',
    partTypes: [
      { name: 'DDR', countSubComponents: true },
      { name: 'LPDDR', countSubComponents: false },
      { name: 'OBDDR', countSubComponents: false },
    ],
    maxScore: 15,
    bonusStage: 'No',
    calcBase: 'Part Type',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '8',
    description: 'XIV - RAM Module',
    partTypes: [{ name: 'DDR', countSubComponents: false }],
    maxScore: 2,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB', '0 - Local', '8 - Local'],
    ignoreSKUs: '',
  },
  {
    id: '9',
    description: 'XV - SSD & eMMC ICs',
    partTypes: [
      { name: 'EMMC', countSubComponents: false },
      { name: 'SSD', countSubComponents: true },
    ],
    maxScore: 14,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB'],
    ignoreSKUs: '',
  },
  {
    id: '10',
    description: 'XVI - SSD',
    partTypes: [{ name: 'SSD', countSubComponents: false }],
    maxScore: 3,
    bonusStage: 'No',
    calcBase: 'Finish Good',
    taxOrigins: ['4 - PPB', '0 - Local', '8 - Local'],
    ignoreSKUs: '',
  },
  {
    id: '11',
    description: 'XVII - Final Assembling',
    partTypes: [],
    maxScore: 5,
    bonusStage: 'Yes',
    calcBase: '',
    taxOrigins: [],
    ignoreSKUs: '',
  },
  {
    id: '12',
    description: 'XVIII - Test',
    partTypes: [],
    maxScore: 1,
    bonusStage: 'Yes',
    calcBase: '',
    taxOrigins: [],
    ignoreSKUs: '',
  },
];

export const dataProdutRules = [
  {
    id: '1',
    productName: 'Notebook',
    description: 'Portaria Interministerial MDIC/MCT nº 170, de 4 de agosto de 2010',
    targetScore: '42',
    inForceDate: '2019-09-21',
    publishedDate: '2019-09-21',
    modified: '2021-12-23',
  },
  {
    id: '2',
    productName: 'Mobile',
    description: 'Portaria Interministerial MDIC/MCT nº 170, de 4 de agosto de 2010',
    targetScore: '38',
    inForceDate: '2019-09-21',
    publishedDate: '2021-04-12',
    modified: '2021-12-23',
  },
  {
    id: '3',
    productName: 'Desktop',
    description: 'Portaria Interministerial MDIC/MCT nº 170, de 4 de agosto de 2010',
    targetScore: '38',
    inForceDate: '2019-09-21',
    publishedDate: '2019-12-22',
    modified: '2021-12-23',
  },
];

export const copyPrices = [
  {
    id: '59vz6jup0em1658150548355',
    supplier: {
      id: '61fad9b0b62a105582e8e5ca',
      name: 'ACER INC.',
      shortName: 'ACER',
      codFoxconn: null,
      state: null,
      local: false,
      filename: null,
      stampDateTime: null,
      updateDateTime: '2022-02-17T16:03:37.616',
    },
    component: {
      id: '62c2b915b3989a42d8d5a69d',
      spec: 'SO4GBIV_BR',
      partNumber: 'KN.4GBBB.001',
      partType: 'MEM',
      type: null,
      filename: 'MATERIAL_ACER_20220718220126',
      stampDateTime: '2022-07-04T09:55:33.443',
      updateDateTime: null,
      controller: 'MG14A',
      productLine: 'B09',
      planFlag: '1',
      origin: '1',
      subComponent: 0,
      materialNumber: null,
      originMater: null,
      oldMater: null,
      description:
        'Memory SAMSUNG SO-DIMM DDRIV 3200 4GB M471A5244BB0-CWE LF+HF D20 B-die for BR import',
      changed: false,
    },
    january: {
      price: {
        value: 18,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 18,
    },
    february: {
      price: {
        value: 18,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 18,
    },
    march: {
      price: {
        value: 19,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 19,
    },
    april: {
      price: {
        value: 19,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 19,
    },
    may: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    june: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    july: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    august: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    september: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    october: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    november: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
    december: {
      price: {
        value: 17,
        edited: false,
      },
      factor: {
        value: 1,
        edited: false,
      },
      gap: null,
      locked: null,
      total: 17,
    },
  },
];

export const dataSuppliers = [
  {
    key: '1',
    name: 'AGP TECNOLOGIA E INFORMATICA DO BRASIL LTDA',
    shortname: 'ACER',
    state: 'SP',
  },
  {
    key: '2',
    name: 'UNICOBA DA AMAZONIA LTDA',
    shortname: 'UNICOBA',
    state: 'AM',
  },
  {
    key: '3',
    name: 'DIGITRONDA AMAZONIA IND. COM. S/A',
    shortname: 'DIGITRON',
    state: 'AM',
  },
  {
    key: '4',
    name: 'SMART MODULAR TECHNOLOGIES DO BRASIL IND. E COMP. LTDA',
    shortname: 'DIGITRON',
    state: 'SP',
  },
  {
    key: '5',
    name: 'MULTILASER INDUSTRIA DE EQUIPAMENTOS DE INFORMATICA, ELETRON',
    shortname: 'MULTILASER INDUSTRIA',
    state: 'MG',
  },
  {
    key: '6',
    name: 'DIGITRON DA AMAZONIA INDUSTRIA E COMERCIO LTDA',
    shortname: 'DIGITRON DA AMAZONIA',
    state: 'MG',
  },
  {
    key: '7',
    name: 'ADATA ELECTRONICS BRAZIL S/A',
    shortname: 'ADATA',
    state: 'SP',
  },
  {
    key: '8',
    name: 'HIGH BRIDGE SOLUTIONS INDUSTRIA ELET',
    shortname: 'HIGH BRIDGE',
    state: 'SP',
  },
];

export const dataScenariosUser = [
  {
    id: '1',
    targetYear: '2021',
    version: '3.0',
    name: 'Scenario',
    modified: '20/10/2021',
    base: '2021 - v 1.0',
    targetScore: '42',
    currentScore: '42',
    idBom: '1',
    idComponents: '1',
    idForecasts: '1',
    idFactors: '1',
    idPrices: '1',
  },
  {
    id: '2',
    targetYear: '2021',
    version: '2.5',
    name: 'Scenario II',
    modified: '20/09/2021',
    base: '2021 - Base II - v 1.0',
    targetScore: '42',
    currentScore: '47',
    idBom: '2',
    idComponents: '2',
    idForecasts: '2',
    idFactors: '2',
    idPrices: '2',
  },
  {
    id: '3',
    targetYear: '2020',
    version: '2.0',
    name: 'Scenario II',
    modified: '20/10/2020',
    base: '2020 - Base II - v 1.0',
    targetScore: '42',
    currentScore: '42',
    idBom: '3',
    idComponents: '3',
    idForecasts: '3',
    idFactors: '3',
    idPrices: '3',
  },
  {
    id: '4',
    targetYear: '2020',
    version: '1.0',
    name: 'Scenario II',
    modified: '20/10/2020',
    base: '2020 - Base II - v 2.0',
    targetScore: '42',
    currentScore: '35',
    idBom: '4',
    idComponents: '4',
    idForecasts: '4',
    idFactors: '4',
    idPrices: '4',
  },
];

export const dataScenariosBase = [
  {
    id: '1',
    targetYear: '2021',
    version: '2.0',
    name: 'Base II',
    modified: '20/10/2021',
    targetScore: '42',
    currentScore: '42',
    idBom: '1',
    idComponents: '1',
    idForecasts: '1',
  },
  {
    id: '2',
    targetYear: '2020',
    version: '1.0',
    name: 'Base I',
    modified: '20/10/2020',
    targetScore: '42',
    currentScore: '39',
    idBom: '2',
    idComponents: '2',
    idForecasts: '2',
  },
];

export const dataSimulator = [
  {
    id: '1',
    partType: 'MEMORY',
    costTotal: '3072858.60',
    gapTotal: '-498429.00',
    score: '3.0',
    children: [
      {
        id: '10',
        spec: 'F8128PM_BR',
        costTotal: '1886298.48',
        gapTotal: '-217355.76',
        score: '1.5',
        children: [
          {
            id: '100',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'JAN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '101',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'FEB',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '102',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '103',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'ABR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '104',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAI',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '105',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '106',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUL',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '107',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'AGO',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '108',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'SET',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '109',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'OUT',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '110',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'NOV',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
          {
            id: '111',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'DEZ',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '157191.54',
            gap: '-3.98',
            gapTotal: '-18112.98',
          },
        ],
      },
      {
        id: '20',
        spec: 'F80256S3_BR',
        costTotal: '-1186560.12',
        gapTotal: '-281073.24',
        score: '1.5',
        children: [
          {
            id: '200',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'JAN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '201',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'FEB',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '202',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '203',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'ABR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '204',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAI',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '205',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '206',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUL',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '207',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'AGO',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '208',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'SET',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '209',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'OUT',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '210',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'NOV',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '211',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'DEZ',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    isType: true,
    partType: 'MOTHERBOARD',
    costTotal: '118560.12',
    gapTotal: '-562146.48',
    score: '8.0',
    children: [
      {
        id: '30',
        spec: 'F8128PM_BR',
        costTotal: '-118560.12',
        gapTotal: '-281073.24',
        score: '2.0',
        children: [
          {
            id: '300',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'JAN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '301',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'FEB',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '302',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '303',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'ABR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '304',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAI',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '305',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '306',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUL',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '307',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'AGO',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '308',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'SET',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '309',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'OUT',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '310',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'NOV',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '311',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'DEZ',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
        ],
      },
      {
        id: '40',
        spec: 'F8128PM_BR',
        costTotal: '-118560.12',
        gapTotal: '-281073.24',
        score: '2.0',
        children: [
          {
            id: '400',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'JAN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '401',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'FEB',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '402',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '403',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'ABR',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '404',
            editedFob: false,
            editedBr: false,
            disabled: true,
            month: 'MAI',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '405',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUN',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '406',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'JUL',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '407',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'AGO',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '408',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'SET',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '409',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'OUT',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '410',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'NOV',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
          {
            id: '411',
            editedFob: false,
            editedBr: false,
            disabled: false,
            month: 'DEZ',
            baseForecast: '4551',
            userForecast: '4551',
            autoFob: '0',
            autoBr: '4551',
            userFob: '551',
            userBr: '4000',
            costTotal: '-98880.01',
            gap: '-5.67',
            gapTotal: '-23422.77',
          },
        ],
      },
    ],
  },
];

export const dataSimulatorModalOld = [
  {
    partType: 'Memory',
    score: {
      auto: '3 pontos',
      manual: '4 pontos',
    },
    invest: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    cost: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    total: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    br: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    fob: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
  },
  {
    partType: 'Motherboard',
    score: {
      auto: '3 pontos',
      manual: '4 pontos',
    },
    invest: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    cost: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    total: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    br: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    fob: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
  },
  {
    partType: 'SSD',
    score: {
      auto: '3 pontos',
      manual: '4 pontos',
    },
    invest: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    cost: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    total: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    br: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
    fob: {
      auto: '$ 100.00',
      manual: '$ 200.00',
    },
  },
  {
    partType: 'Total',
    score: {
      auto: '9 pontos',
      manual: '12 pontos',
    },
    invest: {
      auto: '$ 300.00',
      manual: '$ 600.00',
    },
    cost: {
      auto: '$ 300.00',
      manual: '$ 600.00',
    },
    total: {
      auto: '$ 300.00',
      manual: '$ 600.00',
    },
    br: {
      auto: '$ 300.00',
      manual: '$ 600.00',
    },
    fob: {
      auto: '$ 300.00',
      manual: '$ 600.00',
    },
  },
];

export const dataSimulationMock2 = {
  id: '6488bde40e58612c3c96c26d',
  totalCost: 3.0873221413609e8,
  currentScore: 17.73944491060237,
  maxScore: 17.0,
  partTypeMaxScore: 83.0,
  simulationPartTypes: [
    {
      id: '1685381861820',
      notes: null,
      deprecated: false,
      partType: 'ADAPTER',
      currentScore: 1.6372658752694813,
      maxScore: 0.0,
      partTypeMaxScore: 4.0,
      totalCost: 2.277821824814e7,
      totalGap: null,
      costPerPoint: 1.3912351434302557e7,
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1685381861820-january',
          month: 'january',
          baseForecast: 249228,
          userForecast: 33803,
          kcFob: 22541,
          kcBr: 11262,
          ppb: 33.31656953524835,
          currentScore: 1.332662781409934,
          maxScore: null,
          totalGap: null,
          totalCost: 1438301.8304800002,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-february',
          month: 'february',
          baseForecast: 83580,
          userForecast: 36019,
          kcFob: 16024,
          kcBr: 19995,
          ppb: 55.51236847219523,
          currentScore: 1.7906676978602734,
          maxScore: null,
          totalGap: null,
          totalCost: 1714836.97525,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-march',
          month: 'march',
          baseForecast: 42420,
          userForecast: 31264,
          kcFob: 10356,
          kcBr: 20908,
          ppb: 66.87563971340839,
          currentScore: 2.064182972914152,
          maxScore: null,
          totalGap: null,
          totalCost: 1234196.79268,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 32636,
          kcFob: 16273,
          kcBr: 16363,
          ppb: 50.13788454467459,
          currentScore: 2.0498646445611044,
          maxScore: null,
          totalGap: null,
          totalCost: 1686686.96249,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-may',
          month: 'may',
          baseForecast: 33852,
          userForecast: 31666,
          kcFob: 12522,
          kcBr: 19144,
          ppb: 60.45600960020211,
          currentScore: 2.1203956756233824,
          maxScore: null,
          totalGap: null,
          totalCost: 1497576.71454,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-june',
          month: 'june',
          baseForecast: 50148,
          userForecast: 35745,
          kcFob: 35545,
          kcBr: 200,
          ppb: 0.5595188138201147,
          currentScore: 1.747540184852809,
          maxScore: null,
          totalGap: null,
          totalCost: 3074724.6516500004,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 30500,
          kcFob: 24700,
          kcBr: 5800,
          ppb: 19.016393442622952,
          currentScore: 1.6175933480980689,
          maxScore: null,
          totalGap: null,
          totalCost: 2409314.0589999994,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 34200,
          kcFob: 25500,
          kcBr: 8700,
          ppb: 25.43859649122807,
          currentScore: 1.540395661938134,
          maxScore: null,
          totalGap: null,
          totalCost: 2072738.0880000002,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 34000,
          kcFob: 24800,
          kcBr: 9200,
          ppb: 27.058823529411764,
          currentScore: 1.4884552400836466,
          maxScore: null,
          totalGap: null,
          totalCost: 2415674.8729999997,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 32600,
          kcFob: 10800,
          kcBr: 21800,
          ppb: 66.87116564417178,
          currentScore: 1.604798560913026,
          maxScore: null,
          totalGap: null,
          totalCost: 2411695.6939999997,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 21201,
          kcFob: 12200,
          kcBr: 9001,
          ppb: 42.45554454978539,
          currentScore: 1.610399452541328,
          maxScore: null,
          totalGap: null,
          totalCost: 2030668.0700499997,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1685381861820-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 9100,
          kcFob: 3000,
          kcBr: 6100,
          ppb: 67.03296703296704,
          currentScore: 1.6372658752694813,
          maxScore: null,
          totalGap: null,
          totalCost: 791803.5370000001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670416908912',
      notes: null,
      deprecated: false,
      partType: 'SSD',
      currentScore: 1.082272408982891,
      maxScore: 0.0,
      partTypeMaxScore: 3.0,
      totalCost: 7.972280560132e7,
      totalGap: null,
      costPerPoint: 7.366242079130772e7,
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670416908912-january',
          month: 'january',
          baseForecast: 252195,
          userForecast: 33803,
          kcFob: 10901,
          kcBr: 22902,
          ppb: 67.75138301334201,
          currentScore: 2.0325414904002606,
          maxScore: null,
          totalGap: null,
          totalCost: 6942217.605029997,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-february',
          month: 'february',
          baseForecast: 84575,
          userForecast: 36019,
          kcFob: 8244,
          kcBr: 27775,
          ppb: 77.11207973569505,
          currentScore: 2.177408266735413,
          maxScore: null,
          totalGap: null,
          totalCost: 7496193.02596,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-march',
          month: 'march',
          baseForecast: 42925,
          userForecast: 31264,
          kcFob: 10233,
          kcBr: 21031,
          ppb: 67.2690634595701,
          currentScore: 2.128128524226896,
          maxScore: null,
          totalGap: null,
          totalCost: 6450642.232049999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 32636,
          kcFob: 5443,
          kcBr: 27193,
          ppb: 83.32209829635985,
          currentScore: 2.218804684345134,
          maxScore: null,
          totalGap: null,
          totalCost: 6811249.494359997,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-may',
          month: 'may',
          baseForecast: 34255,
          userForecast: 31666,
          kcFob: 9036,
          kcBr: 22630,
          ppb: 71.46466241394556,
          currentScore: 2.204470699204295,
          maxScore: null,
          totalGap: null,
          totalCost: 6650889.813680001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-june',
          month: 'june',
          baseForecast: 50745,
          userForecast: 35745,
          kcFob: 27417,
          kcBr: 8328,
          ppb: 23.298363407469576,
          currentScore: 1.936912391303267,
          maxScore: null,
          totalGap: null,
          totalCost: 8705026.436930003,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 30500,
          kcFob: 29500,
          kcBr: 1000,
          ppb: 3.278688524590164,
          currentScore: 1.6948232764761497,
          maxScore: null,
          totalGap: null,
          totalCost: 7279392.087000001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 34200,
          kcFob: 34200,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 1.4767805351480066,
          maxScore: null,
          totalGap: null,
          totalCost: 7487417.465000002,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 34000,
          kcFob: 34000,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 1.30931885416215,
          maxScore: null,
          totalGap: null,
          totalCost: 7612145.385000001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 32600,
          kcFob: 32600,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 1.1809206667208128,
          maxScore: null,
          totalGap: null,
          totalCost: 7095894.104,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 21201,
          kcFob: 21201,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 1.1101223298664722,
          maxScore: null,
          totalGap: null,
          totalCost: 5152872.03831,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416908912-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 9100,
          kcFob: 9100,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 1.082272408982891,
          maxScore: null,
          totalGap: null,
          totalCost: 2038865.9139999999,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670416982492',
      notes: null,
      deprecated: false,
      partType: 'WIRELESS LAN',
      currentScore: 0.0,
      maxScore: 0.0,
      partTypeMaxScore: 2.0,
      totalCost: 1.1659207521230001e7,
      totalGap: null,
      costPerPoint: 'Infinity',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670416982492-january',
          month: 'january',
          baseForecast: 249228,
          userForecast: 33803,
          kcFob: 33803,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1045406.5383700001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-february',
          month: 'february',
          baseForecast: 83580,
          userForecast: 36019,
          kcFob: 36019,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1140101.34522,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-march',
          month: 'march',
          baseForecast: 42420,
          userForecast: 31264,
          kcFob: 31264,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 952612.1358400001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 32636,
          kcFob: 32636,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1049598.6902099997,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-may',
          month: 'may',
          baseForecast: 33852,
          userForecast: 31666,
          kcFob: 31666,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 971216.0669999998,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-june',
          month: 'june',
          baseForecast: 50148,
          userForecast: 35745,
          kcFob: 35745,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1210995.1112199998,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 30500,
          kcFob: 30500,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1040540.0150000001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 34200,
          kcFob: 34200,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1110360.0850000002,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 34000,
          kcFob: 34000,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1059635.7019999998,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 32600,
          kcFob: 32600,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1055903.857,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 21201,
          kcFob: 21201,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 727441.6723700002,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670416982492-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 9100,
          kcFob: 9100,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 295396.30199999997,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417561975',
      notes: null,
      deprecated: false,
      partType: 'BATTERY',
      currentScore: 0.0,
      maxScore: 0.0,
      partTypeMaxScore: 5.0,
      totalCost: 4.666003484454e7,
      totalGap: null,
      costPerPoint: 'Infinity',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417561975-january',
          month: 'january',
          baseForecast: 249228,
          userForecast: 34079,
          kcFob: 34079,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4188022.3195399996,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-february',
          month: 'february',
          baseForecast: 83580,
          userForecast: 36019,
          kcFob: 36019,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4608484.557610001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-march',
          month: 'march',
          baseForecast: 42420,
          userForecast: 31264,
          kcFob: 31264,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 3838363.85746,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 32636,
          kcFob: 32636,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4278991.12271,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-may',
          month: 'may',
          baseForecast: 33852,
          userForecast: 31666,
          kcFob: 31666,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 3831910.3900099993,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-june',
          month: 'june',
          baseForecast: 50148,
          userForecast: 35745,
          kcFob: 35745,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 5283050.29614,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 30500,
          kcFob: 30500,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4080131.6489999993,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 34200,
          kcFob: 34200,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4372115.173,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 34000,
          kcFob: 34000,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4253715.394,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 32600,
          kcFob: 32600,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 4051035.86,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 21201,
          kcFob: 21201,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 2776987.66107,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417561975-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 9100,
          kcFob: 9100,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 1097226.564,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417632812',
      notes: null,
      deprecated: false,
      partType: 'HDD',
      currentScore: 0.0,
      maxScore: 0.0,
      partTypeMaxScore: 7.0,
      totalCost: 610675.57332,
      totalGap: null,
      costPerPoint: 'Infinity',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417632812-january',
          month: 'january',
          baseForecast: 5934,
          userForecast: 1005,
          kcFob: 1005,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 204167.9811,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-february',
          month: 'february',
          baseForecast: 1990,
          userForecast: 1001,
          kcFob: 1001,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 203355.37222,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-march',
          month: 'march',
          baseForecast: 1010,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 936,
          kcFob: 936,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 190150.47792,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-may',
          month: 'may',
          baseForecast: 806,
          userForecast: 63,
          kcFob: 63,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 12798.58986,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-june',
          month: 'june',
          baseForecast: 1194,
          userForecast: 1,
          kcFob: 1,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 203.15222,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 0.0,
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417632812',
      notes: null,
      deprecated: false,
      partType: 'HDD 2',
      currentScore: 0.0,
      maxScore: 0.0,
      partTypeMaxScore: 7.0,
      totalCost: 0.0,
      totalGap: null,
      costPerPoint: 'NaN',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417632812-january',
          month: 'january',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-february',
          month: 'february',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-march',
          month: 'march',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-may',
          month: 'may',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-june',
          month: 'june',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417632812',
      notes: null,
      deprecated: false,
      partType: 'HDD 3',
      currentScore: 0.0,
      maxScore: 0.0,
      partTypeMaxScore: 7.0,
      totalCost: 0.0,
      totalGap: null,
      costPerPoint: 'NaN',
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417632812-january',
          month: 'january',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-february',
          month: 'february',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-march',
          month: 'march',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-may',
          month: 'may',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-june',
          month: 'june',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417632812-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 0,
          kcFob: 0,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 'NaN',
          maxScore: null,
          totalGap: null,
          totalCost: 0.0,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417696935',
      notes: null,
      deprecated: false,
      partType: 'MEM',
      currentScore: 8.796442986261622,
      maxScore: 0.0,
      partTypeMaxScore: 15.0,
      totalCost: 3.3789233373110004e7,
      totalGap: null,
      costPerPoint: 3841238.2625434375,
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417696935-january',
          month: 'january',
          baseForecast: 483621,
          userForecast: 47121,
          kcFob: 4775,
          kcBr: 42346,
          ppb: 89.86651386855118,
          currentScore: 13.479977080282676,
          maxScore: null,
          totalGap: null,
          totalCost: 2838509.49452,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-february',
          month: 'february',
          baseForecast: 162185,
          userForecast: 48643,
          kcFob: 3749,
          kcBr: 44894,
          ppb: 92.29282733384044,
          currentScore: 13.664842738398562,
          maxScore: null,
          totalGap: null,
          totalCost: 2966108.6148500005,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-march',
          month: 'march',
          baseForecast: 82315,
          userForecast: 47196,
          kcFob: 2551,
          kcBr: 44645,
          ppb: 94.59488092211204,
          currentScore: 13.837961667599327,
          maxScore: null,
          totalGap: null,
          totalCost: 2762767.391409999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 42054,
          kcFob: 4379,
          kcBr: 37675,
          ppb: 89.58719741285014,
          currentScore: 13.747067789464582,
          maxScore: null,
          totalGap: null,
          totalCost: 2579216.64666,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-may',
          month: 'may',
          baseForecast: 65689,
          userForecast: 48529,
          kcFob: 5092,
          kcBr: 43437,
          ppb: 89.5073049104659,
          currentScore: 13.680371494756853,
          maxScore: null,
          totalGap: null,
          totalCost: 2794773.5459300014,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-june',
          month: 'june',
          baseForecast: 97311,
          userForecast: 81309,
          kcFob: 28420,
          kcBr: 52889,
          ppb: 65.04691977517864,
          currentScore: 12.667189663715016,
          maxScore: null,
          totalGap: null,
          totalCost: 4915354.584540001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 45100,
          kcFob: 23500,
          kcBr: 21600,
          ppb: 47.893569844789354,
          currentScore: 11.980180690758768,
          maxScore: null,
          totalGap: null,
          totalCost: 3131241.928,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 54100,
          kcFob: 41000,
          kcBr: 13100,
          ppb: 24.21441774491682,
          currentScore: 10.889429347038536,
          maxScore: null,
          totalGap: null,
          totalCost: 3097359.431999999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 54000,
          kcFob: 43500,
          kcBr: 10500,
          ppb: 19.444444444444443,
          currentScore: 9.969597395161221,
          maxScore: null,
          totalGap: null,
          totalCost: 3188813.786000001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 54900,
          kcFob: 45300,
          kcBr: 9600,
          ppb: 17.48633879781421,
          currentScore: 9.198339426945493,
          maxScore: null,
          totalGap: null,
          totalCost: 2803412.0300000007,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 30702,
          kcFob: 20002,
          kcBr: 10700,
          ppb: 34.85114976223047,
          currentScore: 8.97815242010353,
          maxScore: null,
          totalGap: null,
          totalCost: 1901878.8152,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417696935-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 15700,
          kcFob: 13200,
          kcBr: 2500,
          ppb: 15.92356687898089,
          currentScore: 8.796442986261624,
          maxScore: null,
          totalGap: null,
          totalCost: 809797.1039999998,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417746306',
      notes: null,
      deprecated: false,
      partType: 'MEM',
      currentScore: 1.1728590648348831,
      maxScore: 0.0,
      partTypeMaxScore: 2.0,
      totalCost: 3.3789233373110004e7,
      totalGap: null,
      costPerPoint: 2.880928696907578e7,
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417746306-january',
          month: 'january',
          baseForecast: 483621,
          userForecast: 47121,
          kcFob: 4775,
          kcBr: 42346,
          ppb: 89.86651386855118,
          currentScore: 1.7973302773710236,
          maxScore: null,
          totalGap: null,
          totalCost: 2838509.49452,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-february',
          month: 'february',
          baseForecast: 162185,
          userForecast: 48643,
          kcFob: 3749,
          kcBr: 44894,
          ppb: 92.29282733384044,
          currentScore: 1.821979031786475,
          maxScore: null,
          totalGap: null,
          totalCost: 2966108.6148500005,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-march',
          month: 'march',
          baseForecast: 82315,
          userForecast: 47196,
          kcFob: 2551,
          kcBr: 44645,
          ppb: 94.59488092211204,
          currentScore: 1.8450615556799104,
          maxScore: null,
          totalGap: null,
          totalCost: 2762767.391409999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 42054,
          kcFob: 4379,
          kcBr: 37675,
          ppb: 89.58719741285014,
          currentScore: 1.8329423719286109,
          maxScore: null,
          totalGap: null,
          totalCost: 2579216.64666,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-may',
          month: 'may',
          baseForecast: 65689,
          userForecast: 48529,
          kcFob: 5092,
          kcBr: 43437,
          ppb: 89.5073049104659,
          currentScore: 1.8240495326342472,
          maxScore: null,
          totalGap: null,
          totalCost: 2794773.5459300014,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-june',
          month: 'june',
          baseForecast: 97311,
          userForecast: 81309,
          kcFob: 28420,
          kcBr: 52889,
          ppb: 65.04691977517864,
          currentScore: 1.6889586218286687,
          maxScore: null,
          totalGap: null,
          totalCost: 4915354.584540001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 45100,
          kcFob: 23500,
          kcBr: 21600,
          ppb: 47.893569844789354,
          currentScore: 1.5973574254345024,
          maxScore: null,
          totalGap: null,
          totalCost: 3131241.928,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 54100,
          kcFob: 41000,
          kcBr: 13100,
          ppb: 24.21441774491682,
          currentScore: 1.4519239129384716,
          maxScore: null,
          totalGap: null,
          totalCost: 3097359.431999999,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 54000,
          kcFob: 43500,
          kcBr: 10500,
          ppb: 19.444444444444443,
          currentScore: 1.329279652688163,
          maxScore: null,
          totalGap: null,
          totalCost: 3188813.786000001,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 54900,
          kcFob: 45300,
          kcBr: 9600,
          ppb: 17.48633879781421,
          currentScore: 1.2264452569260658,
          maxScore: null,
          totalGap: null,
          totalCost: 2803412.0300000007,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 30702,
          kcFob: 20002,
          kcBr: 10700,
          ppb: 34.85114976223047,
          currentScore: 1.1970869893471374,
          maxScore: null,
          totalGap: null,
          totalCost: 1901878.8152,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417746306-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 15700,
          kcFob: 13200,
          kcBr: 2500,
          ppb: 15.92356687898089,
          currentScore: 1.1728590648348831,
          maxScore: null,
          totalGap: null,
          totalCost: 809797.1039999998,
          disabled: false,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: '1670417789884',
      notes: null,
      deprecated: false,
      partType: 'SSD',
      currentScore: 5.050604575253492,
      maxScore: 0.0,
      partTypeMaxScore: 14.0,
      totalCost: 7.972280560132e7,
      totalGap: null,
      costPerPoint: 1.5784804455280224e7,
      removeTransferButtons: false,
      simulationPartTypeMonths: [
        {
          id: '1670417789884-january',
          month: 'january',
          baseForecast: 252195,
          userForecast: 33803,
          kcFob: 10901,
          kcBr: 22902,
          ppb: 67.75138301334201,
          currentScore: 9.485193621867882,
          maxScore: null,
          totalGap: null,
          totalCost: 6942217.605029997,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-february',
          month: 'february',
          baseForecast: 84575,
          userForecast: 36019,
          kcFob: 8244,
          kcBr: 27775,
          ppb: 77.11207973569505,
          currentScore: 10.161238578098594,
          maxScore: null,
          totalGap: null,
          totalCost: 7496193.02596,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-march',
          month: 'march',
          baseForecast: 42925,
          userForecast: 31264,
          kcFob: 10233,
          kcBr: 21031,
          ppb: 67.2690634595701,
          currentScore: 9.931266446392181,
          maxScore: null,
          totalGap: null,
          totalCost: 6450642.232049999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-april',
          month: 'april',
          baseForecast: 0,
          userForecast: 32636,
          kcFob: 5443,
          kcBr: 27193,
          ppb: 83.32209829635985,
          currentScore: 10.354421860277292,
          maxScore: null,
          totalGap: null,
          totalCost: 6811249.494359997,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-may',
          month: 'may',
          baseForecast: 34255,
          userForecast: 31666,
          kcFob: 9036,
          kcBr: 22630,
          ppb: 71.46466241394556,
          currentScore: 10.287529929620044,
          maxScore: null,
          totalGap: null,
          totalCost: 6650889.813680001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-june',
          month: 'june',
          baseForecast: 50745,
          userForecast: 35745,
          kcFob: 27417,
          kcBr: 8328,
          ppb: 23.298363407469576,
          currentScore: 9.03892449274858,
          maxScore: null,
          totalGap: null,
          totalCost: 8705026.436930003,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-july',
          month: 'july',
          baseForecast: 0,
          userForecast: 30500,
          kcFob: 29500,
          kcBr: 1000,
          ppb: 3.278688524590164,
          currentScore: 7.9091752902220325,
          maxScore: null,
          totalGap: null,
          totalCost: 7279392.087000001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-august',
          month: 'august',
          baseForecast: 0,
          userForecast: 34200,
          kcFob: 34200,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 6.891642497357363,
          maxScore: null,
          totalGap: null,
          totalCost: 7487417.465000002,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-september',
          month: 'september',
          baseForecast: 0,
          userForecast: 34000,
          kcFob: 34000,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 6.110154652756701,
          maxScore: null,
          totalGap: null,
          totalCost: 7612145.385000001,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-october',
          month: 'october',
          baseForecast: 0,
          userForecast: 32600,
          kcFob: 32600,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 5.510963111363793,
          maxScore: null,
          totalGap: null,
          totalCost: 7095894.104,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-november',
          month: 'november',
          baseForecast: 0,
          userForecast: 21201,
          kcFob: 21201,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 5.180570872710204,
          maxScore: null,
          totalGap: null,
          totalCost: 5152872.03831,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
        {
          id: '1670417789884-december',
          month: 'december',
          baseForecast: 0,
          userForecast: 9100,
          kcFob: 9100,
          kcBr: 0,
          ppb: 0.0,
          currentScore: 5.050604575253491,
          maxScore: null,
          totalGap: null,
          totalCost: 2038865.9139999999,
          disabled: true,
          editedFob: false,
          editedBr: false,
        },
      ],
    },
    {
      id: null,
      notes: null,
      deprecated: false,
      partType: 'VIII - Motherboard',
      currentScore: 0.0,
      maxScore: 11.0,
      partTypeMaxScore: 11.0,
      totalCost: 0.0,
      totalGap: null,
      costPerPoint: 'NaN',
      removeTransferButtons: true,
      simulationPartTypeMonths: [],
    },
    {
      id: null,
      notes: null,
      deprecated: false,
      partType: 'XVII - Final Assembling',
      currentScore: 0.0,
      maxScore: 5.0,
      partTypeMaxScore: 5.0,
      totalCost: 0.0,
      totalGap: null,
      costPerPoint: 'NaN',
      removeTransferButtons: true,
      simulationPartTypeMonths: [],
    },
    {
      id: null,
      notes: null,
      deprecated: false,
      partType: 'Testes do Produto (XVIII)',
      currentScore: 0.0,
      maxScore: 1.0,
      partTypeMaxScore: 1.0,
      totalCost: 0.0,
      totalGap: null,
      costPerPoint: 'NaN',
      removeTransferButtons: true,
      simulationPartTypeMonths: [],
    },
  ],
};
