import { Modal } from 'antd';
import styled from 'styled-components';
import theme from '../../../styles/theme';

interface FooterComponent {
  footer: React.ReactNode;
}

export const ModalStyled = styled(Modal)<FooterComponent>`
  .ant-modal-header {
    padding: 1rem 1.5rem;
  }
  .ant-modal-content {
    /* padding: 0rem 0rem 1rem 0rem; */
    padding: ${({ footer }) => (footer ? '0rem 0rem 1rem 0rem' : '0rem 0rem 0rem 0rem')};
  }
`;
export const ModalBodyContainer = styled.div<FooterComponent>`
  /* padding: ; */
  padding: ${({ footer }) => (footer ? '0rem 1.5rem 1rem 1.5rem' : '0rem 1.5rem 0rem 1.5rem')};
  margin: 0.625rem;
  /* border-bottom: 0.063rem solid ${theme.colors.gray_border}; */
  /* border-bottom: ${({ footer }) =>
    footer ? `0.063rem solid ${theme.colors.gray_border}` : 'none'}; */
  box-sizing: content-box;
`;
export const ModalFooterContainer = styled.div<FooterComponent>`
  /* margin-top: 1rem; */
  border-top: ${({ footer }) => (footer ? `0.063rem solid ${theme.colors.gray_border}` : 'none')};
  /* padding: 0.625rem 1.5rem 0rem 1.5rem; */
  /* padding: 0rem 1.5rem 0rem 1.5rem; */
  padding: ${({ footer }) => (footer ? '0.625rem 1.5rem 0rem 1.5rem' : '0rem 1.5rem 0rem 1.5rem')};
`;
