import { useEffect, useState } from 'react';
import { t } from 'i18next';
import * as S from './styles';
import StyledLabel from '../StyledLabel';
import { StyledSelectOptions, StyledSelectProps } from './types';
import arrowDown from '../../../assets/arrow_down.svg';
import { api } from '../../../services/api';
import { getURI } from '../../../helpers/utils';
import { Product } from '../../forms/ProductsSelect';

export default function StyledSelect({
  label,
  options,
  suffixIcon,
  placeholder,
  onChange,
  styles,
  defaultValue,
  value,
  type,
  showSearch,
  disable,
  ...restProps
}: StyledSelectProps) {
  const [optionsList, setOptionsList] = useState<StyledSelectOptions[]>([]);

  const converterArrayToStyledSelectionOptions = (array: Array<string>) => {
    if (!array) {
      return [];
    }
    return array.map((item: string) => ({
      label: item.charAt(0).toUpperCase() + item.slice(1),
      value: item,
    })) as StyledSelectOptions[];
  };

  const handleFetchProducts = async () => {
    try {
      const {
        data: { content: products },
      } = await api.get(getURI(`ppb`));
      const parsedProductOptions = products.map((prod: Product) => ({
        label: prod.productName,
        value: prod.productName,
      }));
      parsedProductOptions.unshift({ label: t('common.showAll'), value: '' });
      setOptionsList(parsedProductOptions || []);
    } catch (err) {
      console.log('err/products=>', err);
      return setOptionsList([]);
    }
  };

  const handleFetchComponentTypes = async () => {
    try {
      const { data } = await api.get('componentTypes/br');
      const componentTypes = data.map((component: string) => {
        return { value: component, label: component };
      });
      componentTypes.unshift({ label: t('common.showAll'), value: '' });
      setOptionsList(componentTypes);
    } catch (error) {
      setOptionsList([]);
    }
  };

  const handleFetchProjects = async () => {
    try {
      const { data } = await api.get('projects');
      const projects = data.map((project: any) => {
        return { value: project.project, label: project.project };
      });
      projects.unshift({ label: t('common.showAll'), value: '' });
      setOptionsList(projects);
    } catch (error) {
      setOptionsList([]);
    }
  };

  const handleFetchDataByTypeSelect = async () => {
    switch (type) {
      case 'PRODUCTS': {
        handleFetchProducts();
        break;
      }
      case 'COMPONENT_TYPE': {
        handleFetchComponentTypes();
        break;
      }
      case 'PROJECTS': {
        handleFetchProjects();
        break;
      }
      default: {
        setOptionsList(options || []);
      }
    }
  };

  useEffect(() => {
    handleFetchDataByTypeSelect();
  }, []);

  return (
    <S.Container>
      <StyledLabel title={label || ''} />
      <S.StyledSelect
        data-testid="styled-select"
        showSearch={showSearch}
        defaultValue={defaultValue}
        size="middle"
        suffixIcon={suffixIcon || <img alt="icone" src={arrowDown} />}
        placeholder={<S.placeholder>{placeholder}</S.placeholder>}
        value={value}
        filterOption={(input, option) => {
          return String(option?.value).toUpperCase().includes(input.toUpperCase());
        }}
        onChange={onChange}
        // notFoundContent={<Spin size="small" />}
        options={options || optionsList}
        style={styles}
        {...restProps}
        disabled={disable}
      />
    </S.Container>
  );
}
