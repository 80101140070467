import { Button } from 'antd';
import styled from 'styled-components';

interface ContainerValuesProps {
  type: 'DOLAR' | 'BRL';
}

interface SpansHeaderInfo {
  type: 'VALUE' | 'LABEL';
}

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;

  gap: 0.375rem;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid;
  border-color: #dce0e5;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  margin-bottom: 1rem;
`;

export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.125rem;

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
    font-weight: 600;
    font-size: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const HeaderExpandableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    p {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

export const PartNumberHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;

  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  span {
    text-align: center;
    min-width: 7.25rem !important;
  }
`;

export const RowCell = styled.div`
  padding: 1rem;
  font-size: ${({ theme }) => theme.dimensions.font_small};
`;

export const DescriptionHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;
  justify-content: left;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
  padding-left: 1rem;
  align-items: center;
  span {
    text-align: left;
    min-width: 8.5rem !important;
  }
`;

export const LogoHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;
  justify-content: left;
  padding-left: 1rem;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
  span {
    text-align: left;
    min-width: 4.625rem !important;
  }
`;

export const QtyPerHeader = styled.div`
  display: flex;
  font-weight: bold;
  padding-left: 1rem;
  color: #00000099;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
  span {
    text-align: left;
    min-width: 6.125rem !important;
  }
`;

export const SourceHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
    min-width: 4.625rem !important;
  }
`;

export const MonthHeader = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  border-left: 1px solid #dce0e5;
  span {
    text-align: center;
    min-width: 9.25rem !important;
  }
`;

export const GrossNetCell = styled.div`
  display: flex;
  background-color: #dce0e5;
  align-items: center;
  justify-content: right;
  padding: 0.5rem;
  border-left: 1px solid;
  border-color: rgba(0, 0, 0, 0.06);
  span {
    text-align: right;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: ${({ theme }) => theme.dimensions.font_small};
    font-weight: 400;
    min-width: 4.625rem;
  }
`;

export const TaxesCell = styled.div`
  display: flex;
  background-color: #dce0e5;
  align-items: center;
  justify-content: left;
  padding: 0.5rem;
  border-left: 1px solid;
  border-color: rgba(0, 0, 0, 0.06);
  span {
    text-align: left;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-size: ${({ theme }) => theme.dimensions.font_small};
    font-weight: 400;
    min-width: 4.625rem;
  }
`;

export const RowCellNetCoast = styled.div`
  font-size: ${({ theme }) => theme.dimensions.font_small};
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding: 1rem;
  border-left: 1px solid #dce0e5;
  min-width: 4.625rem;
  word-break: break-all;
`;

export const MonthHeaderLastItem = styled.div`
  display: flex;
  font-weight: bold;
  color: #00000099;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #dce0e5;
  span {
    text-align: center;
    min-width: 9.25rem !important;
  }
`;

export const RowCellNetCoastLast = styled.div`
  font-size: ${({ theme }) => theme.dimensions.font_small};
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding: 1rem;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #dce0e5;
  min-width: 4.625rem;
  word-break: break-all;
`;

export const EditButton = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.colors.link} !important;
  }
`;

export const ContainerValues = styled.div<ContainerValuesProps>`
  min-width: 7.313rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  padding: 0.25rem;
  gap: 1rem;
  border-radius: 0.25rem;
  border: ${({ type, theme }) =>
    type === 'DOLAR'
      ? `1px solid ${theme.colorsDesignSystem.blue}`
      : `1px solid ${theme.colorsDesignSystem.primary}`};
  background-color: ${({ type, theme }) =>
    type === 'DOLAR'
      ? theme.colorsDesignSystem.tagColorBlueBackground
      : theme.colorsDesignSystem.tagColorGreenBackground};

  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: normal !important;
    margin: 0;
  }

  span {
    font-weight: bold;
  }
`;

export const SpanError = styled.span`
  margin-top: 0.25rem;
  color: red;
  font-size: 0.85rem;
`;

export const ContainerDollarCotation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 0.5rem;
`;

export const ContainerFileRef = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 0.25rem;
`;

export const LabelDollarCotation = styled.span<SpansHeaderInfo>`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-weight: ${({ type }) => (type === 'VALUE' ? 'bold' : 'normal')};
`;

export const LabelsPackingFile = styled.span<SpansHeaderInfo>`
  font-size: 0.75rem;
  font-weight: ${({ type }) => (type === 'VALUE' ? 'bold' : 'normal')};
  margin-bottom: -0.25rem;
  color: ${({ type, theme }) => (type === 'VALUE' ? theme.colors.dark_green : 'none')};
`;

export const ContainerTotalizer = styled.div`
  display: flex;
  flex-direction: column;
`;
