import { Col, ConfigProvider, Form, Row } from 'antd';
import { BiDownload } from 'react-icons/bi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../../components/ActionButton';
import { EditableCell } from '../../../components/EditableCell';
import { ValidateMessages } from '../../../helpers/ValidateMessages';
import { useReportsScenarios } from './useReportsScenarios';
import { CustomPagination } from '../../../components/CustomPagination';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import ReportsScenariosFilter from './ReportsScenariosFilter';
import { CsvExport } from '../../../components/Common/CsvExport';
import * as SC from '../../../styles/common';
import { Loading } from '../../../components/Loading';
import { getURI } from '../../../helpers/utils';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import SimpleTable from '../../../components/Tables/SimpleTable';

export function ReportsScenariosPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.reports.scenarios.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchScenarios,
    scenariosOptions,
    selectedScenario,
    fetchReportsScenarios,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    onValuesChange,
    searchFilter,
    clearFilter,
    canUpdate,
    handleCloseFilteredTag,
    csvHeaders,
    mergedColumns,
    getReportScenariosParams,
    transformData,
  } = useReportsScenarios(dataModal);

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';
  const showTagsFilter = params.sku;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchReportsScenarios();
    }
  }, [params]);

  return (
    <main>
      <Row>
        {view ? null : (
          <ReportsScenariosFilter
            title={t('pages.reports.scenarios.title')}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle
                      title={t('pages.reports.scenarios.title')}
                      subtitle={scenarioName}
                      updated={modifiedDate}
                    />
                  }
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
                      <CsvExport
                        url={getURI(`scenarios/${params.scenario}/components/report`, {
                          ...getReportScenariosParams(),
                          order: params.order,
                          orderBy: params.orderBy,
                        })}
                        filename={['reports-scenarios', selectedScenario?.label]}
                        limit={pageControl.totalElements}
                        headers={csvHeaders}
                        transformData={transformData}
                      />
                    </ActionButton>,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                  <SC.TagFilter
                    closable
                    visible={params.header_sku}
                    onClose={() => handleCloseFilteredTag('header_sku')}
                  >
                    <div className="tag-title">{t('pages.reports.scenarios.fields.sku')}</div>
                    <div className="tag-value">{params.header_sku}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_partType?.length}
                    onClose={() => handleCloseFilteredTag('header_partType')}
                  >
                    <div className="tag-title">{t('pages.reports.scenarios.fields.partType')}</div>
                    <div className="tag-value">{params.header_partType}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_spec?.length}
                    onClose={() => handleCloseFilteredTag('header_spec')}
                  >
                    <div className="tag-title">{t('pages.reports.scenarios.fields.spec')}</div>
                    <div className="tag-value">{params.header_spec}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_partNumber?.length}
                    onClose={() => handleCloseFilteredTag('header_partNumber')}
                  >
                    <div className="tag-title">
                      {t('pages.reports.scenarios.fields.partNumber')}
                    </div>
                    <div className="tag-value">{params.header_partNumber}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_componentAmount?.length}
                    onClose={() => handleCloseFilteredTag('header_componentAmount')}
                  >
                    <div className="tag-title">
                      {t('pages.reports.scenarios.fields.componentAmount')}
                    </div>
                    <div className="tag-value">{params.header_componentAmount}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_local?.length}
                    onClose={() => handleCloseFilteredTag('header_local')}
                  >
                    <div className="tag-title">{t('pages.reports.scenarios.fields.local')}</div>
                    <div className="tag-value">{params.header_local}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <Form
                  form={form}
                  component={false}
                  onValuesChange={onValuesChange}
                  validateMessages={validateMessages}
                >
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      size="small"
                      bordered
                      striped
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      columns={mergedColumns}
                      dataSource={data}
                      pagination={false}
                      onChange={handleChangeTable}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
