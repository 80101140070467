import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput } from '../../LateralFilter/SelectInput';

export function ErrorStatusSelect({ ...props }) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([
    { label: 'ERRO_INTEGRACAO', value: 'ERRO_INTEGRACAO' },
    { label: 'PENDENTE', value: 'PENDENTE' },
    { label: 'PROCESSADO', value: 'PROCESSADO' },
  ]);

  return (
    <SelectInput
      placeholder={t('filter.status')}
      name="ErrorStatusSelect"
      id="ErrorStatusSelect"
      options={options}
      {...props}
    />
  );
}
