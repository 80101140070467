import styled from 'styled-components';
import StyledSelect from '../../Common/StyledSelect';

export const UploadSheetsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const StyledSelectListTypeContainer = styled.div`
  width: '100%';
  line-height: '1em';
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray_border};
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-form-item {
    margin-bottom: 0rem;
  }
`;

export const ContentContainer = styled.div`
  margin: 2rem 5rem 4rem 5rem;
`;
