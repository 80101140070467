import * as S from './styles';
import StyledModalProps from './types';

export default function StyledModal({
  title,
  open,
  width,
  onClose,
  body,
  footer,
}: StyledModalProps) {
  return (
    <S.ModalStyled
      title={title}
      onCancel={onClose}
      footer={
        footer ? <S.ModalFooterContainer footer={footer}>{footer}</S.ModalFooterContainer> : null
      }
      open={open}
      width={width}
    >
      <S.ModalBodyContainer footer={footer}>{body}</S.ModalBodyContainer>
    </S.ModalStyled>
  );
}
