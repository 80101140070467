import { Form } from 'antd';
import { useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  getFilterURL,
  removeEmptyFilter,
  getSortOrder,
  oneAlert,
  clearRequestParams,
  hasPropertyWithHeader,
  debounce,
  renderDateHour,
} from '../../helpers/utils';
import { api } from '../../services/api';
import { getColumnSearchProps } from '../../helpers/table';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import { useTable } from '../../hooks/useTable';

const initialParams = {
  order: 'modifiedDate',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
  user: '',
  area: '',
  message: '',
  rangeDate: null,
  start_date: null,
  end_date: null,
};

export function useLogUsers() {
  const { t } = useTranslation();
  const { getSortOrderTable } = useTable();

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [canUpdate, setCanUpdate] = useState(true);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchLogUsers = async () => {
    setIsLoading(true);
    const allParams = { ...params };

    if (params?.rangeDate?.length) allParams.rangeDate = null;
    const queryParams = getFilterURL(removeEmptyFilter(clearRequestParams(allParams)));

    try {
      const {
        data: { content, totalElements, totalPages },
      } = await api.get(`userlogs?${queryParams}`);

      const dataContent = content?.length ? content : [];

      setData(dataContent);

      setPageControl({
        pageLabel: params.page + 1,
        totalPages,
        totalElements,
      });
    } catch (error) {
      console.log('Error on the fetch LogUsers');
    }

    setIsLoading(false);
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    if (data.length === 0) return;

    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const save = async (record) => {
    setIsLoading(true);
    try {
      const response = await api.put(`logs/${record.id}`);
      if (response.status !== 200) throw Error();

      await fetchLogUsers();
      oneAlert('success', t('pages.logusers.successMessage'));
    } catch (error) {
      oneAlert('error', t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const searchFilter = async (newFilters, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
    };

    if (newFilters?.rangeDate?.length) {
      filterParams.start_date = moment(newFilters.rangeDate[0]).format('YYYY-MM-DD');
      filterParams.end_date = moment(newFilters.rangeDate[1]).format('YYYY-MM-DD');
    }

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      user: '',
    });
  };

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    const value = formValue?.trim();
    if (value?.length) {
      const filterParams = {
        ...params,
        //removendo paginação para os filtros de buscas dinâmicos
        page: 0,
        [`header_${dataIndex}`]: value,
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else {
      handleHeaderReset(dataIndex);
    }
  };

  const handleSelectRow = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.id]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const handleSelectAllRows = () => {
    setSelectedRowKeys((keys) => (keys.length === data.length ? [] : data.map((r) => r.id)));
  };

  const handleDeleteLogs = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete(`userlogs`, { data: { ids: selectedRowKeys.toString() } });
      if (response.status !== 200) throw Error();

      await fetchLogUsers();
      oneAlert('success', t('pages.logusers.successDeleteMessage'));
      setSelectedRowKeys([]);
    } catch (error) {
      oneAlert('error', t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    fixed: true,
    onSelect: handleSelectRow,
    columnTitle: <> </>,
  };

  const columns = [
    {
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
      title: t('pages.logusers.fields.modifiedDate'),
      // title: () => {
      //   return (
      //     <DatePicker
      //       picker="day"
      //       style={{ width: '100%' }}
      //       placeholder={t('pages.scenarios.fields.targetYear')}
      //       onClick={(e) => e.stopPropagation()}
      //       onChange={(value) => changeDate(value)}
      //     />
      //   );
      // },
      align: 'center',
      label: t('pages.logusers.fields.modifiedDate'),
      sorter: 'true',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modifiedDate' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      width: '180px',
      render: (value) => renderDateHour(value, true),
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: t('pages.logusers.fields.user'),
      label: t('pages.logusers.fields.user'),
      ...getColumnSearchProps({
        dataIndex: 'user',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      key: 'area',
      dataIndex: 'area',
      title: t('pages.logusers.fields.area'),
      label: t('pages.logusers.fields.area'),
      ...getColumnSearchProps({
        dataIndex: 'area',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: t('pages.logusers.fields.message'),
      label: t('pages.logusers.fields.message'),
    },
  ];

  const onCellRecords = (record, col) => ({
    record,
    newInputNode: col.dataIndex === 'partType' && <ComponentsTypeSelect />,
    dataIndex: col.dataIndex,
    title: col.title,
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => onCellRecords(record, col),
    };
  });

  const exportColumns = columns.filter((col) => col.key !== 'action');

  return {
    data,
    params,
    pageControl,
    canUpdate,
    fetchLogUsers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    mergedColumns,
    save,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    rowSelection,
    handleSelectAllRows,
    selectedRowKeys,
    handleDeleteLogs,
  };
}
