import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CbomItem, PricesPageProps } from './types';
import * as S from './styles';
import theme from '../../styles/theme';
import { getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';

export function useFinance() {
  const [openCbomCard, setOpenCbomCard] = useState<boolean>(true);
  const { t } = useTranslation();
  const [priceProps, setPriceProps] = useState<PricesPageProps>();
  const [isLoading, setIsLoading] = useState(false);

  const [cbomItens, setCbomItens] = useState<Array<CbomItem>>([]);

  const handleFetchCbomItens = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        getURI('quotations/details/', {
          level: priceProps?.level,
          sku: priceProps?.sku,
          date: priceProps?.monthYear,
          compType: priceProps?.compType,
        })
      );
      setCbomItens(data.content || []);
    } catch (error: any) {
      console.log(error);
      setCbomItens([]);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormatQuotePrice = (value = '-'): string => {
    try {
      return Number(value).toFixed(2).toString() || '-';
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const handleFormatQtyValue = (value: string): number => {
    try {
      return Number(value);
    } catch (error) {
      return 0;
    }
  };

  const handleFormatValueFields = (number: string | number): string => {
    try {
      return Number(number).toLocaleString(t('common.localeMoney'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (error) {
      console.log(error);
      return '-';
    }
  };

  const columns: ColumnsType<CbomItem> = [
    // part number
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.partNumber`)}</span>
          </S.HeaderCellTable>
        );
      },

      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',

      render: (_, record) => {
        return (
          <S.DataCellTableContent>
            <span>{record.partNumber}</span>
            <Tooltip placement="topLeft" title={record.description}>
              <InfoCircleFilled style={{ color: theme.colors.dark_green }} />
            </Tooltip>
          </S.DataCellTableContent>
        );
      },
    },
    //demand
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.demand`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'demand',
      key: 'demand',
      align: 'right',

      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{record.demand}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //QTY

    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.qty`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'qty',
      key: 'qty',
      align: 'center',

      // render: (_, record) => {
      //   // return <p>{record.qty}</p>;
      //   return (
      //     <S.DataCellTableContent>
      //       <Progress
      //         percent={Number(record.qty) || 0}
      //         style={{ minWidth: '100px' }}
      //         strokeColor={
      //           handleFormatQtyValue(record.qty) <= 50
      //             ? theme.colorsDesignSystem.red
      //             : theme.colorsDesignSystem.blue
      //         }
      //       />
      //     </S.DataCellTableContent>
      //   );
      // },
    },
    //unit price
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.unitPrice`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{handleFormatValueFields(record.unitPrice)}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //total invoice
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.totalInvoiceFbr`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'totaoInvoice',
      key: 'totaoInvoice',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{handleFormatValueFields(record.totalInvoiceFbr)}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //II %
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.ii`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'ii',
      key: 'ii',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{record.ii || '-'}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //cofins
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.cofins`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'cofins',
      key: 'cofins',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{handleFormatValueFields(record.cofins)}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //cif
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.cif`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'cif',
      key: 'cif',
      align: 'right',
      render: (_, record) => {
        return (
          <S.DataCellTableContent className="right">
            <span>{handleFormatValueFields(record.cif)}</span>
          </S.DataCellTableContent>
        );
      },
    },
    //vendor Name
    {
      title: () => {
        return (
          <S.HeaderCellTable>
            <span>{t(`pages.financePage.tableHeaders.vendorName`)}</span>
          </S.HeaderCellTable>
        );
      },
      dataIndex: 'vendorName',
      key: 'vendorName',
      align: 'left',
      render: (_, record) => {
        return (
          <S.DataCellTableContent>
            <span>{record.vendorName}</span>
          </S.DataCellTableContent>
        );
      },
    },
  ];

  return {
    openCbomCard,
    cbomItens,
    columns,
    priceProps,
    isLoading,
    setOpenCbomCard,
    setPriceProps,
    handleFetchCbomItens,
    handleFormatQuotePrice,
  };
}
