import { Table, Row, Col } from 'antd';
import styled from 'styled-components';

export const NoProjectCol = styled(Col)`
  width: 100%;

  .col-no-results {
    max-width: 100%;
    margin-top: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray_border};
  }
`;

export const SearchFilter = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .col-filters {
    display: flex;
    justify-content: flex-end;
  }

  .search-line {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_border};

    .ant-select-selector {
      border: none;
      background-color: transparent;
    }
  }
`;
