import { Card as AntCard } from 'antd';
import styled, { css } from 'styled-components';

type ProjectNameProps = {
  $isActive: boolean;
};

type LegendContainerProps = {
  align?: React.CSSProperties['justifyContent'];
  padding: React.CSSProperties['padding'];
};

type LegendProps = {
  color?: string;
  type?: 'circle' | 'line' | 'square';
};

export const Card = styled(AntCard)`
  width: 100%;
  height: 100%;
  max-height: 392px;

  & .ant-card-body {
    width: 100%;
    height: 392px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProjectName = styled.span<ProjectNameProps>`
  font-weight: 500;
  color: ${({ $isActive }) => ($isActive ? '#5EA320' : 'rgba(102, 102, 102, 0.45)')};
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'default')};
`;

export const LegendContainer = styled.div<LegendContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: ${({ align }) => align};
  padding: ${({ padding }) => padding};

  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Legend = styled.span<LegendProps>`
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color || '#999'};

  ${({ type }) =>
    type === 'line' &&
    css`
      border-radius: 0;
      height: 1px;
      width: 38px;
    `}

  ${({ type }) =>
    type === 'square' &&
    css`
      border-radius: 0;
      height: 12px;
      width: 38px;
    `}
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const ModalTable = styled.table`
  background: #f8f8f8;
`;

export const TableHead = styled.th`
  color: #999;
  padding-top: 8px;
`;

export const TableData = styled.td`
  text-align: center;
  color: #3d85c6;
  padding-bottom: 8px !important;
`;

export const ModalGraphTitle = styled.h6`
  font-weight: 400;
  text-align: center;
  margin: 0 auto 24px;
  color: #303030;
  font-size: 12px;
`;

export const ModalGraphLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
