/* eslint-disable no-undefined */
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form } from 'antd';
import { Row } from 'antd/lib';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import StyledSelect from '../../../components/Common/StyledSelect';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import * as S from './styles';
import { dataType } from './types';
import { useFinanceDashboard } from './useFinanceDashboard';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { CustomTooltip } from '../../../components/Common/RechartsTooltips/FinanceDashboardTableTooltip';
import { Loading } from '../../../components/Loading';
import theme from '../../../styles/theme';
import { SkuAnalysis } from './SkuAnalysis';
import { StartVSEnd } from './StartVSEnd';
import { CostTrend } from './CostTrend';

export const FinanceDashboardPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.finance.dashboard.titleFinance'));
  const {
    columns,
    headerFilterData,
    allSkuData,
    formBody,
    projectsList,
    skusList,
    bodyData,
    selectedData,
    isLoading,
    tableIsLoading,
    params,
    currentDate,
    threeFirstSelectedSkus,

    updateThreeFirstSelectedSkus,
    calculatePercentage,
    updateDate,
    selectedTotalIsEmptyOrZero,
    totalCheckedUSD,
    fetchBodyFilterContent,
    formatProjectNameParamsForCard,
    setSelectedData,
    handleChangeTable,
    updateParams,
    fetchBodyContent,
    handleUpdateSkuData,
    handleClearOldSkuAfterSelectNewProject,
    handleSaveSelectedSkuOnForm,
    fetchAll,
    formatDateForCard,
    handleRenderFilterTags,
    handleRenderClearAllFilters,
  } = useFinanceDashboard();

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <StyledPageHeader title={<HeaderTitle title={t('pages.finance.dashboard.titleFinance')} />} />
      {isLoading ? (
        <Loading />
      ) : (
        <S.BodyContainer>
          <S.FilterAndTagContainer>
            <S.FiltersContainer>
              <StyledSelect
                styles={{ minWidth: '6.25rem' }}
                options={headerFilterData.cm || []}
                value={params.cm}
                label={t('pages.finance.dashboard.labelCM')}
                placeholder={t('pages.finance.dashboard.placeholderCM')}
                onChange={(value: string) => {
                  updateParams('cm', value);
                }}
              />
              <StyledSelect
                styles={{ minWidth: '7.5rem' }}
                options={headerFilterData.productCategory || []}
                value="Notebook"
                disable
                label={t('pages.finance.dashboard.labelProduct')}
                placeholder={t('pages.finance.dashboard.placeholderProduct')}
                onChange={() => {
                  updateParams('product', 'Notebook');
                }}
              />
              <StyledDatePicker
                picker="month"
                mode="month"
                label={t('pages.finance.dashboard.labelPeriod')}
                allowClear={false}
                defaultValue={dayjs(`${params.month}/${params.year}`)}
                format="MM/YYYY"
                onChange={(value) => {
                  updateParams('date', value);
                  updateParams('month', value.format('MMMM'));
                  updateParams('year', value.format('YYYY'));
                }}
              />
              <StyledSelect
                styles={{ minWidth: '60px' }}
                options={headerFilterData.level || []}
                value={params.level}
                onChange={(value: string) => {
                  updateParams('level', value);
                }}
                label={t('pages.finance.dashboard.labelLevel')}
                placeholder={t('pages.finance.dashboard.placeholderLevel')}
              />
              <Checkbox
                checked={params.withForecast}
                style={{ padding: '0.375rem' }}
                onChange={() => {
                  updateParams('withForecast', !params.withForecast);
                }}
              >
                {t('pages.quotes.labels.withDemand')}
              </Checkbox>
              <S.ApplyButton
                variant="primary"
                text={t('pages.finance.dashboard.applyButton')}
                onClick={() => {
                  fetchBodyFilterContent();
                  fetchBodyContent(true);
                  updateDate(params.date);
                }}
              />
            </S.FiltersContainer>
            <S.FiltersTagContainer>
              {handleRenderFilterTags()}
              {handleRenderClearAllFilters()}
            </S.FiltersTagContainer>
          </S.FilterAndTagContainer>
          <S.TableContainers>
            <S.Card>
              <S.CardHeader>
                <strong>SKU Cost</strong> |{' '}
                <S.BlueText>{formatProjectNameParamsForCard(params.projectNames)}</S.BlueText> |{' '}
                <S.BlueText>{formatDateForCard(params.month, params.year)}</S.BlueText>
              </S.CardHeader>
              <Row>
                <Col span={50} className="grafic">
                  <ResponsiveContainer width="100%" height={500}>
                    <PieChart>
                      {selectedTotalIsEmptyOrZero() && <Tooltip content={<CustomTooltip />} />}
                      <Pie
                        dataKey="total"
                        innerRadius={95}
                        outerRadius={130}
                        cx="50%"
                        cy="65%"
                        data={
                          selectedTotalIsEmptyOrZero()
                            ? (calculatePercentage(selectedData) as any)
                            : [{ total: 1 }]
                        }
                      >
                        <Label
                          position="center"
                          value={totalCheckedUSD()}
                          style={{
                            color: `${theme.colorsDesignSystem.text}`,
                            fontWeight: 700,
                            fontSize: '1.5rem',
                          }}
                        />
                        {selectedTotalIsEmptyOrZero() ? (
                          selectedData.map((entry, index) => {
                            if (entry.total) {
                              return <Cell key={`cell-${entry.sku}`} fill={entry.color} />;
                            }
                            return <Cell key={`cell-${entry.sku}`} fill="#F1F2F4" />;
                          })
                        ) : (
                          <Cell key="cell-1" fill="#F1F2F4" />
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <span className="totalLabel">Total USD</span>
                </Col>
                <div style={{ width: '70%' }}>
                  <Form form={formBody} style={{ width: '100%' }}>
                    <S.TableFiltersContainer>
                      <S.SelectContainer>
                        <Form.Item name="projectName1">
                          <StyledSelect
                            placeholder={t(
                              'pages.finance.dashboard.table.placeholders.projectName'
                            )}
                            label={`${t('pages.finance.dashboard.table.labels.projectName')} 01`}
                            showSearch
                            options={projectsList}
                            onChange={(value: string) => {
                              handleClearOldSkuAfterSelectNewProject({ field: 'sku1' });
                              updateParams('projectNames', 'ProjectName01');
                              handleUpdateSkuData('SKU_01', value);
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                      <S.SelectContainer>
                        <Form.Item name="projectName2">
                          <StyledSelect
                            placeholder={t(
                              'pages.finance.dashboard.table.placeholders.projectName'
                            )}
                            label={`${t('pages.finance.dashboard.table.labels.projectName')} 02`}
                            showSearch
                            options={projectsList}
                            onChange={(value: string) => {
                              handleClearOldSkuAfterSelectNewProject({ field: 'sku2' });
                              updateParams('projectNames', 'ProjectName02');
                              handleUpdateSkuData('SKU_02', value);
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                      <S.SelectContainer>
                        <Form.Item name="projectName3">
                          <StyledSelect
                            placeholder={t(
                              'pages.finance.dashboard.table.placeholders.projectName'
                            )}
                            label={`${t('pages.finance.dashboard.table.labels.projectName')} 03`}
                            showSearch
                            options={projectsList}
                            onChange={(value: string) => {
                              handleClearOldSkuAfterSelectNewProject({ field: 'sku3' });
                              updateParams('projectNames', 'ProjectName03');
                              handleUpdateSkuData('SKU_03', value);
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                    </S.TableFiltersContainer>
                    <S.TableFiltersContainer>
                      <S.SelectContainer>
                        <Form.Item name="sku1">
                          <StyledSelect
                            placeholder={t('pages.finance.dashboard.table.placeholders.sku')}
                            label="SKU 01"
                            showSearch
                            options={allSkuData.SKU_01.length ? allSkuData.SKU_01 : skusList}
                            onChange={(value: string) => {
                              handleSaveSelectedSkuOnForm({ field: 'sku1', value });
                              updateParams('skus', 'Sku01');
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                      <S.SelectContainer>
                        <Form.Item name="sku2">
                          <StyledSelect
                            placeholder={t('pages.finance.dashboard.table.placeholders.sku')}
                            label="SKU 02"
                            showSearch
                            options={allSkuData.SKU_02.length ? allSkuData.SKU_02 : skusList}
                            onChange={(value: string) => {
                              handleSaveSelectedSkuOnForm({ field: 'sku2', value });
                              updateParams('skus', 'Sku02');
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                      <S.SelectContainer>
                        <Form.Item name="sku3">
                          <StyledSelect
                            placeholder={t('pages.finance.dashboard.table.placeholders.sku')}
                            label="SKU 03"
                            showSearch
                            options={allSkuData.SKU_03.length ? allSkuData.SKU_03 : skusList}
                            onChange={(value: string) => {
                              handleSaveSelectedSkuOnForm({ field: 'sku3', value });
                              updateParams('skus', 'Sku03');
                            }}
                            suffixIcon={
                              <S.SearchButton>
                                <SearchOutlined className="icon" />
                              </S.SearchButton>
                            }
                          />
                        </Form.Item>
                      </S.SelectContainer>
                    </S.TableFiltersContainer>
                  </Form>
                  <SimpleTable
                    dataSource={bodyData as any}
                    onChange={handleChangeTable}
                    rowKey="id"
                    isLoading={tableIsLoading}
                    rowSelection={{
                      setSelectedData,
                      defaultSelectedRowKeys: selectedData.map((row) => row.id),
                      selectedRowKeys: selectedData.map((row) => row.id),
                      selectedData,
                      onChange: (selectedRowKeys: number, selectedRows: Array<dataType>) => {
                        const formatedRows: any = selectedRows.map((row: dataType) => {
                          return {
                            ...row,
                            porcentage: row.porcentage,
                            total: Number(row.total),
                          };
                        });
                        updateThreeFirstSelectedSkus(selectedRows);
                        setSelectedData(formatedRows);
                      },
                    }}
                    scroll={{ y: 350 }}
                    pagination={false}
                    columns={columns}
                  />
                </div>
              </Row>
            </S.Card>
            <SkuAnalysis
              skus={threeFirstSelectedSkus}
              date={currentDate}
              level={params.level}
              cm={params.cm}
            />
            <CostTrend
              cm={params.cm}
              level={params.level}
              date={params.date}
              selectedSkus={threeFirstSelectedSkus}
            />
          </S.TableContainers>
          <S.TableContainers>
            <StartVSEnd
              skus={threeFirstSelectedSkus}
              date={currentDate}
              level={params.level}
              cm={params.cm}
            />
          </S.TableContainers>
          <S.TableContainers />
        </S.BodyContainer>
      )}
    </>
  );
};
