import { Col, Row } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import theme from '../../styles/theme';

export const Container = styled.main`
  margin-right: 0.5rem;
`;

export const SearchFilter = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: max-content;
  width: 100%;
  padding-left: 0.938rem;
  gap: 0.5rem;
`;

export const TagFilter = styled(Row)`
  display: flex;
  flex-direction: row;
  margin-top: 0.938rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 0.938rem;
  margin-inline-end: none !important;
  gap: 0.313rem;
`;
export const RadioFilter = styled(Row)`
  display: flex;
  flex-direction: row;
  margin-top: 3.125rem;
  margin-bottom: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.313rem;
`;
export const BodyPage = styled.div`
  margin-top: 1rem;
  padding-left: 0.75rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.4rem;
  border-top: solid 1px #f0f0f0;
`;

export const LinkTable = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 0.938rem;
  word-break: break-all;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 0.2rem;
  padding: 0rem;
`;

export const MessageTitle = styled.span`
  font-weight: 400;
  font-size: 1rem;
  padding: 0rem;
`;
export const MessageBody = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0rem;
`;
export const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  padding-bottom: 0.3rem;
`;

export const MessageIcon = styled(SyncOutlined)`
  font-size: 1.5rem;
  color: ${theme.colorsDesignSystem.blue};
  margin-top: 0.15rem;
  margin-right: 0.7rem;
`;

export const CheckIconsContainer = styled.div`
  margin-top: 1.2rem;
`;
