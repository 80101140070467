import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import { Average, SupplierInvoice, Params } from './types';
import { SpanHeaderTables } from './styles';
import { api } from '../../services/api';
import { getURI, oneAlert } from '../../helpers/utils';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';

export function useAverage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSku, setSelectedSku] = useState('');
  const [partNumbers, setPartNumbers] = useState<StyledSelectOptions[]>([]);
  const [categoriesByCm, setCategoriesByCm] = useState<StyledSelectOptions[]>([]);
  const [averageData, setAverageData] = useState<Array<Average>>([]);
  const [fieldsErros, setFieldsErros] = useState({
    period: false,
    partNumber: false,
  });

  const CMs = [
    { value: 'Compal', label: 'Compal' },
    { value: 'Foxconn', label: 'Foxconn' },
  ];

  const sources = [
    { value: '', label: t('common.showAll') },
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
    { value: 'N/A', label: 'N/A' },
  ];

  const levels = [
    { value: '', label: t('common.showAll') },
    { value: 'L6', label: 'L6' },
    { value: 'L10', label: 'L10' },
  ];

  const initialParams: Params = {
    product: '',
    period: dayjs(),
    cm: 'Compal',
    category: '',
    source: '',
    partNumber: null,
    level: '',
    page: 0,
    limit: 10,
  };

  const [params, setParams] = useState(initialParams);

  const [expandableViewStatus, setExpandableViewStatus] = useState({
    average: true,
    priceTable: false,
    suppliersInvoice: false,
  });

  // const averageData: Array<Average> = [
  //   {
  //     category: 'CPU',
  //     material: 'KC.R30B2.325',
  //     materialDescription: 'CPU(BGA) AMD RYZEN 3 3250U FP5 2.6G 15W',
  //     qtyPurchase: 5079,
  //     purchasePriceUSD: 70.18,
  //     purchaseInvNo: '2.220027835E9',
  //     invoiceDate: '2020-12',
  //     weightAveragePriceUSD: 70.18,
  //     fgQuoteDemand: 'December',
  //     demandQty: 5000,
  //     totalPOUSD: 356444,
  //     totalQuoteUSD: 350900,
  //     currentInventory: 117,
  //     currentAvgInventoryUnit: '72.37069092581494',
  //     beforeUseInventory: 5079,
  //     beforeUseAvgInventoryUnit: '70.18',
  //     remark: '',
  //   },
  //   {
  //     category: 'CPU',
  //     material: 'KC.R30B2.325',
  //     materialDescription: 'CPU(BGA) AMD RYZEN 3 3250U FP5 2.6G 15W',
  //     qtyPurchase: 5079,
  //     purchasePriceUSD: 70.18,
  //     purchaseInvNo: '2.220027835E9',
  //     invoiceDate: '2020-12',
  //     weightAveragePriceUSD: 70.18,
  //     fgQuoteDemand: 'December',
  //     demandQty: 5000,
  //     totalPOUSD: 356444,
  //     totalQuoteUSD: 350900,
  //     currentInventory: 117,
  //     currentAvgInventoryUnit: '72.37069092581494',
  //     beforeUseInventory: 5079,
  //     beforeUseAvgInventoryUnit: '70.18',
  //     remark: 'Atrition',
  //   },
  // ];

  const columns: ColumnsType<Average> = [
    {
      title: t('pages.average.tableHeaders.partNumber'),
      align: 'center',
      dataIndex: 'material',
      key: 'material',
    },
    {
      title: (
        <SpanHeaderTables>
          Qty <br />
          {t('pages.average.tableHeaders.qtyPurchase')}
        </SpanHeaderTables>
      ),
      align: 'right',
      dataIndex: 'qtyPurchase',
      key: 'qtyPurchase',
      render: (_, record) => {
        const convertedValue = Number(record.qtyPurchase);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'));
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.price')} <br />{' '}
          {t('pages.average.tableHeaders.qtyPurchase')}
        </SpanHeaderTables>
      ),
      key: 'purchasePriceUSD',
      dataIndex: 'purchasePriceUSD',
      align: 'right',
      render: (_, record) => {
        const convertedValue = Number(record.purchasePriceUSD);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            });
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.qtyPurchase')} <br />{' '}
          {t('pages.average.tableHeaders.invNo')}
        </SpanHeaderTables>
      ),
      key: 'purchaseInvNo',
      dataIndex: 'purchaseInvNo',
      align: 'center',
    },
    {
      title: (
        <SpanHeaderTables>
          <SpanHeaderTables>{t('pages.average.tableHeaders.invDate')}</SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'invoiceDate',
      dataIndex: 'invoiceDate',
      align: 'center',
    },
    {
      title: (
        <div>
          <SpanHeaderTables>
            {t('pages.average.tableHeaders.weight')} <br />{' '}
            {t('pages.average.tableHeaders.average')} <br /> {t('pages.average.tableHeaders.price')}
          </SpanHeaderTables>
        </div>
      ),
      key: 'weightAveragePriceUSD',
      dataIndex: 'weightAveragePriceUSD',
      align: 'center',
      render: (_, record) => {
        const convertedValue = Number(record.weightAveragePriceUSD);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            });
      },
    },
    {
      title: (
        <SpanHeaderTables>
          FG {t('pages.average.tableHeaders.quote')} <br /> {t('pages.average.tableHeaders.demand')}
        </SpanHeaderTables>
      ),
      key: 'fgQuoteDemand',
      dataIndex: 'fgQuoteDemand',
      align: 'center',
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.demand')} <br />{' '}
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (Qty)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'demandQty',
      dataIndex: 'demandQty',
      align: 'right',
      render: (value, record) => {
        return record.remark ? (
          <Tooltip title={record.remark} placement="right">
            <span style={{ color: theme.colorsDesignSystem.primary }}>{value}</span>
          </Tooltip>
        ) : (
          <span>{Number(value).toLocaleString(t('common.localeMoney'))}</span>
        );
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.total')} PO <br />{' '}
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (USD)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'totalPOUSD',
      dataIndex: 'totalPOUSD',
      align: 'right',
      render: (_, record) => {
        const convertedValue = Number(record.totalPOUSD);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.total')} <br /> {t('pages.average.tableHeaders.quote')}{' '}
          <br />
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (USD)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'totalQuoteUSD',
      dataIndex: 'totalQuoteUSD',
      align: 'center',
      render: (_, record) => {
        const convertedValue = Number(record.totalQuoteUSD);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.current')} <br /> Avg. Inv.
        </SpanHeaderTables>
      ),
      key: 'currentAvgInventoryUnit',
      dataIndex: 'currentAvgInventoryUnit',
      align: 'center',
      render: (_, record) => {
        const convertedValue = Number(record.currentAvgInventoryUnit);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            });
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.before')} <br /> {t('pages.average.tableHeaders.use')}{' '}
          {t('pages.average.tableHeaders.inventory')}
        </SpanHeaderTables>
      ),
      key: 'beforeUseInventory',
      dataIndex: 'beforeUseInventory',
      align: 'center',
      render: (_, record) => {
        const convertedValue = Number(record.beforeUseInventory);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'));
      },
    },
    {
      title: (
        <SpanHeaderTables>
          {t('pages.average.tableHeaders.before')} <br /> {t('pages.average.tableHeaders.use')} Avg
        </SpanHeaderTables>
      ),
      key: 'beforeUseAvgInventoryUnit',
      dataIndex: 'beforeUseAvgInventoryUnit',
      align: 'center',
      render: (_, record) => {
        const convertedValue = Number(record.beforeUseAvgInventoryUnit);
        return Number.isNaN(convertedValue)
          ? 0
          : convertedValue.toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            });
      },
    },
  ];

  const supplierInvoiceData: Array<SupplierInvoice> = [
    {
      originUf: 'AE(SP)',
      destiny: 'Foxconn(MAO)',
      date: '11/23',
      netPrice: '38.41',
      grossPrice: '47.97',
      delivery: '999.997',
      billingData: '13.273',
      invoice: '999999',
    },
    {
      originUf: 'AE(SP)',
      destiny: 'Foxconn(MAO)',
      date: '11/23',
      netPrice: '38.41',
      grossPrice: '47.97',
      delivery: '999.997',
      billingData: '13.273',
      invoice: '999999',
    },
    {
      originUf: 'AE(SP)',
      destiny: 'Foxconn(MAO)',
      date: '11/23',
      netPrice: '38.41',
      grossPrice: '47.97',
      delivery: '999.997',
      billingData: '13.273',
      invoice: '999999',
    },
    {
      originUf: 'AE(SP)',
      destiny: 'Foxconn(MAO)',
      date: '11/23',
      netPrice: '38.41',
      grossPrice: '47.97',
      delivery: '999.997',
      billingData: '13.273',
      invoice: '999999',
    },
    {
      originUf: 'AE(SP)',
      destiny: 'Foxconn(MAO)',
      date: '11/23',
      netPrice: '38.41',
      grossPrice: '47.97',
      delivery: '999.997',
      billingData: '13.273',
      invoice: '999999',
    },
  ];

  const columnsInvoice: ColumnsType<SupplierInvoice> = [
    {
      title: 'Origin (UF)',
      align: 'center',
      dataIndex: 'originUf',
      key: 'originUf',
    },
    {
      title: <SpanHeaderTables>Destiny</SpanHeaderTables>,
      align: 'right',
      dataIndex: 'destiny',
      key: 'destiny',
    },

    {
      title: <SpanHeaderTables>Date</SpanHeaderTables>,
      key: 'date',
      dataIndex: 'date',
      align: 'right',
    },
    {
      title: (
        <SpanHeaderTables>
          Net Price <br />{' '}
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (USD)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'netPrice',
      dataIndex: 'netPrice',
      align: 'center',
    },
    {
      title: (
        <SpanHeaderTables>
          Gross Price <br />{' '}
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (USD)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'grossPrice',
      dataIndex: 'grossPrice',
      align: 'center',
    },
    {
      title: (
        <SpanHeaderTables>
          Delivery <br />{' '}
          <SpanHeaderTables style={{ fontSize: '0.625rem', fontWeight: 'normal' }}>
            (USD)
          </SpanHeaderTables>
        </SpanHeaderTables>
      ),
      key: 'delivery',
      dataIndex: 'delivery',
      align: 'center',
    },
    {
      title: <SpanHeaderTables>Billing Data</SpanHeaderTables>,
      key: 'billingData',
      dataIndex: 'billingData',
      align: 'center',
    },
    {
      title: <SpanHeaderTables>Invoice</SpanHeaderTables>,
      key: 'invoice',
      dataIndex: 'invoice',
      align: 'center',
    },
  ];

  const handleUpdateFilters = (field: string, value: any) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleUpdateExpandableViews = (field: string, value: boolean) => {
    setExpandableViewStatus((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const clearAllFilters = () => {
    setSelectedSku('');
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (
      // params.cm ||
      params.category ||
      params.partNumber ||
      params.source ||
      params.level

      // params.project ||
      // params.project ||
      // params.sku
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.cm && (
          <StyledFilterTag
            label={t('pages.quotes.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              handleUpdateFilters('cm', '');
            }}
          />
        )} */}
        {/* {params.period && (
          <StyledFilterTag
            label={t('pages.quotes.labels.date')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.period}
            closeble
            onClose={() => {
              handleUpdateFilters('period', '');
            }}
          />
        )} */}
        {params.level && (
          <StyledFilterTag
            label={t('pages.quotes.labels.level')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.level}
            closeble
            onClose={() => {
              handleUpdateFilters('level', '');
            }}
          />
        )}
        {params.product && (
          <StyledFilterTag
            label={t('pages.quotes.labels.product')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.product}
            closeble
            onClose={() => {
              handleUpdateFilters('product', '');
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.average.filtersLabels.source')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.source}
            closeble
            onClose={() => {
              handleUpdateFilters('source', '');
            }}
          />
        )}
        {params.category && (
          <StyledFilterTag
            label={t('pages.average.filtersLabels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', '');
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.average.filtersLabels.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}
      </>
    );
  };

  const handleFetchPartNumberByCategory = async () => {
    try {
      //limpando partnumbers caso seja removida a categoria selecionada
      if (!params.category) {
        setPartNumbers([]);
        handleUpdateFilters('partNumber', '');
        return;
      }

      const { data } = await api.get(
        getURI('average/types', {
          ...params,
          period: dayjs(params.period).format('MMMM/YYYY').toUpperCase(),
        })
      );

      setPartNumbers(
        data.map((item: string) => {
          return { label: item, value: item };
        }) || []
      );
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const handleFetchCategoryByCm = async () => {
    try {
      setCategoriesByCm([]);
      handleUpdateFilters('category', '');

      const { data } = await api.get(`average/cm?cm=${params.cm}`);

      setCategoriesByCm(
        data.map((item: string) => {
          return { label: item, value: item };
        }) || []
      );
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const handleFetchAverage = async () => {
    try {
      if (!params.period) {
        setFieldsErros((prevState) => {
          return {
            ...prevState,
            period: true,
          };
        });

        return;
      }

      if (!params.partNumber) {
        setFieldsErros((prevState) => {
          return {
            ...prevState,
            partNumber: true,
          };
        });

        return;
      }

      setIsLoading(true);
      const { data } = await api.get(
        getURI('average', {
          ...params,
          period: dayjs(params.period).format('YYYY/MM'),
        })
      );
      setAverageData(data || []);
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    CMs,
    levels,
    params,
    selectedSku,
    expandableViewStatus,
    columns,
    columnsInvoice,
    averageData,
    supplierInvoiceData,
    sources,
    partNumbers,
    fieldsErros,
    categoriesByCm,
    setFieldsErros,
    setSelectedSku,
    clearAllFilters,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    handleRenderFiltersTags,
    handleUpdateExpandableViews,
    handleFetchPartNumberByCategory,
    handleFetchAverage,
    handleFetchCategoryByCm,
  };
}
