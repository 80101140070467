import React from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LateralFilter } from '../../components/LateralFilter';
import { ErrorStatusSelect } from '../../components/forms/ErrorStatusSelect';

export default function FileErrorsFilter({ clearFilter, searchFilter, values, title }) {
  const { t } = useTranslation();

  return (
    <LateralFilter
      onClearFilter={clearFilter}
      onSearch={searchFilter}
      values={values}
      title={title}
    >
      <Row gutter={[8]} data-cy="filter-form">
        <Col span={24}>
          <Form.Item label={t('filter.status')} name="status">
            <ErrorStatusSelect intable="intable" onClick={(e) => e.stopPropagation()} />
          </Form.Item>
        </Col>
      </Row>
    </LateralFilter>
  );
}
