import { NavLink, useHistory, useLocation } from 'react-router-dom';
import LogoImg from '../../../assets/Acer_green.svg';
import Barra from '../../../assets/barra_header.svg';
import * as S from './styles';
import { RoutesPath } from '../../../routes/routesPath';

export function LoginHeader({ children }) {
  const history = useHistory();
  const location = useLocation();

  const getLocationTitle = () => {
    // console.log(location);
    const locationTitle = location.pathname.split('/')[1];
    return locationTitle.charAt(0).toUpperCase() + locationTitle.slice(1);
  };

  return (
    <S.Header>
      <S.LogoContainer>
        <NavLink to={RoutesPath.scenarios}>
          <S.LogoAcerImg
            src={LogoImg}
            alt="Acer Simulador PPB"
            onClick={() => {
              history.push(RoutesPath.scenarios);
            }}
          />
        </NavLink>
        <img src={Barra} alt="Acer Simulador PPB" />
        <S.HeaderTitle>Procurement / {getLocationTitle()}</S.HeaderTitle>
      </S.LogoContainer>
      {children}
    </S.Header>
  );
}
