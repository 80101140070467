import dayjs from 'dayjs';

// eslint-disable-next-line no-shadow
export enum SliderTooltipMarks {
  'Top 5',
  'Top 10',
  'Top 15',
  'Top 20',
  'View all',
}

export interface FilterData {
  key: string;
  value: string;
}

export interface PageFilters {
  product?: string;
  cm?: string;
  projectName?: string;
  sku?: string;
  componentType?: string;
  date?: any;
  level: string;
  topBy: number;
  order?: string;
  orderBy?: string;
  withForecast?: boolean;
  componentGroup?: string;
  spec?: string;
  partNumber?: string;
  source?: string;
  supplier?: string;
  month: string;
}

export interface columnsTypes {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
  sorter: boolean;
  editable: boolean;
}

export interface avgForMonthForecast {
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
}

export interface dataType {
  id: string;
  sku: string;
  project: string;
  cm: string;
  monthForecast: string | number;
  unit: string | number;
  total: string | number;
  porcentage: string | number;
  dashboardMonths: avgForMonthForecast;
}

export interface AllData {
  data: dataType[];
  totalUSD: number;
}

export interface dataPartNumber {
  partNumber: string | null;
  supplier: string | null;
  negotiatedValue: number;
  executedValue: number;
}

export interface tooltipChart {
  projectName: string | null;
  spec: string | null;
  partNumbers: Array<dataPartNumber>;
}

export interface chartUnitPriceTotalInvoiceData {
  negotiated: number;
  executed: number;
  gap: number;
  tooltipData: Array<tooltipChart>;
}

export interface negotiatedExecutedData {
  componentGroup: string | null;
  supplier: string | null;
  spec: string | null;
  average: boolean;
  chartValues: Array<chartUnitPriceTotalInvoiceData>;
}
export interface dataBar {
  name: string;
  value: [number, number];
  label: number;
}
export interface chartData {
  dataChart: Array<dataBar>;
  tooltipData: Array<tooltipChart>;
  legend?: boolean;
}
