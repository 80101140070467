import React from 'react';
import { Col, Form, Row, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { LateralFilter } from '../../components/LateralFilter';

const { RangePicker } = DatePicker;

export default function LogUsersFilter({ clearFilter, searchFilter, values, title }) {
  const { t } = useTranslation();

  function handleResetParams(event) {
    if (event === null) {
      clearFilter();
    }
  }

  return (
    <LateralFilter
      onClearFilter={clearFilter}
      onSearch={searchFilter}
      values={values}
      title={title}
    >
      <Row gutter={[8]} data-cy="filter-form">
        <Col span={24}>
          <Form.Item name="rangeDate" label={t('pages.logusers.fields.chooseDate')}>
            <RangePicker
              onChange={(e) => handleResetParams(e)}
              picker="day"
              label={{
                start: t('pages.logusers.fields.startDate'),
                end: t('pages.logusers.fields.endDate'),
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </LateralFilter>
  );
}
