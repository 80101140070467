/* eslint-disable react/no-unstable-nested-components */
import { Col, Row, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { SelectInput } from '../../../components/LateralFilter/SelectInput';
import { ShareProportionsGraph, VerticalBarChart } from '../../../components/Dashboard';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import * as S from './styles';
import { useDashboardSpecifications } from './useDashboardSpecifications';
import { Loading } from '../../../components/Loading';
import { SKUTable } from '../SKUTable';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import StyledSelect from '../../../components/Common/StyledSelect';
import StyledButton from '../../../components/Common/StyledButton';

export function SpecificationsDashboardPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.dashboard.titleShareProportion'));

  const {
    data,
    CMs,
    isLoading,
    columns,
    selectedProject,
    toggleModal,
    isModalOpen,
    params,
    projects,
    components,
    handleFilter,
    fetchData,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
  } = useDashboardSpecifications();

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            {/* HEADER */}
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle
                      title={`${t('pages.dashboard.fields.title')}`}
                      updated={String(new Date())}
                    />
                  }
                  className="pl-0 pr-0"
                />
              </Col>
            </Row>

            {/* FILTERS */}
            <Row justify="center">
              <Col span={23}>
                <S.FilterContainer>
                  <StyledSelect
                    styles={{ minWidth: '10.125rem' }}
                    value={params.product}
                    label={t('pages.dashboard.labelProduct')}
                    placeholder={t('pages.finance.dashboard.placeholderProduct')}
                    disable
                    onChange={(selected: string) => handleFilter(selected, 'product')}
                  />
                  <StyledSelect
                    styles={{ minWidth: '10.125rem' }}
                    value={params.cm}
                    label={t('pages.dashboard.labelCM')}
                    options={CMs}
                    disable
                    placeholder={t('pages.dashboard.placeholderCM')}
                    onChange={(selected: string) => handleFilter(selected, 'cm')}
                  />
                  <StyledSelect
                    showSearch
                    styles={{ minWidth: '10.125rem' }}
                    value={params.projectName}
                    label={t('pages.dashboard.labelProject')}
                    options={projects}
                    placeholder={`${t('common.all')} ${t('pages.dashboard.placeholderProject')}`}
                    onChange={(selected: string) => handleFilter(selected, 'projectName')}
                  />
                  <StyledSelect
                    showSearch
                    styles={{ minWidth: '10.125rem' }}
                    value={params.componentType || ''}
                    label={t('pages.dashboard.labelComponents')}
                    options={components}
                    placeholder={`${t('common.all')} ${t('pages.dashboard.fields.components')}`}
                    onChange={(selected: string) => handleFilter(selected, 'component')}
                  />
                  <StyledDatePicker
                    picker="year"
                    mode="year"
                    label={t('pages.dashboard.labelYear')}
                    allowClear={false}
                    defaultValue={params.targetYear}
                    onChange={(dateTime, date: string) => {
                      handleFilter(dateTime, 'targetYear');
                    }}
                  />
                  <StyledButton variant="primary" onClick={() => fetchData()}>
                    {t('common.apply')}
                  </StyledButton>
                </S.FilterContainer>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <S.FiltersTagContainer>
                  {handleRenderFiltersTags()} {handleRenderClearAllFilters()}
                </S.FiltersTagContainer>
              </Col>
            </Row>
            {/* LEGEND */}
            <Row justify="center">
              <Col span={23}>
                <S.GraphInfoContainer>
                  <span>{t('pages.dashboard.fields.componentTypes')} FOB vs BR</span>
                  <S.LegendContainer padding="4px 0 16px">
                    <div>
                      <S.Legend color="#8BB7F9" />
                      <span>FOB</span>
                    </div>

                    <div>
                      <S.Legend color="#FFC600" />
                      <span>BR</span>
                    </div>
                  </S.LegendContainer>
                </S.GraphInfoContainer>
              </Col>
            </Row>

            {/* GRAPH */}
            <Row justify="center">
              <Col span={23}>
                <S.GraphsContainer>
                  {data.verticalBarChartData && data.verticalBarChartData.length > 0 ? (
                    <S.Card>
                      {/* <S.SliderContainer>
                    <span>Top List</span>
                    <Slider
                      defaultValue={5}
                      step={5}
                      max={50}
                      min={5}
                      handleStyle={{ borderColor: '#80C343' }}
                      trackStyle={{ backgroundColor: '#80C343' }}
                    />
                  </S.SliderContainer> */}

                      <VerticalBarChart data={data.verticalBarChartData} type="specifications" />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}

                  {data.shareProportionsGraphData && data.shareProportionsGraphData.length > 0 ? (
                    <S.Card>
                      <ShareProportionsGraph
                        data={data.shareProportionsGraphData}
                        type="specifications"
                      />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}
                </S.GraphsContainer>
              </Col>
            </Row>

            {/* LEGEND */}
            <Row justify="center">
              <Col span={23}>
                <S.LegendContainer align="flex-end" padding="24px 0 16px">
                  <div>
                    <S.Legend color="#5EA320" />
                    <span>{t('pages.dashboard.fields.newProjects')}</span>
                  </div>

                  <div>
                    <S.Legend />
                    <span>{t('pages.dashboard.fields.finishedProjects')}</span>
                  </div>
                </S.LegendContainer>
              </Col>
            </Row>

            {/* TABLE */}
            <SKUTable
              data={data}
              columns={columns}
              isLoading={isLoading}
              selectedProject={selectedProject}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
            />
          </Col>
        )}
      </Row>
    </main>
  );
}
