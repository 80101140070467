import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Tooltip, Typography } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import { AiOutlineCopy, AiOutlineDelete } from 'react-icons/ai';

import * as S from './styles';
import * as SC from '../../styles/common';
import { Button } from '../Button';
import { ActionButton } from '../ActionButton';
import { renderDateHour, renderDecimal } from '../../helpers/utils';
import { RoutesPath } from '../../routes/routesPath';

export function Card({ data, type, openModal, copyScenario, deleteScenario, ...props }) {
  const history = useHistory();
  const { t } = useTranslation();

  const navigateToEditScenario = (id, year) => {
    history.push(`${RoutesPath.scenarios}/${id}/${year}`);
  };

  const navigateToSettings = (id, targetYear) => {
    history.push(`${RoutesPath.scenariosSettings}/${id}/${targetYear}`);
  };

  const navigateToSimulate = (id, targetYear, product, scenarioName) => {
    localStorage.setItem('scenario', id);
    localStorage.setItem('targetYear', targetYear);
    localStorage.setItem('productName', product);
    localStorage.setItem('scenarioName', scenarioName);
    history.push(RoutesPath.simulation);
  };

  return (
    <S.Card {...props}>
      <section>
        <div className="line">
          <div className="block">
            <h3>{t('pages.scenarios.fields.targetYear')}</h3>
            <div>{data.targetYear}</div>
          </div>
          <div className="block">
            <h3>{t('pages.scenarios.fields.version')}</h3>
            <div>{data.version}</div>
          </div>
          <div className="block">
            <h3>{t('pages.scenarios.fields.modified')}</h3>
            <div>{renderDateHour(data.lastUpdate)}</div>
          </div>
        </div>

        {type === 'user' && (
          <div className="line">
            <div className="block scenario-name">
              <h3>{t('pages.scenarios.fields.name')}</h3>
              <Typography.Link
                onClick={() => {
                  navigateToEditScenario(data.id, data.targetYear);
                }}
              >
                <strong>{data.name}</strong>
                <FiEdit2 />
              </Typography.Link>
            </div>
            <div className="block">
              <h3>{t('pages.scenarios.fields.currentScore')}</h3>
              <S.ScoreColor score={data.simulation.score}>
                {renderDecimal(data?.simulation?.score)}
              </S.ScoreColor>
            </div>
          </div>
        )}

        <div className="line">
          <div className="block">
            <h3>{t('pages.scenarios.fields.base')}</h3>
            <div>
              <SC.SpanBlue>{type === 'user' ? data.base.name : data.name}</SC.SpanBlue>
            </div>
          </div>
          <div className="block">
            <h3>{data?.ppb?.targetScore && t('pages.scenarios.fields.targetScore')}</h3>
            <S.ScoreColor score={data?.ppb?.targetScore}>{data?.ppb?.targetScore}</S.ScoreColor>
          </div>
        </div>

        {type === 'user' ? (
          <div className="line">
            <div className="block block-action">
              <Tooltip placement="top" title={t('common.delete')}>
                <Popconfirm
                  title={t('common.deleteMessage')}
                  onConfirm={async () => {
                    await deleteScenario(data.id);
                  }}
                >
                  <Button
                    htmlType="button"
                    className="btn-action btn-delete"
                    icon={<AiOutlineDelete />}
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>

              <Tooltip placement="top" title={t('common.copy')}>
                <Popconfirm
                  title={t('common.copyMessage')}
                  onConfirm={async () => {
                    await copyScenario(data.id);
                  }}
                >
                  <Button
                    htmlType="button"
                    className="btn-action"
                    icon={<AiOutlineCopy />}
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </div>
            <div className="block">
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  navigateToSimulate(data.id, data.targetYear, data.ppb.productName, data.name);
                }}
              >
                {t('pages.scenarios.simulate')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="line align-left">
            <div className="block block-action">
              <Tooltip placement="top" title={t('common.delete')}>
                <Popconfirm
                  title={t('common.deleteMessage')}
                  onConfirm={async () => {
                    await deleteScenario(data.id);
                  }}
                >
                  <Button
                    htmlType="button"
                    className="btn-action btn-delete"
                    icon={<AiOutlineDelete />}
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )}
      </section>

      <aside>
        <h3>{t('pages.scenarios.tableTitle')}</h3>
        <ActionButton type="button" onClick={() => openModal('prices', type, data)}>
          {t('pages.prices.title')}
        </ActionButton>
        <ActionButton type="button" onClick={() => openModal('bom', type, data)}>
          {t('pages.bom.title')}
        </ActionButton>
        <ActionButton type="button" onClick={() => openModal('forecasts', type, data)}>
          {t('pages.demands.title')}
        </ActionButton>

        {type === 'user' && (
          <ActionButton
            type="button"
            className="rules"
            onClick={() => navigateToSettings(data.id, data.targetYear)}
          >
            {t('pages.scenarios.settings.specificRules')}
          </ActionButton>
        )}
      </aside>
    </S.Card>
  );
}
