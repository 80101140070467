import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import StyledSelect from '../../../components/Common/StyledSelect';
import * as S from './styles';
import StyledButton from '../../../components/Common/StyledButton';
import { useInOut } from './useInOut';
import StyledDatePicker from '../../../components/Common/StyledDatePicker';
import { Loading } from '../../../components/Loading';
import theme from '../../../styles/theme';

export function InOutPage() {
  const {
    inOutComponents,
    isLoading,
    CMs,
    sources,
    params,
    categories,
    specifications,
    partNumbers,
    handleFetchComponents,
    handleFetchHeaders,
    handleRenderTablesComponents,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    updateParams,
  } = useInOut();

  useEffect(() => {
    handleFetchHeaders();
  }, [params]);

  useEffect(() => {
    handleFetchComponents();
  }, []);
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.ContainerFilters>
        <StyledSelect
          label={t('pages.inOut.labels.cm')}
          placeholder={t('pages.inOut.placeholders.cm')}
          options={CMs}
          value={params.cm}
          styles={{ minWidth: '7.25rem' }}
          onChange={(value: string) => updateParams('cm', value)}
          disable
        />
        {/* <StyledSelect
          label={t('pages.inOut.labels.product')}
          placeholder={t('pages.inOut.placeholders.product')}
          onChange={null}
          // options={CMs}
          // value={params.cm}
          styles={{ minWidth: '7.25rem' }}
        /> */}
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.inOut.labels.category')}
          placeholder={t('pages.inOut.placeholders.category')}
          options={categories}
          value={params.category || null}
          styles={{ minWidth: '7.25rem' }}
          showSearch
          onChange={(value: string) => updateParams('category', value)}
        />
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.inOut.labels.specification')}
          placeholder={t('pages.inOut.placeholders.specification')}
          options={specifications}
          value={params.spec || null}
          styles={{ minWidth: '8.25rem' }}
          showSearch
          onChange={(value: string) => updateParams('spec', value)}
        />
        <StyledSelect
          label={t('pages.inOut.labels.source')}
          placeholder={t('pages.inOut.placeholders.source')}
          options={sources}
          value={params.source}
          styles={{ minWidth: '7.25rem' }}
          onChange={(value: string) => updateParams('source', value)}
        />
        <StyledSelect
          suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
          label={t('pages.inOut.labels.partNumber')}
          placeholder={t('pages.inOut.placeholders.partNumber')}
          options={partNumbers}
          value={params.partNumber || null}
          styles={{ minWidth: '8.75rem' }}
          showSearch
          onChange={(value: string) => updateParams('partNumber', value)}
        />
        <StyledDatePicker
          label={t('pages.inOut.labels.month')}
          picker="month"
          mode="month"
          format="MM/YY"
          onChange={(date, _) => {
            updateParams('date', date);
          }}
          defaultValue={params.date}
          styles={{ minWidth: '4rem' }}
        />
        <StyledButton
          small
          onClick={handleFetchComponents}
          variant="primary"
          style={{ height: '2rem' }}
          text={t('pages.inventory.labels.apply')}
        />
      </S.ContainerFilters>
      <S.FiltersTagContainer>
        {handleRenderFiltersTags()}
        {handleRenderClearAllFilters()}
      </S.FiltersTagContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {inOutComponents.length > 0 && handleRenderTablesComponents()}
          {!inOutComponents.length && <Empty />}
        </>
      )}
    </S.Container>
  );
}
