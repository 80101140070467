import { Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as S from './styles';
import find from '../../../assets/find.svg';
import StyledSelect from '../../Common/StyledSelect';
import StyledInput from '../../Common/StyledInput';
import { states } from '../../../helpers/utils';
import StyledDatePicker from '../../Common/StyledDatePicker';
import StyledButton from '../../Common/StyledButton';
import { Taxes } from '../../../pages/SettingsTaxes/types';
import { ContentNewTaxProps } from './types';
import StyledMultipleSelect from '../../Common/StyledMultipleSelect';
import { api } from '../../../services/api';
import { StyledSelectOptions } from '../../Common/StyledSelect/types';
import { formatDataToSelect } from '../../../helpers/formatDataToSelect';

export default function ContentNewTax({ closeModal, taxe, saveNewTax }: ContentNewTaxProps) {
  const [form] = Form.useForm();
  const [specs, setSpecs] = useState<StyledSelectOptions[]>([]);

  const { t } = useTranslation();

  const handleTaxToEdit = () => {
    taxe &&
      form.setFieldsValue({
        ...taxe,
        periodFrom: dayjs(taxe.periodFrom),
        periodTo: dayjs(taxe.periodTo),
      });
  };

  const fetchSpecs = async () => {
    try {
      const { status, data } = await api.get('/components/packaging/prices/header');
      if (status === 200) {
        const formattedData = formatDataToSelect(data.specList, false);
        setSpecs(formattedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleTaxToEdit();
    fetchSpecs();
  }, []);

  const onFinish = (fieldsValue: Taxes) => {
    const formatedFieldsValue =
      fieldsValue.componentType === 'PACKING'
        ? {
            ...fieldsValue,
            from: 'SP',
            to: 'SP',
          }
        : fieldsValue;
    saveNewTax(formatedFieldsValue);
    closeModal(false);
  };

  const onSelectsChange = (field: string, value: string) => {
    form.setFieldValue([field], value);
  };

  const pattern = /^\d+(\.\d+)?$/;
  const patternFixDecimal = /^\d+\.\d{2}$/;

  const regexPattern = new RegExp(pattern);

  return (
    <S.container>
      <Divider orientation="left" orientationMargin="0">
        {t('components.modals.addNewTax.description')}
      </Divider>
      <Form form={form} style={{ width: '100%' }} onFinish={onFinish}>
        <Form.Item<Taxes> noStyle name="id" />

        <Form.Item<Taxes>
          name="componentType"
          rules={[
            {
              required: true,
              message: `${t('components.modals.addNewTax.partType')} ${t(
                `common.validate.required`
              )}`,
            },
          ]}
        >
          <StyledSelect
            type="COMPONENT_TYPE"
            styles={{ maxWidth: '347px' }}
            value={form.getFieldValue('componentType')}
            suffixIcon={<img alt="" src={find} />}
            label={t('components.modals.addNewTax.partType')}
            placeholder={t('components.modals.addNewTax.selectComponentType')}
            onChange={(value: string) => {
              onSelectsChange('componentType', value);
            }}
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => {
            if (form.getFieldValue('componentType') !== 'PACKING') {
              return (
                <>
                  <S.labels>{t('components.modals.addNewTax.logistic')}</S.labels>
                  <S.logisticContainer>
                    <Form.Item<Taxes>
                      style={{ marginRight: '1.5rem' }}
                      name="from"
                      rules={[
                        {
                          required: true,
                          message: `${t('components.modals.addNewTax.from')} ${t(
                            `common.validate.required`
                          )}`,
                        },
                      ]}
                    >
                      <StyledSelect
                        styles={{ minWidth: '100px' }}
                        value={form.getFieldValue('from')}
                        label={t('components.modals.addNewTax.from')}
                        onChange={(value: any) => {
                          onSelectsChange('from', value);
                        }}
                        options={states('no-state')}
                        placeholder="SP"
                      />
                    </Form.Item>
                    <Form.Item<Taxes>
                      name="to"
                      rules={[
                        {
                          required: true,
                          message: `${t('components.modals.addNewTax.to')} ${t(
                            `common.validate.required`
                          )}`,
                        },
                      ]}
                    >
                      <StyledSelect
                        value={form.getFieldValue('to')}
                        placeholder="SP"
                        styles={{ minWidth: '100px' }}
                        label={t('components.modals.addNewTax.to')}
                        onChange={(value: any) => {
                          onSelectsChange('to', value);
                        }}
                        options={states()}
                      />
                    </Form.Item>
                  </S.logisticContainer>
                </>
              );
            }
            return (
              <Form.Item<Taxes>
                style={{ marginRight: '1.5rem' }}
                name="spec"
                rules={[
                  {
                    required: true,
                    message: `${t('components.modals.addNewTax.spec')} ${t(
                      `common.validate.required`
                    )}`,
                  },
                ]}
              >
                <StyledSelect
                  styles={{ minWidth: '100px' }}
                  value={form.getFieldValue('spec')}
                  label={t('components.modals.addNewTax.spec')}
                  onChange={(value: any) => {
                    onSelectsChange('from', value);
                  }}
                  options={specs}
                  placeholder="Specification"
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <S.labelsTaxesContainer>
          <S.labels style={{ width: '40%' }}>{t('components.modals.addNewTax.taxes')}</S.labels>
          <S.labels style={{ width: '60%' }}>
            {t('components.modals.addNewTax.recoveryTaxes')}
          </S.labels>
        </S.labelsTaxesContainer>

        <S.taxesContainer>
          {/* taxas */}
          <S.onlyTaxesContainer>
            <Form.Item<Taxes>
              name={['taxDto', 'taxesIpi']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.ipi')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ maxWidth: '65px' }}
                label={t('components.modals.addNewTax.ipi')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
            <Form.Item<Taxes>
              name={['taxDto', 'taxesIcms']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.icms')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ maxWidth: '65px' }}
                label={t('components.modals.addNewTax.icms')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
            <Form.Item<Taxes>
              name={['taxDto', 'taxesPisCofins']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.pisConfins')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ maxWidth: '65px' }}
                label={t('components.modals.addNewTax.pisConfins')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
          </S.onlyTaxesContainer>
          {/* recuperação de taxas */}
          <S.recoveryTaxesContainer>
            <Form.Item<Taxes>
              name={['recoveryTaxDto', 'recoveryTaxesIpi']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.ipi')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ width: '4.063rem' }}
                label={t('components.modals.addNewTax.ipi')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
            <Form.Item<Taxes>
              name={['recoveryTaxDto', 'recoveryTaxesIcms']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.icms')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ width: '4.063rem' }}
                label={t('components.modals.addNewTax.icms')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
            <Form.Item<Taxes>
              name={['recoveryTaxDto', 'recoveryTaxesPisCofins']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.pisConfins')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ width: '4.063rem' }}
                label={t('components.modals.addNewTax.pisConfins')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
            <Form.Item<Taxes>
              name={['recoveryTaxDto', 'taxesRD']}
              rules={[
                {
                  required: true,
                  message: `${t('components.modals.addNewTax.rd')} ${t(
                    `common.validate.required`
                  )}`,
                },
                {
                  pattern: regexPattern,
                  message: `${t('toast.invalidField')}`,
                },
                {
                  pattern: patternFixDecimal,
                  message: `${t('components.modals.addNewTax.formatTaxe')}`,
                },
              ]}
            >
              <StyledInput
                styles={{ width: '4.063rem' }}
                label={t('components.modals.addNewTax.rd')}
                placeholder={t('common.moneyPlaceholder')}
              />
            </Form.Item>
          </S.recoveryTaxesContainer>
        </S.taxesContainer>

        {/* container de datas */}
        <S.labels>{t('components.modals.addNewTax.period')}</S.labels>
        <S.periodContainer>
          <Form.Item<Taxes>
            name="periodFrom"
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.from')} ${t(
                  `common.validate.required`
                )}`,
              },
              {
                type: 'date',
                message: `${t('toast.invalidField')}`,
              },
            ]}
          >
            <StyledDatePicker label={t('components.modals.addNewTax.from')} />
          </Form.Item>
          <Form.Item<Taxes>
            name="periodTo"
            rules={[
              {
                required: true,
                message: `${t('components.modals.addNewTax.to')} ${t(`common.validate.required`)}`,
              },
              {
                type: 'date',
                message: `${t('toast.invalidField')}`,
              },
            ]}
          >
            <StyledDatePicker label={t('components.modals.addNewTax.to')} />
          </Form.Item>
        </S.periodContainer>

        {/* container de botões */}
        <S.buttonsContainer>
          <StyledButton
            text={t('components.modals.addNewTax.cancel')}
            variant="secondary"
            onClick={() => {
              closeModal(false);
            }}
          />
          <Form.Item>
            <StyledButton
              text={
                taxe ? t('components.modals.addNewTax.save') : t('components.modals.addNewTax.add')
              }
              variant="primary"
              htmlType="submit"
            />
          </Form.Item>
        </S.buttonsContainer>
      </Form>
    </S.container>
  );
}
