import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api } from '../../services/api';
import { setAccessTokens, setSession } from '../../services/auth';
import { oneAlert } from '../../helpers/utils';
import { RoutesPath } from '../../routes/routesPath';

export function useLogin() {
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleNavigateHome = () => {
    history.push(RoutesPath.scenarios);
    setIsLoading(false);
  };

  const handleNavigatePassword = (email) => {
    history.push(`/reset-password?first=${email}`);
    setIsLoading(false);
  };

  const handleRegisterPassword = ({ email, password, session }) => {
    history.push({
      pathname: '/user/registerPassword',
      state: { email, oldPassword: password, isRequired: true, session },
    });
    setIsLoading(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const { email, password } = values;
    setError(false);

    try {
      const { data } = await api.post(`${import.meta.env.VITE_API_COGNITO}/login`, {
        username: email,
        password,
      });

      if (data?.challengeName) {
        if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setSession(data);
          handleNavigatePassword(email);
        }
      } else if (data?.accessToken) {
        setAccessTokens(data);
        await api.post(`userlogs/login`, null, {
          headers: {
            Authorization: `Bearer ${data.idToken}`,
            Permissions: data.permissionsToken,
          },
        });

        handleNavigateHome();
      }
    } catch (errorData) {
      oneAlert('error', t('toast.errorOnLogin'));
    }
    setIsLoading(false);
  };

  const onFieldsChange = () => {
    if (error) setError(false);
  };

  return {
    isLoading,
    error,
    onFinish,
    onFieldsChange,
    handleNavigateHome,
    handleRegisterPassword,
  };
}
