import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  padding: 3.063rem 1.125rem 0 1.375rem;
  margin: 0.5rem;
  padding-bottom: 2rem;
`;

export const TitleFilters = styled.p`
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  margin: 0;
`;

export const ProjectName = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  margin-bottom: 65px;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 2rem;
`;

export const ContainerHeadersItens = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.875rem 1.063rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.border};
  margin-top: 0.688rem;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.dimensions.border_radius};
  margin-bottom: 1rem;
`;

export const TableContainer = styled.div`
  margin-top: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledSpan = styled.span`
  min-width: 11.375rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
  min-width: 11.75rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  margin-right: 1rem;
`;

export const EstimatedForecastContainer = styled.div`
  height: 2.375rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tableHeadSecondary};
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  div {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
    border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.borderFieldTable};
    border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.borderFieldTable};

    padding-right: 0.75rem;
    min-width: 7.75rem;
    justify-content: end;
    display: flex;
  }
`;

export const TableLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.25rem;
  margin-bottom: 0.563rem;
  margin-top: 0.875rem;

  span {
    font-size: 0.688rem;
    color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
  }
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const QuoteFilesLabel = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.primary} !important;
  font-size: 0.75rem;
`;
