import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Drawer, Popconfirm, Row } from 'antd';
import { FaTrashAlt } from 'react-icons/fa';
import * as S from './styles';
import { Button } from '../../Button';
import { useNotifications } from '../../../context/notification';
import { RoutesPath } from '../../../routes/routesPath';

interface NotificationsProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationsMenu = ({ onClose, open }: NotificationsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notifications, getNotificationDescription, deleteAllNotifications, deleteNotification } =
    useNotifications();

  const handleOpenNotification = (id: string) => {
    history.push(`${RoutesPath.alertsSKU}/${id}`);
    onClose();
  };

  const handleSeeAllNotifications = () => {
    history.push(RoutesPath.notifications);
    onClose();
  };

  const formatHours = (time: string) => {
    const [hour, minute] = time.split(':');

    return `${Number(hour) - 3}:${minute}`;
  };

  return (
    <Drawer
      title="Notifications"
      placement="right"
      onClose={onClose}
      open={open}
      className="drawer-notifications"
      footer={
        <S.NotificationsFooter>
          <Button
            buttonText={null}
            icon={null}
            iconEnd={null}
            onClick={() => deleteAllNotifications()}
            type={null}
          >
            {t('notifications.clearAll')}
          </Button>
        </S.NotificationsFooter>
      }
    >
      <p style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          buttonText={null}
          icon={null}
          iconEnd={null}
          onClick={handleSeeAllNotifications}
          type="link"
          style={{ height: 'auto', padding: 0 }}
        >
          {t('notifications.seeAll')}
        </Button>
      </p>
      {notifications.map((noti) => {
        return (
          <S.NotificationWrapper key={noti.id} seen={noti.visualized}>
            <Row>
              <Col span={14}>
                <p className="description">
                  {getNotificationDescription(noti.type, noti.data)}
                  <Button
                    buttonText={null}
                    icon={null}
                    iconEnd={null}
                    onClick={() => handleOpenNotification(noti.id)}
                    type="link"
                    style={{ height: 'auto', padding: 0 }}
                  >
                    {t('notifications.check')}
                  </Button>
                </p>
              </Col>
              <Col span={5} offset={1}>
                <S.NotificationDate>
                  <p>{noti.formatedDate.date}</p>
                  <span>{noti.formatedDate.time}</span>
                </S.NotificationDate>
              </Col>
              <Col span={3} offset={1}>
                <Popconfirm
                  onClick={(e) => e.stopPropagation()}
                  title={t('common.deleteMessage')}
                  onConfirm={() => deleteNotification(noti.id)}
                  placement="left"
                  cancelText={t('common.cancel')}
                >
                  <Button
                    buttonText={null}
                    icon={null}
                    iconEnd={null}
                    onClick={null}
                    type="link"
                    style={{ height: 'auto' }}
                  >
                    <FaTrashAlt />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </S.NotificationWrapper>
        );
      })}
    </Drawer>
  );
};
