import { Col, ConfigProvider, Form, Modal, Popconfirm, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus, BiDownload, BiUpload } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { Button } from '../../components/Button';
import { csvArrayToObj, transformDataPrices, getURI } from '../../helpers/utils';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { EditableCell } from '../../components/EditableCell';
import { usePrices } from './usePrices';
import { TablePF } from '../../components/Tables/TablePF';
import { FilterViewPF } from '../../components/FilterViewPF';
import { CustomPagination } from '../../components/CustomPagination';
import { useModal } from '../../components/Modal/useModal';
import { ModalWrapper } from '../../components/Modal';
import CsvUploadReader from '../../components/CsvUploadReader';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import PricesFilter from './PricesFilter';
import { Loading } from '../../components/Loading';
import * as SC from '../../styles/common';
import * as S from './styles';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { CsvExport } from '../../components/Common/CsvExport';
import StyledModal from '../../components/Common/StyledModal';

export function PricesPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.prices.title'));

  const validateMessages = ValidateMessages();

  const [preview, setPreview] = useState([]);
  const [visiblePreview, openModalPreview, closeModalPreview] = useModal();
  const [visiblePrice, openModalPrice, closeModalPrice] = useModal();

  const {
    data,
    params,
    pageControl,
    scenariosOptions,
    selectedScenario,
    fetchScenarios,
    fetchPrices,
    changePageValue,
    onValuesChange,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    editingKey,
    deleteAll,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    groupBySpec,
    setGroupBySpec,
    optionsViewsFilter,
    setViewFilter,
    viewFilter,
    canUpdate,
    handleUpload,
    isLoadingUpload,
    dataHidden,
    hiddenPrices,
    HiddenPricesForm,
    mergedColumns,
    headersPrices,
    headersFactors,
    columnsPreview,
    expandedRowKeys,
    setExpandedRowKeys,
  } = usePrices(dataModal);

  const title = optionsViewsFilter.find(({ value }) => value === viewFilter)?.label;
  const disabledActions = hiddenPrices || !(viewFilter === 'price' || viewFilter === 'factor');

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';

  const showTagsFilter =
    params.header_spec?.length ||
    params.header_partNumber?.length ||
    params.header_supplier?.length;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchPrices();
    }
  }, [params]);

  const expandRowIds = (expanded, record) => {
    const keys = [];

    if (record.partNumber) return; // if is a children, don't close the row

    if (expanded && record.spec) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  const handlePreview = async (dataPreview) => {
    const arrayToObj = csvArrayToObj(dataPreview);
    if (arrayToObj?.length) {
      setPreview(arrayToObj);
      closeModalPrice();
      openModalPreview();
    } else {
      closeModalPrice();
      closeModalPreview();
    }
  };

  return (
    <S.MainContainer>
      <Row>
        {visiblePrice && (
          <ModalWrapper
            area="PricesAndFactors"
            title={title}
            isVisible={visiblePrice}
            onClose={closeModalPrice}
          >
            <CsvUploadReader
              getCsvData={async (csvData) => {
                await handlePreview(csvData);
              }}
            />
          </ModalWrapper>
        )}

        {visiblePreview && (
          <StyledModal
            title={`Preview - ${title}`}
            open={visiblePreview}
            width="95%"
            onClose={closeModalPreview}
            body={
              <S.PricePreviewTable
                rowKey="id"
                data-cy="table"
                striped
                columns={columnsPreview}
                dataSource={preview}
                pagination={false}
              />
            }
            footer={
              <SC.ModalFooter>
                <Button onClick={closeModalPreview}>{t('common.cancel')}</Button>
                <Button
                  type="primary"
                  loading={isLoadingUpload}
                  onClick={async () => {
                    await handleUpload(preview, closeModalPrice, closeModalPreview);
                  }}
                >
                  {t('common.fields.uploadFile')}
                </Button>
              </SC.ModalFooter>
            }
          />
        )}

        {view ? null : (
          <PricesFilter
            title={title}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle title={title} subtitle={scenarioName} updated={modifiedDate} />
                  }
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      buttonText={`${t('common.add')} ${t('common.item')}`}
                      disabled={
                        disabledActions ||
                        isNewItem ||
                        dataModal?.base === null ||
                        viewFilter === 'factor'
                      }
                      onClick={handleAddItem}
                      icon={<BiPlus />}
                      key={1}
                    />,
                    <ActionButton
                      icon={<BiUpload />}
                      key={2}
                      disabled={
                        disabledActions ||
                        (!data?.length && viewFilter !== 'price') ||
                        dataModal?.base === null
                      }
                      onClick={openModalPrice}
                    >
                      {`${t('common.fields.uploadFile')}`}
                    </ActionButton>,
                    <ActionButton icon={<BiDownload />} disabled={disabledActions} key={3}>
                      <CsvExport
                        url={getURI(`scenarios/${params.scenario}/prices`, {
                          ...params,
                          order: 'spec',
                          orderBy: 'ASC',
                        })}
                        filename={[viewFilter, selectedScenario?.label]}
                        limit={pageControl.totalElements}
                        headers={viewFilter === 'factor' ? headersFactors : headersPrices}
                        transformData={transformDataPrices}
                      />
                    </ActionButton>,
                    <Popconfirm
                      key={4}
                      title={t('common.deleteMessageAll')}
                      onConfirm={() => deleteAll()}
                    >
                      <ActionButton
                        buttonText={`${t('common.delete')} ${t('common.all')}`}
                        disabled={disabledActions || dataModal?.base === null}
                        icon={<AiOutlineDelete />}
                      />
                    </Popconfirm>,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerViewsAndTags>
                  <FilterViewPF
                    value={viewFilter}
                    setValue={setViewFilter}
                    groupBySpec={groupBySpec}
                    setGroupBySpec={setGroupBySpec}
                    options={optionsViewsFilter}
                  />
                  <SC.ContainerFilteredTags>
                    {showTagsFilter && (
                      <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>
                    )}
                    <SC.TagFilter
                      closable
                      visible={params.header_spec?.length}
                      onClose={() => handleCloseFilteredTag('header_spec')}
                    >
                      <div className="tag-title">{t('pages.prices.fields.spec')}</div>
                      <div className="tag-value">{params.header_spec}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_partNumber?.length}
                      onClose={() => handleCloseFilteredTag('header_partNumber')}
                    >
                      <div className="tag-title">{t('pages.prices.fields.partNumber')}</div>
                      <div className="tag-value">{params.header_partNumber}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_supplier}
                      onClose={() => handleCloseFilteredTag('header_supplier')}
                    >
                      <div className="tag-title">{t('pages.prices.fields.supplier')}</div>
                      <div className="tag-value">{params.header_supplier}</div>
                    </SC.TagFilter>
                  </SC.ContainerFilteredTags>

                  <HiddenPricesForm disabledActions={editingKey !== ''} />
                </SC.ContainerViewsAndTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                {viewFilter === 'price' || viewFilter === 'factor' ? (
                  <Form
                    form={form}
                    component={false}
                    onValuesChange={onValuesChange}
                    validateMessages={validateMessages}
                  >
                    <ConfigProvider>
                      <SimpleTable
                        rowKey="id"
                        data-cy="table"
                        size="small"
                        bordered
                        striped
                        components={{
                          body: {
                            cell: EditableCell,
                          },
                        }}
                        columns={mergedColumns}
                        dataSource={hiddenPrices ? dataHidden : data}
                        pagination={false}
                        expandRowByClick
                        defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                        expandable={{ expandedRowKeys, indentSize: 0 }}
                        onExpand={expandRowIds}
                        locale={{
                          emptyText: (
                            <ResultNoScenarios
                              title={t('common.results.noDataTitle')}
                              subTitle={t('common.results.noDataSubtitle')}
                            />
                          ),
                        }}
                      />
                    </ConfigProvider>
                  </Form>
                ) : (
                  <TablePF
                    dataSource={hiddenPrices ? dataHidden : data}
                    showGap={viewFilter === 'price, factor and gap'}
                    bordered={false}
                    locale={{
                      emptyText: (
                        <ResultNoScenarios
                          title={t('common.results.noDataTitle')}
                          subTitle={t('common.results.noDataSubtitle')}
                        />
                      ),
                    }}
                  />
                )}

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </S.MainContainer>
  );
}
