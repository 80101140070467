import * as S from './styles';
import { StyledButtonProps } from './types';
import theme from '../../../styles/theme';

export default function StyledButton({
  variant,
  text,
  iconLeft,
  iconRight,
  styles,
  small,
  children,
  ...restProps
}: StyledButtonProps) {
  return (
    <>
      {variant === 'primary' && (
        <S.PrimaryButton
          data-testid="styled-button"
          style={small ? { height: `${theme.dimensions.height_small_button}` } : styles}
          {...restProps}
        >
          {children || null}
          {iconLeft || null}
          {text}
          {iconRight || null}
        </S.PrimaryButton>
      )}
      {variant === 'secondary' && (
        <S.SecondaryButton
          data-testid="styled-button"
          style={small ? { height: `${theme.dimensions.height_small_button}` } : styles}
          {...restProps}
        >
          {iconLeft || null}
          {children || null}
          {text}
          {iconRight || null}
        </S.SecondaryButton>
      )}
      {variant === 'slim' && (
        <S.SlimButton data-testid="styled-button" style={styles} {...restProps}>
          {iconLeft || null}
          {text}
          {children || null}
          {iconRight || null}
        </S.SlimButton>
      )}
    </>
  );
}
