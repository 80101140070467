import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import StyledClearTagProps from './types';

export default function StyledClearTag({ onClick }: StyledClearTagProps) {
  const { t } = useTranslation();
  return (
    <S.clearTags onClick={onClick} type="default" size="small" data-testid="clear-tag">
      <span data-testid="clear-text">{t('common.clearFilters')}</span>
      <span data-testid="delete-icon">
        <DeleteOutlined style={{ fontSize: 12 }} />
      </span>
    </S.clearTags>
  );
}
