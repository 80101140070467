import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useShareProportion } from './useShareProportion';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import * as S from './styles';
import { Loading } from '../../components/Loading';
import StyledSelect from '../../components/Common/StyledSelect';
import theme from '../../styles/theme';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import StyledButton from '../../components/Common/StyledButton';
import StyledModal from '../../components/Common/StyledModal';
import { ContentModalShareProportion } from './ContentModalShareProportion';

export function ShareProportion() {
  const {
    isLoading,
    params,
    CMs,
    Sources,
    categories,
    partNumbers,
    specifications,
    suppliers,
    shareProportionComponents,
    visibleModal,
    projects,
    modalProps,
    handleFetchHeaders,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleRenderTablesComponents,
    handleFetchComponents,
    setVisibleModal,
    disabledDate,
  } = useShareProportion();
  const { t } = useTranslation();
  useDocumentTitle(t('pages.shareProportion.title'));

  useEffect(() => {
    handleFetchHeaders();
  }, [params]);

  useEffect(() => {
    handleFetchComponents();
  }, []);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginBottom: '1.688rem' }}>
            <S.ProjectName>{t('pages.shareProportion.title')}</S.ProjectName>
          </div>
          <S.ContainerFilters>
            <StyledSelect
              label={t('pages.shareProportion.labels.cm')}
              placeholder={t('pages.shareProportion.placeholders.cm')}
              onChange={(value: string) => {
                handleUpdateFilters('cm', value);
              }}
              options={CMs}
              value={params.cm}
              styles={{ minWidth: '7.25rem' }}
            />

            <StyledSelect
              options={categories}
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              showSearch
              label={t('pages.shareProportion.labels.category')}
              placeholder={t('pages.shareProportion.placeholders.category')}
              onChange={(value: string) => {
                handleUpdateFilters('category', value);
              }}
              styles={{ minWidth: '7.563rem' }}
              value={params.category}
            />

            <StyledSelect
              options={projects}
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              showSearch
              label={t('pages.shareProportion.labels.project')}
              placeholder={t('pages.shareProportion.placeholders.project')}
              onChange={(value: string) => {
                handleUpdateFilters('project', value);
              }}
              styles={{ minWidth: '8.53rem' }}
              value={params.project}
            />

            <StyledSelect
              label={t('pages.shareProportion.labels.partNumber')}
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              placeholder={t('pages.shareProportion.labels.partNumber')}
              onChange={(value: string) => {
                handleUpdateFilters('partNumber', value);
              }}
              options={partNumbers}
              showSearch
              value={params.partNumber}
              styles={{ minWidth: '8.75rem' }}
            />
            <StyledSelect
              options={specifications}
              showSearch
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              label={t('pages.shareProportion.labels.specification')}
              placeholder={t('pages.shareProportion.placeholders.specification')}
              onChange={(value: string) => {
                handleUpdateFilters('spec', value);
              }}
              styles={{ minWidth: '11rem' }}
              value={params.spec}
            />
            <StyledSelect
              suffixIcon={<SearchOutlined style={{ color: theme.colors.dark_green }} />}
              label={t('pages.shareProportion.labels.supplier')}
              placeholder={t('pages.shareProportion.labels.supplier')}
              onChange={(value: string) => {
                handleUpdateFilters('supplier', value);
              }}
              options={suppliers}
              showSearch
              value={params.supplier}
              styles={{ minWidth: '7.25rem' }}
            />
            <StyledSelect
              label={t('pages.shareProportion.labels.source')}
              placeholder={t('pages.shareProportion.labels.source')}
              onChange={(value: string) => {
                handleUpdateFilters('source', value);
              }}
              options={Sources}
              value={params.source}
              styles={{ minWidth: '7.25rem' }}
            />
            <StyledDatePicker
              label={t('pages.shareProportion.labels.date')}
              onChange={(date, _) => {
                handleUpdateFilters('month', date);
              }}
              picker="month"
              defaultValue={params.month}
              format="MM/YYYY"
              styles={{ minWidth: '7.25rem' }}
              disableDate={disabledDate}
            />
            <StyledButton
              small
              onClick={handleFetchComponents}
              variant="primary"
              style={{ height: '2rem' }}
              text={t('pages.shareProportion.labels.apply')}
            />
          </S.ContainerFilters>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          {shareProportionComponents.length > 0 && handleRenderTablesComponents()}
          {!shareProportionComponents.length && <Empty />}
          {visibleModal && (
            <StyledModal
              title="Share Proportion Details"
              open={visibleModal}
              width="64.375rem"
              footer={null}
              body={
                <ContentModalShareProportion
                  cm={params.cm || ''}
                  month={params.month}
                  projectName={modalProps?.projectName || ''}
                  specification={modalProps?.specification || ''}
                  supplier={modalProps?.supplier || ''}
                />
              }
              onClose={() => {
                setVisibleModal(false);
              }}
            />
          )}
        </>
      )}
    </S.Container>
  );
}
