import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.25rem !important;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  height: 1.375rem;
  font-weight: 400;
  font-size: 0.75rem;
  margin-inline-end: 0;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  gap: 0.5rem;

  &:hover {
    border-color: ${({ theme }) => theme.colorsDesignSystem.primary} !important;
  }
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.313rem;

  .title {
    cursor: default;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.blue};
  }
  .label {
    cursor: default;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.placeHolder};
  }
  .pipe {
    cursor: default;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.pipe};
  }
`;
