import { Col, ConfigProvider, Row } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useUplodFiles } from './useUploadFiles';

import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import StyledDatePicker from '../../components/Common/StyledDatePicker';
import StyledCheckbox from '../../components/Common/StyledCheckbox';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import SttledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { ModalStyled } from '../../components/Modal';
import { UploadSheetsContent } from '../../components/Modal/UploadSheets/index';
import StyledButton from '../../components/Common/StyledButton';
import * as S from './styles';
import close from '../../assets/closeTag.svg';
import { Loading } from '../../components/Loading';
import StyledSearchInput from '../../components/Common/StyledSearchInput';
import { CustomPagination } from '../../components/CustomPagination';
import StyledSelect from '../../components/Common/StyledSelect';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';

export function UploadFilesPage({ location }: any) {
  const { t } = useTranslation();
  const parameters = location.state || {};
  const listTypesOptions: StyledSelectOptions[] = [
    {
      value: 'Average',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.average`)}`,
    },
    {
      value: 'Forecast',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.demand`)}`,
    },
    {
      value: 'Imports Components Price',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.componentsPrice`)}`,
    },
    {
      value: 'Local Components Price',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.localComponentsPrice`)}`,
    },
    {
      value: 'Packaging Price',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.packingPrice`)}`,
    },
    {
      value: 'Quotes',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.quotes`)}`,
    },
    {
      value: 'Share Proportion',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.shareProportion`)}`,
    },
    {
      value: 'In Out',
      label: `${t(`components.modals.uploadSheets.listTypeOptions.inOut`)}`,
    },
  ];

  const {
    columnsWithAction,
    isLoading,
    content,
    params,
    filterListType,
    filterResponsible,
    filterStatus,
    initialModal,
    showModal,
    checkBoxInitialOptions,
    checkBoxValue,
    loading,
    pageControl,
    isTableLoading,

    handleChangeTable,
    fetchFiles,
    clearFilters,
    handleChangeFilter,
    handleSearch,
    handleCloseTag,
    handleCloseModal,
    handleChangeCheckbox,
    newUpload,
    handleChangeDate,
    setCheckBoxValue,
    uploadFile,
    setFile,
    changePageValue,
  } = useUplodFiles();

  useDocumentTitle(t('pages.finance.uploadFiles.title'));
  const StatusOptions: StyledSelectOptions[] = [
    { value: 'Success', label: `${t('pages.finance.uploadFiles.filterStatusOptions.success')}` },
    {
      value: 'Processing',
      label: `${t('pages.finance.uploadFiles.filterStatusOptions.processing')}`,
    },
    { value: 'Error', label: `${t('pages.finance.uploadFiles.filterStatusOptions.error')}` },
  ];
  const showtagFilter =
    params.tag_listType || params.tag_date || params.tag_responsible || params.tag_status;

  useEffect(() => {
    if (parameters.cm && parameters.date && parameters.listType) {
      params.cm = parameters.cm;
      params.date = parameters.date;
      params.listType = parameters.listType;
      params.tag_date = parameters.date;
      params.tag_listType = parameters.listType;
      setCheckBoxValue([parameters.cm]);
    }
    fetchFiles();
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [params]);

  return (
    <S.Container>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <PageHeader
                  title={<HeaderTitle title={t('pages.finance.uploadFiles.title')} />}
                  extra={[
                    <StyledButton
                      key={0}
                      variant="primary"
                      text={`${t('pages.finance.uploadFiles.newUpload')}`}
                      iconLeft={<UploadOutlined />}
                      onClick={newUpload}
                    />,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <S.SearchFilter>
                  <StyledSelect
                    label={`${t('pages.finance.uploadFiles.labelListType')}`}
                    placeholder={t('pages.finance.uploadFiles.listPlaceHolder')}
                    value={filterListType}
                    options={listTypesOptions}
                    onChange={(value: string) => {
                      handleChangeFilter('listType', value);
                      handleSearch('listType', value);
                    }}
                    styles={{ width: '13rem' }}
                  />

                  <StyledDatePicker
                    format="MM/YYYY"
                    picker="month"
                    defaultValue={params.date}
                    onChange={handleChangeDate}
                    label={`${t('pages.finance.uploadFiles.labelPeriod')}`}
                    placeholder={t('pages.finance.uploadFiles.periodPlaceHolder')}
                  />
                  <StyledSearchInput
                    placeholder={t('pages.finance.uploadFiles.labelResposible')}
                    label={`${t('pages.finance.uploadFiles.labelResposible')}`}
                    value={filterResponsible}
                    onChange={(event) => {
                      handleChangeFilter('responsible', event.target.value);
                    }}
                    onKeyDown={(event) => {
                      event.key === 'Enter' && handleSearch('responsible', filterResponsible);
                    }}
                    suffix={
                      <S.SearchButton
                        onClick={() => handleSearch('responsible', filterResponsible)}
                      >
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    label={t('pages.finance.uploadFiles.labelStatus')}
                    placeholder={t('pages.finance.uploadFiles.labelStatus')}
                    value={filterStatus}
                    options={StatusOptions}
                    onChange={(value: string) => {
                      handleSearch('status', value);
                      handleChangeFilter('status', value);
                    }}
                    styles={{ width: '10rem' }}
                  />
                  <S.CheckIconsContainer>
                    <StyledCheckbox
                      value={checkBoxValue}
                      options={checkBoxInitialOptions}
                      defaultValue={['Foxconn', 'Compal']}
                      onChange={handleChangeCheckbox}
                    />
                  </S.CheckIconsContainer>
                </S.SearchFilter>
                {showtagFilter && (
                  <S.TagFilter>
                    {params.tag_listType && (
                      <StyledFilterTag
                        label={t('pages.finance.uploadFiles.labelListType')}
                        title={
                          params.tag_listType === 'Forecast'
                            ? 'Demands'
                            : params.tag_listType === 'Packaging Price'
                            ? 'Packing Price'
                            : params.tag_listType
                        }
                        closeble
                        closeicon={<img src={close} alt="" />}
                        onClose={() => handleCloseTag('listType', '')}
                      />
                    )}
                    {params.tag_date && (
                      <StyledFilterTag
                        label={t('pages.finance.uploadFiles.labelPeriod')}
                        title={params.tag_date}
                        closeble
                        closeicon={<img src={close} alt="" />}
                        onClose={() => handleCloseTag('date', '')}
                      />
                    )}
                    {params.tag_responsible && (
                      <StyledFilterTag
                        label={t('pages.finance.uploadFiles.labelResposible')}
                        title={params.tag_responsible}
                        closeble
                        closeicon={<img src={close} alt="" />}
                        onClose={() => handleCloseTag('responsible', '')}
                      />
                    )}
                    {params.tag_status && (
                      <StyledFilterTag
                        label={t('pages.finance.uploadFiles.labelStatus')}
                        title={params.tag_status}
                        closeble
                        closeicon={<img src={close} alt="" />}
                        onClose={() => handleCloseTag('status', '')}
                      />
                    )}
                    <SttledClearTag onClick={clearFilters} />
                  </S.TagFilter>
                )}
              </Col>
            </Row>
            <S.BodyPage>
              <Row justify="center">
                <Col span={23}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={content}
                      columns={columnsWithAction}
                      pagination={false}
                      onChange={handleChangeTable}
                      isLoading={isTableLoading}
                    />
                  </ConfigProvider>
                </Col>
              </Row>
            </S.BodyPage>
          </Col>
        )}
      </Row>

      {showModal && (
        <ModalStyled
          key="modal-finance"
          style={{ minWidth: '38.75rem' }}
          width="55% !important"
          title={t('components.modals.uploadSheets.title')}
          open
          onCancel={handleCloseModal}
          footer={null}
        >
          <UploadSheetsContent
            uplodaData={initialModal}
            onUploadFiles={(file) => setFile(file)}
            onClearFile={() => {
              setFile(null);
            }}
            saveFile={uploadFile}
            closeModal={handleCloseModal}
            loading={loading}
            listTypeOptions={listTypesOptions}
          />
        </ModalStyled>
      )}
      {pageControl.totalPages > 1 && (
        <CustomPagination
          limit={params.limit}
          page={params.page + 1}
          pageLabel={pageControl.pageLabel}
          total={pageControl.totalElements}
          totalPages={pageControl.totalPages}
          changePageValue={changePageValue}
        />
      )}
    </S.Container>
  );
}
