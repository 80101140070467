import { Button, Form, Popconfirm, Space } from 'antd';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import {
  getErrorMessage,
  getFilterURL,
  getSortOrder,
  oneAlert,
  removeEmptyFilter,
  renderDateHour,
  getURI,
} from '../../helpers/utils';
import { useTable } from '../../hooks/useTable';

import { api } from '../../services/api';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { Button as TableActionButton } from '../../components/Button';

const initialParams = {
  order: 'project',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
  sku: '',
  project: '',
  modelName: '',
  cm: 'Foxconn',
  product: 'Notebook',
};

const CMs = [
  { value: 'Compal', label: 'Compal' },
  { value: 'Foxconn', label: 'Foxconn' },
];

export function useSKU() {
  const { t } = useTranslation();
  const { getSortOrderTable, getSortTitle } = useTable();

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState(initialParams);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [projects, setProjects] = useState();
  const [skus, setSkus] = useState();
  const [modelName, setModelName] = useState();
  const [products, setProducts] = useState();

  const [isTableActionsVisible, setIsTableActionsVisible] = useState({
    rowId: '',
    state: false,
  });

  const fetchProduct = async () => {
    try {
      const { data: dataProducts, status: statusProducts } = await api.get(
        getURI('/sku/products', {
          cm: params.cm,
        })
      );
      if (statusProducts === 200) {
        const formattedProducst = dataProducts.map((product) => {
          if (product === 'Other') {
            return {
              label: t('pages.sku.filters.otherProducts'),
              value: product,
            };
          }
          return {
            label: product,
            value: product,
          };
        });
        formattedProducst.unshift({ label: t('common.showAll'), value: '' });
        setProducts(formattedProducst);
        return;
      }
      setProducts([]);
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchProjects = async () => {
    try {
      const { data: dataProjects, status: statusProject } = await api.get(
        getURI('/projects', {
          cm: params.cm,
          product: params.product,
          clearIncomplete: true,
        })
      );
      if (statusProject === 200) {
        const convertedProjects = dataProjects.map((project) => {
          return {
            label: project.project,
            value: project.project,
          };
        });
        convertedProjects.unshift({ label: t('common.showAll'), value: '' });
        setProjects(convertedProjects);
        return;
      }
      setProjects([]);
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchSKU = async () => {
    try {
      const { data: dataSku, status: statusSku } = await api.get(
        getURI('/sku/findSkus', {
          cm: params.cm,
          product: params.product,
          project: params.project,
        })
      );
      if (statusSku === 200) {
        const convertedSkus = dataSku.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        convertedSkus.unshift({ label: t('common.showAll'), value: '' });
        setSkus(convertedSkus);
        return;
      }
      setSkus([]);
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchModelName = async () => {
    try {
      const { data: dataModelName, status: statusModelName } = await api.get(
        getURI('/sku/modelNames', {
          cm: params.cm,
          product: params.product,
          project: params.project,
          sku: params.sku,
        })
      );
      if (statusModelName === 200) {
        const convertedModelName = dataModelName.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        convertedModelName.unshift({ label: t('common.showAll'), value: '' });
        setModelName(convertedModelName);
        return;
      }
      setModelName([]);
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchSKUs = async () => {
    const queryParams = getFilterURL(removeEmptyFilter(params));
    setIsLoading(true);

    try {
      const {
        data: { content, totalElements, totalPages },
      } = await api.get(`sku?${queryParams}`);

      const dataContent = content?.length ? content : [];
      setData(dataContent);

      setPageControl({
        pageLabel: params.page + 1,
        totalPages,
        totalElements,
      });
    } catch (error) {
      console.log('Error on the SKUs fetch', error);
    }
    setIsLoading(false);
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const save = async () => {
    setIsLoading(true);

    try {
      const row = await form.getFieldValue();
      const response = await api.post('sku', { ...row, cm: params.cm });
      if (response.status !== 201) throw Error();

      setParams((oldParam) => {
        return { ...oldParam, order: 'updateDateTime', orderBy: 'DESC' };
      });
      setIsNewItem(false);

      oneAlert('success', t('toast.successOnSave'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
    }
    setIsLoading(false);
  };

  const editItem = async (id) => {
    const row = await form.getFieldValue();
    try {
      const response = await api.put(`sku/${id}`, { ...row, cm: params.cm });
      if (response.status !== 200) throw Error();

      setParams((oldParam) => {
        return { ...oldParam, order: 'updateDateTime', orderBy: 'DESC' };
      });
      setEditingKey('');
      oneAlert('success', t('toast.successOnSave'));
      fetchSKU();
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
    }
  };

  const saveOrEdit = (id) => {
    isNewItem ? save(id) : editItem(id);
  };

  const handleAddItem = () => {
    const newItem = {
      id: data.length + 1,
      sku: '',
      project: '',
      modelName: '',
    };

    setIsNewItem(true);
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);

    //fetchSKUs();
  };

  const deleteItem = async (itemId) => {
    setIsLoading(true);
    try {
      if (isNewItem) {
        setIsNewItem(false);
        await fetchSKUs();
      } else {
        const response = await api.delete(`sku/${itemId}`);
        if (response.status !== 200) throw Error();

        await fetchSKUs();
        oneAlert('info', t('toast.successOnDelete'));
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const isEditing = (record) => {
    return record.id === editingKey;
  };

  const cancel = () => {
    if (isNewItem) {
      const newData = data.filter((item) => item.id !== editingKey);
      setData(newData);
      setIsNewItem(false);
    }
    setEditingKey('');
  };

  const searchFilter = async (newFilters) => {
    setParams({
      ...initialParams,
      ...newFilters,
    });
  };

  const clearFilter = async () => {
    setParams({
      ...initialParams,
      sku: null,
      project: null,
      modelName: null,
    });
  };

  const handleFilters = (type, value) => {
    setParams({ ...params, [type]: value });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const columns = [
    {
      title: t('pages.sku.fields.projectName'),
      label: t('pages.sku.fields.projectName'),
      dataIndex: 'project',
      key: 'project',
      required: true,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'project' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'project') },
    },
    {
      title: t('pages.sku.fields.sku'),
      label: t('pages.sku.fields.sku'),
      dataIndex: 'sku',
      key: 'sku',
      required: true,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'sku' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'sku') },
    },
    {
      title: t('pages.sku.fields.modelName'),
      label: t('pages.sku.fields.modelName'),
      dataIndex: 'modelName',
      key: 'modelName',
      required: true,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modelName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'modelName') },
    },
    {
      title: t('pages.sku.fields.created'),
      label: t('pages.sku.fields.created'),
      dataIndex: 'createDateTime',
      key: 'createDateTime',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'createDateTime' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      editable: false,
      width: 160,
      render: (value) => renderDateHour(value),
    },
    {
      title: t('pages.sku.fields.createdBy'),
      label: t('pages.sku.fields.createdBy'),
      dataIndex: 'createBy',
      key: 'createBy',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'createBy' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      editable: false,
      width: 160,
      render: (value) => value || '-',
    },
    {
      title: t('pages.sku.fields.updated'),
      label: t('pages.sku.fields.updated'),
      dataIndex: 'updateDateTime',
      key: 'updateDateTime',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'updateDateTime' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      editable: false,
      width: 160,
      render: (value) => renderDateHour(value),
    },
    {
      title: t('pages.sku.fields.updatedBy'),
      label: t('pages.sku.fields.updatedBy'),
      dataIndex: 'updateBy',
      key: 'updateBy',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'updateBy' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      editable: false,
      width: 160,
      render: (value) => value || '-',
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      align: 'center',
      width: 130,
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: (
              <TableActionButton
                type="table_action"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                data-cy="edit"
              >
                {t('common.edit')}
              </TableActionButton>
            ),
          },
        ];

        if (record?.createBy !== 'sistema') {
          actions.push({
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                placement="topLeft"
                onConfirm={() => deleteItem(record.id)}
              >
                <Button type="link" danger disabled={editingKey !== ''} data-cy="delete">
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          });
        }

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => saveOrEdit(record.id))
                  .catch(() => false);
              }}
              data-cy="save"
            >
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction
            dropdownItems={actions}
            onVisibleChange={(visible) =>
              setIsTableActionsVisible({ rowId: record.id, state: visible })
            }
            visible={isTableActionsVisible.rowId === record.id && isTableActionsVisible.state}
          />
        );
      },
    },
  ];

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const skuCreatedBySystem = col.dataIndex === 'sku' && record.createBy === 'sistema';

        return {
          record,
          dataIndex: col.dataIndex,
          required: col.required,
          title: col.title,
          editable: !skuCreatedBySystem && col.editable && isEditing(record),
          editing: isEditing(record),
        };
      },
    };
  });

  const csvHeaders = [
    { label: t('pages.sku.fields.projectName'), key: 'project' },
    { label: t('pages.sku.fields.sku'), key: 'sku' },
    { label: t('pages.sku.fields.modelName'), key: 'modelName' },
    { label: t('pages.sku.fields.created'), key: 'createDateTime' },
    { label: t('pages.sku.fields.createdBy'), key: 'createBy' },
    { label: t('pages.sku.fields.updated'), key: 'updateDateTime' },
    { label: t('pages.sku.fields.updatedBy'), key: 'updateBy' },
  ];

  useEffect(() => {
    Promise.all([fetchProduct(), fetchSKUs(), fetchProjects(), fetchModelName()]);
  }, []);

  useEffect(() => {
    setParams((prevState) => {
      return { ...prevState, modelName: '' };
    });
    fetchModelName();
  }, [params.sku]);

  useEffect(() => {
    setParams((prevState) => {
      return { ...prevState, sku: '', modelName: '' };
    });
    Promise.all([fetchSKU(), fetchModelName()]);
  }, [params.project]);

  useEffect(() => {
    setParams((prevState) => {
      return { ...prevState, project: '' };
    });
    fetchProjects();
    fetchSKU();
    fetchModelName();
  }, [params.product]);

  useEffect(() => {
    fetchProduct();
  }, [params.cm]);

  useEffect(() => {
    fetchSKUs();
  }, [params.page, params.orderBy, params.order]);

  return {
    data,
    params,
    pageControl,
    fetchSKUs,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    searchFilter,
    clearFilter,
    columns,
    mergedColumns,
    csvHeaders,
    CMs,
    products,
    fetchProjects,
    projects,
    handleFilters,
    clearAllFilters,
    fetchSKU,
    skus,
    fetchModelName,
    modelName,
    fetchProduct,
    setParams,
  };
}
