import { Button, Typography } from 'antd';
import styled from 'styled-components';

export const EditButton = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.colors.link} !important;
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme, edited }) => (edited ? theme.colors.text_blue : theme.colors.text)};
  font-weight: 500;
`;

export const CellEdited = styled(Typography)`
  color: ${({ theme }) => theme.colorsDesignSystem.textEditable};
  font-weight: 600;
`;

export const CellDisabled = styled(Typography)`
  color: ${({ theme }) => theme.colorsDesignSystem.textDisabled};
  font-weight: 600;
  cursor: not-allowed;
`;

export const CellValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
`;
