import { ResponsiveContainer, Bar, XAxis, YAxis, Tooltip, ComposedChart, Line } from 'recharts';
import { useTranslation } from 'react-i18next';
import { Forecast } from '../../../pages/Dashboards/Specifications/types';
import { getMaxAxisValue } from '../../../helpers/utils';

type ScorePerformedGraphProps = {
  data: Forecast[];
};

export const ScorePerformedGraph = ({ data }: ScorePerformedGraphProps) => {
  const { t } = useTranslation();
  const filteredData = data.filter((d) => d.month !== 'Total');
  const maxValue = getMaxAxisValue(filteredData);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={filteredData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis
          dataKey="month"
          label={{
            value: t('pages.dashboard.fields.months'),
            position: 'insideBottom',
            offset: -20,
          }}
          scale="auto"
        />
        <YAxis
          domain={[0, maxValue]}
          label={{ value: t('common.score'), angle: -90, position: 'insideLeft', offset: 15 }}
        />
        <Tooltip />
        <Bar dataKey="value" barSize={38} fill="#D9D9D9" label={{ position: 'top' }} />
        {/*<Line type="monotone" dataKey="trend" stroke="#FF8B27" />*/}
        <Line type="monotone" dataKey="target" stroke="#3D85C6" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
