import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from '../../../components/Button';
import * as SC from '../../../styles/common';
import { routesConfig } from '../../../routes/config';

const FORM_INITAL_VALUES = {
  name: '',
  old_name: '',
  permissions: [],
};

export function GroupForm({ onClose, form, save, loading, ...rest }) {
  const { t } = useTranslation();

  const onFinish = async (values) => {
    await save();
    onClose();
  };

  const validateMessages = {
    required: `\${label} ${t('common.validate.isRequired')}`,
  };
  const routes = routesConfig.routes.sort((item, next) => (item.label > next.label ? 1 : -1));

  return (
    <Row justify="center">
      <Col span={24}>
        <Spin
          tip="Loading..."
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={FORM_INITAL_VALUES}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t('pages.groups.fields.name')}
                  rules={[{ required: true }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder={t('pages.groups.fields.name')} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="permissions"
                  label={t('common.permissions')}
                  style={{ width: '100%' }}
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group>
                    <Row gutter={16}>
                      {routes.map(({ route, label }) => (
                        <Col span={8} key={route}>
                          <Checkbox value={route} style={{ textTransform: 'capitalize' }}>
                            {label}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>

            <SC.HR />

            <Row>
              <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button htmlType="button" onClick={onClose} danger>
                  {t('common.cancel')}
                </Button>

                <Button type="primary" htmlType="submit" data-cy="save">
                  {t('common.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
}
