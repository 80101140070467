import { Button, Checkbox, Col, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownload } from 'react-icons/bi';
import { CloseOutlined } from '@ant-design/icons';
import { CsvExport } from '../../components/Common/CsvExport';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { EditableCell } from '../../components/EditableCell';
import { useAVLC } from './useAVLC';
import { CustomPagination } from '../../components/CustomPagination';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { ModalStyled } from '../../components/Modal';
import { AVLCDetails } from './Details';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { getURI } from '../../helpers/utils';
import { NewComponent } from './NewComponent';
import StyledModal from '../../components/Common/StyledModal';
import StyledSelect from '../../components/Common/StyledSelect';
import * as S from './styles';
import StyledButton from '../../components/Common/StyledButton';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';

export function AVLCPage({ view = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.avlc.title'));

  const {
    data,
    params,
    pageControl,
    fetchAVLC,
    changePageValue,
    handleChangeTable,
    isLoading,
    mergedColumns,
    onModalClose,
    modalData,
    lastDate,
    transformData,
    getQueryParams,
    headerCsv,
    componentModalData,
    form,
    saveComponent,
    clearAllFilters,
    handleParams,
    projects,
    categories,
    specs,
    partNumbers,
    sources,
    manufacturers,
    status,
  } = useAVLC(dataModal);

  const modalTitle = useMemo(() => {
    return `${t('pages.avlc.fields.project')}: ${modalData?.projectName}`;
  }, [modalData]);

  const showTagsFilter =
    params.projectName?.length ||
    params.partType?.length ||
    params.spec?.length ||
    params.partNumber ||
    params.qStatus ||
    params.location ||
    params.vendor;

  return (
    <main>
      {isLoading ? (
        <Loading />
      ) : (
        <Col span={24} className="filter-page">
          <Row justify="center">
            <Col span={23}>
              <StyledPageHeader
                title={
                  <HeaderTitle
                    title={t('pages.avlc.title')}
                    subtitle={null}
                    updated={lastDate}
                    breadcrumb={null}
                  />
                }
                className="pl-0 pr-0"
                extra={[
                  <ActionButton
                    key={2}
                    className="btn-csv"
                    icon={<BiDownload />}
                    buttonText={null}
                    iconEnd={null}
                  >
                    <CsvExport
                      url={getURI(`avlc`, { ...getQueryParams(params), generateTable: true })}
                      filename={['avlc', new Date().toDateString()]}
                      limit={pageControl.totalElements}
                      headers={headerCsv}
                      transformData={transformData}
                    />
                  </ActionButton>,
                ]}
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={23}>
              <S.ContainerFilters>
                <StyledSelect
                  onChange={(e: string) => handleParams('projectName', e)}
                  styles={{ minWidth: '8.5rem' }}
                  showSearch
                  options={projects}
                  value={params.projectName}
                  label={t('pages.avlc.filters.project')}
                  placeholder={t('pages.avlc.filters.projectPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('partType', e)}
                  styles={{ minWidth: '8.125rem' }}
                  showSearch
                  options={categories}
                  value={params.partType}
                  label={t('pages.avlc.filters.category')}
                  placeholder={t('pages.avlc.filters.categoryPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('spec', e)}
                  styles={{ minWidth: '10rem' }}
                  showSearch
                  options={specs}
                  value={params.spec}
                  label={t('pages.avlc.filters.specification')}
                  placeholder={t('pages.avlc.filters.specificationPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('partNumber', e)}
                  styles={{ minWidth: '8rem' }}
                  showSearch
                  options={partNumbers}
                  value={params.partNumber}
                  label={t('pages.avlc.filters.partNumber')}
                  placeholder={t('pages.avlc.filters.partNumberPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('location', e)}
                  styles={{ minWidth: '6.25rem' }}
                  options={sources}
                  value={params.location}
                  label={t('pages.avlc.filters.source')}
                  placeholder={t('pages.avlc.filters.sourcePlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('vendor', e)}
                  styles={{ minWidth: '8.5rem' }}
                  showSearch
                  options={manufacturers}
                  value={params.vendor}
                  label={t('pages.avlc.filters.manufacturer')}
                  placeholder={t('pages.avlc.filters.manufacturerPlaceholder')}
                />
                <StyledSelect
                  onChange={(e: string) => handleParams('qStatus', e)}
                  styles={{ minWidth: '6.625rem' }}
                  label={t('pages.avlc.filters.status')}
                  options={status}
                  value={params.qStatus}
                  placeholder={t('pages.avlc.filters.statusPlaceholder')}
                />
                <Checkbox
                  style={{ padding: '0.375rem' }}
                  checked={params.hasComponent || false}
                  onChange={() => {
                    const newValue = params.hasComponent ? null : true;
                    handleParams('hasComponent', newValue);
                  }}
                >
                  {t('pages.avlc.filters.noRegistryComponent')}
                </Checkbox>
                <StyledButton
                  onClick={() => fetchAVLC()}
                  variant="primary"
                  text={t('common.apply')}
                />
              </S.ContainerFilters>
            </Col>
            <Col span={23}>
              <S.FiltersTagContainer>
                {params.projectName && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.project')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.projectName}
                    closeble
                    onClose={() => handleParams('projectName', '')}
                  />
                )}
                {params.partType && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.category')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.partType}
                    closeble
                    onClose={() => handleParams('partType', '')}
                  />
                )}
                {params.spec && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.specification')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.spec}
                    closeble
                    onClose={() => handleParams('spec', '')}
                  />
                )}
                {params.partNumber && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.partNumber')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.partNumber}
                    closeble
                    onClose={() => handleParams('partNumber', '')}
                  />
                )}
                {params.location && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.source')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.location}
                    closeble
                    onClose={() => handleParams('location', '')}
                  />
                )}
                {params.vendor && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.manufacturer')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.vendor}
                    closeble
                    onClose={() => handleParams('vendor', '')}
                  />
                )}
                {params.qStatus && (
                  <StyledFilterTag
                    label={t('pages.avlc.filters.status')}
                    closeicon={<CloseOutlined color={theme.colors.prim} />}
                    title={params.qStatus}
                    closeble
                    onClose={() => handleParams('qStatus', '')}
                  />
                )}
                {showTagsFilter && <StyledClearTag onClick={clearAllFilters} />}
              </S.FiltersTagContainer>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={23}>
              <SimpleTable
                rowKey="id"
                data-cy="table"
                bordered
                striped
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                scroll={{ x: 'max-content' }}
                columns={mergedColumns}
                dataSource={data}
                onChange={handleChangeTable}
                pagination={false}
                expandRowByClick={false}
                locale={{
                  emptyText: (
                    <ResultNoScenarios
                      title={t('common.results.noDataTitle')}
                      subTitle={t('common.results.noDataSubtitle')}
                      icon={null}
                    />
                  ),
                }}
              />

              <ModalStyled
                key="modal-avlc"
                title={modalTitle}
                open={modalData !== null}
                onCancel={onModalClose}
                onOk={onModalClose}
                width={1200}
                footer={null}
              >
                <AVLCDetails data={modalData} />
              </ModalStyled>

              <StyledModal
                key="modal-newcomponent"
                title={t('pages.avlc.fields.modalName')}
                open={componentModalData !== null}
                onClose={onModalClose}
                footer={
                  <Row justify="space-between">
                    <Button type="link" onClick={onModalClose}>
                      {t('common.cancel')}
                    </Button>
                    <Button type="primary" onClick={saveComponent}>
                      {t('common.save')}
                    </Button>
                  </Row>
                }
                body={<NewComponent form={form} />}
                width="64rem"
              />

              {pageControl.totalPages > 1 && (
                <CustomPagination
                  limit={params.limit}
                  page={params.page + 1}
                  pageLabel={pageControl.pageLabel}
                  total={pageControl.totalElements}
                  totalPages={pageControl.totalPages}
                  changePageValue={changePageValue}
                />
              )}
            </Col>
          </Row>
        </Col>
      )}
    </main>
  );
}
