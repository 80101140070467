import { Col, ConfigProvider, Row, Form } from 'antd';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { useGroups } from './useGroups';
import { EditableCell } from '../../components/EditableCell';
import { ModalWrapper } from '../../components/Modal';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import GroupsFilter from './GroupsFilter';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { GroupForm } from './Form/GroupForm';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';

export function GroupsPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.groups.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    fetchGroups,
    isLoading,
    form,
    isNewItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    mergedColumns,
    handleAddItem,
    isGroupModalOpen,
    save,
    onCloseModal,
  } = useGroups();

  const modalRef = useRef(null);
  const showTagsFilter = params.name || params.email;

  useEffect(() => {
    fetchGroups();
  }, [params]);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.groups.title')} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={1}
                      icon={<BiPlus />}
                      disabled={isNewItem}
                      onClick={handleAddItem}
                      buttonText={`${t('pages.groups.addGroup')}`}
                    />,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}

                  <SC.TagFilter
                    closable
                    visible={params.name}
                    onClose={() => handleCloseFilteredTag('name')}
                  >
                    <div className="tag-title">{t('pages.groups.fields.name')}</div>
                    <div className="tag-value">{params.name}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <ModalWrapper
        title={isNewItem ? t('pages.groups.addGroup') : t('pages.groups.editGroup')}
        isVisible={isGroupModalOpen}
        onClose={onCloseModal}
        key="modal-pbb"
        width={480}
        ref={modalRef}
      >
        <GroupForm onClose={onCloseModal} form={form} save={save} loading={isLoading} />
      </ModalWrapper>
    </main>
  );
}
