import { Button, Form, Input, Popconfirm, Space } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import {
  getSortOrder,
  oneAlert,
  getURI,
  getErrorMessage,
  debounce,
  hasPropertyWithHeader,
  removeNullOptions,
} from '../../helpers/utils';
import { api } from '../../services/api';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import ComponentsSelect from '../../components/forms/ComponentsSelect';
import { getColumnSearchProps } from '../../helpers/table';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import {
  ComponentsGroupFilters,
  ComponentsGroupHeaderFilters,
  ComponentsGroupParams,
  ComponentTypeGrouping,
  Grouping,
  GroupRecords,
  Option,
  PPBProduct,
  Spec,
} from './types';

export function useComponentsGroup() {
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const localProduct = localStorage.getItem('product') || '';

  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 10,
    targetYear: localTargetYear,
    product: localProduct?.split('$')[1] || '',
    productId: localProduct?.split('$')[0] || '',
    spec: [],
    partType: [],
    partNumber: [],
    groupName: '',
    supplier: '',
  } as unknown as ComponentsGroupParams;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [data, setData] = useState<ComponentTypeGrouping[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [isEditAction, setIsEditAction] = useState(false);
  const [actionLevel, setActionLevel] = useState('');
  const [isNewItem, setIsNewItem] = useState(false);
  const [isNewSubItem, setIsNewSubItem] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<any>(initialParams.product);
  const [params, setParams] = useState(initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [refetchData, setRefetchData] = useState<{
    spec: string | string[];
    partType: string | string[];
    groupName: string;
  }>({
    spec: [],
    partType: [],
    groupName: '',
  });
  const [productsOptions, setProductsOptions] = useState<Option[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[] | React.Key[]>([]);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [isTableActionsVisible, setIsTableActionsVisible] = useState({
    rowId: '',
    state: false,
  });
  const [modalData, setModalData] = useState<Spec | null>(null);

  const resetControls = (expandedRows: string[] = []) => {
    setEditingKey('');
    setActionLevel('');
    setIsEditAction(false);
    setIsNewItem(false);
    setIsNewSubItem(false);
    setExpandedRowKeys(expandedRows);
    setIsTableActionsVisible({ rowId: '', state: false });

    setRefetchData({
      groupName: '',
      partType: [],
      spec: [],
    });

    form.setFieldsValue({
      groupName: '',
      partType: [],
      spec: [],
    });
  };

  function getQueryParams() {
    const getParams = { ...params };

    if (!params.spec?.length) {
      getParams.spec = null;
    } else {
      getParams.spec = btoa(params.spec as string);
    }

    if (!params.partType?.length) getParams.partType = null;
    if (!params.partNumber?.length) getParams.partNumber = null;

    delete getParams.product;
    delete getParams.productId;
    delete getParams.targetYear;

    return getParams;
  }

  const fetchProducts = async () => {
    try {
      const {
        data: { content: products },
      } = await api.get(getURI(`ppb`));
      const parsedProductOptions: Option[] = products.map((prod: PPBProduct) => ({
        label: prod.productName,
        value: `${prod.id}$${prod.productName}`,
      }));

      const optionsFiltered: Option[] = removeNullOptions(parsedProductOptions);
      setProductsOptions(optionsFiltered);
      setParams((prev) => ({
        ...prev,
        product: parsedProductOptions[0].value.split('$')[1],
        productId: parsedProductOptions[0].value.split('$')[0],
      }));
      localStorage.setItem('product', parsedProductOptions[0].value);
    } catch (err) {
      console.log('err/products=>', err);
      setProductsOptions([]);
    }
  };

  const fetchComponentGroups = async () => {
    if (params) {
      const getParams = getQueryParams();

      try {
        if (!isLoading) setIsLoading(true);

        const {
          data: { content, totalElements, totalPages },
        } = await api.get(getURI(`components/grouping/componentType`, getParams));

        if (params.product && params.targetYear) {
          localStorage.setItem('product', `${params.productId}$${params.product}`);
          localStorage.setItem('targetYear', params.targetYear as string);
        }

        const dataContent: ComponentTypeGrouping[] = content?.length ? content : [];
        setData(dataContent);

        if (dataContent?.length) {
          setExpandedRowKeys([dataContent[0].id]);
        }

        setPageControl({
          pageLabel: `${params.page + 1}`,
          totalPages,
          totalElements,
        });

        resetControls();
        setIsLoading(false);
      } catch (error) {
        setData([]);
        setPageControl({
          pageLabel: '1',
          totalPages: 0,
          totalElements: 0,
        });

        setIsLoading(false);
      }
    }
  };

  const fetchGroupsOnDelete = async () => {
    try {
      const getParams = getQueryParams();

      const {
        data: { content, totalElements, totalPages },
      } = await api.get(getURI(`components/grouping/componentType`, getParams));

      const dataContent = content?.length ? content : [];

      setData(dataContent);
      setPageControl({
        ...pageControl,
        totalElements,
        totalPages,
      });
    } catch (error) {
      setData([]);
      setPageControl({
        pageLabel: '1',
        totalPages: 0,
        totalElements: 0,
      });
    }
  };

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    } else {
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    }
  };

  const handleChangeTable = (_: unknown, __: unknown, sorter: any) => {
    if (data.length === 0) return;
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const getPartTypeFromId = (id: string) => {
    const idAsArray = id.split('$');
    const partType = idAsArray[1];
    return partType;
  };

  const getPartTypeRowId = (id: string) => {
    const idAsArray = id.split('$');
    const rowId = `${idAsArray[0]}$${idAsArray[1]}`;
    return rowId.includes('new') ? rowId.split('-')[1] : rowId;
  };

  const getActionLevel = (record: GroupRecords) => {
    if (record?.id?.startsWith('newPt')) return 'partType';
    if (record?.id?.startsWith('newSpec')) return 'spec';
    if (record?.id?.startsWith('newGn')) return 'groupName';
    if ('partType' in record && record.partType) return 'partType';
    if ('spec' in record && record.spec) return 'spec';
    return 'groupName';
  };

  const getIds = (specId: string) => {
    let typeId;
    let groupId;

    data.forEach((type) => {
      type.children.forEach((group) => {
        group.children.forEach((spec) => {
          if (spec.id === specId) {
            typeId = type.id;
            groupId = group.id;
          }
        });
      });
    });

    return [typeId, groupId];
  };

  const cancelNewItem = (_: unknown, key = null) => {
    const keyId = key || editingKey;
    const newData = data.filter((item) => item.id !== keyId);
    setData(newData);
    resetControls([]);
  };

  const cancelNewSubItem = (record: GroupRecords, key = null) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const keyId = key || editingKey;
    const newData = [...data];
    const level = getActionLevel(record);
    const partType = getPartTypeRowId(record.id);

    if (level === 'groupName') {
      setData(
        newData.map((pT) => {
          const newChildren = pT.children?.filter((gN) => !gN.id.startsWith('newGn'));
          return { ...pT, children: newChildren };
        })
      );
      resetControls([partType]);
    } else {
      setData(
        newData.map((pT) => ({
          ...pT,
          children: pT.children?.map((gN) => ({
            ...gN,
            children: gN.children?.filter((sP) => !sP.id.startsWith('new')),
          })),
        }))
      );
      resetControls([partType]);
    }
  };

  const cancel = (record: GroupRecords, event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    if (isNewItem) cancelNewItem(record);
    if (isNewSubItem) cancelNewSubItem(record);
    resetControls();
  };

  const handleSaveItem = async (
    row: any,
    record: GroupRecords,
    responseData: any,
    level: string
  ) => {
    let newData = [...data];
    const partTypeRow = record.id.includes('new') ? responseData.id : getPartTypeRowId(record.id);

    if (level === 'partType') {
      newData = data.filter((type) => type.id !== responseData.id);
      newData.unshift(responseData);
      newData = newData.filter((item) => !item.id.includes('new'));
    } else if (isEditAction) {
      const replaceIndex = newData.findIndex((item) => item.id === partTypeRow);
      const groupIndex = newData[replaceIndex].children.findIndex((item) => item.id === record.id);
      const oldGroup = newData[replaceIndex].children[groupIndex];

      newData[replaceIndex].children.splice(groupIndex, 1, {
        ...oldGroup,
        groupName: row.groupName,
      });
    } else {
      fetchComponentGroups();
      return;
    }
    setData(newData);

    if (actionLevel === 'partType') {
      resetControls([partTypeRow]);
    } else {
      resetControls([partTypeRow, record?.id]);
    }
  };

  const handleAddItem = (e: any) => {
    e.stopPropagation();
    const newItem = {
      id: `newPt-${data.length + 1}`,
      groupName: '',
      partType: '',
      spec: '',
      children: [],
    };

    setIsNewItem(true);
    setActionLevel('partType');
    form.setFieldsValue({ ...newItem });
    setData([newItem, ...data]);
    setEditingKey(newItem.id);
    setExpandedRowKeys([newItem.id]);
  };

  const handleAddSubItem = (record: GroupRecords, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsTableActionsVisible({ ...isTableActionsVisible, state: false });

    const newData = [...data];
    const copyRecord = { ...record };

    const level = getActionLevel(copyRecord);
    const parTypeRow = getPartTypeRowId(record.id);

    const newSubItem =
      level === 'partType'
        ? {
            id: `newGn-${copyRecord.id}$${level}${newData.length + 1}`,
            spec: [],
            partType: 'partType' in copyRecord ? copyRecord.partType : '',
            groupName: '',
            className: '',
            children: null,
          }
        : {
            id: `newSpec-${copyRecord.id}$${level}${newData.length + 1}`,
            spec: [],
            groupName: 'groupName' in copyRecord ? copyRecord.groupName : '',
            partType: getPartTypeFromId(copyRecord.id),
            className: 'spec',
            children: null,
          };

    if (level === 'groupName') {
      form.setFieldsValue({ ...newSubItem });
      const newChildren =
        'children' in copyRecord ? [newSubItem, ...copyRecord.children] : [newSubItem];
      const newRecord = { ...record, children: newChildren };
      const rowIndex = newData?.findIndex((row) => row.id === parTypeRow);
      const index = newData[rowIndex]?.children?.findIndex((item) => item.id === copyRecord.id);

      if (index > -1) {
        newData[rowIndex].children.splice(index, 1, newRecord as Grouping);
      }

      const expand = [parTypeRow, newRecord.id, newSubItem.id];
      setExpandedRowKeys(expand);
    } else if (level === 'partType') {
      newSubItem.className = 'groupName';
      form.setFieldsValue({ ...newSubItem });
      const newChildren = 'children' in record ? [newSubItem, ...record.children] : [newSubItem];
      const newRecord = { ...copyRecord, children: newChildren };
      const index = newData.findIndex((item) => item.id === record.id);

      if (index > -1) {
        newData.splice(index, 1, newRecord as ComponentTypeGrouping);
      }

      const expand = [record.id];
      setExpandedRowKeys(expand);
    }

    setData(newData);
    setEditingKey(newSubItem.id);
    setIsNewSubItem(true);
    setActionLevel(level);
  };

  const save = async (record: GroupRecords, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    const { id } = record;

    setIsLoading(true);
    await form
      .validateFields()
      .then(async (row) => {
        const itemRow = { ...row };
        const typeId = getPartTypeRowId(record.id);
        try {
          let response: any;
          if (isEditAction) {
            if (actionLevel === 'groupName') {
              response = await api.put(
                getURI(`components/grouping/componentType/${typeId}/componentgroup/${id}`),
                { ...row }
              );
              if (response.status !== 200) throw Error();
              response.data = { ...record, ...row };
            } else {
              throw Error('No actionLevel found');
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (actionLevel === 'partType') {
              if (record.id.includes('newGn')) {
                response = await api.post(getURI(`components/grouping/componentType/${typeId}`), {
                  ...row,
                  product: params.product,
                  productId: params.productId,
                  targetYear: params.targetYear,
                  partType: row.partType,
                });

                if (response.status !== 200) throw Error(response?.errorResponse);
              } else {
                response = await api.post(getURI(`components/grouping/componentType`), {
                  ...row,
                  product: params.product,
                  productId: params.productId,
                  targetYear: params.targetYear,
                  partType: row.partType,
                });
                if (response.status !== 201) throw Error(response?.errorResponse);
              }
            } else if (actionLevel === 'groupName') {
              const [type, group] = getIds(record.id);
              response = await api.post(
                getURI(`components/grouping/componentType/${type}/componentgroup/${group}/spec`),
                row
              );
              if (response.status !== 201) throw Error(response.errorResponse);
            } else {
              throw Error('No actionLevel found');
            }
          }

          // fetchComponentGroups();
          handleSaveItem(itemRow, record, response?.data, actionLevel);
          oneAlert('success', t('toast.successOnSave'));
        } catch (error) {
          oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
        }
      })
      .catch((error) => {
        console.log('Validate Failed: ', error);
      });

    setIsLoading(false);
  };

  const deleteItem = async (
    record: GroupRecords,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setIsLoading(true);
    try {
      event.stopPropagation();
      const level = getActionLevel(record);
      const typeId = getPartTypeRowId(record.id);
      if (level === 'groupName') {
        const response = await api.delete(
          getURI(`components/grouping/componentType/${typeId}/componentgroup/${record.id}`)
        );

        if (response.status !== 200) throw Error();
        fetchGroupsOnDelete();
      } else if (level === 'spec') {
        const [type, group] = getIds(record.id);
        const response = await api.delete(
          getURI(
            `components/grouping/componentType/${type}/componentgroup/${group}/spec/${record.id}`
          )
        );

        if (response.status !== 200) throw Error();
        fetchGroupsOnDelete();
      } else {
        const response = await api.delete(getURI(`components/grouping/componentType/${typeId}`));

        if (response.status !== 200) throw Error();
        fetchGroupsOnDelete();
      }

      const isLastItem = data.length === 1;
      if (isLastItem) {
        const newPage = params.page - 1;
        setParams({ ...params, page: newPage < 0 ? 0 : newPage });
      }

      setPageControl({ ...pageControl, totalElements: pageControl.totalElements - 1 });
      setIsTableActionsVisible({ rowId: '', state: false });

      oneAlert('info', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }

    await fetchGroupsOnDelete();

    setIsLoading(false);
  };

  const isEditing = (record: GroupRecords) => record.id === editingKey;

  const edit = (record: GroupRecords, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    setRefetchData({
      groupName: 'groupName' in record ? record.groupName : '',
      partType: 'partType' in record ? record.partType : '',
      spec: 'spec' in record ? record.spec : '',
    });
    form.setFieldsValue({ ...record });

    setActionLevel(getActionLevel(record));
    setEditingKey(record.id);
    setIsEditAction(true);
  };

  const searchFilter = async (newFilters: ComponentsGroupParams, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
      product: newFilters?.product?.split('$')[1],
      productId: newFilters?.product?.split('$')[0],
    };

    setSelectedProduct(newFilters.product);

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      targetYear: null,
      product: null,
      productId: null,
      groupName: null,
      partType: [],
      spec: [],
      partNumber: [],
      supplier: null,
    });
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce((e: any) => {
        const value = !e.target.value ? '' : e.target.value.toString().toLowerCase().trim();
        setSearchValue(value);
      }, 300),
    []
  );

  const debouncedOnCell = useMemo(
    () =>
      debounce((fn: any) => {
        fn();
      }, 0),
    []
  );

  const onValuesChange = (changedValues: any) => {
    if (changedValues.partType) {
      setRefetchData({
        ...refetchData,
        groupName: changedValues.groupName ? changedValues?.groupName : '',
        partType: changedValues?.partType,
        spec: [],
      });
      form.setFieldsValue({
        spec: [],
        partNumber: [],
        supplier: '',
      });
    }
    if (changedValues?.spec) {
      setRefetchData({ ...refetchData, spec: changedValues.spec });
      form.setFieldsValue({
        partNumber: [],
        supplier: '',
      });
    }
  };

  const rowClassName = (row: any) => {
    const className = row.className
      ? row.className
      : row.groupName
      ? 'groupName'
      : row?.spec?.length > 0
      ? 'spec'
      : '';
    if (isNewItem && row.id === editingKey) {
      return className ? `data-row-editing ${className}` : 'data-row-editing';
    }
    return className;
  };

  const handleHeaderReset = (dataIndex: ComponentsGroupFilters) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (
    field: ComponentsGroupFilters | ComponentsGroupHeaderFilters,
    idx = 0
  ) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex as ComponentsGroupFilters);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [new Array(params[field])];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex: ComponentsGroupFilters, formValue: string) => {
    if (formValue?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: formValue.trim(),
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else if (!formValue) {
      handleHeaderReset(dataIndex);
    }
  };

  const removeEmptyChildren = (dataSource: ComponentTypeGrouping[]) => {
    return dataSource.map((type) => {
      const typeChildren =
        type.children?.length > 0
          ? type.children.map((group, index) => {
              const groupChildren = group?.children?.length > 0 ? group.children : null;
              return { ...group, children: groupChildren };
            })
          : null;
      return { ...type, children: typeChildren };
    });
  };

  const columns = [
    {
      title: t('pages.componentsGroup.fields.partType'),
      label: t('pages.componentsGroup.fields.partType'),
      width: 300,
      dataIndex: 'partType',
      key: 'partType',
      editable: true,
      required: true,
      ...getColumnSearchProps({
        dataIndex: 'partType',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.componentsGroup.fields.groupName'),
      label: t('pages.componentsGroup.fields.groupName'),
      width: 300,
      dataIndex: 'groupName',
      key: 'groupName',
      editable: true,
      required: true,
      ...getColumnSearchProps({
        dataIndex: 'groupName',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.componentsGroup.fields.spec'),
      label: t('pages.componentsGroup.fields.spec'),
      dataIndex: 'spec',
      key: 'spec',
      editable: true,
      required: true,
      ...getColumnSearchProps({
        dataIndex: 'spec',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      key: 'action',
      width: 130,
      align: 'center',
      render: (_: unknown, record: GroupRecords) => {
        const editable = isEditing(record);
        const level = getActionLevel(record);

        const actions = [
          {
            key: '1',
            label: level === 'groupName' && (
              <Button
                type="link"
                disabled={editingKey !== '' || isLoading}
                onClick={(e) => edit(record, e)}
                data-cy="edit"
              >
                {t('common.edit')}
              </Button>
            ),
          },
          {
            key: '2',
            label: (
              <Popconfirm
                placement="topLeft"
                title={t('common.deleteMessage')}
                onConfirm={(e) => e && deleteItem(record, e)}
                onCancel={(e) => e?.stopPropagation()}
                onClick={(e: any) => e.stopPropagation()}
              >
                <Button
                  type="link"
                  danger
                  disabled={editingKey !== '' || isLoading}
                  data-cy="delete"
                >
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
          {
            key: '3',
            label: (level === 'partType' || level === 'groupName') && (
              <Button
                type="link"
                onClick={(e) => handleAddSubItem(record, e)}
                disabled={editingKey !== '' || isLoading}
              >{`${t('common.add')} ${t('common.item')}`}</Button>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={(e) => save(record, e)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={(e) => {
                cancel(record, e);
              }}
              onCancel={(e) => e?.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              <Button shape="circle" icon={<AiOutlineClose />} />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction
            placement="bottomLeft"
            dropdownItems={actions}
            onVisibleChange={(visible: boolean) =>
              setIsTableActionsVisible({ rowId: record.id, state: visible })
            }
            visible={isTableActionsVisible.rowId === record.id && isTableActionsVisible.state}
            className="actions"
          />
        );
      },
    },
  ];

  const headerCsv = [
    { label: t('pages.componentsGroup.fields.partType'), key: 'partType' },
    { label: t('pages.componentsGroup.fields.groupName'), key: 'groupName' },
    { label: t('pages.componentsGroup.fields.spec'), key: 'spec' },
    { label: t('pages.componentsGroup.fields.partNumber'), key: 'partNumber' },
    { label: t('pages.componentsGroup.fields.supplier'), key: 'supplier' },
  ];

  const transformData = (dataSource: ComponentTypeGrouping[]) => {
    const rows: any[] = [];
    dataSource.forEach((partType) => {
      rows.push({
        partType: partType.partType,
        groupName: '',
        spec: '',
        partNumber: '',
        supplier: '',
      });
      partType?.children.forEach((groupName) => {
        rows.push({
          partType: '',
          groupName: groupName.groupName,
          spec: '',
          partNumber: '',
          supplier: '',
        });
        groupName?.children.forEach((spec) => {
          rows.push({
            partType: '',
            groupName: '',
            spec: spec.spec,
            partNumber: '',
            supplier: '',
          });
          spec?.itens.forEach((item) => {
            rows.push({
              partType: '',
              groupName: '',
              spec: '',
              partNumber: item.partNumber,
              supplier: item.supplier,
            });
          });
        });
      });
    });

    return rows;
  };

  const inputNode = (column: any) => {
    const row = form.getFieldValue();

    switch (column) {
      case 'partType':
        return (
          <ComponentsTypeSelect
            id="partTypeSelect"
            allowClear={false}
            placeholder={t('filter.partType')}
            intable="intable"
            search={searchValue}
            onKeyUp={debouncedEventHandler}
            onClick={(e: any) => e.stopPropagation()}
            ppb={false}
          />
        );
      case 'spec':
        return (
          <ComponentsSelect
            id="specSelect"
            intable="intable"
            placeholder={t('filter.spec')}
            disabled={!refetchData?.partType?.length && !row?.partType?.length}
            componentType={row?.partType}
            onClick={(e: any) => e.stopPropagation()}
          />
        );
      default:
        return <Input onClick={(e) => e.stopPropagation()} />;
    }
  };

  const getEditableColumn = (colIndex: string, record: GroupRecords, editable: boolean) => {
    const level = getActionLevel(record);
    switch (level) {
      case 'spec':
        return colIndex === 'spec';
      case 'partType':
        return isEditAction ? colIndex === 'groupName' : true;
      case 'groupName':
        return isEditAction ? colIndex === 'groupName' : colIndex !== 'partType';
      default:
        return editable;
    }
  };

  const handleRowClick = (record: GroupRecords | object) => {
    return 'spec' in record && !isEditing(record)
      ? {
          ...record,
          onClick: () => {
            setModalData(record);
          },
        }
      : record;
  };

  const onModalClose = () => {
    setModalData(null);
  };

  const mergedColumns = columnsWithAction.map((col: any, index) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: GroupRecords) => {
        return {
          record,
          editable: getEditableColumn(col.dataIndex, record, col.editable),
          limit: col.dataIndex === 'spec' ? 255 : col.dataIndex === 'groupName' ? 20 : 100,
          required: col.required,
          newInputNode: inputNode(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          inputType: col.dataIndex === 'groupName' ? 'stringAndSpecial' : null,
          min: 2,
        };
      },
    };
  });

  return {
    rowClassName,
    mergedColumns,
    transformData,
    headerCsv,
    data,
    params,
    pageControl,
    canUpdate,
    selectedProduct,
    fetchComponentGroups,
    changePageValue,
    handleChangeTable,
    onValuesChange,
    refetchData,
    setRefetchData,
    searchValue,
    debouncedEventHandler,
    debouncedOnCell,
    isLoading,
    form,
    isNewItem,
    isNewSubItem,
    save,
    cancel,
    handleAddItem,
    handleAddSubItem,
    actionLevel,
    edit,
    isEditing,
    isEditAction,
    editingKey,
    deleteItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    expandedRowKeys,
    setExpandedRowKeys,
    setParams,
    getQueryParams,
    modalData,
    handleRowClick,
    onModalClose,
    getActionLevel,
    fetchProducts,
    productsOptions,
    removeEmptyChildren,
  };
}
