import { useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../services/api';
import { AlertNotification, Notification, SKUAlert } from '../../../context/types';
import { useNotifications } from '../../../context/notification';
import { parseSKUNotification } from '../../../helpers/typed-utils';
import { getFilterURL, getSortOrder, removeEmptyFilter } from '../../../helpers/utils';
import { useTable } from '../../../hooks/useTable';

const initialParams = {
  order: 'projectName',
  orderBy: 'ASC',
  page: 0,
  limit: 25,
};

export function useSKUAlert() {
  const { t } = useTranslation();
  const { id: alertId } = useParams<{ id: string }>();
  const { viewNotification } = useNotifications();
  const { getSortOrderTable, getSortTitle } = useTable();

  const [data, setData] = useState<Notification>();
  const [tableData, setTableData] = useState<SKUAlert[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(initialParams);

  const fetchAlert = async () => {
    const { data: response }: { data: AlertNotification } = await api.get(
      `/list/alerts/${alertId}`
    );

    const parsedSKUs = parseSKUNotification(
      { obj: response.message },
      response.id,
      response.creationDateTime,
      response.scenario,
      response.visualized
    );

    setData(parsedSKUs);

    const tData = parsedSKUs?.skus.map((item) => ({
      ...item,
      id: `${item.sku}-${item.modelName}`,
      projectName: item.project,
    }));

    setTableData(tData);

    await viewNotification(alertId);
    setIsLoading(false);
  };

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    setParams({
      ...params,
      order: sorter.columnKey,
      orderBy: getSortOrder(sorter.order),
      page: 0,
    });
  };

  const columns: ColumnsType<object> = [
    {
      title: t('pages.skuAlert.fields.sku'),
      dataIndex: 'sku',
      key: 'sku',
      width: 300,
      sorter: (a: any, b: any) => {
        return a.sku.localeCompare(b.sku);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'sku' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'sku', false) },
    },
    {
      title: t('pages.skuAlert.fields.projectName'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: 300,
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => {
        return a.projectName.localeCompare(b.projectName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'projectName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: {
        title: getSortTitle(params.orderBy, params.order, 'projectName', false),
      },
    },
    {
      title: t('pages.skuAlert.fields.modelName'),
      dataIndex: 'modelName',
      key: 'modelName',
      sorter: (a: any, b: any) => {
        return a.modelName.localeCompare(b.modelName);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modelName' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: getSortTitle(params.orderBy, params.order, 'modelName', false) },
    },
  ];

  return {
    isLoading,
    columns,
    data,
    tableData,
    params,
    fetchAlert,
    handleChangeTable,
  };
}
