import { Col, ConfigProvider, Row, Form } from 'antd';
import { BiDownload, BiPlus } from 'react-icons/bi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import StyledButton from '../../components/Common/StyledButton';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { useSKU } from './useSKU';
import { EditableCell } from '../../components/EditableCell';
import { CustomPagination } from '../../components/CustomPagination';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import * as S from './styles';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { ActionButton } from '../../components/ActionButton';
import { CsvExport } from '../../components/Common/CsvExport';
import { getURI, getFilterURL, removeEmptyFilter } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import StyledSelect from '../../components/Common/StyledSelect';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';

export function SKUPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.sku.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    fetchSKUs,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    mergedColumns,
    csvHeaders,
    handleAddItem,
    CMs,
    products,
    projects,
    handleFilters,
    clearAllFilters,
    skus,
    modelName,
  } = useSKU();

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.sku.title')} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={0}
                      icon={<BiPlus />}
                      onClick={(e) => handleAddItem(e)}
                      buttonText={`${t('common.add')} ${t('common.item')}`}
                    />,
                    <ActionButton key={1} className="btn-csv" icon={<BiDownload />}>
                      <CsvExport
                        url={`sku?${getFilterURL(removeEmptyFilter(params))}`}
                        filename={['sku']}
                        limit={pageControl.totalElements}
                        headers={csvHeaders}
                      />
                    </ActionButton>,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <S.FiltersContainer>
                  <StyledSelect
                    showSearch
                    label={t('pages.sku.filters.cm')}
                    placeholder={t('pages.sku.filters.cmPlaceholder')}
                    allowClear
                    disable
                    style={{ width: 'auto', minWidth: '8.5rem' }}
                    options={CMs}
                    value={params.cm}
                  />
                  <StyledSelect
                    showSearch
                    label={t('pages.sku.filters.product')}
                    placeholder={t('pages.sku.filters.productPlaceholder')}
                    allowClear
                    style={{ width: 'auto', minWidth: '8.5rem' }}
                    options={products}
                    value={params.product}
                    onChange={(e) => {
                      handleFilters('product', e);
                    }}
                  />
                  <StyledSelect
                    showSearch
                    label={t('pages.sku.filters.project')}
                    placeholder={t('pages.sku.filters.projectPlaceholder')}
                    allowClear
                    style={{ width: 'auto', minWidth: '8.5rem' }}
                    options={projects}
                    value={params.project}
                    onChange={(e) => {
                      handleFilters('project', e);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    showSearch
                    label={t('pages.sku.filters.sku')}
                    placeholder={t('pages.sku.filters.skuPlaceholder')}
                    allowClear
                    style={{ width: 'auto', minWidth: '8.5rem' }}
                    options={skus}
                    value={params.sku}
                    onChange={(e) => {
                      handleFilters('sku', e);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledSelect
                    showSearch
                    label={t('pages.sku.filters.modelName')}
                    placeholder={t('pages.sku.filters.modelNamePlaceholder')}
                    allowClear
                    style={{ width: 'auto', minWidth: '8.5rem' }}
                    options={modelName}
                    value={params.modelName}
                    onChange={(e) => {
                      handleFilters('modelName', e);
                    }}
                    suffixIcon={
                      <S.SearchButton>
                        <SearchOutlined className="icon" />
                      </S.SearchButton>
                    }
                  />
                  <StyledButton
                    small
                    onClick={fetchSKUs}
                    variant="primary"
                    text={t('common.apply')}
                  />
                </S.FiltersContainer>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <S.FilterTagsContainer>
                  {params.project && (
                    <StyledFilterTag
                      label={t('pages.sku.fields.projectName')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.project}
                      closeble
                      onClose={() => {
                        handleFilters('project', '');
                      }}
                    />
                  )}
                  {params.sku && (
                    <StyledFilterTag
                      label={t('pages.sku.fields.sku')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.sku}
                      closeble
                      onClose={() => {
                        handleFilters('sku', '');
                      }}
                    />
                  )}
                  {params.modelName && (
                    <StyledFilterTag
                      label={t('pages.sku.fields.modelName')}
                      closeicon={<CloseOutlined color={theme.colors.prim} />}
                      title={params.modelName}
                      closeble
                      onClose={() => {
                        handleFilters('modelName', '');
                      }}
                    />
                  )}
                  {(params.project || params.sku || params.modelName) && (
                    <StyledClearTag onClick={clearAllFilters} />
                  )}
                </S.FilterTagsContainer>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
