import {
  Dispatch,
  Key,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SendJsonMessage, SendMessage } from 'react-use-websocket/dist/lib/types';
import useWebSocket from 'react-use-websocket';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';
import { FaTrashAlt } from 'react-icons/fa';
import { DefaultOptionType } from 'antd/lib/select';
import { api } from '../services/api';
import {
  AlertNotification,
  JsonMessage,
  Notification,
  NotificationType,
  NotificationsParams,
  PageControl,
  ValidNotificationMessage,
} from './types';
import { Button } from '../components/Button';
import { parseSKUNotification } from '../helpers/typed-utils';
import { oneAlert, renderDateHour } from '../helpers/utils';
import { RoutesPath } from '../routes/routesPath';

interface NotificationsContextData {
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
  fetchNotifications: () => Promise<void>;
  viewNotification: (id: string) => void;
  getNotificationDescription: (type: NotificationType, data: number) => string;
  deleteNotification: (id: string) => Promise<void>;
  deleteSomeNotifications: (ids: string[]) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
  isLoading: boolean;
  isOpen: boolean;
  handleOpen: () => void;
  onClose: () => void;
  params: NotificationsParams;
  pageControl: PageControl;
  changePageValue: (page: number, type: string) => void;
  columnsWithAction: ColumnType<object>[];
  selectedNotifications: Key[];
  handleSelection: (selectedKeys: Key[]) => void;
  toggleFullSelection: () => void;
  sendJsonMessage: SendJsonMessage;
  lastMessage: MessageEvent<any> | null;
  lastJsonMessage: JsonMessage;
  sendMessage: SendMessage;
  users: DefaultOptionType[];
  closeWebSocketConnection: () => void;
}

const NotificationsContext = createContext({} as NotificationsContextData);

const compareNotifications = (a: Notification, b: Notification): number => {
  if (a.visualized === b.visualized) {
    const dateA = new Date(a.stamp).getTime();
    const dateB = new Date(b.stamp).getTime();
    return dateB - dateA; // Sort in descending order
  }
  return a.visualized ? 1 : -1; // False (visualized) comes first
};

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [users, setUsers] = useState<DefaultOptionType[]>([]);
  const [isOpen, setNotificationsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageControl, setPageControl] = useState<PageControl>({
    page: 0,
    totalElements: 2,
    totalPages: 1,
    pageLabel: '1',
  });
  const [params, setParams] = useState<NotificationsParams>({
    page: 1,
    limit: 10,
  });
  const [selectedNotifications, setSelectedNotifications] = useState<React.Key[]>([]);

  const { sendJsonMessage, lastMessage, lastJsonMessage, sendMessage } = useWebSocket(
    import.meta.env.VITE_API_WEBSOCKET,
    {
      onOpen: () => {
        sendJsonMessage({ action: 'subscribe', channel: 'Alerts' });
      },
      onClose: () => {
        sendJsonMessage({ action: 'unsubscribe', channel: 'Alerts' });
      },
      shouldReconnect: () => true,
      reconnectInterval: 60, // reconnect after 60 seconds
    }
  );

  const closeWebSocketConnection = () => {
    sendJsonMessage({ action: 'unsubscribe', channel: 'Alerts' });
  };

  const fetchNotifications = async () => {
    try {
      const {
        data: { content },
      }: { data: { content: AlertNotification[] } } = await api.get('/list/alerts');

      const parsedNotifications = content.map((noti) =>
        parseSKUNotification(
          { obj: noti.message },
          noti.id,
          noti.creationDateTime,
          noti.scenario,
          noti.visualized
        )
      );

      // const parsedNotifications2 = [
      //   {
      //     id: '6488d8d00e58612c3c96e131231235',
      //     type: 'sku_no_motherboard',
      //     data: 52,
      //     skus: [
      //       {
      //         sku: 'NH.QEDAL.002',
      //         project: 'QX60_TLS',
      //         modelName: 'PH315-54-70LH',
      //       },
      //     ],
      //     createdAt: '12/06/2023, 7:00',
      //     stamp: 'Tue Jun 12 2023 7:00:00 GMT-0300 (Brasilia Standard Time)',
      //     visualized: false,
      //     scenario: 'Cenário Base 13.0.0',
      //   },
      //   {
      //     id: '6488f4f00e58612c3c96e23423413b',
      //     type: 'sku_no_motherboard',
      //     data: 52,
      //     skus: [
      //       {
      //         sku: 'NH.QEDAL.002',
      //         project: 'QX60_TLS',
      //         modelName: 'PH315-54-70LH',
      //       },
      //     ],
      //     createdAt: '12/06/2023, 9:00',
      //     stamp: 'Tue Jun 12 2023 9:00:00 GMT-0300 (Brasilia Standard Time)',
      //     visualized: false,
      //     scenario: 'Cenário Base 13.0.0',
      //   },
      //   {
      //     id: '6488d8d00e58612c3c96e13453435',
      //     type: 'sku_no_motherboard',
      //     data: 52,
      //     skus: [
      //       {
      //         sku: 'NH.QEDAL.002',
      //         project: 'QX60_TLS',
      //         modelName: 'PH315-54-70LH',
      //       },
      //     ],
      //     createdAt: '13/06/2023, 21:00',
      //     stamp: 'Tue Jun 13 2023 21:00:00 GMT-0300 (Brasilia Standard Time)',
      //     visualized: false,
      //     scenario: 'Cenário Base 13.0.0',
      //   },
      //   {
      //     id: '6488f4f00e58612c3c45645696e13b',
      //     type: 'sku_no_motherboard',
      //     data: 52,
      //     skus: [
      //       {
      //         sku: 'NH.QEDAL.002',
      //         project: 'QX60_TLS',
      //         modelName: 'PH315-54-70LH',
      //       },
      //     ],
      //     createdAt: '13/06/2023, 23:00',
      //     stamp: 'Tue Jun 13 2023 23:00:00 GMT-0300 (Brasilia Standard Time)',
      //     visualized: true,
      //     scenario: 'Cenário Base 13.0.0',
      //   },
      //   {
      //     id: '6488e6e10e58612c3c9123326e138',
      //     type: 'sku_no_motherboard',
      //     data: 52,
      //     skus: [
      //       {
      //         sku: 'NH.QEDAL.002',
      //         project: 'QX60_TLS',
      //         modelName: 'PH315-54-70LH',
      //       },
      //     ],
      //     createdAt: '13/06/2023, 22:00',
      //     stamp: 'Tue Jun 13 2023 22:00:01 GMT-0300 (Brasilia Standard Time)',
      //     visualized: true,
      //     scenario: 'Cenário Base 13.0.0',
      //   },
      // ];
      // console.log('parsedNotifications=>', parsedNotifications);

      const sortNoficationsByDate = parsedNotifications.sort(compareNotifications);
      // console.log('sortNoficationsByDate=>', sortNoficationsByDate);

      setNotifications(sortNoficationsByDate);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const fetchUsers = useCallback(async () => {
    if (users.length === 0) {
      try {
        const { data } = await api.get(`${import.meta.env.VITE_API_COGNITO}${RoutesPath.users}`);
        const parsedUsers: DefaultOptionType[] = data.map((user: any) => ({
          label: user.name,
          value: user.email,
        }));
        setUsers(parsedUsers);
      } catch (error) {
        console.log('Error on the fetch Users', error);
      }
    }
  }, [users]);

  useEffect(() => {
    if (!!lastJsonMessage && 'obj' in lastJsonMessage) {
      // pegar id da notificação nova direto do banco
      // não usar dados do websocket

      // const notification = parseSKUNotification(lastJsonMessage as ValidNotificationMessage);

      // setNotifications((old) =>
      //   [notification, ...old]
      //     .sort((a, b) => {
      //       return new Date(b.stamp).getTime() - new Date(a.stamp).getTime();
      //     })
      //     .sort((a, b) => {
      //       if (!b.visualized && a.visualized) return 1;
      //       return -1;
      //     })
      // );
      fetchNotifications();
    }
  }, [lastJsonMessage]);

  const handleOpen = () => {
    setNotificationsOpen(true);
  };

  const onClose = () => {
    setNotificationsOpen(false);
  };

  const handleSelection = (selectedRowKeys: React.Key[]) => {
    setSelectedNotifications(selectedRowKeys);
  };

  const toggleFullSelection = () => {
    if (selectedNotifications.length === notifications.length) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(notifications.map((n) => n.id));
    }
  };

  const getNotificationDescription = (type: NotificationType, data: number) => {
    const notificationsMap = {
      sku_no_motherboard: `${t('notifications.skuDesc', { data })}`,
    };
    return notificationsMap[type];
  };
  const getNotificationTitle = (type: NotificationType) => {
    const notificationsMap = {
      sku_no_motherboard: `${t('notifications.sku')}`,
    };
    return notificationsMap[type];
  };

  const viewNotification = async (alertId: string) => {
    await api
      .put(`/alerts/vizualization/${alertId}`, {
        visualized: true,
      })
      .then(async (res) => {
        await fetchNotifications();
      })
      .catch((err) => console.error(err));
  };

  const deleteAllNotifications = async () => {
    await api
      .delete('/list/alerts')
      .then(async (res) => {
        await fetchNotifications();
        oneAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        oneAlert('error', t('toast.errorOnDelete'));
      });
  };

  const deleteSomeNotifications = async (ids: string[] | number[]) => {
    await api
      .delete('/alerts/lot', {
        data: {
          ids: ids.join(),
        },
      })
      .then(async (res) => {
        await fetchNotifications();
        oneAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        oneAlert('error', t('toast.errorOnDelete'));
      });
  };

  const deleteNotification = async (id: string) => {
    await api
      .delete(`/list/alerts/${id}`)
      .then(async (res) => {
        await fetchNotifications();
        oneAlert('success', t('toast.successOnDelete'));
      })
      .catch((err) => {
        oneAlert('error', t('toast.errorOnDelete'));
      });
  };

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    } else {
      setPageControl({ ...pageControl, pageLabel: `${page}` });
    }
  };

  const columns: ColumnType<object>[] = [
    {
      title: t('pages.notifications.fields.alert'),
      dataIndex: 'type',
      key: 'type',
      width: 600,
      sorter: true,
      render: (value, record: any) => {
        return <span>{getNotificationTitle(record.type)}</span>;
      },
    },
    {
      title: t('pages.notifications.fields.dateTime'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (value, record: any) => {
        return <span>{record.formatedDate.dateTime}</span>;
      },
    },
  ];

  const columnsWithAction: ColumnType<object>[] = [
    ...columns,
    {
      title: t('common.action'),
      key: 'actions',
      width: 100,
      align: 'center',
      render: (value: unknown, record: any) => {
        return (
          <Popconfirm
            onClick={(e) => e.stopPropagation()}
            title={t('common.deleteMessage')}
            onConfirm={() => deleteNotification(record.id)}
            placement="left"
            cancelText={t('common.cancel')}
          >
            <Button
              icon={null}
              iconEnd={null}
              buttonText={null}
              onClick={null}
              type="link"
              style={{ padding: 0, width: '100%' }}
            >
              <FaTrashAlt color="#FF4D4D" size={18} />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    fetchNotifications();
    fetchUsers();
  }, []);

  const data = useMemo(
    () => ({
      notifications,
      fetchNotifications,
      getNotificationDescription,
      deleteNotification,
      deleteAllNotifications,
      deleteSomeNotifications,
      isOpen,
      handleOpen,
      onClose,
      isLoading,
      pageControl,
      params,
      changePageValue,
      columnsWithAction,
      selectedNotifications,
      handleSelection,
      toggleFullSelection,
      sendJsonMessage,
      lastMessage,
      lastJsonMessage: lastJsonMessage as JsonMessage,
      sendMessage,
      setNotifications,
      viewNotification,
      users,
      closeWebSocketConnection,
    }),
    [
      notifications,
      isOpen,
      isLoading,
      params,
      lastMessage,
      lastJsonMessage,
      columnsWithAction,
      selectedNotifications,
      pageControl,
      users,
    ]
  );
  return <NotificationsContext.Provider value={data}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
