import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ScoreColor = styled.span`
  color: ${({ score }) => {
    if (score < 42) {
      return '#FF0000';
    }

    if (score > 42) {
      return '#FF9900';
    }

    return '#0B5394';
  }};
`;
