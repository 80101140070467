import { Button, Tag } from 'antd';
import styled from 'styled-components';

export const clearTags = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
  height: 1.375rem;
  padding: 0;
  align-items: center;
  color: ${({ theme }) => theme.colors.prim};
  border: ${({ theme }) => `1px solid ${theme.colors.prim}`};
  border-radius: 0.25rem;
  font-size: medium;
  box-shadow: none;
  margin-left: 0.5rem;
  gap: 0.5rem;

  &:hover {
    border: none !important;
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.dark_green} !important;
  }
`;
