import { Button as antButton } from 'antd';
import styled from 'styled-components';

export const PrimaryButton = styled(antButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.dimensions.border_radius} !important;
  background-color: ${({ theme, disabled }) => theme.colors.prim};
  color: ${({ theme }) => theme.colors.white}!important;
  border: none;
  box-shadow: 0px 2px #0000000b;
  padding: 0.25rem 1rem;
`;

export const SecondaryButton = styled(antButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.prim};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.prim};
  border-radius: ${({ theme }) => theme.dimensions.border_radius} !important;
  box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SlimButton = styled(antButton)`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.prim};
`;
