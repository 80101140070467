import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from '../../../components/Button';
import * as SC from '../../../styles/common';

export function CreateProduct({ onClose, data, form, save, loading, ...rest }) {
  const { t } = useTranslation();
  const dateFormat = localStorage.getItem('language') === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';

  const validateMessages = {
    required: `\${label} ${t('common.validate.isRequired')}`,
    pattern: {
      mismatch: `\${label} ${t('common.validate.invalid')}`,
    },
    types: {
      number: `\${label} ${t('common.validate.number')}`,
    },
    string: {
      range: `\${label} ${t('common.validate.range')} \${min} ${t('common.and')} \${max} ${t(
        'common.characters'
      )}`,
    },
    number: {
      range: `\${label} ${t('common.validate.range')} \${min} ${t('common.and')} \${max}`,
    },
  };

  const onFinish = async (values) => {
    await save();
    onClose();
  };
  return (
    <Row justify="center">
      <Col span={24}>
        <Spin
          tip="Loading..."
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="productName"
                  label={t('pages.ppbProducts.fields.productName')}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t('pages.ppbProducts.fields.productName')} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={t('pages.ppbProducts.fields.description')}
                  rules={[{ required: true, type: 'string', min: 3, max: 100 }]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder={t('pages.ppbProducts.fields.description')}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="targetScore"
                  label={t('pages.ppbProducts.fields.targetScore')}
                  rules={[{ required: true, pattern: /^[0-9]{1,8}[.]*[0-9]{0,2}$/ }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder={t('pages.ppbProducts.fields.targetScore')} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="inforceDate"
                  label={t('pages.ppbProducts.fields.inForceDate')}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    format={dateFormat}
                    placeholder={t('pages.ppbProducts.fields.inForceDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="publishedDate"
                  label={t('pages.ppbProducts.fields.publishedDate')}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    format={dateFormat}
                    placeholder={t('pages.ppbProducts.fields.publishedDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <SC.HR />

            <Row>
              <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onClose} danger>
                  {t('common.cancel')}
                </Button>

                <Button type="primary" htmlType="submit" data-cy="save">
                  {t('common.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
}
