import styled from 'styled-components';
import { LabelType, OrientationType } from './types';

export const ContainerHeader = styled.div`
  height: 6.813rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  margin-top: 1.5rem;
  border-radius: 0.375rem;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  padding: 0.813rem;
  padding-inline: 2.188rem;
  margin-bottom: 1.5rem;
`;

export const TitleHeader = styled.span`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;

export const ContainerLabels = styled.div<OrientationType>`
  display: flex;
  flex-direction: ${({ type }) => (type === 'HORIZONTAL' ? 'row' : 'column')};
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const Labels = styled.span<LabelType>`
  font-weight: ${({ type }) => (type === 'TITLE' ? 400 : 700)};
  color: ${({ type, theme }) =>
    type === 'TITLE' ? theme.colorsDesignSystem.inputLabelColor : theme.colorsDesignSystem.text};
  font-size: 0.875rem;
`;

export const ContainerTags = styled.div<OrientationType>`
  display: flex;
  flex-direction: ${({ type }) => (type === 'HORIZONTAL' ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: ${({ type }) => (type === 'HORIZONTAL' ? '3.313rem' : '0.5rem')};
  padding-right: 1.125rem;
  height: 100%;
  &:nth-child(1),
  &:nth-child(2) {
    border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  }
`;
export const HeaderCellTable = styled.div`
  display: flex;
  flex-direction: row;
  height: 3.438rem;
  align-items: center;
  border-top-left-radius: 10px;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};

  span {
    border-right: 1px solid #00000026;
    width: 100%;
    padding-right: 1rem;
    font-weight: bold;
  }
`;

export const ContainerTagsTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleHeaderTable = styled.strong`
  color: ${({ theme }) => theme.colorsDesignSystem.headerSubTitleTableText};
`;
