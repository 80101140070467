import { Button, Switch } from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import { dataAlerts } from '../../mocks/data';
import { Alert } from './types';
import { api } from '../../services/api';
import { oneAlert, renderDateHour } from '../../helpers/utils';
import { useModal } from '../../components/Modal/useModal';
import { RoutesPath } from '../../routes/routesPath';

export function useAlert() {
  const { t } = useTranslation();

  const [visible, openModal, closeModal] = useModal();
  const [data, setData] = useState<Alert[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableActionVisible, setTableActionVisible] = useState<{ rowId: string; state: boolean }>({
    rowId: '',
    state: false,
  });
  const [isLocked, setIsLocked] = useState(false);
  const [editingAlert, setEditingAlert] = useState<Alert | null>(null);

  const fetchAlerts = async () => {
    setIsLoading(true);
    try {
      const { data: response } = await api.get(RoutesPath.alerts);

      const content = response?.content?.length ? response.content : dataAlerts;
      setData(content);
    } catch (error) {
      setData([]);
    }
    setIsLoading(false);
  };

  const handleStopEditing = () => {
    closeModal();
  };

  const handleEditAlert = (alert: Alert) => {
    fetchAlerts();
  };

  const handleToggleAlert = async (record: Alert) => {
    setIsLocked(true);
    try {
      await api
        .put(`${RoutesPath.alerts}/${record.id}`, {
          ...record,
        })
        .then((res) => {
          const newAlerts: Alert[] = [...data].map((alert) =>
            alert.id === record.id ? record : alert
          );
          setData(newAlerts);
          oneAlert('success', t('toast.successOnSave'));
        });
    } catch (e) {
      oneAlert('error', t('toast.errorOnSave'));
      setData(
        data.map((alert) => (alert.id === record.id ? { ...alert, active: !record.active } : alert))
      );
    } finally {
      setIsLocked(false);
    }
  };

  const columns: ColumnsType<object> = [
    {
      title: t('pages.alert.fields.alerts'),
      dataIndex: 'alertName',
      render: () => t('notifications.sku'),
    },
    {
      title: t('pages.alert.fields.description'),
      dataIndex: 'description',
    },
    {
      title: t('pages.alert.fields.activate'),
      dataIndex: 'active',
      align: 'center',
      render: (value: boolean, record: any) => {
        return (
          <Switch
            checked={value}
            size="small"
            loading={isLocked}
            onChange={async (e) => {
              await handleToggleAlert({ ...record, active: e });
            }}
          />
        );
      },
    },
    {
      title: t('pages.alert.fields.date'),
      dataIndex: 'updatedAt',
      align: 'center',
      render: (value) => {
        return value?.length > 0 ? (
          {
            children: <span>{renderDateHour(value)}</span>,
            props: { colSpan: 2 },
          }
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: t('pages.alert.fields.time'),
      render: (value) => ({
        props: { colSpan: 0 },
      }),
    },
    {
      title: t('pages.alert.fields.frequency'),
      dataIndex: 'frequency',
      align: 'center',
      render: (value) => {
        return value > 0 ? (
          <span>
            {value} {t('pages.alert.fields.day')}
            {value > 1 ? 's' : ''}
          </span>
        ) : (
          <span>-</span>
        );
      },
    },
  ];

  const columnsWithAction: ColumnsType<object> = [
    ...columns,
    {
      title: t('pages.alert.fields.actions'),
      key: 'action',
      align: 'center',
      render: (_: unknown, record: any) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingAlert(record);
                  setTableActionVisible({ rowId: record.id, state: false });
                  openModal();
                }}
              >
                {t('common.edit')}
              </Button>
            ),
          },
        ];

        return (
          <TableAction
            placement="bottomLeft"
            dropdownItems={actions}
            onVisibleChange={(isVisible: boolean) =>
              setTableActionVisible({ rowId: record.id, state: isVisible })
            }
            visible={tableActionVisible.rowId === record.id && tableActionVisible.state}
          />
        );
      },
    },
  ];

  return {
    isLoading,
    columns: columnsWithAction,
    data,
    fetchAlerts,
    editingAlert,
    handleStopEditing,
    handleEditAlert,
    visible,
  };
}
