import { Col, ConfigProvider, Form, Row } from 'antd';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus, BiDownload } from 'react-icons/bi';
import { CsvExport } from '../../components/Common/CsvExport';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { EditableCell } from '../../components/EditableCell';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { useBOM } from './useBOM';
import { CustomPagination } from '../../components/CustomPagination';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import BomFilter from './BomFilter';
import * as SC from '../../styles/common';
import * as SB from './styles';
import { Loading } from '../../components/Loading';
import ProjectsSelect from '../../components/forms/ProjectsSelect';
import { getURI } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import StyledSelect from '../../components/Common/StyledSelect';

export function BOMPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.bom.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchScenarios,
    scenariosOptions,
    selectedScenario,
    fetchBOM,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    handleAddItem,
    onValuesChange,
    editingKey,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    isBaseScenario,
    expandedRowKeys,
    canUpdate,
    rowClassName,
    mergedColumns,
    transformData,
    headerCsv,
    setExpandedRowKeys,
    updateProject,
    updateProduct,
    getQueryParams,
  } = useBOM(dataModal);

  const scenarioName = selectedScenario
    ? `${params?.targetYear} - ${selectedScenario?.label}`
    : t('common.results.noScenarioTitle');

  const modifiedDate = selectedScenario
    ? (dataModal && dataModal.modified) || selectedScenario?.updated?.split('T')[0]
    : '';

  const showTagsFilter =
    params.header_sku?.length ||
    params.header_partType?.length ||
    params.header_spec?.length ||
    params.header_partNumber ||
    params.header_amount ||
    params.header_local ||
    params.header_description;

  useEffect(() => {
    if (params.targetYear) {
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    if (params.targetYear && params.scenario && canUpdate) {
      fetchBOM();
    }
  }, [params]);

  const expandRowIds = (expanded, record) => {
    const keys = [];
    if (record.sku) {
      if (expanded) {
        keys.push(record.id);
      }
    } else if (record.className === 'partType') {
      const sku = record?.id.split('$')[0];
      if (expanded) {
        keys.push(record.id, sku);
      } else {
        keys.push(sku);
      }
    } else if (record?.partTypeId) {
      const sku = record?.id.split('$')[0];
      if (expanded) {
        keys.push(record.partTypeId, sku);
      } else {
        keys.push(sku);
      }
    }

    setExpandedRowKeys(keys);
  };

  return (
    <main>
      <Row>
        {view ? null : (
          <BomFilter
            title={t('pages.bom.title')}
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            scenariosOptions={scenariosOptions}
            values={params}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle
                      title={t('pages.bom.title')}
                      subtitle={scenarioName}
                      updated={modifiedDate}
                    />
                  }
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={1}
                      icon={<BiPlus />}
                      onClick={(e) => handleAddItem(e)}
                      disabled={editingKey !== '' || isBaseScenario || isLoading}
                      buttonText={`${t('common.add')} ${t('common.item')}`}
                    />,
                    <ActionButton
                      key={2}
                      className="btn-csv"
                      disabled={editingKey !== ''}
                      icon={<BiDownload />}
                    >
                      <CsvExport
                        url={getURI(`scenarios/${params.scenario}/bom`, getQueryParams(params))}
                        filename={['bom', selectedScenario?.label]}
                        limit={pageControl.totalElements}
                        headers={headerCsv}
                        transformData={transformData}
                      />
                    </ActionButton>,
                  ]}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Row style={{ gap: '1rem' }}>
                  <StyledSelect
                    style={{ width: '7rem' }}
                    label={t('filter.product')}
                    placeholder={t('filter.product')}
                    value={params?.product}
                    options={[
                      { label: 'Show All', value: '' },
                      { label: 'Desktop', value: 'Desktop' },
                      { label: 'Notebook', value: 'Notebook' },
                    ]}
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProduct(value)}
                  />
                  <ProjectsSelect
                    value={params?.project}
                    // valueAsId
                    intable="intable"
                    onClick={(e) => e.stopPropagation()}
                    onSelect={(value) => updateProject(value)}
                  />
                </Row>
                <Col span={18} className="col-filters">
                  <SC.ContainerFilteredTags>
                    <SC.TagFilter
                      closable
                      visible={params.product}
                      onClose={() => handleCloseFilteredTag('product')}
                    >
                      <div className="tag-title">{t('filter.product')}</div>
                      <div className="tag-value">{params.product}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.project}
                      onClose={() => handleCloseFilteredTag('project')}
                    >
                      <div className="tag-title">{t('filter.project')}</div>
                      <div className="tag-value">{params.project}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_sku}
                      onClose={() => handleCloseFilteredTag('header_sku')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.sku')}</div>
                      <div className="tag-value">{params.header_sku}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_partType?.length}
                      onClose={() => handleCloseFilteredTag('header_partType')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.partType')}</div>
                      <div className="tag-value">{params.header_partType}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_spec?.length}
                      onClose={() => handleCloseFilteredTag('header_spec')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.spec')}</div>
                      <div className="tag-value">{params.header_spec}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_partNumber?.length}
                      onClose={() => handleCloseFilteredTag('header_partNumber')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.partNumber')}</div>
                      <div className="tag-value">{params.header_partNumber}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_amount?.length}
                      onClose={() => handleCloseFilteredTag('header_amount')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.amount')}</div>
                      <div className="tag-value">{params.header_amount}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_local?.length}
                      onClose={() => handleCloseFilteredTag('header_local')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.local')}</div>
                      <div className="tag-value">{params.header_local}</div>
                    </SC.TagFilter>
                    <SC.TagFilter
                      closable
                      visible={params.header_description?.length}
                      onClose={() => handleCloseFilteredTag('header_description')}
                    >
                      <div className="tag-title">{t('pages.bom.fields.description')}</div>
                      <div className="tag-value">{params.header_description}</div>
                    </SC.TagFilter>
                  </SC.ContainerFilteredTags>
                </Col>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Form
                  form={form}
                  component={false}
                  onValuesChange={onValuesChange}
                  validateMessages={validateMessages}
                >
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      columns={mergedColumns}
                      dataSource={data}
                      onChange={handleChangeTable}
                      pagination={false}
                      expandRowByClick
                      defaultExpandedRowKeys={showTagsFilter ? [data[0]?.id] : null}
                      expandable={{ expandedRowKeys, indentSize: 0 }}
                      onExpand={expandRowIds}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
