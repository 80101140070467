/* eslint-disable react/no-unstable-nested-components */
import { Col, Row, Slider } from 'antd';

import { useTranslation } from 'react-i18next';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { SelectInput } from '../../../components/LateralFilter/SelectInput';
import { ResultNoScenarios } from '../../../components/ResultNoScenarios';
import { ShareProportionsGraph, VerticalBarChart } from '../../../components/Dashboard';
import * as S from './styles';
import { useDashboardSKU } from './useDashboardSKU';
import { Loading } from '../../../components/Loading';
import { SKUTable } from '../SKUTable';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

export function SKUDashboardPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.dashboard.titleSku'));

  const {
    data,
    isLoading,
    columns,
    selectedProject,
    toggleModal,
    isModalOpen,
    params,
    sliderValue,
    setSliderValue,
    updateSliderValue,
    projects,
    handleFilterProject,
  } = useDashboardSKU();

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            {/* HEADER */}
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={
                    <HeaderTitle
                      title={`SKU - ${t('pages.dashboard.fields.costTrend')}`}
                      updated={String(new Date())}
                    />
                  }
                  className="pl-0 pr-0"
                />
              </Col>
            </Row>

            {/* FILTERS */}
            <Row justify="center">
              <Col span={23}>
                <S.FilterContainer>
                  <SelectInput
                    placeholder={`${t('common.all')} ${t('pages.dashboard.fields.projects')}`}
                    options={projects}
                    style={{ width: 162 }}
                    onSelect={handleFilterProject}
                    allowClear
                    onClear={() => handleFilterProject('')}
                    // eslint-disable-next-line no-undefined
                    value={params.projectName ? params.projectName : undefined}
                  />
                  <SelectInput
                    placeholder="2023"
                    defaultValue={params.targetYear}
                    options={[{ label: '2023', value: '2023' }]}
                    style={{ width: 110 }}
                  />
                </S.FilterContainer>
              </Col>
            </Row>

            {/* LEGEND */}
            <Row justify="center">
              <Col span={23}>
                <S.GraphInfoContainer>
                  <span>SKU&apos;s</span>
                  <S.LegendContainer padding="4px 0 16px">
                    <div>
                      <S.Legend color="#F79E4F" />
                      <span>{t('pages.dashboard.fields.occurred')}</span>
                    </div>

                    <div>
                      <S.Legend color="#999999" />
                      <span>{t('pages.dashboard.fields.forecast')}</span>
                    </div>
                  </S.LegendContainer>
                </S.GraphInfoContainer>
              </Col>
            </Row>

            {/* GRAPH */}
            <Row justify="center">
              <Col span={23}>
                <S.GraphsContainer>
                  {data.verticalBarChartData && data.verticalBarChartData.length > 0 ? (
                    <S.Card>
                      <S.SliderContainer>
                        <span>{t('pages.dashboard.fields.topList')}</span>
                        <Slider
                          value={sliderValue}
                          onChange={setSliderValue}
                          onAfterChange={updateSliderValue}
                          step={5}
                          max={15}
                          min={5}
                          handleStyle={{ borderColor: '#80C343' }}
                          trackStyle={{ backgroundColor: '#80C343' }}
                        />
                      </S.SliderContainer>

                      <VerticalBarChart data={data.verticalBarChartData} type="SKU" />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}

                  {data.shareProportionsGraphData && data.shareProportionsGraphData.length > 0 ? (
                    <S.Card>
                      <ShareProportionsGraph data={data.shareProportionsGraphData} type="SKU" />
                    </S.Card>
                  ) : (
                    <S.ErrorCard>
                      {/* @ts-ignore */}
                      <ResultNoScenarios
                        title={t('common.results.noDataTitle')}
                        subTitle={t('common.results.noDataSubtitle')}
                      />
                    </S.ErrorCard>
                  )}
                </S.GraphsContainer>
              </Col>
            </Row>

            {/* LEGEND */}
            <Row justify="center">
              <Col span={23}>
                <S.LegendContainer align="flex-end" padding="24px 0 16px">
                  <div>
                    <S.Legend color="#5EA320" />
                    <span>{t('pages.dashboard.fields.newProjects')}</span>
                  </div>

                  <div>
                    <S.Legend />
                    <span>{t('pages.dashboard.fields.finishedProjects')}</span>
                  </div>
                </S.LegendContainer>
              </Col>
            </Row>

            {/* TABLE */}
            <SKUTable
              data={data}
              columns={columns}
              isLoading={isLoading}
              selectedProject={selectedProject}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
            />
          </Col>
        )}
      </Row>
    </main>
  );
}
