import { useState } from 'react';
import { Form, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { api } from '../../services/api';
import {
  SpotColumns,
  SpotStrategy,
  SpotStrategyParams,
  SpotStrategyPart,
  SpotStrategyPayload,
  SpotStrategyResponse,
} from './types';
import { getErrorMessage, getURI, oneAlert, removeNullOptions } from '../../helpers/utils';
import { Product } from '../../components/forms/ProductsSelect';

export function useSpot() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const validateMessages = ValidateMessages();
  const [isEditing, setIsEditing] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [data, setData] = useState<SpotStrategy | null>(null);
  const [form] = Form.useForm();
  const [totalScore, setTotalScore] = useState<string | number>(0);
  const [productsOptions, setProductsOptions] = useState<{ label: string; value: string }[]>([]);
  const [rawData, setRawData] = useState<SpotStrategy | null>(null);

  // const localTargetYear = localStorage.getItem('targetYear') || '2024';
  const localTargetYear = '2024';
  const localProduct = localStorage.getItem('productName');

  const initialParams: SpotStrategyParams = {
    productName: localProduct,
    order: '',
    orderBy: '',
    limit: 1,
    page: 1,
  };

  const [params, setParams] = useState(initialParams);

  function getQueryParams() {
    const getParams = { ...params };

    return getParams;
  }

  const searchFilter = async (newFilters: SpotStrategyParams, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams: SpotStrategyParams = {
      ...params,
      ...newFilters,
    };

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      productName: null,
    });
  };

  const handleEdit = () => {
    setRawData(data);
    setIsEditing(true);
  };

  const cancel = () => {
    form.resetFields();
    setData(rawData);
    setIsEditing(false);
  };

  const getTotalScore = (content: SpotStrategyPart[]) => {
    return content
      ? content?.reduce((total, pT) => total + Number(pT.currentScore), 0).toFixed(2)
      : data?.partTypes.reduce((total, pT) => total + Number(pT.currentScore), 0).toFixed(2);
  };

  const parseTypes = (spot: SpotStrategy): SpotStrategyPart[] => {
    const parsed = spot;
    return parsed.partTypes
      .map((pT) => ({
        ...pT,
        import: Number(pT.import),
        local: Number(pT.local),
        currentScore: ((Number(pT.local) / 100) * Number(pT.maxScore)).toFixed(2),
      }))
      .sort((a, b) => {
        if (a.groupName < b.groupName) {
          return -1;
        }
        if (a.groupName > b.groupName) {
          return 1;
        }
        return 0;
      });
  };

  const fetchProducts = async () => {
    try {
      const {
        data: { content: products },
      } = await api.get(`ppb`);
      const parsedProductOptions: { label: string; value: string }[] = products.map(
        (prod: Product) => ({
          label: prod.productName,
          value: prod.productName,
        })
      );

      const optionsFiltered: { label: string; value: string }[] =
        removeNullOptions(parsedProductOptions);
      setProductsOptions(optionsFiltered);

      setParams((prev) => ({
        ...prev,
        productName: 'Notebook',
      }));
    } catch (err) {
      console.log('err/products=>', err);
      setProductsOptions([]);
    }
  };

  const fetchStrategies = async () => {
    if (isEditing) cancel();

    try {
      if (productsOptions.length < 1) await fetchProducts();

      const fetchParams = getQueryParams();

      if (!fetchParams?.productName?.length) return;

      const { data: fetchData } = await api.get<SpotStrategyResponse>(
        getURI('/estrategiaSpot', fetchParams)
      );

      if (params.productName) {
        localStorage.setItem('productName', params.productName);
      }

      const dataContent =
        fetchData?.content && fetchData?.content?.length ? fetchData.content[0] : null;

      if (dataContent) {
        setData({ ...dataContent, partTypes: parseTypes(dataContent) });
        setTotalScore(getTotalScore(parseTypes(dataContent)) || 0);
      } else {
        setData({ partTypes: [] } as unknown as SpotStrategy);
      }
      setIsLoading(false);
      setCanUpdate(false);
    } catch (error) {
      console.error('fetchStrategies=>', error);
      setData({ partTypes: [] } as unknown as SpotStrategy);
      setTotalScore(0);
      setIsLoading(false);
    }
    setCanUpdate(false);
  };

  const save = async (isNew: boolean) => {
    if (data) {
      await form
        .validateFields()
        .then(async (table) => {
          try {
            const calcTotalScore = Object.entries(table)
              .filter((row) => row[0].includes('currentScore'))
              .map((row) => row[1])
              .reduce((a, b) => Number(a) + Number(b));
            const partTypes: SpotStrategyPayload[] = data?.partTypes.map((pT) => ({
              groupName: pT.groupName,
              local: `${table[`${pT.id}-local`]}`,
              import: `${table[`${pT.id}-import`]}`,
              currentScore: table[`${pT.id}-currentScore`],
              maxScore: pT.maxScore,
              id: pT.id,
            }));
            let response;

            if (isNew) {
              response = await api.post('estrategiaSpot', {
                partTypes,
                totalScore: calcTotalScore,
              });
            } else {
              response = await api.put(`estrategiaSpot/${data.id}`, {
                partTypes,
                totalScore: calcTotalScore,
              });
            }

            if (!(response.status === 200 || response.status === 201)) throw new Error();

            // setData(response.data);
            await fetchStrategies();

            oneAlert('success', t('toast.successOnSave'));
          } catch (error) {
            oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
          }
        })
        .catch((error) => {
          console.log('Validate Failed: ', error);
        });
    }
  };

  const handleSaveStrategy = async () => {
    if (data?.id.startsWith('new')) {
      save(true);
    } else {
      save(false);
    }
  };

  const columns = [
    {
      title: t('pages.spot.fields.groupName'),
      width: 160,
      dataIndex: 'groupName',
      key: 'groupName',
      editable: false,
      required: false,
    },
    {
      title: t('pages.spot.fields.local'),
      width: 100,
      dataIndex: 'local',
      key: 'local',
      editable: true,
      required: false,
      render: (value: number) => {
        return {
          props: { style: { textAlign: 'center' } },
          children: <div>{`${value?.toFixed(2)}%`}</div>,
        };
      },
    },
    {
      title: t('pages.spot.fields.import'),
      width: 100,
      dataIndex: 'import',
      key: 'import',
      editable: true,
      required: false,
      render: (value: number) => {
        return {
          props: { style: { textAlign: 'center' } },
          children: <div>{`${value?.toFixed(2)}%`}</div>,
        };
      },
    },
    {
      title: t('pages.spot.fields.currentScore'),
      width: 100,
      dataIndex: 'currentScore',
      key: 'currentScore',
      editable: true,
      required: false,
    },
    {
      title: t('pages.spot.fields.maxScore'),
      width: 300,
      dataIndex: 'maxScore',
      key: 'maxScore',
      editable: false,
      required: false,
      render: (value: number) => {
        return {
          children: <div>{`${value?.toFixed(2)}`}</div>,
        };
      },
    },
  ];

  const inputNode = (column: SpotColumns, record: SpotStrategyPart) => {
    form.setFieldValue(`${record.id}-${column}`, Number(record[column]));
    switch (column) {
      case 'local':
        return (
          <InputNumber
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              form.setFieldValue(`${record.id}-import`, 100 - Number(e));
              form.setFieldValue(`${record.id}-local`, e);
              form.setFieldValue(
                `${record.id}-currentScore`,
                ((e as number) / 100) * Number(record.maxScore)
              );
            }}
            precision={2}
            addonAfter="%"
            inputMode="numeric"
          />
        );
      case 'import':
        return (
          <InputNumber
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              form.setFieldValue(`${record.id}-local`, 100 - Number(e));
              const local = form.getFieldValue(`${record.id}-local`);
              form.setFieldValue(`${record.id}-import`, e);
              form.setFieldValue(
                `${record.id}-currentScore`,
                ((local as number) / 100) * Number(record.maxScore)
              );
            }}
            precision={2}
            addonAfter="%"
          />
        );
      default:
        return <InputNumber contentEditable="false" readOnly precision={2} />;
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: SpotStrategyPart) => {
        return {
          record,
          editable: (isEditing && (col.key === 'local' || col.key === 'import')) || col.editable,
          inputType: 'number',
          limit: col.key === 'currentScore' ? 1000 : 100,
          required: col.required,
          newInputNode: inputNode(col.dataIndex as SpotColumns, record),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing,
          name: `${record.id}-${col.dataIndex}`,
        };
      },
    };
  });

  return {
    isLoading,
    form,
    validateMessages,
    mergedColumns,
    fetchStrategies,
    data,
    isEditing,
    canUpdate,
    handleSaveStrategy,
    totalScore,
    fetchProducts,
    productsOptions,
    clearFilter,
    searchFilter,
    params,
    cancel,
    handleEdit,
  };
}
