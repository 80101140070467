import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import {
  FilterData,
  dataType,
  columnsTypes,
  SliderTooltipMarks,
  PageFilters,
  AllData,
  avgForMonthForecast,
  negotiatedExecutedData,
  dataBar,
  chartUnitPriceTotalInvoiceData,
  tooltipChart,
} from './types';
import { api } from '../../../services/api';
import { capitalizeFirstLetter, getSortOrder, getURI, oneAlert } from '../../../helpers/utils';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import theme from '../../../styles/theme';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { CellMoneyHeaderSpan, CellMoneySpan } from './styles';
import { PriceTableChartItem } from './ChartPriceTableForMonth/types';

export const useProcurementDashboard = () => {
  const FILTER_VIEW_ALL_SLIDER = 4;
  const JUMP_SLIDER_ITENS = 5;

  const initialParams = {
    // product: '',
    cm: 'Foxconn',
    date: dayjs(),
    projectName: '',
    sku: '',
    level: 'L10',
    withForecast: false,
    topBy: FILTER_VIEW_ALL_SLIDER,
    componentGroup: 'SSD 512',
    spec: '',
    partNumber: '',
    source: '',
    supplier: '',
    month: dayjs().format('MMM').toUpperCase(),
  };
  const chartIntialValues: negotiatedExecutedData = {
    componentGroup: null,
    supplier: null,
    spec: null,
    average: false,
    chartValues: [
      {
        negotiated: 5,
        executed: 0,
        gap: 0,
        tooltipData: [
          {
            projectName: null,
            spec: null,
            partNumbers: [
              {
                partNumber: null,
                supplier: null,
                negotiatedValue: 0,
                executedValue: 0,
              },
            ],
          },
        ],
      },
      {
        negotiated: 0,
        executed: 0,
        gap: 0,
        tooltipData: [
          {
            projectName: null,
            spec: null,
            partNumbers: [
              {
                partNumber: null,
                supplier: null,
                negotiatedValue: 0,
                executedValue: 0,
              },
            ],
          },
        ],
      },
    ],
  };
  const initialDataUnitPrice: Array<dataBar> = [
    {
      name: 'Negociated',
      value: [0, 0],
      label: 0,
    },
    {
      name: 'Executed',
      value: [0, 0],
      label: 0,
    },
    {
      name: 'Gap',
      value: [0, 0],
      label: 0,
    },
  ];

  const [params, setParams] = useState<PageFilters>({
    ...initialParams,
    month: dayjs().format('MMM').toUpperCase(),
  });

  const [optionsProjectName, setOptionsProjectName] = useState<Array<StyledSelectOptions>>([]);
  const [optionsSku, setOptionsSku] = useState<Array<StyledSelectOptions>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [showClearTag, setShowClearTag] = useState(false);
  const [bodyData, setBodyData] = useState<Array<dataType>>([]);
  const [totalUSD, setTotalUSD] = useState();
  const [selectedData, setSelectedData] = useState<Array<dataType>>([]); //data selecionada na table
  const [form] = Form.useForm();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [avgForecastForMonth, setAvgForecastForMonth] = useState<Array<PriceTableChartItem>>([]);
  const [negotiatedExecuted, setNegotiatedExecuted] =
    useState<negotiatedExecutedData>(chartIntialValues);
  const [optionsComponentGroup, setOptionsComponentGroup] = useState<Array<StyledSelectOptions>>(
    []
  );
  const [optionsSpec, setOptionsSpec] = useState<Array<StyledSelectOptions>>([]);
  const [optionsPartNumber, setOptionsPartNumber] = useState<Array<StyledSelectOptions>>([]);
  const [optionsSupplier, setOptionsSupplier] = useState<Array<StyledSelectOptions>>([]);
  const [optionsMonth, setOptionsMonth] = useState<Array<StyledSelectOptions>>([]);
  const [paramsChartFilter, setParamsChartFilter] = useState();
  const [unitPriceData, setUnitPriceData] = useState<Array<dataBar>>(initialDataUnitPrice);
  const [totalInvoiceData, setTotalInvoiceData] = useState<Array<dataBar>>(initialDataUnitPrice);
  const [tooltipDataUnitPrice, setTooltipDataUnitPrice] = useState<Array<tooltipChart>>([]);
  const [tooltipDataTotalInvoice, setTooltipDataTotalInvoice] = useState<Array<tooltipChart>>([]);
  const { t } = useTranslation();

  const totalCheckedUSDReduce = (data: Array<dataType>) => {
    const total = data.reduce((accumulator, item) => {
      accumulator += Number(item.total);
      return accumulator;
    }, 0);
    return total;
  };

  const calculatePercentage = (data: dataType[]) => {
    const total = totalCheckedUSDReduce(data);
    const percentage = data.map((item) => {
      return {
        ...item,
        porcentage: ((Number(item.total) / total) * 100).toFixed(2),
      };
    });
    return percentage;
  };

  const CMs: Array<StyledSelectOptions> = [
    {
      label: t('common.viewAll'),
      value: '',
    },
    {
      label: 'Foxconn',
      value: 'Foxconn',
    },
    {
      label: 'Compal',
      value: 'Compal',
    },
  ];

  const products: Array<StyledSelectOptions> = [
    {
      label: 'Notebook',
      value: 'Notebook',
    },
  ];

  const financeLevels: Array<StyledSelectOptions> = [
    { value: 'L06', label: 'L6' },
    { value: 'L10', label: 'L10' },
  ];

  const sources = [
    { value: '', label: t('common.showAll') },
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];
  const months = [
    { value: 'JAN', label: t('common.months.january') },
    { value: 'FEB', label: t('common.months.february') },
    { value: 'MAR', label: t('common.months.march') },
    { value: 'APR', label: t('common.months.april') },
    { value: 'MAY', label: t('common.months.may') },
    { value: 'JUN', label: t('common.months.june') },
    { value: 'JUL', label: t('common.months.july') },
    { value: 'AUG', label: t('common.months.august') },
    { value: 'SEP', label: t('common.months.september') },
    { value: 'OCT', label: t('common.months.october') },
    { value: 'NOV', label: t('common.months.november') },
    { value: 'DEC', label: t('common.months.december') },
  ];

  function calculateAvgForecastPerMonthAndSkus(data: Array<dataType>) {
    try {
      const values: avgForMonthForecast = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      };

      data.forEach((sku) => {
        values.january += Number(sku.dashboardMonths.january) || 0;
        values.february += Number(sku.dashboardMonths.february) || 0;
        values.march += Number(sku.dashboardMonths.march) || 0;
        values.april += Number(sku.dashboardMonths.april) || 0;
        values.may += Number(sku.dashboardMonths.may) || 0;
        values.june += Number(sku.dashboardMonths.june) || 0;
        values.july += Number(sku.dashboardMonths.july) || 0;
        values.august += Number(sku.dashboardMonths.august) || 0;
        values.september += Number(sku.dashboardMonths.september) || 0;
        values.october += Number(sku.dashboardMonths.october) || 0;
        values.november += Number(sku.dashboardMonths.november) || 0;
        values.december += Number(sku.dashboardMonths.december) || 0;
      });

      setAvgForecastForMonth([
        {
          name: t('common.months.january'),
          month: 1,
          value: values.january,
        },
        {
          name: t('common.months.february'),
          month: 2,
          value: values.february,
        },
        {
          name: t('common.months.march'),
          month: 3,
          value: values.march,
        },
        {
          name: t('common.months.april'),
          month: 4,
          value: values.april,
        },
        {
          name: t('common.months.may'),
          month: 5,
          value: values.may,
        },
        {
          name: t('common.months.june'),
          month: 6,
          value: values.june,
        },
        {
          name: t('common.months.july'),
          month: 7,
          value: values.july,
        },
        {
          name: t('common.months.august'),
          month: 8,
          value: values.august,
        },
        {
          name: t('common.months.september'),
          month: 9,
          value: values.september,
        },
        {
          name: t('common.months.october'),
          month: 10,
          value: values.october,
        },
        {
          name: t('common.months.november'),
          month: 11,
          value: values.november,
        },
        {
          name: t('common.months.december'),
          month: 12,
          value: values.december,
        },
      ]);
    } catch (error) {
      console.log(error);
      setAvgForecastForMonth([]);
    }
  }

  /**
   *
   * @param qtColors how many colors will be generated
   * @returns list of colors
   */
  function colorsGenerator(qtColors: number) {
    const COLORS = [];
    const red = 123;
    const green = 45;
    const blue = 20;

    for (let i = 1; i <= qtColors; i++) {
      COLORS.push(
        `rgb(${((red * i) % 180) + 35}, ${((green * i) % 180) + 30}, ${((blue * i) % 180) + 35})`
      );
    }
    return COLORS;
  }

  const updateParams = (field: string, value: any) => {
    setShowClearTag(true);
    setParams((prevState) => {
      switch (field) {
        case 'cm':
          return {
            ...prevState,
            [field]: value,
            projectName: '',
            sku: '',
          };
        case 'projectName':
          return {
            ...prevState,
            [field]: value,
            sku: '',
          };
        case 'componentGroup':
          return {
            ...prevState,
            [field]: value,
            spec: '',
            partNumber: '',
            source: '',
            supplier: '',
          };
        case 'spec':
          return {
            ...prevState,
            [field]: value,
            partNumber: '',
            source: '',
            supplier: '',
          };
        case 'partNumber':
          return {
            ...prevState,
            [field]: value,
            source: '',
            supplier: '',
          };
        case 'source':
          return {
            ...prevState,
            [field]: value,
            supplier: '',
          };
        case 'supplier':
          return {
            ...prevState,
            [field]: value,
          };
        case 'month':
          return {
            ...prevState,
            [field]: value,
          };

        default:
          break;
      }
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const columns: ColumnsType<columnsTypes> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: '8.25rem',
      sorter: true,
    },
    {
      title: t('pages.procurement.dashboard.headerTitles.projectName'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: '10.25rem',
      sorter: true,
    },
    {
      title: 'CM',
      dataIndex: 'cm',
      key: 'cm',
      width: '6.375rem',
      sorter: true,
    },
    {
      title: (
        <CellMoneyHeaderSpan>
          {t('pages.procurement.dashboard.headerTitles.monthDemand')}
        </CellMoneyHeaderSpan>
      ),
      dataIndex: 'monthForecast',
      key: 'monthForecast',
      align: 'right',
      width: '6.25rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            {Number(record.monthForecast).toLocaleString(t('common.localeMoney'))}
          </CellMoneySpan>
        );
      },
    },
    {
      title: (
        <CellMoneyHeaderSpan>
          {t('pages.procurement.dashboard.headerTitles.unit')}
        </CellMoneyHeaderSpan>
      ),
      dataIndex: 'unit',
      key: 'unit',
      align: 'right',
      width: '7.25rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            USD{' '}
            {Number(record.unit).toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </CellMoneySpan>
        );
      },
    },
    {
      title: <CellMoneyHeaderSpan>Total</CellMoneyHeaderSpan>,
      dataIndex: 'total',
      align: 'right',
      key: 'total',
      width: '9.5rem',
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <CellMoneySpan>
            USD{' '}
            {Number(record.total).toLocaleString(t('common.localeMoney'), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </CellMoneySpan>
        );
      },
    },
    {
      title: <CellMoneyHeaderSpan style={{ marginRight: '1rem' }}>%</CellMoneyHeaderSpan>,
      dataIndex: 'percentage',
      align: 'right',
      key: 'percentage',
      width: '5.25rem',
      render: (text: string, record: any) => {
        return <CellMoneySpan>{record.percentage}</CellMoneySpan>;
      },
    },
  ];

  /**
   *
   * @returns params without empty values
   */
  // const ignoreEmptyParams = () => {
  //   const updatedParams: any = params;

  //   const keys = Object.keys(updatedParams);
  //   const paramsWithoutEmpty: any = {};
  //   keys.forEach((key) => {
  //     if (updatedParams[key] !== '') {
  //       paramsWithoutEmpty[key] = updatedParams[key];
  //     }
  //   });
  //   console.log(paramsWithoutEmpty);
  //   return paramsWithoutEmpty;
  // };

  const tableView = (data: Array<dataType>) => {
    const dataView = data.map((item: dataType) => {
      return {
        ...item,
        percentage: `${Number(item.porcentage).toFixed(2)}%`,
      };
    });
    return dataView;
  };

  const sliderTooltipFormater = (value: number) => {
    return SliderTooltipMarks[value];
  };

  const calculateTopByValue = (sliderIndex: number) => {
    return (sliderIndex + 1) * JUMP_SLIDER_ITENS;
  };

  const handleFormatDateToFetch = (): string => {
    return dayjs(params.date).format('MMMM/YYYY').toUpperCase();
  };

  const fetchBySlider = async () => {
    setIsTableLoading(true);
    try {
      const { data } = await api.get(
        getURI('finance/sku/dashboard/header/filter', {
          ...params,
          date: handleFormatDateToFetch(),
          topBy: params.topBy !== FILTER_VIEW_ALL_SLIDER ? calculateTopByValue(params.topBy) : null,
        })
      );

      const newSelectedData = () => {
        return selectedData.filter((item) => {
          return data.dashboardSkuCostDto.find((item2: any) => item2.id === item.id);
        });
      };
      data && setTotalUSD(data.totalUSD);
      data && setBodyData(tableView(data.dashboardSkuCostDto) || []);
      data && setSelectedData(newSelectedData() || []);
      calculateAvgForecastPerMonthAndSkus(data.dashboardSkuCostDto || []);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      return error;
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleConvertOptions = (list: Array<any>, type = ''): Array<StyledSelectOptions> => {
    if (list && list.length) {
      const options = list.map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });
      options.unshift({ label: t('common.viewAll'), value: '' });
      return options;
    }
    return [];
  };
  const handleDataChart = (chartData: chartUnitPriceTotalInvoiceData) => {
    if (chartData) {
      if (chartData.negotiated > chartData.executed) {
        const dataUnitPrice: Array<dataBar> = [
          {
            name: t('pages.procurement.chart.chartBarsLabel.negotiated'),
            value: [0, chartData.negotiated],
            label: chartData.negotiated,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.executed'),
            value: [0, chartData.executed],
            label: chartData.executed,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.gap'),
            value: [chartData.executed, chartData.negotiated],
            label: chartData.gap,
          },
        ];
        return dataUnitPrice;
      }
      if (chartData.negotiated < chartData.executed) {
        const dataUnitPrice: Array<dataBar> = [
          {
            name: t('pages.procurement.chart.chartBarsLabel.negotiated'),
            value: [0, chartData.negotiated],
            label: chartData.negotiated,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.executed'),
            value: [0, chartData.executed],
            label: chartData.executed,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.gap'),
            value: [chartData.negotiated, chartData.executed],
            label: chartData.gap,
          },
        ];
        return dataUnitPrice;
      }
      if (chartData.negotiated === chartData.executed) {
        const dataUnitPrice: Array<dataBar> = [
          {
            name: t('pages.procurement.chart.chartBarsLabel.negotiated'),
            value: [0, chartData.negotiated],
            label: chartData.negotiated,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.executed'),
            value: [0, chartData.executed],
            label: chartData.executed,
          },
          {
            name: t('pages.procurement.chart.chartBarsLabel.gap'),
            value: [0, 0],
            label: chartData.gap,
          },
        ];
        return dataUnitPrice;
      }
    }
    return initialDataUnitPrice;
  };
  const handleFetchHeadersNegotiatedExecuted = async () => {
    try {
      const { data } = await api.get(
        getURI('finance/sku/dynamicfilters/dashboard', {
          cm: params.cm,
          projectName: params.projectName,
          sku: params.sku,
          level: params.level,
          componentGroup: params.componentGroup,
          specification: params.spec,
          partNumber: params.partNumber,
          source: params.source,
          supplier: params.supplier,
        })
      );
      setOptionsComponentGroup(handleConvertOptions(data.componentGroup));
      setOptionsSpec(handleConvertOptions(data.specification));
      setOptionsPartNumber(handleConvertOptions(data.partNumber));
      setOptionsSupplier(handleConvertOptions(data.supplier));
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };
  const fetchtNegotiatedExecutedContent = async () => {
    setIsLoadingChart(true);
    try {
      const { data } = await api.get(
        getURI('finance/sku/dashboard/negotiatedExecuted', {
          cm: params.cm,
          projectName: params.projectName,
          sku: params.sku,
          level: params.level,
          month: `${dayjs(capitalizeFirstLetter(params.month), 'MMM')
            .format('MMMM')
            .toUpperCase()}/${dayjs(params.date).format('YYYY')}`,
          componentGroup: params.componentGroup,
          specification: params.spec,
          partNumber: params.partNumber,
          source: params.source,
          supplier: params.supplier,
        })
      );
      setUnitPriceData(handleDataChart(data.chartValuesUnitPrice));
      setTotalInvoiceData(handleDataChart(data.chartValuesTotalInvoice));
      setTooltipDataUnitPrice(data.chartValuesUnitPrice?.tooltipData || []);
      setTooltipDataTotalInvoice(data.chartValuesTotalInvoice?.tooltipData || []);
      setNegotiatedExecuted(data);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoadingChart(false);
    }
  };

  const handleFetchHeaders = async () => {
    try {
      const { data, status } = await api.get(
        getURI('finance/sku/filterdynamic', {
          cm: params.cm,
          projectName: params.projectName,
          sku: params.sku,
        })
      );
      if (status === 200) {
        setOptionsProjectName(handleConvertOptions(data.projectNames));
        setOptionsSku(handleConvertOptions(data.skus));
        return;
      }

      //limpando colections em caso de erro ou retorno vazio
      setOptionsProjectName(handleConvertOptions([]));
      setOptionsSku(handleConvertOptions([]));
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchContent = async () => {
    setIsTableLoading(true);
    try {
      const { data } = await api.get(
        getURI('finance/sku/dashboard/header/filter', {
          ...params,
          date: handleFormatDateToFetch(),
          topBy: params.topBy !== FILTER_VIEW_ALL_SLIDER ? calculateTopByValue(params.topBy) : null,
        })
      );
      data && setTotalUSD(data.totalUSD);
      setBodyData(tableView(data.dashboardSkuCostDto || []));
      setSelectedData(data.dashboardSkuCostDto || []);
      calculateAvgForecastPerMonthAndSkus(data.dashboardSkuCostDto || []);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
      setIsTableLoading(false);
    }
  };

  const handleClearTag = () => {
    setParams(initialParams);
  };

  const haddleClearChartFilter = () => {
    setParams((prevState) => {
      return {
        ...prevState,
        componentGroup: 'SSD 512',
        spec: '',
        partNumber: '',
        source: '',
        supplier: '',
        month: dayjs().format('MMM').toUpperCase(),
      };
    });
  };

  const handleRenderClearAllFilters = () => {
    if (
      params.cm ||
      params.projectName ||
      params.sku ||
      // params.level ||
      // params.date ||
      params.sku
    ) {
      return <StyledClearTag onClick={handleClearTag} />;
    }
  };
  const handleRenderClearAllFiltersChart = () => {
    if (
      params.componentGroup ||
      params.spec ||
      params.partNumber ||
      params.source ||
      params.supplier
    ) {
      return <StyledClearTag onClick={haddleClearChartFilter} />;
    }
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {/* {params.date && (
          <StyledFilterTag
            label={t('pages.quotes.labels.date')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={dayjs(params.date).format('MM/YYYY')}
            closeble
            onClose={() => {
              updateParams('date', initialParams.date);
            }}
          />
        )}
        {params.level && (
          <StyledFilterTag
            label={t('pages.finance.dashboard.labelLevel')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.level}
            closeble
            onClose={() => {
              updateParams('levels', initialParams.level);
            }}
          />
        )} */}

        {params.projectName && (
          <StyledFilterTag
            label={t('pages.procurement.dashboard.table.placeholders.projectName')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.projectName}
            closeble
            onClose={() => {
              updateParams('projectName', initialParams.projectName);
            }}
          />
        )}
        {params.sku && (
          <StyledFilterTag
            label={t('pages.procurement.dashboard.table.placeholders.sku')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.sku}
            closeble
            onClose={() => {
              updateParams('sku', initialParams.sku);
            }}
          />
        )}
        {params.cm && (
          <StyledFilterTag
            label={t('pages.procurement.dashboard.labelCM')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              updateParams('cm', '');
            }}
          />
        )}
      </>
    );
  };

  const formatDateForCard = () => {
    return `${dayjs(params.date).format('MMM')} ${dayjs(params.date).format('YY')}`;
  };

  /**
   *
   * @param order the field you want to order
   * @param orderBy the way you want to order, like ASC or DESC
   * @returns error if something goes wrong
   */
  const orderBodyContent = async (order: string, orderBy: string) => {
    // const paramsWithoutEmpty = ignoreEmptyParams();
    try {
      setIsTableLoading(true);
      const { data } = await api.get(
        getURI('finance/sku/dashboard/header/filter', {
          ...params,
          orderBy,
          order,
          date: handleFormatDateToFetch(),
          topBy: params.topBy !== FILTER_VIEW_ALL_SLIDER ? calculateTopByValue(params.topBy) : null,
        })
      );
      // const { data } = await api.get('finance/sku/dashboard/header/filter', {
      //   params: {
      //     ...paramsWithoutEmpty,
      //     orderBy,
      //     order,
      //   },
      // });
      setBodyData(tableView(data.dashboardSkuCostDto) || []);
    } catch (error) {
      return error;
    } finally {
      setIsTableLoading(false);
    }
  };

  /**
   *  Handle the change of the table, like pagination, filters and sorter
   * @param pagination number of page if has one
   * @param filters filter if has one on the table header
   * @param sorter object with sorter details, like ASC or column key
   */
  const handleChangeTable = async (pagination: number, filters: any, sorter: any) => {
    orderBodyContent(sorter.columnKey, getSortOrder(sorter.order));
  };

  const totalCheckedUSD = () => {
    const total = totalCheckedUSDReduce(selectedData);
    return total.toLocaleString(t('common.localeMoney'), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const selectedTotalIsEmptyOrZero = () => {
    return selectedData.length && totalCheckedUSDReduce(selectedData);
  };

  return {
    financeLevels,
    columns,
    bodyData,
    isLoading,
    params,
    optionsProjectName,
    optionsSku,
    selectedData,
    showClearTag,
    form,
    isTableLoading,
    CMs,
    products,
    FILTER_VIEW_ALL_SLIDER,
    totalUSD,
    avgForecastForMonth,
    negotiatedExecuted,
    optionsComponentGroup,
    optionsSpec,
    optionsPartNumber,
    optionsSupplier,
    optionsMonth,
    sources,
    unitPriceData,
    totalInvoiceData,
    tooltipDataUnitPrice,
    tooltipDataTotalInvoice,
    months,
    isLoadingChart,
    calculatePercentage,
    selectedTotalIsEmptyOrZero,
    totalCheckedUSD,
    setIsTableLoading,
    setIsLoading,
    updateParams,
    handleChangeTable,
    fetchBySlider,
    formatDateForCard,
    setShowClearTag,
    handleClearTag,
    setSelectedData,
    fetchContent,
    setParams,
    sliderTooltipFormater,
    colorsGenerator,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    calculateAvgForecastPerMonthAndSkus,
    handleFetchHeaders,
    handleFetchHeadersNegotiatedExecuted,
    fetchtNegotiatedExecutedContent,
    handleRenderClearAllFiltersChart,
  };
};
