import styled from 'styled-components';
import { Menu, Switch as AntSwitch } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const { Item, SubMenu: AntdSubMenu } = Menu;

export const Switch = styled(AntSwitch)`
  && {
    background-color: ${(props) => props.theme.colors.link};
  }
  :hover {
    background-color: ${(props) => props.theme.colors.link} !important;
  }
`;

export const MenuButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .menu-btn {
    color: ${(props) => props.theme.colors.text_light};
    font-size: 14px;
    padding-right: 8px;
    text-transform: uppercase;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
`;

export const MenuItem = styled(Item)`
  border-radius: 4px !important;
  && {
    color: ${({ theme }) => theme.colors.link};
  }
  &.ant-menu-item:active {
    background: #e9ffe1;
    font-weight: 500;
  }
  &.ant-menu-submenu:hover:not(.ant-menu-item-selected),
  &.ant-menu-item:hover:not(.ant-menu-item-selected) {
    background: #e9ffe1 !important;
    color: ${({ theme }) => theme.colors.link} !important;
  }
  &.ant-menu-item::after {
    border-right: 3px solid ${({ theme }) => theme.colors.link};
  }
  &.ant-menu-item.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.shape};
    background: ${({ theme }) => theme.colors.backgroundNavbar};
    font-weight: 500;
    & > span.ant-menu-title-content {
      color: ${({ theme }) => theme.colors.shape};
    }
  }
  span {
    &:hover {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`;

export const SubMenu = styled(AntdSubMenu)`
  margin: 4px;
  border-radius: 4px !important;
  && {
    color: ${({ theme }) => theme.colors.link};
  }
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.link};
  }
  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colors.link};
  }
  &.ant-menu-submenu-selected {
    background-color: #e9ffe1 !important;
    .ant-menu-submenu-title {
      color: ${({ theme }) => theme.colors.link};
    }
  }
  &.ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
    background-color: ${({ theme }) => theme.colors.backgroundNavbar} !important;
    font-weight: 500;
    .ant-menu-submenu-title {
      color: ${({ theme }) => theme.colors.shape} !important;
    }
    &:hover,
    &:focus {
      background-color: #e9ffe1 !important;
    }
  }

  div.ant-menu-submenu-title:hover:not(.ant-menu-submenu-selected),
  div.ant-menu-submenu-title:focus {
    color: ${({ theme }) => theme.colors.link} !important;
    background-color: #e9ffe1 !important;
    i.ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.link} !important;
    }
  }
  i.ant-menu-submenu-arrow {
    color: inherit;
  }
`;

export const LogoutContainer = styled.div`
  cursor: pointer;
  margin-top: auto;
  text-align: right;
`;

export const LogoutLink = styled.span`
  color: ${(props) => props.theme.colors.link};
`;

export const Label = styled.span`
  color: ${(props) => props.theme.colors.title};
  padding-left: 1rem;
  padding-right: 0.5rem;
`;

export const BellIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colorsDesignSystem.primary};
`;

export const BellOutlinedIcon = styled(BellOutlined)`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.25rem;
`;
