import styled from 'styled-components';
import theme from '../../../styles/theme';

interface PaddingAndBoarder {
  headerNoPadding: boolean;
  noBorder: boolean;
  scrollable: boolean;
}

export const TableContainer = styled.div<PaddingAndBoarder>`
  padding-bottom: 24px;
  overflow-x: ${({ scrollable }) => (scrollable ? 'scroll' : 'none')};

  .ant-table {
    background-color: transparent;
  }

  .light-row {
    background-color: #fff;
  }

  .dark-row {
    background-color: #efefef;
  }
  th,
  td.ant-table-cell {
    padding: ${({ headerNoPadding }) => (headerNoPadding ? '0 !important' : 'none')};
  }

  .highlighted:not(th) {
    background-color: #c5c8cc29 !important;
  }

  .ant-table-content {
    thead > tr > th::before {
      content: none !important;
    }

    table > thead > tr:first-child > *:last-child {
      border-radius: ${({ noBorder }) => (noBorder ? '0 !important' : 'none')};
    }
    table {
      border: ${({ noBorder }) => (noBorder ? 'none' : `1px solid transparent !important`)};
      border-top: ${({ noBorder }) =>
        noBorder ? `1px solid ${theme.colors.gray_border}` : 'none'};
      border-radius: ${({ noBorder }) => (noBorder ? '0 !important' : 'none')};

      /*Body */

      tbody > tr > td {
        border-left: ${({ noBorder }) =>
          noBorder ? 'none' : `1px solid ${theme.colors.gray_border} !important`};
        border-bottom: 1px solid ${theme.colors.gray_border} !important;
        border-top: none !important;
      }

      tbody > tr > td:last-child {
        border-right: ${({ noBorder }) =>
          noBorder ? 'none' : `1px solid ${theme.colors.gray_border} !important`};
      }

      tbody > tr:first-child > td:not(.ant-table-row-expand-icon-expanded) {
        background-color: #fff;
      }
      .ant-table-column-sort {
        background: none;
      }
      .ant-table-cell-fix-left {
        background: none;
      }

      /*Header */
      thead {
        .ant-table-cell::before {
          content: none;
        }
      }

      thead > tr:nth-child(-n + 1) > th:first-child {
        border-radius: ${({ noBorder }) => (noBorder ? '0 !important' : 'none')};

        border-left: ${({ noBorder }) =>
          noBorder ? 'none' : `1px solid ${theme.colors.gray_border} !important`};
      }
      thead > tr:nth-child(-n + 1) > th {
        border-top: ${({ noBorder }) =>
          noBorder ? 'none' : `1px solid ${theme.colors.gray_border} !important`};
        padding: ${({ headerNoPadding }) =>
          headerNoPadding ? 0 : '16px 10px !important'}; // Header padding: ;
      }
      thead > tr > th:last-child {
        border-right: 1px solid ${theme.colors.gray_border} !important;
        /* border-right: ${({ noBorder }) =>
          noBorder ? 'none' : `1px solid ${theme.colors.gray_border} !important`}; */
      }
      thead > tr > th {
        font-weight: 400;
      }
      thead > tr:has(+ tr) > th:last-child {
        border-bottom: none !important;
      }
      thead {
        background-color: ${theme.colors.gray_header};
        color: rgb(0, 0, 0, 0.6);

        &::before {
          display: none;
        }
      }

      /*Sorter/Filter Icons*/

      .ant-table-column-sorter-up.active {
        color: ${theme.colors.link};
      }
      .ant-table-column-sorter-down.active {
        color: ${theme.colors.link};
      }
      .ant-table-filter-trigger {
        margin-inline: 4px -4px;
      }

      /*AVLC Icon*/

      tr > td > div > img {
        margin-right: 4px;
      }

      /*Table With Children */

      .ant-table-cell-with-append > .anticon {
        color: ${theme.colors.link};
      }
    }
  }
`;
