import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import * as S from './styles';
import ExpandableCard from '../../components/Common/ExpandableCard';
import { useFinance } from './useFinance';
import HeaderCard from './HeaderCards';
import SimpleTable from '../../components/Tables/SimpleTable';
import { RoutesPath } from '../../routes/routesPath';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function FinancePage(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  useDocumentTitle(t('pages.finance.title'));

  const {
    openCbomCard,
    cbomItens,
    columns,
    priceProps,
    isLoading,
    setPriceProps,
    setOpenCbomCard,
    handleFetchCbomItens,
    handleFormatQuotePrice,
  } = useFinance();

  useEffect(() => {
    setPriceProps(props.location.state);
  }, []);

  useEffect(() => {
    priceProps && handleFetchCbomItens();
  }, [priceProps]);

  return (
    <S.Container>
      <S.BackPageQuotesContainer
        onClick={() => {
          const filters = sessionStorage.getItem('filtersQuotes');
          filters &&
            history.push({
              pathname: RoutesPath.quotes,
              state: { filters: JSON.parse(filters) },
            });
        }}
      >
        <ArrowLeftOutlined />
        <p>{t('pages.financePage.labels.quotes')}</p>
      </S.BackPageQuotesContainer>
      <S.TitlePage>{`${priceProps?.sku || '-'} | ${priceProps?.project || '-'}`}</S.TitlePage>
      <S.InfoContainer>
        <S.QuotePriceContainer>
          <S.LabelInfoContainer>{t('pages.financePage.labels.quuotesPrice')}</S.LabelInfoContainer>
          <S.QuotesInfoPriceContainer>
            <span>USD</span>
            <p>{handleFormatQuotePrice(priceProps?.quotePrice)}</p>
          </S.QuotesInfoPriceContainer>
        </S.QuotePriceContainer>
        <S.OtherInformationsPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.monthYear')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.monthYear || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.category')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.category || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.cm')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.cm || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
          <S.SingleInformationPriceContainer>
            <S.LabelInfoContainer>{t('pages.financePage.labels.source')}</S.LabelInfoContainer>
            <S.InfoContainerText>{priceProps?.source || '-'}</S.InfoContainerText>
          </S.SingleInformationPriceContainer>
        </S.OtherInformationsPriceContainer>
      </S.InfoContainer>

      <ExpandableCard
        isOpen={openCbomCard}
        headerCard={
          <HeaderCard
            cbom={`CBOM ${priceProps?.level}`}
            componentName={priceProps?.category || '-'}
          />
        }
        onExpandableContent={() => {
          setOpenCbomCard(!openCbomCard);
        }}
      >
        <SimpleTable
          striped
          bordered={false}
          pagination={false}
          dataSource={cbomItens}
          rowKey="partNumber"
          columns={columns}
          headerNoPadding
          isLoading={isLoading}
        />
      </ExpandableCard>
    </S.Container>
  );
}
