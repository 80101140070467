import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { IParams, PriceTaxes, PriceTaxesForColumns } from './types';
import { getErrorMessage, getURI, oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import { formatDataToSelect } from '../../helpers/formatDataToSelect';
import { formatValueToCash } from '../../helpers/formatValueToCash';

export function usePricesTaxes() {
  const { t } = useTranslation();

  const initialParams: IParams = {
    tipoBusca: 'dinamica',
    cm: 'Foxconn',
    category: null,
    source: null,
    specification: null,
    partNumber: null,
    supplier: null,
    from: null,
    to: null,
    period: dayjs() || null,
    page: 0,
    limit: 10,
    orderby: 'ASC',
  };

  const [partNumber, setPartNumber] = useState<string>('');
  const [period, setPeriod] = useState<string | null>(null);
  const [taxesPrice, setPricesTaxe] = useState<Array<PriceTaxesForColumns>>([]);
  const [params, setParams] = useState<IParams>(initialParams);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [specs, setSpecs] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSuppliers] = useState<Array<StyledSelectOptions>>([]);
  const [sources, setSources] = useState<Array<StyledSelectOptions>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });

  const headerCsv = [
    { label: t('pages.pricesTaxes.fields.partNumber'), key: 'partNumber' },
    { label: t('pages.pricesTaxes.fields.supplier'), key: 'supplier' },
    { label: t('pages.pricesTaxes.fields.from'), key: 'from' },
    { label: t('pages.pricesTaxes.fields.to'), key: 'to' },
    { label: t('pages.pricesTaxes.fields.period'), key: 'period' },
    { label: t('pages.pricesTaxes.fieldsCsv.grossPrice'), key: 'grossPrice' },
    { label: t('pages.pricesTaxes.fieldsCsv.ipi'), key: 'ipi' },
    { label: t('pages.pricesTaxes.fieldsCsv.icms'), key: 'icms' },
    { label: t('pages.pricesTaxes.fieldsCsv.pisCofins'), key: 'pisCofins' },
    { label: t('pages.pricesTaxes.fieldsCsv.netPrice'), key: 'netPrice' },
    {
      label: `${t('pages.pricesTaxes.fields.recovery')} ${t('pages.pricesTaxes.fieldsCsv.ipi')}`,
      key: 'recoveryTaxesIpi',
    },
    {
      label: `${t('pages.pricesTaxes.fields.recovery')} ${t('pages.pricesTaxes.fieldsCsv.icms')}`,
      key: 'recoveryTaxesIcms',
    },
    {
      label: `${t('pages.pricesTaxes.fields.recovery')} ${t(
        'pages.pricesTaxes.fieldsCsv.pisCofins'
      )}`,
      key: 'recoveryTaxesPisCofins',
    },
    { label: t('pages.pricesTaxes.fieldsCsv.costAfterCredit'), key: 'costAfterCredit' },
  ];

  const transformDataToCsv = (data: Array<PriceTaxes>) => {
    const tableRows: any = [];
    data.forEach((priceTaxe: PriceTaxes) => {
      tableRows.push({
        partNumber: priceTaxe.partNumber,
        supplier: priceTaxe.supplier,
        from: priceTaxe.from,
        to: priceTaxe.to,
        period: priceTaxe.period,
        grossPrice: priceTaxe.grossPrice,
        ipi: priceTaxe.ipi,
        icms: priceTaxe.icms,
        pisCofins: priceTaxe.pisCofins,
        netPrice: priceTaxe.netPrice,
        recoveryTaxesIpi: priceTaxe.rec_ipi,
        recoveryTaxesIcms: priceTaxe.rec_icms,
        recoveryTaxesPisCofins: priceTaxe.rec_pisCofins,
        costAfterCredit: priceTaxe.costAfterCredit,
      });
    });
    return tableRows;
  };

  const handleTitleColumnsBreakText = (title: string) => {
    const words = title.split(',');
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textOrientation: 'revert-layer',
        }}
      >
        {words.map((word: string) => (
          <p key={word} style={{ margin: 0 }}>
            {word}
          </p>
        ))}
      </div>
    );
  };

  const fetchCategories = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/taxRecoveryCalculation/header', { filterToReturn: 'category', cm: params.cm })
      );
      if (status === 200) {
        setCategories(formatDataToSelect(data, true));
      } else if (status === 204) {
        setCategories([]);
      }
    } catch (error) {
      oneAlert({ type: 'error', message: t('toast.errorOnList') });
    }
  };

  const fetchSpecifications = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/taxRecoveryCalculation/header', {
          filterToReturn: 'specification',
          cm: params.cm,
          category: params.category,
        })
      );
      if (status === 200) {
        setSpecs(formatDataToSelect(data, true));
      } else if (status === 204) {
        setSpecs([]);
      }
    } catch (error) {
      oneAlert({ type: 'error', message: t('toast.errorOnList') });
    }
  };

  const fetchSource = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/taxRecoveryCalculation/header', {
          filterToReturn: 'source',
          cm: params.cm,
          category: params.category,
          specification: params.specification,
        })
      );
      if (status === 200) {
        setSources(formatDataToSelect(data, true));
      } else if (status === 204) {
        setSources([]);
      }
    } catch (error) {
      oneAlert({ type: 'error', message: t('toast.errorOnList') });
    }
  };

  const fetchPartNumbers = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/taxRecoveryCalculation/header', {
          filterToReturn: 'partNumber',
          cm: params.cm,
          category: params.category,
          source: params.source,
          specification: params.specification,
          supplier: params.supplier,
        })
      );
      if (status === 200) {
        setPartNumbers(formatDataToSelect(data, true));
      } else if (status === 204) {
        setPartNumbers([]);
      }
    } catch (error) {
      oneAlert({ type: 'error', message: t('toast.errorOnList') });
    }
  };

  const fetchSuppliersv2 = async () => {
    try {
      const paramsSuppliers = {
        filterToReturn: 'supplier',
        orderBy: 'ASC',
        cm: params.cm,
        category: params.category,
        source: params.source,
        specification: params.specification,
      };
      const { data } = await api.get(getURI('/taxRecoveryCalculation/header', paramsSuppliers));
      if (data) {
        const suppliersReceived = data.map((supplier: string) => ({
          label: supplier,
          value: supplier,
        }));
        setSuppliers(suppliersReceived);
      } else {
        setSuppliers([]);
      }
    } catch (error) {
      oneAlert({ type: 'error', message: getErrorMessage(error) || t('toast.errorOnList') });
    }
  };

  const fetchSuppliers = async (valueAsId = false) => {
    try {
      const paramsSuppliers = { orderBy: 'ASC' };
      const { data } = await api.get(
        getURI('taxRecoveryCalculation/supplierTypes', paramsSuppliers)
      );
      if (data) {
        const suppliersReceived = data.map((supplier: string) => ({
          label: supplier,
          value: supplier,
        }));
        setSuppliers(suppliersReceived);
      } else {
        setSuppliers([]);
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnList'));
    }
  };

  const columns: ColumnsType<PriceTaxes> = [
    {
      title: t('pages.pricesTaxes.fields.partNumber'),
      align: 'center',
      dataIndex: 'partNumber',
      key: 'partNumber',
    },
    {
      title: t('pages.pricesTaxes.fields.supplier'),
      align: 'center',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: t('pages.pricesTaxes.fields.logistic'),
      dataIndex: '',
      children: [
        {
          title: t('pages.pricesTaxes.fields.from'),
          dataIndex: 'from',
          key: 'from',
          align: 'center',
        },
        {
          title: 'To',
          dataIndex: 'to',
          key: 'to',
          align: 'center',
        },
      ],
    },
    {
      title: t('pages.pricesTaxes.fields.period'),
      key: 'period',
      dataIndex: 'period',
      align: 'center',
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.grossPrice')),
      key: 'grossPrice',
      dataIndex: 'grossPrice',
      align: 'center',
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.ipi')),
      key: 'ipi',
      dataIndex: 'ipi',
      align: 'center',
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.icms')),
      key: 'icms',
      dataIndex: 'icms',
      align: 'center',
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.pisCofins')),
      key: 'pisCofins',
      dataIndex: 'pisCofins',
      align: 'center',
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.netPrice')),
      key: 'netPrice',
      dataIndex: 'netPrice',
      align: 'center',
    },
    {
      title: `Recovery`,
      key: 'recovery',
      children: [
        {
          title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.ipi')),
          key: 'rec_ipi',
          dataIndex: 'rec_ipi',
          align: 'center',
        },
        {
          title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.icms')),
          key: 'rec_icms',
          dataIndex: 'rec_icms',
          align: 'center',
        },
        {
          title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.pisCofins')),
          key: 'rec_pisCofins',
          dataIndex: 'rec_pisCofins',
          align: 'center',
        },
      ],
    },
    {
      title: handleTitleColumnsBreakText(t('pages.pricesTaxes.fields.costAfterCredit')),
      key: 'costAfterCredit',
      dataIndex: 'costAfterCredit',
      align: 'center',
    },
    // {
    //   title: t('pages.pricesTaxes.fields.action'),
    //   key: 'action',
    //   width: 120,
    //   render: (component: PriceTaxes) => renderActions(component),
    // },
  ];

  const handleRoundedPricesTaxes = (prices: PriceTaxes[]) => {
    const pricesRounded = prices.map((price: PriceTaxes) => {
      return {
        ...price,
        grossPrice: formatValueToCash(price.grossPrice) || 0,
        ipi: formatValueToCash(price.ipi) || 0.0,
        icms: formatValueToCash(price.icms) || 0.0,
        pisCofins: formatValueToCash(price.pisCofins) || 0.0,
        netPrice: formatValueToCash(price.netPrice) || 0.0,
        rec_ipi: formatValueToCash(price.rec_ipi) || 0.0,
        rec_icms: formatValueToCash(price.rec_icms) || 0.0,
        rec_pisCofins: formatValueToCash(price.rec_pisCofins) || 0.0,
        costAfterCredit: formatValueToCash(price.costAfterCredit) || 0.0,
      };
    });
    setPricesTaxe(pricesRounded);
  };

  // eslint-disable-next-line default-param-last
  const handleFetchPricesTaxe = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.get(
        getURI('taxRecoveryCalculation', {
          ...params,
          period: params.period?.format('YYYY/MM') || null,
        })
      );
      if (data) {
        handleRoundedPricesTaxes(data.content);
        setPageControl({
          pageLabel: String(params.page + 1),
          totalElements: data.totalElements,
          totalPages: data.totalPages,
        });
      } else {
        setPricesTaxe([]);
        setPageControl({
          pageLabel: String(params.page + 1),
          totalElements: 0,
          totalPages: 0,
        });
      }
    } catch (error: any) {
      getErrorMessage(error) || t('toast.errorOnList');
    } finally {
      setIsLoading(false);
    }
  };

  const changePageValue = (page: number, type: string) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: String(page) });
    } else {
      setPageControl({ ...pageControl, pageLabel: String(page) });
    }
  };

  const handleUpdateFilters = (field: string, value: string | null | dayjs.Dayjs) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.pricesTaxes.fields.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', null);
              setPartNumber('');
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.pricesTaxes.fields.supplier')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', null);
            }}
          />
        )}
        {params.from && (
          <StyledFilterTag
            label={t('pages.taxes.placeholders.from')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.from}
            closeble
            onClose={() => {
              handleUpdateFilters('from', null);
            }}
          />
        )}
        {params.to && (
          <StyledFilterTag
            label={t('pages.taxes.placeholders.to')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.to}
            closeble
            onClose={() => {
              handleUpdateFilters('to', null);
            }}
          />
        )}
        {params.period && (
          <StyledFilterTag
            label={t('pages.pricesTaxes.fields.period')}
            closeicon={<CloseOutlined />}
            title={params.period.format('MM/YYYY')}
            closeble
            onClose={() => {
              handleUpdateFilters('period', null);
              setPeriod('');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setPartNumber('');
    setPeriod(null);
    setParams({
      ...initialParams,
      period: null,
    });
  };

  const handleRenderClearAllFilters = () => {
    if (params.partNumber || params.supplier || params.from || params.to || params.period) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  return {
    pageControl,
    params,
    isLoading,
    columns,
    taxesPrice,
    suppliers,
    partNumber,
    period,
    headerCsv,
    changePageValue,
    handleFetchPricesTaxe,
    fetchSuppliers,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    handleUpdateFilters,
    setPartNumber,
    setPeriod,
    transformDataToCsv,
    setParams,
  };
}
