import { Col, Form, Row, Skeleton, Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { AiFillQuestionCircle } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import { StyledPageHeader } from '../../../components/Common/StyledPageHeader/styles';
import { Button } from '../../../components/Button';
import { useScenariosSettings } from './useScenariosSettings';
import { useModal } from '../../../components/Modal/useModal';
import { ModalWrapper } from '../../../components/Modal';
import { debounce, parseScenarioName } from '../../../helpers/utils';
import { HeaderTitle } from '../../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../../components/ActionButton';
import * as S from './styles';
import * as SC from '../../../styles/common';
import { DataSettings } from './dataSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

export function ScenariosSettings({ simulationModal }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.scenarios.settings.title'));

  const [modalData, setModalData] = useState();
  const [visible, openModal, closeModal] = useModal();

  const {
    scenarioData,
    isLoading,
    formValue,
    fetchScenarios,
    onFinish,
    onCancel,
    handleChangeSettings,
  } = useScenariosSettings({ simulationModal });

  const scenarioName = scenarioData ? parseScenarioName(scenarioData) : null;

  useEffect(() => {
    fetchScenarios();
  }, []);

  return (
    <S.Row justify="center" className={simulationModal ? 'isModal' : ''}>
      {visible && (
        <ModalWrapper
          area="settings"
          title={`${scenarioName}`}
          isVisible={visible}
          onClose={closeModal}
          footer={
            <SC.ModalFooter>
              <Button onClick={closeModal}>{t('common.close')}</Button>
            </SC.ModalFooter>
          }
        >
          <DataSettings
            onClose={closeModal}
            scenarioData={scenarioData}
            modalData={modalData}
            simulationModal={simulationModal}
          />
        </ModalWrapper>
      )}

      <Col span={simulationModal ? 24 : 16}>
        <StyledPageHeader
          title={
            <HeaderTitle title={t('pages.scenarios.settings.title')} subtitle={scenarioName} />
          }
          className="pl-0 pr-0"
        />

        {isLoading ? (
          <Skeleton active paragraph={{ rows: 2 }} />
        ) : (
          <Form
            name="form_settings"
            layout="vertical"
            onFinish={onFinish}
            initialValues={formValue}
          >
            <S.RowSettings sm={24} md={24}>
              <Col md={21} className="col-title">
                <div className="text-tooltip">
                  <div>{t('pages.scenarios.settings.componentPurchaseMaxLabel')}</div>
                  <Tooltip
                    placement="left"
                    title={t('pages.scenarios.settings.componentPurchaseMax')}
                  >
                    <AiFillQuestionCircle />
                  </Tooltip>
                </div>
                {!formValue.componentPurchaseMax ? null : (
                  <div>
                    <ActionButton
                      type="button"
                      onClick={() => {
                        setModalData({
                          area: 'settings',
                          type: 'componentPurchaseMax',
                          title: t('pages.scenarios.settings.componentPurchaseMax'),
                        });
                        openModal();
                      }}
                    >
                      <strong>{t('pages.scenarios.settings.details')}</strong>
                    </ActionButton>
                  </div>
                )}
              </Col>
              <Col md={3}>
                <Form.Item name="componentPurchaseMax">
                  <S.Switch
                    checked={formValue.componentPurchaseMax}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={debounce(() => {
                      handleChangeSettings('componentPurchaseMax');
                    }, 200)}
                  />
                </Form.Item>
              </Col>
            </S.RowSettings>

            <S.RowSettings sm={24} md={24}>
              <Col md={21} className="col-title">
                <div className="text-tooltip">
                  <div>{t('pages.scenarios.settings.componentPurchaseMinLabel')}</div>
                  <Tooltip
                    placement="left"
                    title={t('pages.scenarios.settings.componentPurchaseMin')}
                  >
                    <AiFillQuestionCircle />
                  </Tooltip>
                </div>
                {!formValue.componentPurchaseMin ? null : (
                  <div>
                    <ActionButton
                      type="button"
                      onClick={() => {
                        setModalData({
                          area: 'settings',
                          type: 'componentPurchaseMin',
                          title: t('pages.scenarios.settings.componentPurchaseMin'),
                        });
                        openModal();
                      }}
                    >
                      <strong>{t('pages.scenarios.settings.details')}</strong>
                    </ActionButton>
                  </div>
                )}
              </Col>
              <Col md={3}>
                <Form.Item name="componentPurchaseMin">
                  <S.Switch
                    checked={formValue.componentPurchaseMin}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={debounce(() => {
                      handleChangeSettings('componentPurchaseMin');
                    }, 200)}
                  />
                </Form.Item>
              </Col>
            </S.RowSettings>

            <S.RowSettings sm={24} md={24}>
              <Col md={21} className="col-title">
                <div className="text-tooltip">
                  <div>{t('pages.scenarios.settings.componentPurchaseNotNationalLabel')}</div>
                  <Tooltip
                    placement="left"
                    title={t('pages.scenarios.settings.componentPurchaseNotNational')}
                  >
                    <AiFillQuestionCircle />
                  </Tooltip>
                </div>
                {!formValue.componentPurchaseNotNational ? null : (
                  <div>
                    <ActionButton
                      type="button"
                      onClick={() => {
                        openModal();
                        setModalData({
                          area: 'settings',
                          type: 'componentPurchaseNotNational',
                          title: t('pages.scenarios.settings.componentPurchaseNotNational'),
                        });
                      }}
                    >
                      <strong>{t('pages.scenarios.settings.details')}</strong>
                    </ActionButton>
                  </div>
                )}
              </Col>
              <Col md={3}>
                <Form.Item name="componentPurchaseNotNational">
                  <S.Switch
                    checked={formValue.componentPurchaseNotNational}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={debounce(() => {
                      handleChangeSettings('componentPurchaseNotNational');
                    }, 200)}
                  />
                </Form.Item>
              </Col>
            </S.RowSettings>

            <S.RowSettings sm={24} md={24}>
              <Col md={21} className="col-title">
                <div className="text-tooltip">
                  <div>{t('pages.scenarios.settings.componentWithoutPriceLabel')}</div>
                  <Tooltip
                    placement="left"
                    title={t('pages.scenarios.settings.componentWithoutPrice')}
                  >
                    <AiFillQuestionCircle />
                  </Tooltip>
                </div>
              </Col>
              <Col md={3}>
                <Form.Item name="componentWithoutPrice">
                  <S.Switch
                    checked={formValue.componentWithoutPrice}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={debounce(() => {
                      handleChangeSettings('componentWithoutPrice');
                    }, 200)}
                  />
                </Form.Item>
              </Col>
            </S.RowSettings>

            <S.RowSettings sm={24} md={24}>
              <Col md={21} className="col-title">
                <div className="text-tooltip">
                  <div>{t('pages.scenarios.settings.componentWithoutQGLabel')}</div>
                  <Tooltip
                    placement="left"
                    title={t('pages.scenarios.settings.componentWithoutQG')}
                  >
                    <AiFillQuestionCircle />
                  </Tooltip>
                </div>
              </Col>
              <Col md={3}>
                <Form.Item name="componentWithQualificationOngoing">
                  <S.Switch
                    checked={formValue.componentWithQualificationOngoing}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    onChange={debounce(() => {
                      handleChangeSettings('componentWithQualificationOngoing');
                    }, 200)}
                  />
                </Form.Item>
              </Col>
            </S.RowSettings>

            <Divider />

            <Row>
              <Col sm={24} md={24}>
                <Row justify="space-between">
                  <Col sm={12} md={4}>
                    <Button htmlType="button" danger onClick={onCancel} className="btn-cancel">
                      {t('common.cancel')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
    </S.Row>
  );
}
