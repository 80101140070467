import { Button } from 'antd';
import styled from 'styled-components';
import { Table } from '../../styles/common';

export const Container = styled.div`
  height: calc(100vh - 50px);
  width: 100%;
`;

export const ActionButton = styled(Button)`
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.link};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.link};
    text-decoration: underline;
  }
`;

export const EditButton = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.colors.link} !important;
  }
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 8px;
  min-width: 380px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  padding-bottom: 16px;

  div.ant-descriptions {
    tr.ant-descriptions-row > th {
      .ant-descriptions-item-label {
        color: ${({ theme }) => theme.colors.text};
      }
    }
    tr.ant-descriptions-row > td {
      .ant-descriptions-item-content {
        font-weight: 500;
      }
    }
  }
`;
