import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colors.new_background_color};
  min-height: 95vh;
  padding-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 1.125rem;
  margin: 0.5rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.colors.horizontal_row_color};
  padding-bottom: 0.625rem;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.dimensions.font_title};
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.063rem;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5rem;
`;

export const TableContainer = styled.div`
  margin-top: 1rem;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;
