import { AimOutlined, FolderViewOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { DatePicker, Empty } from 'antd';
import StyledSelect from '../../../components/Common/StyledSelect';
import * as S from './styles';
import { usePackingPricePage } from './usePackingPricePage';
import StyledButton from '../../../components/Common/StyledButton';
import StyledLabel from '../../../components/Common/StyledLabel';
import { Loading } from '../../../components/Loading';

export function PackingPricePage() {
  const {
    params,
    isLoading,
    projects,
    partOptions,
    cms,
    specifications,
    showError,
    dollarQuotation,
    monthImportedReferente,
    packingPriceComponents,
    setShowError,
    handleUpdateFilters,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    RenderTablesComponents,
    handleFetchPackingPrices,
    handleFetchFiltersData,
  } = usePackingPricePage();
  const { t } = useTranslation();

  useEffect(() => {
    handleFetchPackingPrices();
  }, []);

  useEffect(() => {
    handleFetchFiltersData();
  }, [params.cm, params.partNumber, params.projectName, params.spec]);

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.ContainerFilters>
            <StyledSelect
              options={cms}
              label={t('pages.packingPrices.filters.cm')}
              placeholder={t('pages.packingPrices.placeholders.cm')}
              onChange={(value: any) => handleUpdateFilters('cm', value)}
              styles={{ width: '6rem' }}
              value={params.cm === '' ? null : params.cm}
            />
            <StyledSelect
              options={projects}
              showSearch
              label={t('pages.packingPrices.filters.project')}
              placeholder={t('pages.packingPrices.placeholders.project')}
              onChange={(value: any) => handleUpdateFilters('projectName', value)}
              value={params.projectName || null}
            />
            <StyledSelect
              styles={{ width: '8.438rem' }}
              label={t('pages.packingPrices.filters.spec')}
              placeholder={t('pages.packingPrices.placeholders.specification')}
              onChange={(value: any) => handleUpdateFilters('spec', value)}
              showSearch
              value={params.spec || null}
              options={specifications}
              suffixIcon={
                <S.SearchButton>
                  <SearchOutlined className="icon" />
                </S.SearchButton>
              }
            />
            <StyledSelect
              styles={{ width: '8.438rem' }}
              label={t('pages.packingPrices.filters.PartNumber')}
              placeholder={t('pages.packingPrices.placeholders.parNumber')}
              onChange={(value: any) => handleUpdateFilters('partNumber', value)}
              showSearch
              value={params.partNumber || null}
              options={partOptions}
              suffixIcon={
                <S.SearchButton>
                  <SearchOutlined className="icon" />
                </S.SearchButton>
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <StyledLabel title={t('pages.packingPrices.filters.quarter')} />
              <DatePicker
                placeholder={t('pages.packingPrices.placeholders.quarter')}
                picker="quarter"
                value={params.quarter}
                defaultValue={params.quarter}
                onChange={(date) => {
                  handleUpdateFilters('quarter', date);
                  setShowError(false);
                }}
              />
              {showError && <S.SpanError>{t('pages.packingPrices.error.quarter')}</S.SpanError>}
            </div>
            <StyledButton
              small
              onClick={handleFetchPackingPrices}
              variant="primary"
              text={t('pages.componentsPrices.titleButtons.apply')}
            />
          </S.ContainerFilters>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.ContainerDollarCotation>
            <AimOutlined style={{ fontSize: 24 }} />
            <S.LabelDollarCotation type="VALUE">{dollarQuotation || '-'}</S.LabelDollarCotation>
            <S.LabelDollarCotation type="LABEL">(USD)</S.LabelDollarCotation>
          </S.ContainerDollarCotation>
          <S.ContainerFileRef>
            <FolderViewOutlined style={{ fontSize: 16 }} />
            <S.LabelsPackingFile type="LABEL">Packing</S.LabelsPackingFile>
            <S.LabelsPackingFile type="VALUE">{monthImportedReferente || '-'}</S.LabelsPackingFile>
          </S.ContainerFileRef>
          {packingPriceComponents.length > 0 && RenderTablesComponents()}
          {!packingPriceComponents.length && <Empty />}
        </>
      )}
    </S.Container>
  );
}
