import styled from 'styled-components';
import theme from '../../../styles/theme';

export interface CellIsTotal {
  isTotal: boolean;
}
export interface VarianceStatus {
  status: boolean;
}

export const Container = styled.main`
  background-color: ${theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  padding: 3.063rem 1.125rem 0 1.375rem;
  margin: 0.5rem;
  padding-bottom: 2rem;
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 0.75rem;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0.938rem;
  background-color: ${theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  box-sizing: border-box;
  border: 0.063rem solid;
  border-color: ${theme.colorsDesignSystem.newTablesColors.borderColor};
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  margin-bottom: 1rem;
`;

export const HeaderExpandableItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.125rem;

  span {
    color: ${theme.colorsDesignSystem.fontColor};
    font-weight: 600;
    font-size: 1rem;
  }

  p {
    color: ${theme.colorsDesignSystem.blue};
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
`;

export const HeaderExpandableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div:nth-child(1) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    p {
      font-size: 1rem;
      font-weight: 600;
      min-width: 18px;
      text-align: right;
    }
  }
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const ColumnHeader = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.938rem;
  margin: 0;
`;

export const PartNumberHeader = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.938rem;
  margin: 0;
  border: none;
`;

export const PartNumberData = styled.p`
  color: ${theme.colorsDesignSystem.primary};
  font-weight: 400;
  margin: 0;
  padding: 0.25rem 0.938rem;
`;

export const ComponentsQntContainer = styled.span`
  min-width: 115px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  .partNumbers {
    color: ${theme.colorsDesignSystem.fontColor};
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const NormalField = styled.p<CellIsTotal>`
  font-weight: ${({ isTotal }) => (isTotal ? '600' : '400')};
  margin: 0;
  padding: 0.25rem 1rem 0.25rem 1rem;
`;

export const TableData = styled.p`
  padding-left: 1rem;
  margin: 0;
`;

export const VarianceColor = styled.p<VarianceStatus>`
  color: ${({ status }) => (status ? theme.colorsDesignSystem.blue : theme.colorsDesignSystem.red)};
  padding: 0;
  margin: 0;
  gap: 0.75rem;
`;
