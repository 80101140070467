import { Form, Input, Popconfirm, Space, Tag } from 'antd';
import React, { useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import {
  oneAlert,
  filterAndUrlParams,
  getURI,
  debounce,
  getErrorMessage,
  parseScenariosLite,
  parseBaseScenarios,
  getTagColor,
  renderPrice,
  hasPropertyWithHeader,
} from '../../helpers/utils';
import { initialPrice } from './initialPrice';
import { api } from '../../services/api';
import { useHiddenPrices } from '../../hooks/useHiddenPrices';
import { Button } from '../../components/Button';
import ComponentsSelect from '../../components/forms/ComponentsSelect';
import SuppliersSelect from '../../components/forms/SuppliersSelect';
import * as S from './styles';
import { getColumnSearchProps } from '../../helpers/table';
import { TableAction } from '../../components/Tables/TableDropdownAction';
import StyledIndicationTag from '../../components/IndicationTag';

export function usePrices(dataModal = null) {
  const localTargetYear = localStorage.getItem('targetYear') || new Date().getFullYear().toString();

  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: 25,
    targetYear: localTargetYear,
    scenario: '',
    supplier: null,
    local: null,
    spec: [],
    partNumber: [],
  };

  //Modal must replace all others params
  if (dataModal && dataModal.scenario && dataModal.targetYear) {
    initialParams.scenario = dataModal.scenario;
    initialParams.targetYear = dataModal.targetYear;
  }

  const { t } = useTranslation();
  const { HiddenPricesForm, hiddenPrices } = useHiddenPrices();

  const [form] = Form.useForm();
  const searchInput = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [dataHidden, setDataHidden] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);
  const [selectedScenario, setSelectedScenario] = useState();
  const [params, setParams] = useState(initialParams);
  const [scenariosOptions, setScenariosOptions] = useState([]);
  const [openMultiplePrices, setOpenMultiplePrices] = useState(false);
  const [price, setPrice] = useState('');
  const [viewFilter, setViewFilter] = useState('price');
  const [groupBySpec, setGroupBySpec] = useState(false);
  const [refetchData, setRefetchData] = useState({ spec: [] });
  const [pageControl, setPageControl] = useState({
    pageLabel: '1',
    totalPages: 0,
    totalElements: 0,
  });
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);

  const optionsViewsFilter = [
    { value: 'price', label: `${t('pages.factors.views.prices')}` },
    { value: 'factor', label: `${t('pages.factors.views.factors')}` },
    { value: 'price and factor', label: `${t('pages.factors.views.priceFactor')}` },
  ];

  const hidePricesFromData = (dataPrices) => {
    if (!dataPrices?.length) return [];
    const dataClone = structuredClone(dataPrices);
    const keysToKeep = ['id', 'spec', 'partNumber', 'supplier', 'children', 'description'];

    dataClone.forEach((spec) => {
      spec.children.forEach((prices) => {
        Object.entries(prices).forEach(([priceKey]) => {
          if (!keysToKeep.includes(priceKey)) {
            prices[priceKey].total = '*';
            prices[priceKey].gap = '*';
            prices[priceKey].price.value = '*';
            prices[priceKey].factor.value = '*';
          }
        });
      });
    });

    return dataClone;
  };

  const resetControls = (expandeRows = null) => {
    setEditingKey('');
    setIsNewItem(false);
    setRefetchData({ spec: [], partNumber: [] });
    setExpandedRowKeys(expandeRows);

    form.setFieldsValue({
      spec: [],
    });
  };

  const fetchScenarios = async () => {
    if (!dataModal) {
      try {
        const allParams = filterAndUrlParams(params);

        const { data: content } = await api.get(
          `scenarios/lite?targetYear=${allParams.targetYear}`
        );

        const selectScenarios = parseScenariosLite(content);

        if (selectScenarios?.length) {
          if (!allParams.scenario) {
            const localScenario = localStorage.getItem('scenario');
            const scenarioExist = selectScenarios.find((i) => i.value === localScenario);

            if (!localScenario || !scenarioExist) {
              allParams.scenario = selectScenarios[0]?.value;
            } else {
              allParams.scenario = localScenario;
            }
          }

          if (allParams.scenario && allParams.targetYear) {
            localStorage.setItem('scenario', allParams.scenario);
            localStorage.setItem('targetYear', allParams.targetYear);
          }

          setSelectedScenario(selectScenarios.find((i) => i.value === allParams?.scenario));
          setScenariosOptions(selectScenarios);
          setParams(allParams);
        } else {
          setScenariosOptions([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      try {
        const {
          data: { content },
        } = await api.get(`scenarios?targetYear=${params.targetYear}`);

        const selectScenarios = parseBaseScenarios(content);
        if (selectScenarios?.length) {
          setSelectedScenario(selectScenarios.find((i) => i.value === params?.scenario));
        }
      } catch (error) {
        oneAlert('error', getErrorMessage(error) || t('toast.missingScenario'));
      }
    }
  };

  const fetchPrices = async () => {
    if (params && !!params.scenario) {
      //change filter params
      const getParams = { ...params };
      getParams.scenario = null;
      getParams.targetYear = null;
      if (!params.spec?.length) {
        getParams.spec = null;
      } else {
        getParams.spec = btoa(params.spec);
      }
      if (!params.partType?.length) getParams.partType = null;
      if (!params.partNumber?.length) getParams.partNumber = null;

      try {
        if (!isLoading) setIsLoading(true);

        const {
          data: { content, totalElements, totalPages },
        } = await api.get(
          getURI(`scenarios/${params.scenario}/prices`, {
            ...getParams,
            order: 'spec',
            orderBy: 'ASC',
          })
        );

        if (params.scenario && params.targetYear) {
          localStorage.setItem('scenario', params.scenario);
          localStorage.setItem('targetYear', params.targetYear);
        }

        const dataContent = content?.length ? content : [];
        setData(dataContent);
        setDataHidden(hidePricesFromData(dataContent));

        if (scenariosOptions?.length && params?.scenario) {
          setSelectedScenario(scenariosOptions.find((i) => i.value === params?.scenario));
        }

        setPageControl({
          pageLabel: params.page + 1,
          totalPages,
          totalElements,
        });

        setEditingKey('');
        setSearchValue('');
        setIsNewItem(false);
        setIsLoading(false);
        setRefetchData({ spec: [], partNumber: [] });
      } catch (error) {
        setData([]);
        setIsLoading(false);
      }
    }
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce((e) => {
        const value = !e.target.value ? '' : e.target.value.toString().toLowerCase().trim();
        setSearchValue(value);
      }, 300),
    []
  );

  const isEditing = (record) => record.id === editingKey;

  const cancelNewItem = () => {
    const newData = data.filter((item) => item.id !== editingKey);
    setData(newData);
    resetControls();
  };

  const cancel = () => {
    if (isNewItem) cancelNewItem();
    resetControls();
  };

  const edit = (record, e) => {
    e.stopPropagation();
    // console.log('edit =>', record);

    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const deleteItem = async (record) => {
    setIsLoading(true);
    const ids = [];

    if (record.supplier) {
      ids.push({
        id: record.id,
      });
    } else if (record.spec) {
      record?.children.forEach((partNumber) => {
        ids.push({
          id: partNumber.id,
        });
      });
    }

    try {
      const response = await api.delete(`scenarios/${params.scenario}/prices`, {
        data: ids,
      });

      if (response.status !== 200) throw Error();
      oneAlert('success', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }

    const isLastItem = data.length === 1;

    if (isLastItem) {
      const newPage = params.page - 1;
      setParams({ ...params, page: newPage < 0 ? 0 : newPage });
    }

    await fetchPrices();
    setIsLoading(false);
  };

  const deleteAll = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete(`scenarios/${params.scenario}/prices/all`);
      if (response.status !== 200) throw Error();

      await fetchPrices();
      oneAlert('success', t('toast.successOnDelete'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnDelete'));
    }
    setIsLoading(false);
  };

  const changePageValue = (page, type) => {
    if (type === 'input' || type === 'navigation') {
      setParams({ ...params, page: page - 1 });
      setPageControl({ ...pageControl, pageLabel: page });
    } else {
      setPageControl({ ...pageControl, pageLabel: page });
    }
  };

  const onValuesChange = (changedValues) => {
    if (changedValues.spec) {
      setRefetchData({ spec: changedValues.spec, partNumber: [] });
      form.setFieldsValue({
        partNumber: [],
      });
    }
  };

  const handleSaveItem = (row, record, responseData) => {
    const newData = [...data];

    const index = data.findIndex((item) => item?.children.find((child) => child.id === row.id));

    const item = data[index];

    const childReplaceIndex = item.children.findIndex((child) => child.id === row.id);

    newData[index].children.splice(childReplaceIndex, 1, responseData.children[0]);

    setData(newData);

    resetControls([item.id, item.spec]);
  };

  const isMonthChanged = (month = {}) => {
    return month.price.value !== month.total;
  };

  const priceRegexValidation = (value) => {
    const pattern = /^[0-9]{1,8}[.]*[0-9]{0,5}$/;
    const priceRegex = new RegExp(pattern);

    return priceRegex.test(value);
  };

  const factorRegexValidation = (value) => {
    const pattern = /^[0-9]{1,8}[.]*[0-9]{0,5}$/;
    const factorRegex = new RegExp(pattern);

    return factorRegex.test(value);
  };

  let isNotChanged = false;

  const inputYearValidation = (row = {}) => {
    const columns = Object.values(row).slice(4, 16);
    const isPrice = viewFilter === 'price';
    let validatedColumns = false;

    if (isPrice) {
      const changedColumns = columns.filter(isMonthChanged);

      if (changedColumns.length === 0) {
        isNotChanged = true;
        return !isNotChanged;
      }

      if (isPrice) {
        changedColumns.forEach((column) => {
          if (priceRegexValidation(column.price.value)) validatedColumns = true;
        });
      } else {
        columns.forEach((column) => {
          if (factorRegexValidation(column.factor.value)) validatedColumns = true;
        });
      }
      return validatedColumns;
    }
    validatedColumns = true;
    return validatedColumns;
  };

  const save = async (record) => {
    setIsLoading(true);
    await form
      .validateFields()
      .then(async () => {
        try {
          let response;
          const row = form.getFieldValue();
          if (!inputYearValidation(row)) throw Error("Can't update the cell");

          const newRow = { ...row, component: row.partNumber };

          delete newRow.spec;
          delete newRow.partNumber;

          if (isNewItem) {
            newRow.supplier = row.supplier.shortName;
            // console.log('newRow=>', id, newRow);

            response = await api.post(getURI(`scenarios/${params.scenario}/prices`), newRow);

            if (response.status !== 201) throw Error();
          } else {
            newRow.supplier = selectedSupplier;
            // console.log('newRow=>', id, newRow);

            response = await api.put(
              getURI(`scenarios/${params.scenario}/prices/${record.id}`),
              newRow
            );

            handleSaveItem(newRow, record, response?.data);
            if (response.status !== 201) throw Error();
          }

          await fetchPrices();

          oneAlert('success', t('toast.successOnSave'));
        } catch (error) {
          oneAlert(
            'error',
            getErrorMessage(error) || isNotChanged ? t('toast.noChanges') : t('toast.errorOnSave')
          );
        }
      })
      .catch((error) => {
        console.log('Validate Failed: ', error);
      });
    setIsLoading(false);
  };

  const handleUpload = async (uploadData, closeModalPrice, closeModalPreview) => {
    setIsLoadingUpload(true);
    try {
      if (!uploadData?.length) throw Error();

      if (viewFilter === 'price') {
        const response = await api.post(getURI(`scenarios/${params.scenario}/prices/upload`), {
          pricesFile: uploadData,
        });
        if (response.status !== 201) throw Error();
      } else {
        const response = await api.post(getURI(`scenarios/${params.scenario}/factors/upload`), {
          factorsFile: uploadData,
        });
        if (response.status !== 201) throw Error();
      }

      closeModalPrice();
      closeModalPreview();

      await fetchPrices();
      oneAlert('success', t('toast.successOnSave'));
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
      closeModalPrice();
    }
    setIsLoadingUpload(false);
  };

  const searchFilter = async (newFilters, filterName = '') => {
    setCanUpdate(filterName === 'submit');

    const filterParams = {
      ...params,
      ...newFilters,
    };

    //Reset dependent filters
    if (filterName === 'targetYear') {
      filterParams.scenario = null;
    }

    setParams(filterParams);
  };

  const clearFilter = async () => {
    setCanUpdate(false);

    setParams({
      ...initialParams,
      targetYear: null,
      scenario: null,
      supplier: null,
      local: null,
      spec: [],
      partNumber: [],
    });
  };

  const closeMultiplePrices = () => {
    setPrice('');
    setOpenMultiplePrices(false);
  };

  const applyMultiplePrice = () => {
    const newPrice = Number(price);
    // call api for update all value...passing new price
    closeMultiplePrices();
  };

  const handleAddItem = () => {
    if (editingKey === '') {
      const newItem = {
        id: (data?.length || 0) + 1,
        ...initialPrice,
      };

      setIsNewItem(true);
      form.setFieldsValue({ ...newItem });
      setData([newItem, ...data]);
      setEditingKey(newItem.id);
    }
  };

  // const getExpandedRowKeys = (dataSource) => {
  //   return dataSource.map((object) => {
  //     return object.id;
  //   });
  // };

  // const expandedRowKeys = getExpandedRowKeys([]);

  const handleHeaderReset = (dataIndex) => {
    const filterParams = { ...params };
    delete filterParams[`header_${dataIndex}`];

    if (!hasPropertyWithHeader(filterParams)) {
      delete filterParams.tipoBusca;
    }

    setParams(filterParams);
  };

  const handleCloseFilteredTag = (field, idx) => {
    if (field?.includes('header_')) {
      const dataIndex = field.replace('header_', '');
      handleHeaderReset(dataIndex);
    } else {
      let newValue;

      if (Array.isArray(params[field])) {
        newValue = [...params[field]];
        newValue.splice(idx, 1);
      } else {
        newValue = '';
      }

      setParams({
        ...params,
        [field]: newValue,
        page: 0,
      });
    }
  };

  const handleHeaderSearch = (dataIndex, formValue) => {
    if (dataIndex === 'local' && formValue?.local?.length) {
      const filterParams = {
        ...params,
        header_local: formValue.local.join(','),
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else if (dataIndex !== 'local' && formValue?.length) {
      const filterParams = {
        ...params,
        [`header_${dataIndex}`]: formValue.trim(),
        tipoBusca: 'dinamica',
      };

      setParams(filterParams);
    } else if (!formValue) {
      handleHeaderReset(dataIndex);
    }
  };

  const columns = [
    {
      title: t('pages.prices.fields.spec'),
      label: t('pages.prices.fields.spec'),
      dataIndex: 'spec',
      key: 'spec',
      width: '210px',
      editable: isNewItem,
      ...getColumnSearchProps({
        dataIndex: 'spec',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.prices.fields.partNumber'),
      label: t('pages.prices.fields.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      width: '120px',
      editable: isNewItem,
      ...getColumnSearchProps({
        dataIndex: 'partNumber',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('pages.prices.fields.supplier'),
      label: t('pages.prices.fields.supplier'),
      dataIndex: ['supplier', 'shortName'],
      key: 'supplier',
      width: '50px',
      editable: isNewItem,
      render: (value, row) => {
        return !value ? null : (
          <Space direction="vertical" size={0}>
            {value}
            <StyledIndicationTag
              text={row?.supplier?.place === 'PPB' ? 'BR' : row.supplier.place}
            />
          </Space>
        );
      },
      ...getColumnSearchProps({
        dataIndex: 'supplier',
        search: handleHeaderSearch,
        reset: handleHeaderReset,
        filterData: params,
        searchInput,
      }),
    },
    {
      title: t('common.months.january'),
      label: t('common.months.january'),
      dataIndex: ['january', viewFilter, 'value'],
      key: 'january',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.january) {
          return {
            children: (
              <S.Text edited={row.january[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? renderPrice(value) : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.february'),
      label: t('common.months.february'),
      dataIndex: ['february', viewFilter, 'value'],
      key: 'february',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.february) {
          return {
            children: (
              <S.Text edited={row.february[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.march'),
      label: t('common.months.march'),
      dataIndex: ['march', viewFilter, 'value'],
      key: 'march',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.march) {
          return {
            children: (
              <S.Text edited={row?.march[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.april'),
      label: t('common.months.april'),
      dataIndex: ['april', viewFilter, 'value'],
      key: 'april',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.april) {
          return {
            children: (
              <S.Text edited={row.april[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.may'),
      label: t('common.months.may'),
      dataIndex: ['may', viewFilter, 'value'],
      key: 'may',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.may) {
          return {
            children: (
              <S.Text edited={row?.may[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.june'),
      label: t('common.months.june'),
      dataIndex: ['june', viewFilter, 'value'],
      key: 'june',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.june) {
          return {
            children: (
              <S.Text edited={row?.june[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.july'),
      label: t('common.months.july'),
      dataIndex: ['july', viewFilter, 'value'],
      key: 'july',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.july) {
          return {
            children: (
              <S.Text edited={row?.july[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.august'),
      label: t('common.months.august'),
      dataIndex: ['august', viewFilter, 'value'],
      key: 'august',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.august) {
          return {
            children: (
              <S.Text edited={row?.august[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.september'),
      label: t('common.months.september'),
      dataIndex: ['september', viewFilter, 'value'],
      key: 'september',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.september) {
          return {
            children: (
              <S.Text edited={row?.september[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.october'),
      label: t('common.months.october'),
      dataIndex: ['october', viewFilter, 'value'],
      key: 'october',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.october) {
          return {
            children: (
              <S.Text edited={row?.october[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.november'),
      label: t('common.months.november'),
      dataIndex: ['november', viewFilter, 'value'],
      key: 'november',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.november) {
          return {
            children: (
              <S.Text edited={row?.november[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
    {
      title: t('common.months.december'),
      label: t('common.months.december'),
      dataIndex: ['december', viewFilter, 'value'],
      key: 'december',
      editable: 'true',
      align: 'right',
      render: (value, row) => {
        if (row.december) {
          return {
            children: (
              <S.Text edited={row?.december[viewFilter]?.edited || null}>
                {viewFilter === 'price' ? <>{renderPrice(value)}</> : value}
              </S.Text>
            ),
          };
        }
      },
    },
  ];

  const columnsPreview = [
    {
      title: t('pages.prices.fields.spec'),
      label: t('pages.prices.fields.spec'),
      dataIndex: 'spec',
    },
    {
      title: t('pages.prices.fields.partNumber'),
      label: t('pages.prices.fields.partNumber'),
      dataIndex: 'partNumber',
    },
    {
      title: t('pages.prices.fields.supplier'),
      label: t('pages.prices.fields.supplier'),
      dataIndex: 'supplier',
    },
    {
      title: t('common.months.january'),
      label: t('common.months.january'),
      dataIndex: 'january',
    },
    {
      title: t('common.months.february'),
      label: t('common.months.february'),
      dataIndex: 'february',
    },
    {
      title: t('common.months.march'),
      label: t('common.months.march'),
      dataIndex: 'march',
    },
    {
      title: t('common.months.april'),
      label: t('common.months.april'),
      dataIndex: 'april',
    },
    {
      title: t('common.months.may'),
      label: t('common.months.may'),
      dataIndex: 'may',
    },
    {
      title: t('common.months.june'),
      label: t('common.months.june'),
      dataIndex: 'june',
    },
    {
      title: t('common.months.july'),
      label: t('common.months.july'),
      dataIndex: 'july',
    },
    {
      title: t('common.months.august'),
      label: t('common.months.august'),
      dataIndex: 'august',
    },
    {
      title: t('common.months.september'),
      label: t('common.months.september'),
      dataIndex: 'september',
    },
    {
      title: t('common.months.october'),
      label: t('common.months.october'),
      dataIndex: 'october',
    },
    {
      title: t('common.months.november'),
      label: t('common.months.november'),
      dataIndex: 'november',
    },
    {
      title: t('common.months.december'),
      label: t('common.months.december'),
      dataIndex: 'december',
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: t('common.action'),
      align: 'center',
      width: 120,
      render: (_, record) => {
        const editable = isEditing(record);

        const actions = [
          {
            key: '1',
            label: record.supplier ? (
              <Button
                type="table_action"
                disabled={hiddenPrices || editingKey !== '' || dataModal?.base === null}
                onClick={(e) => edit(record, e)}
                data-cy="edit"
              >
                {t('common.edit')}
              </Button>
            ) : null,
          },
          {
            key: '2',
            label: (
              <Popconfirm
                title={t('common.deleteMessage')}
                onConfirm={() => deleteItem(record)}
                onCancel={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                placement="topLeft"
              >
                <Button
                  type="table_action"
                  danger
                  disabled={hiddenPrices || editingKey !== '' || dataModal?.base === null}
                  data-cy="delete"
                >
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button type="primary" onClick={(e) => save(record, e)} data-cy="save">
              {t('common.save')}
            </Button>
            <Popconfirm title={t('common.cancelMessage')} onConfirm={cancel}>
              <Button shape="circle" default icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} />
        );
      },
    },
  ];

  const inputNode = (column) => {
    switch (column) {
      case 'spec':
        return (
          <ComponentsSelect
            emptySearch
            allowClear={false}
            placeholder={t('filter.spec')}
            intable="intable"
            search={searchValue}
            onKeyUp={debouncedEventHandler}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'partNumber':
        return (
          <ComponentsSelect
            valueAsId
            placeholder={t('filter.partNumber')}
            intable="intable"
            disabled={!refetchData.spec?.length}
            spec={refetchData.spec}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case 'supplier':
        return (
          <SuppliersSelect
            valueAsId
            intable="intable"
            onClick={(e) => e.stopPropagation()}
            onSelect={(value) => setSelectedSupplier(value)}
          />
        );

      default:
        return <Input maxLength={6} onClick={(e) => e.stopPropagation()} />;
    }
  };

  const mergedColumns = columnsWithAction.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.key === 'supplier' || col.key === 'spec' || col.key === 'partNumber'
            ? 'text'
            : viewFilter === 'price'
            ? 'money'
            : 'factor',
        required: true,
        newInputNode: inputNode(col.key, record),
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const headersPrices = [
    { label: 'spec', key: 'spec' },
    { label: 'partNumber', key: 'partNumber' },
    { label: 'supplier', key: 'supplier' },
    { label: 'january', key: 'january.price.value' },
    { label: 'february', key: 'february.price.value' },
    { label: 'march', key: 'march.price.value' },
    { label: 'april', key: 'april.price.value' },
    { label: 'may', key: 'may.price.value' },
    { label: 'june', key: 'june.price.value' },
    { label: 'july', key: 'july.price.value' },
    { label: 'august', key: 'august.price.value' },
    { label: 'september', key: 'september.price.value' },
    { label: 'october', key: 'october.price.value' },
    { label: 'november', key: 'november.price.value' },
    { label: 'december', key: 'december.price.value' },
  ];

  const headersFactors = [
    { label: 'spec', key: 'spec' },
    { label: 'partNumber', key: 'partNumber' },
    { label: 'supplier', key: 'supplier' },
    { label: 'january', key: 'january.factor.value' },
    { label: 'february', key: 'february.factor.value' },
    { label: 'march', key: 'march.factor.value' },
    { label: 'april', key: 'april.factor.value' },
    { label: 'may', key: 'may.factor.value' },
    { label: 'june', key: 'june.factor.value' },
    { label: 'july', key: 'july.factor.value' },
    { label: 'august', key: 'august.factor.value' },
    { label: 'september', key: 'september.factor.value' },
    { label: 'october', key: 'october.factor.value' },
    { label: 'november', key: 'november.factor.value' },
    { label: 'december', key: 'december.factor.value' },
  ];

  return {
    columnsPreview,
    mergedColumns,
    headersPrices,
    headersFactors,
    isLoadingUpload,
    handleUpload,
    hiddenPrices,
    HiddenPricesForm,
    dataHidden,
    data,
    params,
    pageControl,
    scenariosOptions,
    canUpdate,
    selectedScenario,
    fetchScenarios,
    fetchPrices,
    changePageValue,
    onValuesChange,
    refetchData,
    isLoading,
    form,
    isNewItem,
    save,
    cancel,
    edit,
    handleAddItem,
    isEditing,
    editingKey,
    deleteAll,
    deleteItem,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    groupBySpec,
    setGroupBySpec,
    expandedRowKeys,
    setExpandedRowKeys,
    optionsViewsFilter,
    setViewFilter,
    viewFilter,
    applyMultiplePrice,
    closeMultiplePrices,
    setOpenMultiplePrices,
    openMultiplePrices,
    setPrice,
    price,
    searchValue,
    debouncedEventHandler,
  };
}
