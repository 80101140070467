import { Layout } from 'antd';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRouteProps } from './types';
import { getUserGroup, isAuthenticated, getPermissions, cleanTokens } from '../services/auth';
import { NavBar } from '../components/Layout/NavBar';
import { routesConfig } from './config';
import { NotificationsProvider } from '../context/notification';
import { RoutesPath } from './routesPath';

const { Content } = Layout;

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps) {
  const userGroup = getUserGroup();
  const permissions = getPermissions();

  if (!userGroup || !permissions) {
    cleanTokens();
    return <Redirect to={{ pathname: RoutesPath.login }} />;
  }

  const allowedRoutes = routesConfig.routes.filter((route) => permissions.includes(route.route));

  const splitPath = rest.path.split('/');
  const path = splitPath[1];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (userGroup === 'admin') {
            return (
              <NotificationsProvider>
                <Layout>
                  <NavBar />
                  <Layout>
                    <Content className="content">
                      <Component {...props} />
                    </Content>
                  </Layout>
                </Layout>
              </NotificationsProvider>
            );
          }

          const allowedRoute = allowedRoutes.find(
            (route) => route.route === path || route.path.slice(1) === path
          );
          if (allowedRoute && allowedRoute.path && allowedRoute.path === rest.path) {
            return (
              <NotificationsProvider>
                <Layout>
                  <NavBar />
                  <Layout>
                    <Content className="content">
                      <Component {...props} />
                    </Content>
                  </Layout>
                </Layout>
              </NotificationsProvider>
            );
          }

          return (
            <Redirect to={{ pathname: allowedRoutes[0].path, state: { from: props.location } }} />
          );
        }

        return <Redirect to={{ pathname: RoutesPath.login, state: { from: props.location } }} />;
      }}
    />
  );
}
