import styled from 'styled-components';
import { Row as AntdRow, Table as AntdTable } from 'antd';

export const H4 = styled.h4`
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const Row = styled(AntdRow)`
  width: 100%;
  display: block;
`;

export const Table = styled(AntdTable)`
  tr[data-row-key='TOTAL'] {
    font-weight: bold;
  }

  tr td:first-of-type {
    font-weight: 700;
    font-size: 14px;
    color: #7bba24 !important;
    //text-transform: capitalize;
  }
`;
