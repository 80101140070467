import { ValidNotificationMessage, Notification, SKUAlert } from '../context/types';
import { dateFormat } from './utils';

export const parseSKUNotification = (
  message: ValidNotificationMessage,
  id?: string,
  date?: string,
  scenario?: string,
  visualized = false
): Notification => {
  const skuArray: { message: string; data: SKUAlert[] } = JSON.parse(message.obj);
  const stamp = date ? new Date(date).toString() : new Date().toString();
  const formatedDate = dateFormat(stamp);

  return {
    id: id || `${crypto.randomUUID()}`,
    type: 'sku_no_motherboard' as const,
    data: skuArray.data.length,
    skus: skuArray.data,
    stamp,
    formatedDate,
    visualized,
    scenario: scenario || '',
  };
};
