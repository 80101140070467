import { Col, ConfigProvider, Row, Form } from 'antd';
import { BiPlus } from 'react-icons/bi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { useUsers } from './useUsers';
import { EditableCell } from '../../components/EditableCell';
import { CustomPagination } from '../../components/CustomPagination';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { ModalWrapper } from '../../components/Modal';
import { UserForm } from './Form';
import { Loading } from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';

export function UsersPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.user.title'));

  const validateMessages = ValidateMessages();

  const {
    data,
    params,
    pageControl,
    fetchUsers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleCloseFilteredTag,
    mergedColumns,
    visibleModalUser,
    openModalUser,
    closeModalUser,
    userEdit,
    setUserEdit,
  } = useUsers();

  const showTagsFilter = params.name || params.email;

  useEffect(() => {
    fetchUsers();
  }, [params]);

  return (
    <main>
      <Row>
        {visibleModalUser && (
          <ModalWrapper
            area="Users"
            width={415}
            title={userEdit ? t('pages.user.editUser') : t('pages.user.addUser')}
            isVisible={visibleModalUser}
            onClose={() => {
              setUserEdit(null);
              closeModalUser();
            }}
          >
            <UserForm
              onClose={() => {
                setUserEdit(null);
                closeModalUser();
              }}
              data={userEdit}
              fetchUsers={fetchUsers}
            />
          </ModalWrapper>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.user.title')} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={1}
                      icon={<BiPlus />}
                      disabled={isNewItem}
                      onClick={() => {
                        openModalUser();
                      }}
                      buttonText={`${t('pages.user.addUser')}`}
                    />,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}

                  <SC.TagFilter
                    closable
                    visible={params.name}
                    onClose={() => handleCloseFilteredTag('name')}
                  >
                    <div className="tag-title">{t('pages.user.fields.name')}</div>
                    <div className="tag-value">{params.name}</div>
                  </SC.TagFilter>

                  <SC.TagFilter
                    closable
                    visible={params.email}
                    onClose={() => handleCloseFilteredTag('email')}
                  >
                    <div className="tag-title">{t('pages.user.fields.email')}</div>
                    <div className="tag-value">{params.email}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center" style={{ marginBottom: 32 }}>
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
