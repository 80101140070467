import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Form, Popconfirm, Row, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { AiOutlineClose } from 'react-icons/ai';
import {
  ShareProportionData,
  ShareProportionDataItens,
  ParamsPropsShareProportion,
  PayloadSendEditedRecord,
} from './types';
import { StyledSelectOptions } from '../../components/Common/StyledSelect/types';
import * as S from './styles';
import StyledIndicationTag from '../../components/IndicationTag';
import { api } from '../../services/api';
import { getURI, oneAlert } from '../../helpers/utils';
import StyledFilterTag from '../../components/Common/StyledFilterTag';
import theme from '../../styles/theme';
import StyledClearTag from '../../components/Common/StyledFilterTag/StyledClearTag';
import ExpandableTable from '../ComponentsPrices/ExpandableTable';
import SimpleTable from '../../components/Tables/SimpleTable';
import { ModalContentProps } from './ContentModalShareProportion/types';
import EditableTableCell from '../../components/Common/EditableTableCell';
import { TableAction } from '../../components/Tables/TableDropdownAction';

export function useShareProportion() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const initialParams: ParamsPropsShareProportion = {
    cm: 'Foxconn',
    category: null,
    project: null,
    partNumber: null,
    spec: null,
    supplier: null,
    source: null,
    month: dayjs().subtract(1, 'day'),
    page: 0,
    limit: 0,
  };
  const [params, setParams] = useState<ParamsPropsShareProportion>(initialParams);
  const [openCard, setOpenCard] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [categories, setCategories] = useState<Array<StyledSelectOptions>>([]);
  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [specifications, setSpecifications] = useState<Array<StyledSelectOptions>>([]);
  const [partNumbers, setPartNumbers] = useState<Array<StyledSelectOptions>>([]);
  const [suppliers, setSuppliers] = useState<Array<StyledSelectOptions>>([]);
  const ExpandCategories: any = {};
  const [form] = Form.useForm();
  const [keyToEditRecord, setKeyToEditRecord] = useState('');
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const [shareProportionComponents, setShareProportionComponents] = useState<
    Array<ShareProportionData>
  >([]);

  const CMs = [
    { value: 'Compal', label: 'Compal' },
    { value: 'Foxconn', label: 'Foxconn' },
  ];
  const Sources = [
    { value: 'FOB', label: 'FOB' },
    { value: 'BR', label: 'BR' },
  ];
  const products = [
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  const [modalProps, setModalProps] = useState<ModalContentProps>();

  const isEditing = (record: ShareProportionDataItens) => `${record.id}` === keyToEditRecord;

  const columns: ColumnsType<ShareProportionDataItens> = [
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.specification')}</strong>;
      },
      dataIndex: 'itemType',
      key: 'itemType',
      align: 'center',
      render: (_, record) => {
        return <span>{record.itemType || '-'}</span>;
      },
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.partNumber')}</strong>;
      },
      dataIndex: 'acerPn',
      key: 'acerPn',
      align: 'center',
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.supplier')}</strong>;
      },
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'center',
    },
    {
      title: () => {
        return <strong>{t('pages.inventory.tableHeaders.source')}</strong>;
      },
      dataIndex: 'cm',
      key: 'cm',
      align: 'center',
      render: (_, record) => {
        return (
          <Row align="middle">
            <StyledIndicationTag text={record.source || ''} />
          </Row>
        );
      },
    },
  ];

  const handleConvertStringToSelectOptions = (
    values: Array<string>
  ): Array<StyledSelectOptions> => {
    return values.map((value) => {
      return {
        label: value,
        value,
      };
    });
  };

  const handleFetchHeaders = async () => {
    try {
      const { data, status } = await api.get(
        getURI('/shareProportion/dynamicfilters', {
          ...params,
          month: dayjs(params.month).format('MMMM/YYYY'),
        })
      );
      if (status === 200) {
        if (data) {
          setPartNumbers(handleConvertStringToSelectOptions(data.partNumber || []));
          setCategories(handleConvertStringToSelectOptions(data.category || []));
          setSpecifications(handleConvertStringToSelectOptions(data.specification || []));
          setSuppliers(handleConvertStringToSelectOptions(data.supplier || []));
          setProjects(handleConvertStringToSelectOptions(data.project || []));
        }
        return;
      }
      if (status === 204) {
        oneAlert('info', t('pages.shareProportion.noContent'));
        setPartNumbers(handleConvertStringToSelectOptions([]));
        setCategories(handleConvertStringToSelectOptions([]));
        setSpecifications(handleConvertStringToSelectOptions([]));
        setSuppliers(handleConvertStringToSelectOptions([]));
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchComponents = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await api.get(
        getURI('/shareProportion/findAllByFilters', {
          ...params,
          month: dayjs(params.month).format('MMMM/YYYY'),
        })
      );
      status === 200 && setShareProportionComponents(data || []);
      status === 204 && setShareProportionComponents([]);
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnList'));
      setShareProportionComponents([]);
    } finally {
      setKeyToEditRecord('');
      setIsLoading(false);
    }
  };

  const handleUpdateFilters = (field: string, value: string) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.project && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.project}
            closeble
            onClose={() => {
              handleUpdateFilters('project', '');
            }}
          />
        )}
        {/* {params.cm && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => {
              handleUpdateFilters('cm', '');
            }}
          />
        )} */}
        {params.category && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => {
              handleUpdateFilters('category', '');
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              handleUpdateFilters('specification', '');
            }}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.partNumber')}
            closeicon={<CloseOutlined />}
            title={params.partNumber}
            closeble
            onClose={() => {
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}
        {params.source && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.source')}
            closeicon={<CloseOutlined />}
            title={params.source}
            closeble
            onClose={() => {
              handleUpdateFilters('source', '');
            }}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.shareProportion.labels.supplier')}
            closeicon={<CloseOutlined />}
            title={params.supplier}
            closeble
            onClose={() => {
              handleUpdateFilters('supplier', '');
            }}
          />
        )}
      </>
    );
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (
      params.project ||
      // params.cm ||
      params.category ||
      params.spec ||
      params.partNumber ||
      params.source ||
      params.supplier
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleRenderSourceTag = (items: Array<ShareProportionDataItens>, type: 'FOB' | 'BR') => {
    if (items.some((item) => item.source === type)) {
      return <StyledIndicationTag text={type} />;
    }
  };

  const disabledDate = (current: any) => {
    return current && current >= dayjs().subtract(1, 'day');
  };

  const prepareToEdit = (record: ShareProportionDataItens) => {
    form.setFieldsValue({ ...record });
    setKeyToEditRecord(record.id);
  };

  const handleConvertDataToPayloadToSend = (): Array<PayloadSendEditedRecord> => {
    try {
      const valueForm = form.getFieldsValue();
      const payload: Array<PayloadSendEditedRecord> = [];
      Object.keys(valueForm)
        .filter((key) => valueForm[key] !== null && valueForm[key] !== '')
        .forEach((key) => {
          payload.push({
            porcentage: Number(valueForm[key].replace('%', '')),
            projectName: key,
          });
        });

      return payload;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  };

  const SendEditedRecord = async (record: ShareProportionDataItens) => {
    try {
      const { status } = await api.put(
        `shareProportion/${record.id}`,
        handleConvertDataToPayloadToSend()
      );
      if (status === 200) {
        oneAlert('success', t('toast.successOnSave'));
        handleFetchComponents();
      }
    } catch (error: any) {
      oneAlert('error', error.message || t('toast.errorOnSave'));
      setIsLoading(false);
    } finally {
      setKeyToEditRecord('');
    }
  };

  const handleConvertProjectsToColumns = (component: Array<ShareProportionDataItens>) => {
    Object.keys(component[0])
      .filter(
        (key) =>
          key !== 'acerPn' &&
          key !== 'source' &&
          key !== 'itemType' &&
          key !== 'supplier' &&
          key !== 'id' &&
          key !== 'category'
      )
      .forEach((key) => {
        columns.push({
          title: () => {
            return <strong>{key}</strong>;
          },
          dataIndex: `${key}`,
          key: `${key}`,

          align: 'center',
          render: (value, record) => {
            return value || value === '-' ? (
              <S.LabelButton
                onClick={() => {
                  setModalProps({
                    cm: params.cm || '',
                    month: params.month,
                    projectName: key,
                    specification: record.itemType,
                    supplier: record.supplier,
                  });
                  setVisibleModal(true);
                }}
              >
                {value}
              </S.LabelButton>
            ) : (
              <S.ContainerNoContent>
                <span> - </span>
              </S.ContainerNoContent>
            );
          },
          onCell: (record) => ({
            record,
            inputType: 'numberWithZero',
            messageRequired: t('pages.inventory.mandatoryField'),
            require: false,
            dataIndex: `${key}`,
            editing: isEditing(record),
            title: `${key}`,
          }),
        });
      });

    columns.push({
      title: '',
      key: 'action',
      align: 'center',
      width: 40,
      render: (_, record) => {
        const editable = isEditing(record);
        const actions = [
          {
            key: '1',
            label: (
              <S.EditButton
                icon={<EditOutlined />}
                type="link"
                onClick={() => {
                  prepareToEdit(record);
                }}
                data-cy="edit"
              >
                {t('common.edit')}
              </S.EditButton>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button
              type="primary"
              style={{ borderRadius: '8px' }}
              onClick={() => {
                SendEditedRecord(record);
              }}
              data-cy="save"
            >
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={() => {
                setKeyToEditRecord('');
              }}
              onClick={() => {
                setKeyToEditRecord('');
              }}
            >
              <Button shape="circle" icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : (
          <TableAction dropdownItems={actions} placement="bottom" />
        );
      },
    });

    return columns;
  };

  const handleRenderTablesComponents = () => {
    return shareProportionComponents.map((item: ShareProportionData) => {
      return (
        <S.TablesContainer key={item.category}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.category]}
              onClick={() => {
                handleUpdateExpandableViews(item.category);
              }}
            />
            <S.HeaderExpandableContainer>
              <S.HeaderCardContainer>
                <Row>
                  <span>{item.category}</span>
                  {handleRenderSourceTag(item.items, 'FOB')}
                  {handleRenderSourceTag(item.items, 'BR')}
                </Row>
              </S.HeaderCardContainer>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.category] && (
            <Form form={form} component={false}>
              <SimpleTable
                striped
                bordered={false}
                pagination={false}
                dataSource={item.items}
                rowKey={item.category}
                columns={handleConvertProjectsToColumns(item.items)}
                components={{
                  body: {
                    cell: EditableTableCell,
                  },
                }}
                noBorder
                scrollable
              />
            </Form>
          )}
        </S.TablesContainer>
      );
    });
  };

  return {
    isLoading,
    params,
    columns,
    openCard,
    CMs,
    products,
    categories,
    specifications,
    partNumbers,
    projects,
    Sources,
    suppliers,
    shareProportionComponents,
    visibleModal,
    modalProps,
    setOpenCard,
    handleFetchHeaders,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    handleUpdateFilters,
    handleFetchComponents,
    handleRenderTablesComponents,
    disabledDate,
    setVisibleModal,
  };
}
