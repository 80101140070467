import React, { PureComponent, ReactNode } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { ChartTableForMonthProps, PriceTableChartItem } from './types';

const chartTheme = {
  before: '#6CC08B',
  current: '#105965',
  next: '#C2C8D4',
};

const dataPriceTable: Array<PriceTableChartItem> = [
  {
    name: 'JAN',
    month: 1,
    value: 4000,
  },
  {
    name: 'FEB',
    month: 2,
    value: 3000,
  },
  {
    name: 'MAR',
    month: 3,
    value: 2000,
  },
  {
    name: 'APR',
    month: 4,
    value: 2780,
  },
  {
    name: 'MAI',
    month: 5,
    value: 1890,
  },
  {
    name: 'JUN',
    month: 6,
    value: 2390,
  },
  {
    name: 'JUL',
    month: 7,
    value: 3490,
  },
  {
    name: 'AGO',
    month: 8,
    value: 3490,
  },
  {
    name: 'SEP',
    month: 9,
    value: 3490,
  },
  {
    name: 'OCT',
    month: 10,
    value: 3490,
  },
  {
    name: 'NOV',
    month: 11,
    value: 3490,
  },
  {
    name: 'DEC',
    month: 12,
    value: 3490,
  },
];

const handleRenderColorByMonth = (month: number): string => {
  const currentMonth = new Date().getMonth() + 1;

  if (month < currentMonth) {
    return chartTheme.before;
  }

  if (month === currentMonth) {
    return chartTheme.current;
  }

  if (month > currentMonth) {
    return chartTheme.next;
  }

  return '';
};

const handleRenderLegend = () => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: '45px',
          flexDirection: 'row',
          gap: '12px',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <span
          style={{
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: chartTheme.before,
          }}
        />
        <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Months Ago</span>
        <span
          style={{
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: chartTheme.current,
          }}
        />
        <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>This Month</span>
        <span
          style={{
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: chartTheme.next,
          }}
        />
        <span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Next Months</span>
      </div>
    </div>
  );
};

export default function PriceTableChart({ data }: ChartTableForMonthProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 25,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend align="right" content={handleRenderLegend} />
        <Bar barSize={40} dataKey="value" label={{ position: 'top' }}>
          {data.map((entry, index) => (
            <Cell key={entry.month} fill={handleRenderColorByMonth(entry.month)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
