import { Col, ConfigProvider, Row, Form } from 'antd';
import { BiDownload, BiPlus } from 'react-icons/bi';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CsvExport } from '../../components/Common/CsvExport';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ActionButton } from '../../components/ActionButton';
import { useSupplier } from './useSupplier';
import { EditableCell } from '../../components/EditableCell';
import { CustomPagination } from '../../components/CustomPagination';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import * as SC from '../../styles/common';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { getFilterURL, removeEmptyFilter } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import SimpleTable from '../../components/Tables/SimpleTable';

export function SuppliersPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.suppliers.title'));

  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    fetchSuppliers,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    isNewItem,
    handleAddItem,
    handleCloseFilteredTag,
    mergedColumns,
    csvHeaders,
  } = useSupplier();

  const showTagsFilter = params.name || params.shortName || params.state;

  useEffect(() => {
    fetchSuppliers();
  }, [params]);

  return (
    <main>
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          <Col span={24} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.suppliers.title')} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={1}
                      icon={<BiPlus />}
                      disabled={isNewItem}
                      onClick={handleAddItem}
                      buttonText={`${t('common.add')} ${t('common.item')}`}
                    />,
                    <ActionButton key={2} className="btn-csv" icon={<BiDownload />}>
                      <CsvExport
                        url={`suppliers?${getFilterURL(removeEmptyFilter(params))}`}
                        filename={['suppliers']}
                        limit={pageControl.totalElements}
                        headers={csvHeaders}
                      />
                    </ActionButton>,
                  ]}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                  <SC.TagFilter
                    closable
                    visible={params.header_name}
                    onClose={() => handleCloseFilteredTag('header_name')}
                  >
                    <div className="tag-title">{t('pages.suppliers.fields.name')}</div>
                    <div className="tag-value">{params.header_name}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_shortName}
                    onClose={() => handleCloseFilteredTag('header_shortName')}
                  >
                    <div className="tag-title">{t('pages.suppliers.fields.shortName')}</div>
                    <div className="tag-value">{params.header_shortName}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_codigo}
                    onClose={() => handleCloseFilteredTag('header_codigo')}
                  >
                    <div className="tag-title">{t('pages.suppliers.fields.code')}</div>
                    <div className="tag-value">{params.header_codigo}</div>
                  </SC.TagFilter>
                  <SC.TagFilter
                    closable
                    visible={params.header_state}
                    onClose={() => handleCloseFilteredTag('header_state')}
                  >
                    <div className="tag-title">{t('pages.suppliers.fields.state')}</div>
                    <div className="tag-value">{params.header_state}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      bordered
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
