import { Button, Col, ConfigProvider, Row, Space, Popconfirm, Tooltip, Form } from 'antd';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInteraction } from 'react-icons/ai';
import { StyledPageHeader } from '../../components/Common/StyledPageHeader/styles';
import { HeaderTitle } from '../../components/PageHeader/HeaderTitle';
import { ComponentsTypeSelect } from '../../components/forms/ComponentsTypeSelect';
import { useFileErros } from './useFileErros';
import * as SC from '../../styles/common';
import { EditableCell } from '../../components/EditableCell';
import { useTable } from '../../hooks/useTable';
import { CustomPagination } from '../../components/CustomPagination';
import FileErrorsFilter from './FileErrorsFilter';
import { ValidateMessages } from '../../helpers/ValidateMessages';
import { ResultNoScenarios } from '../../components/ResultNoScenarios';
import { Loading } from '../../components/Loading';
import { renderDateHour } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ActionButton } from '../../components/ActionButton';
import SimpleTable from '../../components/Tables/SimpleTable';

export function FileErrorsPage({ view = null, onClose = null, dataModal = null }) {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.fileerrors.title'));

  const { getSortOrderTable } = useTable();
  const validateMessages = ValidateMessages();
  const {
    data,
    params,
    pageControl,
    canUpdate,
    fetchFileErrors,
    changePageValue,
    handleChangeTable,
    isLoading,
    form,
    save,
    searchFilter,
    clearFilter,
    handleCloseFilteredTag,
    selectedRowKeys,
    handleDeleteLogs,
    handleSelectAllRows,
    rowSelection,
    handleProcessFiles,
    selectedToProcess,
  } = useFileErros();

  const modifiedDate = (dataModal && dataModal.modified) || `2022-01-10`;
  const showTagsFilter = params.partType?.length || params.spec;

  const selectButtonText =
    selectedRowKeys.length === data.length
      ? `${t('common.unselect')} ${t('common.all')}`
      : `${t('common.select')} ${t('common.all')}`;

  const columns = [
    {
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
      title: t('pages.fileerrors.fields.modifiedDate'),
      label: t('pages.fileerrors.fields.modifiedDate'),
      sorter: 'true',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: params.order === 'modifiedDate' && getSortOrderTable(params.orderBy),
      showSorterTooltip: { title: t('sort.orderBy') },
      width: '180px',
      render: (value) => renderDateHour(value, true),
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: t('pages.fileerrors.fields.message'),
      label: t('pages.fileerrors.fields.message'),
    },
    {
      key: 'file',
      dataIndex: 'file',
      title: t('pages.fileerrors.fields.file'),
      label: t('pages.fileerrors.fields.file'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('pages.fileerrors.fields.status'),
      label: t('pages.fileerrors.fields.status'),
    },
    {
      key: 'action',
      title: t('common.action'),
      render: (_, record) => {
        return record.status === 'ERRO_INTEGRACAO' ? (
          <div style={{ textAlign: 'center' }}>
            <Tooltip placement="left" title={t('pages.fileerrors.tooltipMessage')}>
              <Space direction="horizontal" size={12}>
                <Popconfirm
                  title={t('pages.fileerrors.confirmMessage')}
                  onConfirm={() => save(record)}
                >
                  <Button shape="circle" default icon={<AiFillInteraction />} />
                </Popconfirm>
              </Space>
            </Tooltip>
          </div>
        ) : null;
      },
    },
  ];

  const onCellRecords = (record, col) => ({
    record,
    newInputNode: col.dataIndex === 'partType' && <ComponentsTypeSelect />,
    dataIndex: col.dataIndex,
    title: col.title,
  });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => onCellRecords(record, col),
    };
  });

  const exportColumns = columns.filter((col) => col.key !== 'action');

  useEffect(() => {
    if (canUpdate) {
      fetchFileErrors();
    }
  }, [params]);

  return (
    <main>
      <Row>
        {view ? null : (
          <FileErrorsFilter
            clearFilter={clearFilter}
            searchFilter={searchFilter}
            values={params}
            title={t('pages.fileerrors.title')}
          />
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Col span={23} className="filter-page">
            <Row justify="center">
              <Col span={23}>
                <StyledPageHeader
                  title={<HeaderTitle title={t('pages.fileerrors.title')} />}
                  className="pl-0 pr-0"
                  extra={[
                    <ActionButton
                      key={1}
                      onClick={handleSelectAllRows}
                      buttonText={selectButtonText}
                      disabled={isLoading}
                    />,
                    <ActionButton
                      key={2}
                      onClick={handleDeleteLogs}
                      buttonText={`${t('common.delete')} ${t('common.selected')}`}
                      disabled={selectedRowKeys.length <= 0 || isLoading}
                      hidden={selectedRowKeys.length <= 0}
                    />,
                    <ActionButton
                      key={3}
                      onClick={handleProcessFiles}
                      buttonText={`${t('pages.fileerrors.processMultipleMessage', {
                        files: selectedToProcess.length,
                      })}`}
                      disabled={selectedRowKeys.length <= 0 || isLoading}
                      hidden={selectedToProcess.length <= 0}
                    />,
                  ]}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <SC.ContainerFilteredTags>
                  {showTagsFilter && <SC.SpanSmLight>{`${t('common.filters')}: `}</SC.SpanSmLight>}
                  <SC.TagFilter
                    closable
                    visible={params.status}
                    onClose={() => handleCloseFilteredTag('status')}
                  >
                    <div className="tag-title">{t('pages.fileerrors.fields.status')}</div>
                    <div className="tag-value">{params.status}</div>
                  </SC.TagFilter>
                </SC.ContainerFilteredTags>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={23}>
                <Form form={form} component={false} validateMessages={validateMessages}>
                  <ConfigProvider>
                    <SimpleTable
                      rowKey="id"
                      data-cy="table"
                      striped
                      dataSource={data}
                      columns={mergedColumns}
                      onChange={handleChangeTable}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      rowSelection={{ ...rowSelection }}
                      pagination={false}
                      locale={{
                        emptyText: (
                          <ResultNoScenarios
                            title={t('common.results.noDataTitle')}
                            subTitle={t('common.results.noDataSubtitle')}
                          />
                        ),
                      }}
                    />
                  </ConfigProvider>
                </Form>

                {pageControl.totalPages > 1 && (
                  <CustomPagination
                    limit={params.limit}
                    page={params.page + 1}
                    pageLabel={pageControl.pageLabel}
                    total={pageControl.totalElements}
                    totalPages={pageControl.totalPages}
                    changePageValue={changePageValue}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </main>
  );
}
