import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DownloadOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useEffect } from 'react';
import * as S from './styles';
import StyledButton from '../../components/Common/StyledButton';
import StyledSelect from '../../components/Common/StyledSelect';
import find from '../../assets/find.svg';
import SimpleTable from '../../components/Tables/SimpleTable';
import { useSettingsTaxe } from './useSettingTaxes';
import { ModalStyled, ModalWrapper } from '../../components/Modal';
import ContentNewTax from '../../components/Modal/ContentNewTax';
import { Loading } from '../../components/Loading';
import { CsvExport } from '../../components/Common/CsvExport';
import { CustomPagination } from '../../components/CustomPagination';
import { getURI, states } from '../../helpers/utils';
import CsvUploadReader from '../../components/CsvUploadReader';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export function SettingsTaxes() {
  const { t } = useTranslation();
  const {
    columns,
    taxesData,
    showModal,
    showUploadFileModal,
    isLoading,
    editTax,
    headerCsv,
    pageControl,
    params,
    handleUpdateParams,
    changePageValue,
    transformDataToCsv,
    setShowModal,
    handleCloseModalUploadFiles,
    saveNewTaxe,
    handleFetchTaxesData,
    handleOpenModalNewTaxe,
    handleRenderFooterModalUpload,
    handleFormatJsonToUpload,
    setShowUploadFileModal,
    handleClearTaxesToUpload,
    handleDeleteAllTaxes,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
  } = useSettingsTaxe();

  useEffect(() => {
    handleFetchTaxesData();
  }, [params]);

  useDocumentTitle(t('pages.taxes.title'));

  return (
    <S.Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <S.HeaderContainer>
            <S.Title>{t('pages.taxes.title')}</S.Title>
            <S.HeaderButtonsContainer>
              <StyledButton
                small
                onClick={() => {
                  setShowUploadFileModal(true);
                }}
                variant="secondary"
                text={t('common.fields.uploadFile')}
                iconLeft={<DownloadOutlined />}
              />
              <StyledButton small variant="secondary" iconLeft={<UploadOutlined />}>
                <CsvExport
                  headers={headerCsv}
                  transformData={transformDataToCsv}
                  filename="Taxes"
                  limit={pageControl.totalElements}
                  url={getURI('taxRateSettings', params)}
                />
              </StyledButton>
              <Popconfirm
                title={t('common.deleteMessageAll')}
                onConfirm={handleDeleteAllTaxes}
                onClick={() => {
                  console.log('empty');
                }}
              >
                <StyledButton
                  small
                  disabled={!taxesData.length}
                  variant="secondary"
                  text={t('common.deleteAll')}
                  iconLeft={<DeleteOutlined />}
                />
              </Popconfirm>
            </S.HeaderButtonsContainer>
          </S.HeaderContainer>
          <S.FiltersContainer>
            <S.Filters>
              <StyledSelect
                type="COMPONENT_TYPE"
                styles={{ minWidth: '11.25rem' }}
                suffixIcon={<img src={find} alt="lupa" />}
                placeholder={t('pages.taxes.placeholders.component')}
                onChange={(value: any) => {
                  handleUpdateParams('componentType', value);
                }}
                value={params.componentType || null}
                label={t('pages.taxes.labels.linkTo')}
              />
              <StyledSelect
                placeholder={t('pages.taxes.placeholders.from')}
                onChange={(value: any) => {
                  handleUpdateParams('from', value);
                }}
                label={t('pages.taxes.labels.logistic')}
                options={states('')}
                value={params.from || null}
              />
              <StyledSelect
                placeholder={t('pages.taxes.placeholders.to')}
                onChange={(value: any) => {
                  handleUpdateParams('to', value);
                }}
                label=""
                options={states('')}
                value={params.to || null}
              />
            </S.Filters>
            <StyledButton
              iconLeft={<PlusOutlined />}
              variant="primary"
              text={t('pages.taxes.addTax')}
              small
              onClick={handleOpenModalNewTaxe}
            />
          </S.FiltersContainer>
          <S.FiltersTagContainer>
            {handleRenderFiltersTags()}
            {handleRenderClearAllFilters()}
          </S.FiltersTagContainer>
          <S.TableContainer>
            <SimpleTable
              striped
              pagination={false}
              columns={columns}
              dataSource={taxesData}
              rowKey=""
            />
          </S.TableContainer>

          {showModal && (
            <ModalStyled
              key="modal-avlc"
              title={t('components.modals.addNewTax.title')}
              open={showModal}
              onCancel={() => {
                setShowModal(false);
              }}
              onOk={() => {
                setShowModal(false);
              }}
              width={717}
              footer={null}
            >
              <ContentNewTax taxe={editTax} closeModal={setShowModal} saveNewTax={saveNewTaxe} />
            </ModalStyled>
          )}

          {showUploadFileModal && (
            <ModalWrapper
              area="PricesAndFactors"
              title={t('pages.taxes.title')}
              isVisible={showUploadFileModal}
              onClose={() => {
                handleCloseModalUploadFiles();
              }}
              footer={handleRenderFooterModalUpload()}
            >
              <CsvUploadReader
                onRemoveFile={() => handleClearTaxesToUpload()}
                getCsvData={async (csvData: any) => {
                  handleFormatJsonToUpload(csvData);
                }}
              />
            </ModalWrapper>
          )}
        </>
      )}
      {pageControl.totalPages > 1 && (
        <CustomPagination
          limit={params.limit}
          page={params.page + 1}
          pageLabel={pageControl.pageLabel}
          total={pageControl.totalElements}
          totalPages={pageControl.totalPages}
          changePageValue={changePageValue}
        />
      )}
    </S.Container>
  );
}
