import { useEffect } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LogoImg from '../../assets/Acer_green.svg';
import * as S from './styles';
import { useLogin } from './useLogin';
import { clearLocalStorage, getQueryString, oneAlert } from '../../helpers/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { RoutesPath } from '../../routes/routesPath';

export function Login() {
  const { t } = useTranslation();
  useDocumentTitle(t('pages.login.docTitle'));
  const { error, isLoading, onFieldsChange, onFinish } = useLogin();

  const history = useHistory();

  const navigateForgotPassword = () => {
    history.push(RoutesPath.forgotPassword);
  };

  useEffect(() => {
    clearLocalStorage();

    const errorMessage = getQueryString('error');
    if (errorMessage) {
      oneAlert('error', t('toast.errorOnAuth'));
    }
  }, []);

  const visibleIcon = (visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

  return (
    <S.Container>
      <S.Main>
        <S.FormCol xs={24} md={8}>
          <S.LogoHeader>
            <S.LogoAcerImg src={LogoImg} alt="" />
            <span style={{ lineHeight: 1.5715 }}>Simulador PPB</span>
          </S.LogoHeader>

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            size="large"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('pages.login.fields.email.messageRequired'),
                },
                {
                  type: 'email',
                  message: t('pages.login.fields.email.messageValidity'),
                },
              ]}
            >
              <Input
                style={{ borderRadius: '2px' }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('pages.login.fields.email.label')}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('pages.login.fields.password.messageRequired') },
              ]}
            >
              <Input.Password
                style={{ borderRadius: '2px' }}
                placeholder={t('pages.login.fields.password.label')}
                prefix={<LockOutlined className="site-form-item-icon" />}
                iconRender={(visible) => visibleIcon(visible)}
              />
            </Form.Item>

            <S.ButtonLogin
              type="primary"
              block
              htmlType="submit"
              disabled={(error && true) || isLoading}
              id="btn_submit_login"
            >
              {t('common.login')}
            </S.ButtonLogin>
          </Form>

          <S.ButtonLink type="link" block onClick={navigateForgotPassword}>
            {t('pages.login.linkForgotPassword')}
          </S.ButtonLink>
        </S.FormCol>

        <S.BackgroundImg />
      </S.Main>
    </S.Container>
  );
}
