import { Tag } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colors.new_background_color};
  min-height: 95vh;
  padding-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 1.125rem;
  margin: 0.5rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.colors.horizontal_row_color};
  padding-bottom: 0.625rem;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.dimensions.font_title};
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.063rem;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Filters = styled.div`
  display: flex;
  align-items: end;
  flex-direction: row;
  gap: 1.5rem;
`;

export const TableContainer = styled.div`
  margin-top: 1rem;
`;

export const FooterModalUpload = styled.div`
  display: flex;
  width: 100%;
  padding: 0.938rem;
  justify-content: end;
  align-items: center;
`;

export const FiltersTagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
`;

export const SpecTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlueBackground};
  border-radius: 0.25rem !important;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
  color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
  .text {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
    font-weight: 600;
  }
`;
