import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { WarningFilled, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useModal } from '../../components/Modal/useModal';
import StyledIndicationTag from '../../components/IndicationTag';

import {
  getURI,
  filterAndUrlParams,
  keyDownA11y,
  parseScenarios,
  renderPrice,
  parseScenarioName,
  getQueryString,
  oneAlert,
  arrayToStringMessage,
  renderDecimal,
  getErrorMessage,
  keyDownA11yEsc,
} from '../../helpers/utils';
import { api } from '../../services/api';
import * as SC from '../../styles/common';
import * as S from './styles';
import { useHiddenPrices } from '../../hooks/useHiddenPrices';
import { PartTypeSimulation } from '../../components/PartTypeSimulation';
import { formatToSelect } from '../../helpers/formatObjDataToSelect';

export function useSimulation() {
  const { t } = useTranslation();
  const { HiddenPricesForm, hiddenPrices } = useHiddenPrices();
  const [visibleQG, openModalQG, closeModalQG] = useModal();

  const history = useHistory();
  const urlScenario = getQueryString('scenario');
  const urlYear = getQueryString('targetYear');
  const localTargetYear =
    urlYear || localStorage.getItem('targetYear') || new Date().getFullYear().toString();
  const localScenario = urlScenario || localStorage.getItem('scenario') || '';
  const localScenarioName = localStorage.getItem('scenarioName');
  const localProductName = localStorage.getItem('productName');
  const [isFirstRendering, setIsFirstRendering] = useState(true);

  const cms = [
    { value: 'Compal', label: 'Compal' },
    { value: 'Foxconn', label: 'Foxconn' },
  ];
  const products = [
    { value: 'Notebook', label: 'Notebook' },
    { value: 'Desktop', label: 'Desktop' },
  ];

  const [projects, setProjects] = useState([]);
  const [targetYears, setTargetYears] = useState([]);
  const [categories, setCategories] = useState([]);

  const initialParams = {
    order: '',
    orderBy: '',
    page: 0,
    limit: null,
    product: localProductName || 'Notebook',
    cm: 'Foxconn',
    project: localStorage.getItem('project') || null,
    partType: localStorage.getItem('partType') || null,
    targetYear: localTargetYear,
    scenario: localScenario,
    scenarioName: localScenarioName || null,
  };

  const [data, setData] = useState([]);
  const [resultData, setResultData] = useState({
    totalCost: 0,
    currentScore: 0,
    partTypeMaxScore: 0,
    maxScore: 0,
    errors: null,
    deprecated: false,
  });

  const [dataHidden, setDataHidden] = useState([]);
  const [resultDataHidden, setResultDataHidden] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(initialParams);
  const [selectedScenario, setSelectedScenario] = useState(initialParams.scenario);
  const [scenariosOptions, setScenariosOptions] = useState([]);
  const [scenarioName, setScenarioName] = useState('');
  const [editingInput, setEditingInput] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [avlcIds, setAvlcIds] = useState([]);
  const [isOngoing, setIsOnGoing] = useState(false);
  const timeId = useRef(null);
  const [isPollingEnable, setIsPollingEnable] = useState(false);

  const simulationForTable = (dataSimulation) => {
    const dataClone = structuredClone(dataSimulation);
    const processedData = dataClone.flatMap((item, index) => {
      const newArr = [];
      const rowSpanCount = (item?.simulationPartTypeMonths?.length || 0) + 1;

      const partTypeData = {
        partType: item.partType,
        removeTransferButtons: item.removeTransferButtons,
        parentId: item.id,
        parentTotalCost: item.totalCost,
        parentTotalGap: item.totalGap,
        parentPartTypeMaxScore: item.partTypeMaxScore,
        parentCurrentScore: item.currentScore,
        parentMaxScore: item.maxScore,
        parentCostPerPoint: item.costPerPoint,
      };

      newArr.push({
        id: `button-${item.id}`,
        buttonRow: true,
        typeRowSpan: rowSpanCount,
        simulationPartTypeMonths: item.simulationPartTypeMonths,
        ...partTypeData,
      });

      newArr.push(
        ...item.simulationPartTypeMonths.map((month) => ({
          typeRowSpan: rowSpanCount,
          ...month,
          ...partTypeData,
        }))
      );

      return newArr;
    });

    return processedData;
  };

  const hidePricesFromData = (dataSimulation) => {
    const dataClone = structuredClone(dataSimulation);
    const keysToKeep = [
      'id',
      'simulationPartTypes',
      'simulationPartTypeMonths',
      'errors',
      'deprecated',
      'removeTransferButtons',
      'partType',
      'month',
      'editedFob',
      'editedBr',
      'disabled',
      'currentScore',
      'partTypeMaxScore',
      'maxScore',
    ];

    //result
    Object.entries(dataClone)?.forEach(([key]) => {
      if (!keysToKeep.includes(key)) {
        dataClone[key] = '*';
      }
    });

    //partType
    dataClone?.simulationPartTypes?.forEach((partType) => {
      Object.entries(partType)?.forEach(([ptKey]) => {
        if (!keysToKeep.includes(ptKey)) {
          partType[ptKey] = '*';
        }
      });

      //month
      partType?.simulationPartTypeMonths?.forEach((month) => {
        Object.entries(month)?.forEach(([monthKey]) => {
          if (!keysToKeep.includes(monthKey)) {
            month[monthKey] = '*';
          }
        });
      });
    });

    return dataClone;
  };

  const refreshSimulation = async () => {
    try {
      setIsRefreshing(true);
      setIsPollingEnable(false);
      const response = await api.post(
        getURI(`scenarios/${selectedScenario}/simulation/v2/refresh`)
      );

      if (response.status === 200) {
        const content = response.data;
        if (content?.children?.length) {
          setResultData(content);
          setData(content.children);

          const hideData = hidePricesFromData(content);
          setResultDataHidden(hideData);
          setDataHidden(hideData.children);
        } else {
          const allParams = filterAndUrlParams(params);
          // eslint-disable-next-line no-use-before-define
          await fetchSimulation(allParams);
        }

        setIsRefreshing(false);
        oneAlert('success', t('toast.successOnSave'));
      } else if (response.status === 204) {
        /* Por definição, quando o status 204 é retornado significa que a atualização está em andamento */
        return;
      } else {
        throw Error();
      }
    } catch (error) {
      oneAlert('error', getErrorMessage(error) || t('toast.errorOnSave'));
      setIsRefreshing(false);
    } finally {
      setIsPollingEnable(true);
    }
  };

  const fetchConfiguration = async (id) => {
    try {
      const { data: content } = await api.get(getURI(`scenarios/${id}/optimizationSetup/`));
      setIsOnGoing(
        !content.componentWithQualificationOngoing
          ? false
          : content.componentWithQualificationOngoing
      );
    } catch (error) {
      console.log('Error fetch configuration Qualification ongoing', error);
    }
  };

  const fetchSimulation = async (allParams) => {
    const getParams = { ...allParams }; // copy obj for temporary replace
    if (getParams && !!getParams.scenario) {
      setIsLoading(true);

      if (scenariosOptions.length > 0) {
        const getScenario = scenariosOptions.find((item) => item.value === allParams.scenario);
        setScenarioName(getScenario?.label);
      }
      setSelectedScenario(allParams.scenario);
      try {
        const { data: content } = await api.get(
          getURI(`scenarios/${getParams.scenario}/simulation/v3`, getParams)
        );

        fetchConfiguration(content.id);
        setIsPollingEnable(true);

        /*** Caso cenário desatualizado, atualiza ***/
        if (content.deprecated) {
          return refreshSimulation();
        }

        if (getParams.scenario && getParams.targetYear) {
          localStorage.setItem('scenario', getParams.scenario);
          localStorage.setItem('targetYear', getParams.targetYear);
        }

        if (content?.simulationPartTypes?.length) {
          const contentTable = simulationForTable(content?.simulationPartTypes);

          setResultData(content);

          setData(contentTable);
          const hideData = hidePricesFromData(content);
          const contentTableHidden = simulationForTable(hideData?.simulationPartTypes);

          setResultDataHidden(hideData);
          setDataHidden(contentTableHidden);
        } else {
          setResultData(content);
        }
      } catch (error) {
        console.log('Error on the fetch Simulation', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchSimulationParams = async () => {
    await fetchSimulation(filterAndUrlParams(params));
  };

  const handleFobBr = async (fobBr, row) => {
    const postData = [];

    row?.simulationPartTypeMonths?.forEach((month) => {
      if (!month.disabled) {
        const forecast = parseInt(month.userForecast, 10);
        if (fobBr === 'br') {
          postData.push({
            partType: row.partType,
            month: month.month,
            fob: '0',
            br: forecast.toString(),
          });
        } else {
          postData.push({
            partType: row.partType,
            month: month.month,
            fob: forecast.toString(),
            br: '0',
          });
        }
      }
    });

    try {
      setIsLoading(true);

      const postParms = {
        project: params.project,
        partType: params.partType,
        targetYear: params.targetYear,
        local: fobBr,
      };

      const { data: content } = await api.post(
        getURI(`scenarios/${selectedScenario}/simulation/v2`, postParms),
        postData
      );

      if (content?.simulationPartTypes?.length) {
        const contentTable = simulationForTable(content?.simulationPartTypes);

        setResultData(content);
        setData(contentTable);

        const hideData = hidePricesFromData(content);
        const contentTableHidden = simulationForTable(hideData?.simulationPartTypes);

        setResultDataHidden(hideData);
        setDataHidden(contentTableHidden);
      } else {
        setResultData(content);
      }

      if (content.errors && content.errors.length) {
        oneAlert('info', arrayToStringMessage(content.errors));
      } else {
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      if (typeof error?.errorResponse?.message === 'string') {
        oneAlert('error', error.errorResponse.message);
      } else {
        oneAlert('error', t('toast.errorOnSave'));
      }
    }
    setIsLoading(false);
  };

  /*** TABLE ***/

  const handleSaveCell = async (newRow, dataIndex) => {
    try {
      setIsLoading(true);
      const forecast = parseInt(newRow.userForecast, 10);
      let fob = parseInt(newRow.kcFob, 10);
      let br = parseInt(newRow.kcBr, 10);

      if (forecast > 0) {
        if (dataIndex[0] === 'kcBr') {
          fob = forecast >= br ? forecast - br : 0;
        } else {
          br = forecast >= fob ? forecast - fob : 0;
        }
      } else {
        oneAlert('error', t('toast.errorOnSave'));
        return;
      }

      const postData = [
        {
          partType: newRow.partType,
          month: newRow.month,
          fob,
          br,
        },
      ];

      const postParms = {
        project: params.project,
        partType: params.partType,
        targetYear: params.targetYear,
      };

      const { data: content } = await api.post(
        getURI(`scenarios/${selectedScenario}/simulation/v2`, postParms),
        postData
      );

      if (content?.simulationPartTypes?.length) {
        const contentTable = simulationForTable(content?.simulationPartTypes);

        setResultData(content);
        setData(contentTable);

        const hideData = hidePricesFromData(content);
        const contentTableHidden = simulationForTable(hideData?.simulationPartTypes);

        setResultDataHidden(hideData);
        setDataHidden(contentTableHidden);
      } else {
        setResultData(content);
      }

      if (content.errors && content.errors.length) {
        oneAlert('info', arrayToStringMessage(content.errors));
      } else {
        oneAlert('success', t('toast.successOnSave'));
      }
    } catch (error) {
      if (typeof error?.errorResponse?.message === 'string') {
        oneAlert('error', error.errorResponse.message);
      } else {
        oneAlert('error', t('toast.errorOnSave'));
      }
    }

    setIsLoading(false);
  };

  // const handleGetProjectName = ()=>{
  //   return localStorage.getItem('project');
  // }

  const EditableContext = React.createContext(null);

  function EditableRow({ index, ...props }) {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  }

  function EditableCell({ title, editable, dataIndex, record, row, children, ...restProps }) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    const toggleEdit = (action) => {
      if (row) {
        const edit = !editing;
        setEditing(edit);
        form.setFieldsValue({
          [dataIndex]: row[dataIndex],
        });
      }
    };

    const save = async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        const values = await form.validateFields();
        toggleEdit();
        await handleSaveCell({ ...row, ...values }, dataIndex);
      } catch (error) {
        console.log('Save failed:', error);
      }
    };

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={toggleEdit}
            onKeyDown={keyDownA11yEsc(toggleEdit)}
          />
        </Form.Item>
      ) : (
        <div
          tabIndex="0"
          role="button"
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
          onKeyDown={keyDownA11y(toggleEdit)}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }

  const rowClassName = (row, index) => {
    // There is a space separator between these classes.
    const month = row.month ? ` month-${row.month.toLowerCase()}` : '';
    const disabled = row.disabled ? ` table-row-disabled${month}` : '';
    const tableDivisor = row.userForecast ? ` table-divisor-test` : '';
    const rowPartType = row.partType.includes('Part Type') ? ` row-parttype` : '';
    const rowColor = index % 2 === 0 ? ' dark-row' : ' light-row';

    if (row.buttonRow) {
      return `table-row-button${month}${disabled}${tableDivisor}${rowPartType}${rowColor}`;
    }

    if (row.partType) {
      return `table-row-componentType${month}${disabled}${tableDivisor}${rowPartType}${rowColor}`;
    }
  };

  const formatScenariosToSelect = (scenariosProps) => {
    const filteredScenarios = scenariosProps.filter(
      (scenario) =>
        (scenario.id ?? scenario?.simulation?.score) && scenario?.name && scenario?.version
    );

    return parseScenarios(filteredScenarios);
  };

  const formatProjectToSelect = (projectsProps) => {
    if (projectsProps.length > 0) {
      return formatToSelect(projectsProps, 'project', true);
    }
    return [];
  };

  const formatCategoriesToSelect = (categoriesProps) => {
    if (categoriesProps.length > 0) {
      return formatToSelect(categoriesProps, 'name', true);
    }
    return [];
  };

  const formatYearsToSelect = (yearsProps) => {
    if (yearsProps.length > 0) {
      return yearsProps.map((year) => {
        return {
          label: year,
          value: year,
        };
      });
    }
    return [];
  };

  const getSelectScenarioName = (scenarioId) => {
    const selectedScenarioName = scenariosOptions.find((scenario) => {
      return scenario.value === scenarioId;
    });

    return selectedScenarioName?.name;
  };

  const fetchDefaultFilters = async () => {
    try {
      setIsLoading(true);
      const { data: defaultFiltersData, status: defaultFiltersStatus } = await api.get(
        getURI('/scenarios/header/simulation/v3', {
          cm: params.cm,
          product: params.product,
        })
      );

      if (defaultFiltersStatus === 200) {
        setProjects(formatProjectToSelect(defaultFiltersData.projectList));
        setCategories(formatCategoriesToSelect(defaultFiltersData.categoryList));
        setTargetYears(formatYearsToSelect(defaultFiltersData.targetYearList));

        const formattedScenarios = formatScenariosToSelect(defaultFiltersData.scenarioDtoList);
        if (formattedScenarios.length) {
          const scenarioExist = formattedScenarios.find(
            (scenario) => scenario.value === localScenario
          );

          const temporaryScenario = params;

          if (!localScenario || !scenarioExist) {
            temporaryScenario.scenario = formattedScenarios[0]?.value;
            localStorage.setItem('scenario', temporaryScenario.scenario);
            localStorage.setItem('targetYear', temporaryScenario.targetYear);
          } else {
            temporaryScenario.scenario = localScenario;
          }

          const getScenario = formattedScenarios.find(
            (item) => item.value === temporaryScenario.scenario
          );

          setScenarioName(getScenario?.label);

          setScenariosOptions(formattedScenarios);
          await fetchSimulation(temporaryScenario);
        }
      }
      if (defaultFiltersStatus === 204) {
        setProjects([]);
        setCategories([]);
        setTargetYears([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
      setIsFirstRendering(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const { data: filtersData, status: defaultFiltersStatus } = await api.get(
        getURI('/scenarios/header/simulation/v3', {
          cm: params.cm,
          product: params.product,
          targetYear: params.targetYear,
          scenario: params.scenario,
          name: getSelectScenarioName(params.scenario),
        })
      );

      if (defaultFiltersStatus === 200) {
        setProjects(formatProjectToSelect(filtersData.projectList));
        setCategories(formatCategoriesToSelect(filtersData.categoryList));
      }
      if (defaultFiltersStatus === 204) {
        setCategories([]);
        setProjects([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };
  const fetchCategories = async () => {
    try {
      const { data: filtersData, status: defaultFiltersStatus } = await api.get(
        getURI('/scenarios/header/simulation/v3', {
          cm: params.cm,
          product: params.product,
          project: params.project,
          targetYear: params.targetYear,
          scenario: params.scenario,
          name: getSelectScenarioName(params.scenario),
        })
      );

      if (defaultFiltersStatus === 200) {
        setCategories(formatCategoriesToSelect(filtersData.categoryList));
      }
      if (defaultFiltersStatus === 204) {
        setCategories([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchTargetYears = async () => {
    try {
      const { data: filtersData, status: defaultFiltersStatus } = await api.get(
        getURI('/scenarios/header/simulation/v3', {
          cm: params.cm,
          product: params.product,
        })
      );

      if (defaultFiltersStatus === 200) {
        setTargetYears(formatYearsToSelect(filtersData.targetYearList));
        setScenariosOptions(formatScenariosToSelect(filtersData.scenarioDtoList));
        setProjects(formatProjectToSelect(filtersData.projectList));
        setCategories(formatCategoriesToSelect(filtersData.categoryList));
      }
      if (defaultFiltersStatus === 204) {
        setScenariosOptions([]);
        setTargetYears([]);
        setProjects([]);
        setCategories([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchScenarios = async () => {
    try {
      const { data: filtersData, status: defaultFiltersStatus } = await api.get(
        getURI('/scenarios/header/simulation/v3', {
          cm: params.cm,
          product: params.product,
          targetYear: params.targetYear,
        })
      );

      if (defaultFiltersStatus === 200) {
        setScenariosOptions(formatScenariosToSelect(filtersData.scenarioDtoList));
        setProjects(formatProjectToSelect(filtersData.projectList));
        setCategories(formatCategoriesToSelect(filtersData.categoryList));
      }
      if (defaultFiltersStatus === 204) {
        setScenariosOptions([]);
        setCategories([]);
        setProjects([]);
      }
    } catch (error) {
      oneAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const columns = [
    {
      title: t('pages.simulation.fieldsHeader.fgDemand'),
      align: 'center',
      className: 'table-divisor',
      children: [
        {
          key: 'partType',
          dataIndex: 'partType',
          title: t('pages.simulation.fieldsHeader.partType'),
          className: 'tr-parttype',
          render: (type, row) => {
            const obj = {
              children: <PartTypeSimulation type={type} row={row} />,
              props: {},
            };

            if (row.typeRowSpan) {
              obj.props.rowSpan = row.buttonRow ? row.typeRowSpan : 0;
            }
            return obj;
          },
        },
        {
          key: 'month',
          dataIndex: 'month',
          title: t('pages.simulation.fieldsHeader.month'),
          align: 'center',
          render: (value, row) => {
            return !row.buttonRow ? t(`common.months.${value}`) : null;
          },
        },
        {
          key: 'baseForecast',
          dataIndex: 'baseForecast',
          title: t('pages.simulation.fieldsHeader.base'),
          align: 'center',
          render: (value) => {
            return <span>{value?.toLocaleString(t('common.localeMoney'))}</span>;
          },
        },
        {
          key: 'userForecast',
          dataIndex: 'userForecast',
          title: t('pages.simulation.fieldsHeader.user'),
          align: 'center',
          className: 'table-divisor',
          render: (value) => {
            return <span>{value?.toLocaleString(t('common.localeMoney'))}</span>;
          },
        },
      ],
    },
    {
      title: t('pages.simulation.fieldsHeader.kc'),
      className: 'table-divisor',
      children: [
        {
          key: 'kcFob',
          dataIndex: 'kcFob',
          title: ' ',
          align: 'center',
          className: 'td-user-fob',
          render: (value, row) => {
            if (row.buttonRow) {
              if (row.removeTransferButtons) return;

              const isPartType = row.partType.includes('Part Type');
              if (isPartType) return;

              return {
                children: (
                  <S.ButtonTransfer
                    type="primary"
                    block
                    disabled={editingInput}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFobBr('fob', row);
                    }}
                  >
                    {`FOB <<`}
                  </S.ButtonTransfer>
                ),
                props: {
                  align: 'center',
                },
              };
            }

            return {
              children: value.toLocaleString(t('common.localeMoney')),
              props: {
                row,
                editable: !row.disabled,
                dataIndex: ['kcFob'],
                title: 'FOB',
                align: 'center',
              },
            };
          },
        },
        {
          key: 'kcBr',
          dataIndex: 'kcBr',
          title: ' ',
          align: 'center',
          className: 'table-divisor td-user-br',
          render: (value, row) => {
            if (row.buttonRow) {
              if (row.removeTransferButtons) return;

              const isPartType = row.partType.includes('Part Type');
              if (isPartType) return;

              return {
                children: (
                  <S.ButtonTransfer
                    type="primary"
                    block
                    disabled={editingInput}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFobBr('br', row);
                    }}
                  >
                    {`>> BR`}
                  </S.ButtonTransfer>
                ),
                props: {
                  align: 'center',
                },
              };
            }

            return {
              children: value.toLocaleString(t('common.localeMoney')),
              props: {
                row,
                editable: !row.disabled,
                dataIndex: ['kcBr'],
                title: 'BR',
                align: 'center',
              },
            };
          },
        },
      ],
    },
    {
      title: '',
      children: [
        {
          key: 'ppb',
          dataIndex: 'ppb',
          title: t('pages.simulation.fieldsHeader.PPB'),
          align: 'center',
          width: 60,
          render: (value, row) => !row.buttonRow && renderDecimal(value),
        },
        {
          key: 'currentScore',
          dataIndex: 'currentScore',
          title: t('pages.simulation.fieldsHeader.currentScore'),
          align: 'center',
          width: 60,
          render: (currentScore, row) => {
            if (!row.buttonRow)
              return (
                <S.TotalScoreContainer>
                  {row.scoreStatus === 'critical' && (
                    <S.CriticalScore>
                      <ExclamationCircleFilled />
                      {` ${renderDecimal(currentScore)} `}
                    </S.CriticalScore>
                  )}
                  {row.scoreStatus === 'high' && (
                    <S.HighScore>
                      <WarningFilled />
                      {` ${renderDecimal(currentScore)} `}
                    </S.HighScore>
                  )}
                  {row.scoreStatus === 'good' && (
                    <S.GoodScore>
                      <CheckCircleFilled />
                      {` ${renderDecimal(currentScore)} `}
                    </S.GoodScore>
                  )}
                  {row.scoreStatus === 'N/A' && (
                    <S.NotApplicable>{` ${renderDecimal(currentScore)} `}</S.NotApplicable>
                  )}
                  {!row.scoreStatus && (
                    <S.NotApplicable>{` ${renderDecimal(currentScore)} `}</S.NotApplicable>
                  )}
                  <span>| </span>
                  <SC.ScoreColorSpanNew>{` ${renderDecimal(row?.maxScore)} `}</SC.ScoreColorSpanNew>
                </S.TotalScoreContainer>
              );
          },
        },
        {
          key: 'totalGap',
          dataIndex: 'totalGap',
          title: t('pages.simulation.fieldsHeader.totalGap'),
          align: 'center',
          width: 110,
          render: (value, row) => (!row.buttonRow ? renderPrice(value, false) : null),
        },
        {
          key: 'totalCost',
          dataIndex: 'totalCost',
          title: t('pages.simulation.fieldsHeader.totalCost'),
          align: 'center',
          width: 110,
          render: (value, row) => (!row.buttonRow ? renderPrice(value, false) : null),
        },
      ],
    },
  ];

  /*** FILTER ***/

  const handleFilter = (type, value) => {
    setParams((prevParams) => {
      return { ...prevParams, [type]: value };
    });
    if (type === 'project') {
      if (value === null) {
        return localStorage.removeItem('project');
      }
      return localStorage.setItem('project', value);
    }

    if (type === 'partType') {
      if (value === null) {
        return localStorage.removeItem('partType');
      }
      return localStorage.setItem('partType', value);
    }

    if (type === 'scenario') {
      if (value === null) {
        localStorage.removeItem('scenario');
        return localStorage.removeItem('scenarioName');
      }
      localStorage.setItem('scenario', value);
      return localStorage.setItem('scenarioName', getSelectScenarioName(value));
    }

    if (type === 'targetYear') {
      if (value === null) {
        return localStorage.removeItem('targetYear');
      }
      return localStorage.setItem('targetYear', value);
    }

    if (type === 'product') {
      if (value === null) {
        return localStorage.removeItem('productName');
      }
      return localStorage.setItem('productName', value);
    }
  };

  const clearAllFilters = () => {
    setParams({
      product: 'Notebook',
      cm: 'Foxconn',
      project: null,
      partType: null,
      targetYear: null,
      scenario: null,
    });
    localStorage.removeItem('project');
    localStorage.removeItem('partType');
    localStorage.removeItem('scenario');
    localStorage.removeItem('scenarioName');
    localStorage.removeItem('targetYear');
  };

  const columnWithQualification = () => {
    const positionToShowColumnQualification = 2;
    if (isOngoing) {
      const qualifiColumn = {
        key: 'ppb',
        dataIndex: 'ppb',
        title: t('pages.simulation.fieldsHeader.qualification'),
        align: 'center',
        width: '0.5rem',
        render: (value, row) =>
          !row.buttonRow &&
          row.qualificationOngoing && (
            <StyledIndicationTag
              clickable
              text="QG"
              onClick={() => {
                setAvlcIds(row.avlcIds);
                openModalQG();
              }}
            />
          ),
      };
      columns[positionToShowColumnQualification].children.unshift(qualifiColumn);

      return columns;
    }
    return columns;
  };

  //no lugar do websocket foi utilizado polling.
  const pollingData = async () => {
    const allParams = filterAndUrlParams(params);
    const { data: deprecatedData } = await api.get(`/scenarios/${allParams.scenario}/deprecated`);
    //se o cenário estiver desatualizado, atualizamos
    if (deprecatedData.deprecated) {
      refreshSimulation();
    }
  };

  const startPolling = () => {
    //a cada 10 segundos, verificamos se o cenário atual está desatualizado
    timeId.current = setInterval(pollingData, 10000);
  };

  const stopPolling = () => {
    clearInterval(timeId.current);
  };

  const updatePolling = () => {
    if (isPollingEnable) {
      startPolling();
    } else {
      stopPolling();
    }
  };

  useEffect(() => {
    updatePolling();
    return () => {
      stopPolling();
    };
  }, [resultData, isPollingEnable]);

  useEffect(() => {
    if (!isFirstRendering) {
      setParams((prev) => {
        return {
          ...prev,
          project: null,
          partType: null,
          targetYear: null,
          scenario: null,
        };
      });
      fetchTargetYears();
    }
  }, [params.cm, params.product]);

  useEffect(() => {
    if (!isFirstRendering) {
      setParams((prev) => {
        return {
          ...prev,
          partType: null,
        };
      });
      fetchCategories();
    }
  }, [params.project]);

  useEffect(() => {
    setParams((prev) => {
      return {
        ...prev,
        project: null,
        partType: null,
      };
    });
    fetchProjects();
  }, [params.scenario]);

  useEffect(() => {
    if (!isFirstRendering) {
      setParams((prev) => {
        return {
          ...prev,
          scenario: null,
          project: null,
          partType: null,
        };
      });
      fetchScenarios();
    }
  }, [params.targetYear]);

  useEffect(() => {
    fetchDefaultFilters();
  }, []);

  useEffect(() => {
    if (isFirstRendering)
      setParams((prev) => {
        return {
          ...prev,
          targetYear: localTargetYear || params.targetYear,
          scenario: localScenario || params.scenario,
          scenarioName: localScenario || params.scenarioName,
        };
      });
  }, [localScenario, localTargetYear]);

  return {
    hiddenPrices,
    isRefreshing,
    dataHidden,
    resultDataHidden,
    scenariosOptions,
    scenarioName,
    scenarioId: localScenario,
    scenarioYear: localTargetYear,
    data,
    resultData,
    isLoading,
    params,
    columns,
    visibleQG,
    avlcIds,
    HiddenPricesForm,
    refreshSimulation,
    fetchSimulation,
    fetchSimulationParams,
    fetchScenarios,
    rowClassName,
    EditableCell,
    EditableRow,
    closeModalQG,
    columnWithQualification,
    products,
    cms,
    handleFilter,
    projects,
    targetYears,
    categories,
    clearAllFilters,
  };
}
